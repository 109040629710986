<template>
  <div>
    <div class="signin-title">
      <img src="@/assets/logo.svg" alt="ekyc main logo" />
    </div>
    <form
      :class="{
        'signin-form default flex-center': !error,
        'signin-form error flex-center': error,
      }"
      :key="`${selectedFindType}-selected`"
    >
      <div class="select-find-type flex-center">
        <div
          :class="
            `${selectedFindType == 0 ? 'selected' : 'unselected'} flex-center`
          "
          @click="selectedFindType = 0"
        >
          아이디 찾기
        </div>
        <div
          :class="
            `${selectedFindType == 1 ? 'selected' : 'unselected'} flex-center`
          "
          @click="selectedFindType = 1"
        >
          비밀번호 찾기
        </div>
      </div>
      <div style="width: 318px; padding-bottom: 16px">
        <CustomInputTextForm
          validity="client-comp-name"
          placeholder="고객사명을 입력하세요."
          @set="
            $event => {
              customerInput = $event.val;
              customerInputChecked = $event.res;
              customerInputValue = $event.compid;
            }
          "
          :comp-name-to-code="true"
          :init-val="customerInput"
          :no-title="true"
          :for-login="true"
          :info-font-small="true"
        />
      </div>

      <div
        v-if="selectedFindType == 1"
        style="width: 318px; padding-bottom: 16px"
      >
        <CustomInputTextForm
          validity="individual-id"
          placeholder="아이디"
          @click="checkRedundancy()"
          @set="
            $event => {
              userIdInput = $event.val;
            }
          "
          :init-val="userIdInput"
          :no-title="true"
          :for-login="true"
          :info-font-small="true"
        />
      </div>
      <div style="width: 318px; padding-bottom: 16px">
        <CustomInputTextForm
          validity="name"
          placeholder="이름"
          @click="checkRedundancy()"
          @set="
            $event => {
              usernameInput = $event.val;
            }
          "
          :init-val="usernameInput"
          :no-title="true"
          :for-login="true"
          :info-font-small="true"
        />
      </div>
      <div style="width: 318px; padding-bottom: 16px">
        <CustomInputTextForm
          validity="email"
          placeholder="이메일"
          @set="
            $event => {
              emailInput = $event.val;
            }
          "
          :init-val="emailInput"
          :no-title="true"
          :for-login="true"
          :info-font-small="true"
        />
      </div>
      <!-- <div v-if="error">{{ error }}</div> -->
      <div stlye="position:relative;">
        <div style="display: flex; flex-direction: row; width: 318px"></div>
        <div v-if="error" class="critical-error">
          {{ error }}
        </div>
      </div>

      <div
        v-show="enableNext && !isLoadingOn"
        @click="find()"
        class="signin-form-btn ok"
      >
        다음
      </div>
      <div v-show="!enableNext && !isLoadingOn" class="signin-form-btn default">
        다음
      </div>
      <div v-show="isLoadingOn" class="signin-form-btn ok">
        <img src="@/assets/Loading_40px.svg" alt="loading animation" />
      </div>
    </form>
  </div>
</template>
<script>
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
import server from '@/api/server';
import collision from '@/store/mixins/collision.js';
export default {
  mixins: [collision],
  name: 'FindCridential',
  components: {
    CustomInputTextForm,
  },
  data() {
    return {
      selectedFindType: 0,
      customerInput: '',
      customerInputChecked: false,
      customerInputValue: '',
      usernameInput: '',
      emailInput: '',
      userIdInput: '',
      error: '',
      isLoadingOn: false,
    };
  },
  mounted() {
    this.otherComponent = document.querySelector('.signin-form');
    this.isCollide();
  },
  updated() {
    this.otherComponent = document.querySelector('.signin-form');
    this.isCollide();
  },
  created() {
    if (this.$route.query.type == 1) {
      this.selectedFindType = 1;
    } else {
      this.selectedFindType = 0;
    }
  },
  computed: {
    enableNext() {
      if (this.selectedFindType == 0)
        return (
          this.usernameInput.length > 0 &&
          this.emailInput.length > 0 &&
          this.customerInputChecked
        );
      else if (this.selectedFindType == 1)
        return (
          this.usernameInput.length > 0 &&
          this.emailInput.length > 0 &&
          this.customerInputChecked &&
          this.userIdInput.length > 0
        );
      return false;
    },
  },
  methods: {
    async find() {
      var data = null;
      switch (this.selectedFindType) {
        case 0:
          this.$log.debug('FindCridential#find~api:findReviewerId', data);
          try {
            data = await server.findReviewerId({
              customer_id: Number(this.customerInputValue),
              name: this.usernameInput,
              email: this.emailInput,
            });
            this.$emit('result', {
              type: 10,
              payload: { userid: data.username },
            });
          } catch (e) {
            if (e.errorCode == 'E532') {
              this.$emit('result', { type: 11, payload: {} });
            } else {
              this.$store.state.errorCode = e.errorCode ? `${e.errorCode}` : e;
              this.$store.state.serverError = true;
            }
          }
          break;
        case 1:
          try {
            this.isLoadingOn = true;
            data = await server
              .findReviewerPassword({
                customer_id: Number(this.customerInputValue),
                name: this.usernameInput,
                email: this.emailInput,
                username: this.userIdInput,
              })
              .then(() => {
                this.isLoadingOn = false;
              });
            this.$emit('result', {
              type: 12,
              payload: {
                userEmail: this.emailInput,
                customerId: Number(this.customerInputValue),
                userName: this.usernameInput,
                userId: this.userIdInput,
              },
            });
          } catch (e) {
            if (e.errorCode == 'E532') {
              this.$emit('result', { type: 13, payload: {} });
            } else {
              //TODO: 메일발송 실패시 재발송용 다이얼로그 추가 및 핸들링
              this.isLoadingOn = false;
              this.$emit('mailError');
            }
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.signin-title {
  font-size: 32px;

  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  color: var(--surface-100);
}
.select-find-type {
  color: var(--primary-80);
  margin: 38px 0 32px 0;
  width: 320px;
  height: 48px;
}
.select-find-type > div {
  width: 160px;
  height: 48px;
  cursor: pointer;
}
.select-find-type > .selected {
  color: var(--primary-80);
  border-bottom: 2px solid var(--primary-80);
}
.select-find-type > .unselected {
  color: var(--surface-disabled);
  border-bottom: 2px solid var(--surface-disabled);
}
</style>
