<template>
  <div class="view-container">
    <div class="content-container">
      <div class="types-container">
        <div style="display: flex; flex-direction: row">
          <div
            v-for="(sType, sIndex) in settingTypes"
            :key="sType"
            :class="
              `types-text types-text${
                sIndex === selectedTypeIndex ? '-selected' : '-unselected'
              }`
            "
            @click="selectSettingType(sIndex)"
          >
            {{ sType }}
          </div>
        </div>
        <UpsideEnrollButton @click="enroll" :button-string="`+등록`" />
      </div>
      <div class="list-table-container">
        <PicaTable
          v-show="selectedTypeIndex == 0"
          :table-name="'pica'"
          :titles="TABLE.PICA.SIZE"
          :titleNames="TABLE.PICA.NAME"
          :contents="picaList()"
          @picaPpRemove="picaPpRemove($event)"
          @picaPpEdit="picaPpEdit($event)"
          :key="`${selectedTypeIndex}-pica`"
          :no-data-messages="['항목이 없습니다.']"
        />
        <PpgTable
          v-show="selectedTypeIndex == 1"
          :table-name="'ppg'"
          :titles="TABLE.PPG.SIZE"
          :titleNames="TABLE.PPG.NAME"
          :contents="ppList()"
          @picaPpRemove="picaPpRemove($event)"
          @picaPpEdit="picaPpEdit($event)"
          :key="`${selectedTypeIndex}-pp`"
          :no-data-messages="['항목이 없습니다.']"
        />
        <Pagenation
          :totalCount="selectedTypeIndex == 0 ? picaListLength : ppListLength"
          @update="showPaPpList($event)"
          :key="`${selectedTypeIndex}-page`"
        />
      </div>
    </div>
    <ReviseDialog
      v-if="revisionFlag"
      v-model="revisionFlag"
      :title="'약관 등록'"
      @close="revisionFlag = false"
      :key="`${revisionFlag}-revise`"
    /><EditDialog
      v-if="editFlag"
      v-model="editFlag"
      :ppKey="ppKeyValue"
      :ppContent="ppContent"
      :title="'약관 편집'"
      :content-title-one="'약관 Key'"
      :content-title-two="'약관 내용 (JSON)'"
      @close="editFlag = false"
      :key="`${editFlag}-edit`"
    />
    <RemoveDialog
      v-if="removeFlag"
      v-model="removeFlag"
      :ppKey="ppKeyValue"
      @close="removeFlag = false"
      :key="`${removeFlag}-remove`"
    />
  </div>
</template>

<script>
import UpsideEnrollButton from '@/components/UpsideEnrollButton.vue';
import PicaTable from '@/components/Table.vue';
import PpgTable from '@/components/Table.vue';
import ReviseDialog from '@/components/root/dialog/PicaPpDialog.vue';
import EditDialog from '@/components/root/dialog/PicaPpEditDialog.vue';
import Pagenation from '@/components/Pagenation.vue';
import RemoveDialog from '@/components/root/dialog/RemoveDialog.vue';
import Vue from 'vue';
import TABLE from '@/table.js';
export default {
  name: 'TermsGuidanceManagement',
  components: {
    UpsideEnrollButton,
    PicaTable,
    PpgTable,
    Pagenation,
    ReviseDialog,
    EditDialog,
    RemoveDialog,
  },
  data() {
    return {
      selectedTypeIndex: 0,
      revisionFlag: false,
      editFlag: false,
      removeFlag: false,
      TABLE: TABLE,
      ppKeyValue: null,
      ppContent: null,
      redundancy: undefined,
      pageStartIndex: 0,
    };
  },
  async created() {
    await this.$store.dispatch('getPicaPpList');
  },
  destroyed() {
    this.$store.state.root.picaPpList = [];
    this.$store.state.root.picaPpDetail = {};
  },
  computed: {
    settingTypes() {
      return ['개인정보수집동의서', '개인정보처리방침'];
    },

    picaPpDetail() {
      return this.$store.state.root.picaPpDetail;
    },
    ppListLength() {
      return this.$store.state.root.picaPpList.filter(v =>
        v.key.includes('-pp-')
      ).length;
    },
    picaListLength() {
      return this.$store.state.root.picaPpList.filter(v =>
        v.key.includes('-pa-')
      ).length;
    },
  },
  methods: {
    async selectSettingType(index) {
      this.pageStartIndex = 0;
      await this.$store.dispatch('getPicaPpList');
      if (this.selectedTypeIndex !== index) {
        this.selectedTypeIndex = index;
      }
    },
    showPaPpList(event) {
      this.$log.debug('TermsGuidanceManagement#showPaPpList~', event);
      this.pageStartIndex = event.start;
    },
    enroll() {
      //use selectedTypeIndex
      this.revisionFlag = true;
      Vue.$log.debug('TermsGuidanceManagement#enroll');
    },
    picaPpRemove(event) {
      Vue.$log.debug('TermsGuidanceManagement#picaRemove', event);
      this.ppKeyValue = event;
      this.removeFlag = true;
    },
    ppList() {
      let localPpList = this.$store.state.root.picaPpList.filter(v =>
        v.key.includes('-pp-')
      );
      return localPpList.slice(this.pageStartIndex, this.pageStartIndex + 10);
    },
    picaList() {
      let localPicaList = this.$store.state.root.picaPpList.filter(v =>
        v.key.includes('-pa-')
      );
      return localPicaList.slice(this.pageStartIndex, this.pageStartIndex + 10);
    },
    picaPpEdit(event) {
      Vue.$log.debug('TermsGuidanceManagement#picaPpEdit', event);
      this.ppContent = decodeURIComponent(atob(this.picaPpDetail[event.id]));
      this.ppKeyValue = event.key;
      this.editFlag = true;
    },
  },
};
</script>

<style scoped lang="scss">
.types-container {
  height: 36px;
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.types-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.types-text-selected {
  color: var(--primary-80);
}
.types-text-unselected {
  cursor: pointer;
  color: var(--surface-disabled);
}
.list-table-container {
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
</style>
