<template>
  <div>
    <div v-if="!!selectedItemData" class="eec-container">
      <div class="eec-container-child-info">
        <v-expansion-panels
          class="expansion-panels"
          style="width: 90%; margin: 24px 0 0 4%; z-index: auto"
          v-model="panel"
          multiple
        >
          <v-expansion-panel
            style="width: 100%;margin:0;"
            v-for="(item, i) in moduleData"
            :key="i"
            @click="item.dataOpened = true"
          >
            <v-expansion-panel-header
              :class="{
                'panel--opened': panel.includes(i),
                'panel--closed': !panel.includes(i),
              }"
              expand-icon="expand_more"
              style="line-height: 72px; height: 72px;"
            >
              <svg
                v-if="item.dataOpened == false"
                style="flex: inherit"
                width="20.08"
                height="20.08"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12.0495"
                  cy="12.0495"
                  r="10.0412"
                  fill="var(--surface-disabled)"
                />
                <path
                  d="M7.34425 12.0498L10.7051 15.4107L16.3066 8.68896"
                  stroke="white"
                  stroke-width="1.34435"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                style="flex: inherit"
                v-if="item.dataOpened == true"
                width="20.08"
                height="20.08"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="10" fill="var(--primary-100)" />
                <path
                  d="M7.31409 11.9999L10.6612 15.347L16.2396 8.65283"
                  stroke="white"
                  stroke-width="1.33883"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                style="
                  flex: inherit;
                  margin-left: 25.91px;
                  font-size: 16px;
                  text-decoration-line: underline;
                "
                class="font-500"
              >
                {{ `0${i + 1}` }}
              </div>
              <div
                style="
                  flex: inherit;
                  width: 90%;
                  margin-left: 8px;
                  font-size: 16px;
                  text-align: left;
                "
                class="font-500"
              >
                {{ item.text }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Account
                v-if="item.text === '기존 계좌 인증' && accountContentExists"
                :account-info="selectedItemData"
              />
              <section v-if="item.text === '신분증 인증'" class="eec-expand">
                <DriveJumin
                  v-if="['운전', '주민등록'].some(str => idType.includes(str))"
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <Foreign
                  v-else-if="
                    ['거소', '영주', '외국인'].some(str => idType.includes(str))
                  "
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <ForeignPass
                  v-else-if="idType.includes('외국')"
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <Pass
                  v-else-if="idType.includes('한국')"
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <div style="width: 32px" />
                <section class="eec-expand-half">
                  <div
                    style="margin-top: 24px; position: relative; height: 200px"
                  >
                    <img
                      v-if="!!selectedItemData"
                      @click="openIdCardExpansionDialog"
                      :src="
                        `${
                          isOriginal
                            ? selectedItemData.id_card_origin
                            : selectedItemData.id_card_image
                        }`
                      "
                      width="280px"
                      height="200px"
                      style="border: 10px solid #c4c4c4; cursor: pointer"
                    />
                    <img
                      v-else
                      @click="openIdCardExpansionDialog"
                      src="@/assets/icon-card-guide.svg"
                      width="280px"
                      height="200px"
                      style="border: 10px solid #c4c4c4; cursor: pointer"
                    />
                    <img
                      @click="openIdCardExpansionDialog"
                      src="@/assets/icon-expantion.svg"
                      width="25px"
                      style="
                        position: absolute;
                        top: 0;
                        left: 255px;
                        cursor: pointer;
                      "
                    />
                  </div>
                  <div style="height: 1rem" />
                  <div
                    v-if="
                      selectedItemData.id_card_origin &&
                        idType.replaceAll(' ', '') !== '외국여권' &&
                        !manualInput
                    "
                    class="flex-center"
                  >
                    <SubmitButton
                      v-show="!isOriginal"
                      width="108px"
                      height="36px"
                      bg-color="var(--surface-100)"
                      line-color="var(--gray-200)"
                      button-string="원본 사진 보기"
                      :text-color="'var(--surface-medium)'"
                      style="font-weight: 500"
                      @click="
                        () => {
                          isOriginal = true;
                        }
                      "
                    />
                    <SubmitButton
                      v-show="isOriginal"
                      width="121px"
                      height="36px"
                      bg-color="var(--surface-100)"
                      line-color="var(--gray-200)"
                      button-string="마스킹 사진 보기"
                      :text-color="'var(--surface-medium)'"
                      style="font-weight: 500"
                      @click="
                        () => {
                          isOriginal = false;
                        }
                      "
                    />
                  </div>
                </section>
              </section>
              <FaceAuthModule
                :item="item"
                :itemData="itemData"
                :detailedItemData="selectedItemData"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="eec-container-child-button">
        <div style="position: relative">
          <div v-if="true">
            <div class="eec-button" style="margin-top: 40px">
              <SubmitButton
                :dim="!isAllOpened"
                :line-color="
                  isAllOpened ? 'var(--success-100)' : 'var(--success-30)'
                "
                width="202px"
                height="48px"
                :button-string="'승인'"
                bg-color="var(--success-10)"
                :text-color="'var(--success-100)'"
                :text-dim-color="'var(--success-30)'"
                bg-dim-color="#f8fdfc"
                font-size="16px"
                :img="
                  isAllOpened
                    ? require('@/assets/icon-button-resolve.svg')
                    : require('@/assets/icon-button-resolve-dim.svg')
                "
                @click="updateTheApprovedState(1)"
                style="font-weight: 500"
              />
            </div>

            <div class="eec-button" style="margin-top: 16px">
              <SubmitButton
                :dim="!isAllOpened"
                :line-color="
                  isAllOpened ? 'var(--error-100)' : 'var(--error-30)'
                "
                width="202px"
                height="48px"
                bg-color="rgba(245, 60, 60, 0.1)"
                bg-dim-color="#fff9f9"
                :button-string="'거부'"
                :text-dim-color="'var(--error-30)'"
                :text-color="'var(--error-100)'"
                font-size="16px"
                :img="
                  isAllOpened
                    ? require('@/assets/icon-button-reject.svg')
                    : require('@/assets/icon-button-reject-dim.svg')
                "
                @click="dialogReject = true"
                style="font-weight: 500"
              />
            </div>
          </div>

          <div style="position: absolute; bottom: 33px; left: 30.5px">
            <div style="display: flex; justify-content: center">
              <img src="@/assets/info_20.svg" />
              <div
                style="
                  line-height: 24px;
                  color: rgb(134, 134, 134);
                  font-size: 14px;
                  padding-left: 10.5px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                "
              >
                정보 확인 후 심사를 수행하세요.
              </div>
            </div>
          </div>
        </div>
      </div>
      <IdCardDialog
        v-if="!!selectedItemData"
        v-model="dialogIdCard"
        :image="
          isOriginal
            ? selectedItemData.id_card_origin
            : selectedItemData.id_card_image
        "
      ></IdCardDialog>
      <IdCardDialog
        v-else
        v-model="dialogIdCard"
        :image="require('@/assets/icon-card-guide.svg')"
      />
      <RejectDialog
        v-model="dialogReject"
        @closeRejectDialog="dialogReject = false"
        @refreshList="refreshAfterRejectTable"
        :item-data="itemData"
        :key="`${dialogReject}-reject`"
      />
    </div>
  </div>
</template>

<script>
import IdCardDialog from '@/components/dialog/IdCardDialog';
import RejectDialog from '@/components/dialog/RejectDialog';
import DriveJumin from '@/components/reviewer/evaluation/idcard/DriveJumin';
import Foreign from '@/components/reviewer/evaluation/idcard/Foreign';
import ForeignPass from '@/components/reviewer/evaluation/idcard/ForeignPass';
import Pass from '@/components/reviewer/evaluation/idcard/Pass';
import FaceAuthModule from '@/components/reviewer/evaluation/FaceAuthModule';
import Account from '@/components/reviewer/evaluation/Account';
import Vue from 'vue';
import SubmitButton from '@/components/SubmitButton.vue';
import loadingMixin from '@/store/mixins/loading';

export default {
  mixins: [loadingMixin],
  components: {
    IdCardDialog,
    RejectDialog,
    FaceAuthModule,
    DriveJumin,
    Foreign,
    ForeignPass,
    Pass,
    Account,
    SubmitButton,
  },
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
    detailedItemData: {
      type: Object,
      default: () => {},
    },
    isModifiedData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.moduleData = [];
    const mod = this.$store.state.reviewer.customerModuleInfo;
    if (mod.id_card_ocr && mod.id_card_verification)
      this.moduleData = this.moduleData.concat({
        text: '신분증 인증',
        dataOpened: false,
        dataTillOpened: false,
      });
    if (mod.face_authentication)
      this.moduleData = this.moduleData.concat({
        text: '얼굴 인증',
        dataOpened: false,
        dataTillOpened: false,
      });

    if (mod.account_verification)
      this.moduleData = this.moduleData.concat({
        text: '기존 계좌 인증',
        dataOpened: false,
        dataTillOpened: false,
      });
  },
  data() {
    return {
      dialogIdCard: false,
      dialogReject: false,
      panel: [],
      moduleData: [],
      isOriginal: false,
    };
  },
  computed: {
    isAllOpened() {
      var opened = true;
      this.moduleData.map(res => {
        if (res.dataOpened == false) opened = false;
      });
      return opened;
    },
    selectedItemData() {
      return this.detailedItemData[this.itemData.id];
    },
    accountContentExists() {
      return this.selectedItemData.account_content_exist;
    },
    idType() {
      return this.selectedItemData.id_type;
    },
    manualInput() {
      return this.selectedItemData.is_manual_input;
    },
  },
  methods: {
    openIdCardExpansionDialog() {
      this.$log.debug('EvaluationExpandedConent#openIdCardExpantionDialog');
      this.dialogIdCard = true;
    },
    refreshAfterRejectTable() {
      try {
        this.$emit('afterAudit');
      } catch {
        Vue.$log.debug('EvaluationExpandedContent#refreshAfterRejectTable');
      }
    },
    updateTheApprovedState(isApproved) {
      //TODO: update state of the audit
      this.onLoading();
      try {
        this.$emit('afterAudit');
        this.$store
          .dispatch('setApproveState', {
            id: this.itemData.id,
            isApproved: isApproved,
          })
          .then(res => {
            this.offLoading();
            if (res.result_code == 'N100') {
              Vue.$log.debug(
                'EvaluationExpandedContent#updateTheApprovedState~ 성공적으로 심사를 수행했습니다.'
              );

              this.$store.dispatch('showRegistrationSnackbar', {
                message: '심사 승인이 완료되었습니다.',
              });
            }
          });
      } catch {
        Vue.$log.debug('EvaluationExpandedContent#updateTheApprovedState');
      }
    },
  },
};
</script>

<style lang="scss">
.panel {
  &--opened {
    background: var(--primary-5);
  }
  &--closed {
    background: var(--surface-100);
  }
}
.eec-container {
  display: flex;
}
.eec-expand {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0 0 56px;
  overflow-x: scroll;
}

.eec-expand-half > div > div {
  width: 280px;
}
.eec-flex-row {
  display: flex;
}
.eec-flex-row > div {
}
.eec-flex-row > .label-key {
  display: flex;
  width: 150px;
}
.eec-flex-row > .label-key-list {
  width: 150px;
  color: var(--surface-medium);
}

.eec-flex-row > .label-value {
  width: 130px;
  &--modified {
    width: 130px;
    color: blue;
  }
  &--red {
    width: 130px;
    color: red;
  }
}
.eec-flex-space-between {
  display: flex;
  justify-content: space-between;
}
.eec-container-child-info {
  display: flex;
  flex-direction: column;
  width: 75%;
  flex-grow: 1;
  overflow-y: scroll;
}
.eec-container-child-button {
  display: flex;
  flex-direction: column;
  width: 25%;
  flex-grow: 1;
  background: var(--primary-5);
}

.eec-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  cursor: pointer;
}

.eec-container-child-button > div {
  padding-left: 8px;
  background: var(--surface-100);
  background-clip: content-box;
  height: 100%;
  min-height: 264px;
}

.eec-split-line {
  margin: 48px 0;
  height: 1px;
  background-color: var(--surface-100);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--gray-300);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--gray-300);
}
.v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
  border-top: 1px solid var(--gray-300);
}
.v-expansion-panel-content {
  border-bottom: 1px solid var(--gray-300);
}
</style>
