<template>
  <div
    class="container"
    style="
      padding: 0px 30px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      position: relative;
    "
  >
    <Step :phase="phase" :items="phaseItems" />

    <div
      style="text-align: center; margin-bottom: 2rem; margin: 65px auto 40px"
    >
      <h2 class="bank-title">계좌 인증을 시작합니다.</h2>
      <h2 class="bank-title">본인 명의 계좌를 준비해 주세요.</h2>
      <!-- <label class="bank-label">테스트를 위한 계좌 소유자 입력 창입니다.</label> -->
    </div>
    <div
      style="width: 94px; height: 94px; border-radius: 50%; margin-bottom: 50px"
    >
      <img
        src="@/assets/guide_account.svg"
        width=""
        style="margin-bottom: 51px"
      />
    </div>
    <div class="guide-container">
      <h2 class="bank-title">올바른 인증 TIP</h2>
      <div class="guide-detail" style="margin-top: 1.5rem">
        <div>
          <img src="@/assets/check_24.svg" />
        </div>
        <p class="bank-label">본인 명의의 계좌로만 인증이 가능합니다.</p>
      </div>
      <div class="guide-detail">
        <div>
          <img src="@/assets/check_24.svg" />
        </div>
        <p class="bank-label">
          계좌 인증 유효 시간은 최대<span style="color: #455aff">5분</span
          >입니다.
        </p>
      </div>
      <div class="guide-detail">
        <div>
          <img src="@/assets/check_24.svg" />
        </div>
        <p class="bank-label">가상 계좌번호는 지정할 수 없습니다.</p>
      </div>
    </div>

    <div class="button-container">
      <v-btn
        class="prev"
        color="var(--surface-medium)"
        @click="onClickBack"
        text
      >
        취소
      </v-btn>
      <v-btn
        class="next"
        dark
        :loading="loading"
        color="var(--primary-100)"
        @click="onClickNext"
      >
        계좌 입력
      </v-btn>
    </div>
  </div>
</template>
<script>
import Step from '../auth/Step';

export default {
  components: {
    Step,
  },
  props: {
    phase: Number,
    phaseItems: Array,
    appData: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onClickBack() {
      this.$emit('cancel');
    },
    onClickNext() {
      this.$emit('next');
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
.guide-container {
  .bank-title {
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;

    color: var(--surface-high);
  }
  h2 {
    text-align: start;
  }
  div {
    display: flex;
    width: 100%;
  }
  .guide-detail {
    margin-bottom: 1rem;
    &:first-child {
      margin-top: 1.5rem;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    div {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      img {
        width: 13.33px;
        height: 10px;
      }
    }
    .bank-label {
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.625rem;
      color: var(--surface-high);
    }
  }
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 16px 0px 40px;
  /* height: 116px; */
  cursor: pointer;
  background-color: white;
  /* border-top: 1px solid var(--gray-100); */
  button {
    height: 60px !important;
    border-radius: 8px;
    font-size: 1rem;
  }
  .prev {
    flex: 3.5;
    background-color: #f6f6f6;
    margin-right: 4px;
  }
  .next {
    flex: 6.5;
    color: #fff;
    margin-left: 4px;
    box-shadow: none;
  }
}
</style>
