import { render, staticRenderFns } from "./CardScan.vue?vue&type=template&id=4f12c582&scoped=true&"
import script from "./CardScan.vue?vue&type=script&lang=js&"
export * from "./CardScan.vue?vue&type=script&lang=js&"
import style0 from "./CardScan.vue?vue&type=style&index=0&id=4f12c582&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f12c582",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
installComponents(component, {VFileInput})
