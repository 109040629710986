<template>
  <button
    @click="$emit('click')"
    class="add-button"
    type="submit"
    :style="additionalButtonStyle"
  >
    {{ buttonString }}
  </button>
</template>

<script>
export default {
  name: 'UpsideEnrollButton',
  props: {
    buttonString: {
      type: String,
      default: '+등록',
    },
    bgColor: {
      type: String,
      default: 'var(--primary-80)',
    },
    height: {
      type: Number,
      default: 32,
    },
    width: {
      type: Number,
      default: 62,
    },
  },
  components: {},
  computed: {
    additionalButtonStyle() {
      return {
        'background-color': this.bgColor,
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  methods: {},
};
</script>

<style scoped>
.add-button {
  color: white;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: normal;
  font-style: normal;
  align-self: end;
  outline: none;
}
</style>
