<template>
  <v-dialog
    v-model="value"
    max-width="500px"
    @click:outside="close"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-text
        style="padding: 0; margin: 0;height:800px;position:relative;"
      >
        <header class="diag-title">정보 수정</header>
        <div class="add-body">
          <div>
            <!-- <div class="title-input">고객사명</div> -->
            <CustomInputTextForm
              :error-form="clientCompNameRedundancyMsg.includes('입력하신')"
              :init-val="itemData.company"
              validity="comp-name-redundancy"
              title="고객사명"
              placeholder="고객사명을 입력하세요."
              @click="checkRedundancy()"
              :infoFontSmall="true"
              :needCheck="true"
              @set="
                $event => {
                  clientCompName = $event.val;
                  if (clientCompName == itemData.company) {
                    clientCompCheck = true;
                  } else {
                    clientCompCheck = false;
                  }
                  clientCompNameRedundancyMsg = '';
                }
              "
            />
            <div
              v-if="clientCompNameRedundancyMsg"
              :style="
                `${
                  clientCompNameRedundancyMsg.includes('확인')
                    ? 'color: var(--primary-100)'
                    : 'color: var(--error-100)'
                };font-size:12px;margin-top:8px;`
              "
            >
              {{ clientCompNameRedundancyMsg }}
            </div>
            <div style="height:24px;" />
            <CustomInputTextForm
              :init-val="this.phone"
              validity="phone"
              title="전화번호"
              :infoFontSmall="true"
              placeholder="‘-’를 포함하여 입력하세요."
              @set="
                $event => {
                  phone = $event.val;
                  phoneCheck = $event.res;
                }
              "
            />
            <div class="title-input">구분</div>
            <Dropdown
              style="align-self: center; width: 100%; height: 56px"
              v-if="value"
              v-model="selectedKind"
              :items="kindList"
              icon-size="24px"
              icon-margin="24px"
              pad="0 24px"
              width="100%"
              height="56px"
              border="1px solid var(--gray-300)"
              @change="
                $event => {
                  this.selectedKind = $event;
                }
              "
            ></Dropdown>

            <div class="title-input">구매형태</div>
            <div style="display: flex">
              <button
                :class="
                  purchaseType === '솔루션'
                    ? 'client-generic-button-left--checked'
                    : 'client-generic-button-left'
                "
                @click="purchaseType = '솔루션'"
              >
                솔루션
              </button>
              <button
                :class="
                  purchaseType === 'API'
                    ? 'client-generic-button-right--checked'
                    : 'client-generic-button-right'
                "
                @click="purchaseType = 'API'"
              >
                API
              </button>
            </div>
            <div class="title-input">활성여부</div>
            <div style="display: flex">
              <button
                :class="
                  isActivated === '활성'
                    ? 'client-generic-button-left--checked'
                    : 'client-generic-button-left'
                "
                @click="isActivated = '활성'"
              >
                활성
              </button>
              <button
                :class="
                  isActivated === '비활성'
                    ? 'client-generic-button-right--checked'
                    : 'client-generic-button-right'
                "
                @click="isActivated = '비활성'"
              >
                비활성
              </button>
            </div>
            <div style="margin-top: 8px; display: flex; flex-direction: row">
              <input
                type="checkbox"
                class="activation-check"
                v-model="finalCheck"
              />
              <div style="font-size: 12px; color: var(--primary-100)">
                활성여부를 전활할 경우 서비스에 바로 반영됩니다.
              </div>
            </div>
          </div>
        </div>
        <div style="border-top:1px solid var(--gray-100); ">
          <div class="button-container">
            <div v-ripple @click="close" class="button cancel">취소</div>
            <div
              v-if="
                clientCompName != '' &&
                  clientCompCheck &&
                  purchaseType != '' &&
                  selectedKind != '' &&
                  selectedKind != '선택' &&
                  isActivated != '' &&
                  finalCheck == true &&
                  phoneCheck
              "
              v-ripple
              @click="modify"
              class="button ok"
            >
              등록
            </div>
            <div v-else class="button default" style="cursor: unset">등록</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
import Dropdown from '@/components/Dropdown.vue';

export default {
  components: { Dropdown, CustomInputTextForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
    },
  },
  data() {
    return {
      modifyList: ['API', '솔루션'],
      kindList: ['선택', '비금융권', '금융권', '가상자산사업자', 'P2P온투업자'],
      clientCompName: this.itemData.company,
      clientCompCheck: true,
      clientCompNameRedundancyMsg: '',
      purchaseType: this.itemData.purchase_type,
      selectedKind: this.itemData.kind,
      isActivated: this.itemData.status,
      finalCheck: false,
      phone: this.itemData.phone,
      phoneCheck: true,
    };
  },
  methods: {
    async close() {
      //initialize
      this.clientCompName = this.itemData.company;
      this.clientCompNameCheck = false;
      this.phone = this.itemData.phone;
      this.purchaseType = this.itemData.purchase_type;
      this.selectedKind = this.itemData.kind;
      this.isActivated = this.itemData.status;
      this.finalCheck = false;
      await this.$store.dispatch('getClientList', {
        start: 0,
        name: '',
      });

      this.$emit('close');
    },
    async modify() {
      await this.$store
        .dispatch('modifyClient', {
          id: Number(this.itemData.id),
          name: this.clientCompName,
          industry: this.kindList.indexOf(this.selectedKind),
          purchase_type: this.modifyList.indexOf(this.purchaseType),
          active: this.isActivated == '활성' ? true : false,
          phone_number: this.phone,
        })
        .then(res => {
          this.$log.debug('ModifyDialog#modify~res', res);
          if (res.result_code == 'N100') {
            this.$store.dispatch('showRegistrationSnackbar', {
              message: '정보 변경이 완료되었습니다.',
            });
          }
        });
      this.close();
      this.$emit('end');
    },
    async checkRedundancy() {
      if (this.clientCompName == this.itemData.company) {
        this.clientCompCheck = true;
        this.clientCompNameRedundancyMsg = '';
        return;
      }
      var res = await this.$store.dispatch('checkCompName', {
        customer_name: this.clientCompName,
      });
      this.$log.debug('ModifyDialog#checkRedundancy', res);
      if (!res.result) {
        this.clientCompCheck = true;
        this.clientCompNameRedundancyMsg = '확인되었습니다.';
      } else {
        this.clientCompCheck = false;
        this.clientCompNameRedundancyMsg =
          '입력하신 고객사명이 이미 존재 합니다.';
      }
      this.$log.debug('ModifyDialog#checkRedundancy', this.clientCompNameCheck);
    },
  },
};
</script>
<style lang="scss" scoped>
.diag-title {
  padding: 8% 0 4% 8%;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--surface-high);
}
.add-body {
  display: flex;
  flex-direction: column;
  margin: 0 8% 0 8%;
  max-height: 592px;
  overflow: scroll;
}
.title-input {
  margin: 44px 0 8px 0;
}
.client-comp-name {
  height: 56px;
  width: 70%;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--redundancy {
    width: 27%;
    margin-left: 3%;
    color: var(--surface-100);
    background: var(--surface-medium);
    border-radius: 8px;
  }
  &--fail {
    height: 56px;
    width: 70%;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    border: 1px solid red;

    caret-color: red;
  }
}
.client-comp-name,
.client-comp-name--fail,
.client-generic-button-left,
.client-generic-button-right,
.client-generic-button-left--checked,
.client-generic-button-right--checked,
.client-comp-name--redundancy,
.activation-check {
  outline: none;
}
.activation-check {
  border: 1px solid var(--gray-300);
  width: 12px;
  height: 12px;
  margin-top: 6px;
  margin-right: 8px;
}
.client-generic-button-left {
  width: 50%;
  height: 56px;
  color: var(--surface-medium);
  background: var(--surface-100);
  border: 1px solid var(--gray-300);
  border-radius: 8px 0 0 8px;
  &--checked {
    width: 50%;
    height: 56px;
    color: var(--surface-medium);
    border: 2px solid var(--primary-80);
    border-radius: 8px 0 0 8px;
    background: var(--surface-100);
  }
}
.client-generic-button-right {
  width: 50%;
  height: 56px;
  color: var(--surface-medium);
  background: var(--surface-100);
  border: 1px solid var(--gray-300);
  border-radius: 0 8px 8px 0;
  &--checked {
    width: 50%;
    height: 56px;
    color: var(--surface-medium);
    background: var(--surface-100);
    border: 2px solid var(--primary-80);
    border-radius: 0 8px 8px 0;
  }
}

.button-container {
  position: absolute;
  margin: 0 8% 0 8%;
  bottom: 0;
  display: flex;
  width: 84%;
  padding: 2% 0 8% 0;
  .default {
    width: 60%;
    color: var(--surface-100);
    background: var(--primary-20);
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    height: 60px;
    cursor: pointer;

    &.cancel {
      width: 40%;
      color: var(--surface-medium);
      margin-right: 9px;
      background: var(--gray-100);
    }

    &.ok {
      width: 60%;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
