import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueLogger from 'vuejs-logger';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

Vue.use(VueLogger, {
  logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
});

/**
 * if you change log level on qa or live server, refer to execute console print
 */
// eslint-disable-next-line no-undef
if (window) {
  // eslint-disable-next-line no-undef
  window.setLogLevel = function(level) {
    VueLogger.install(Vue, { logLevel: level });
  };
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

console.log(`version ${process.env.VUE_APP_VERSION}`);
