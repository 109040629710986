<template>
  <v-dialog
    v-model="dialog"
    max-width="795px"
    @click:outside="() => {}"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-text style="padding: 0; margin: 0;border: 25px solid #C4C4C4;">
        <img
          @click="close"
          src="@/assets/icon-close.svg"
          style="position:absolute;right:0;top:-48px;cursor:pointer;"
        />
        <img :src="image" width="100%" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
  },
};
</script>

<style>
.v-dialog--active {
  overflow: visible !important;
}
</style>
