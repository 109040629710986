<template>
  <v-dialog
    v-model="value"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="close"
    persistent
  >
    <v-card stlye="">
      <v-card-text style="padding: 0; margin: 0">
        <div class="reject-title flex-center">거부 사유</div>
        <div style="margin: 0 30px">
          <DropdownDialog
            v-if="!needInput"
            width="100%"
            pad="0 16px"
            height="56px"
            border="1px solid var(--gray-300)"
            :items="rejectList"
            @input="selectedRejectReason($event)"
            v-model="rejectReason"
          ></DropdownDialog>
          <div v-if="needInput" class="len-check">{{ lenCheck }}/45</div>
          <CustomInputTextForm
            v-if="needInput"
            :necessary="true"
            validity="live"
            :noTitle="true"
            :length-limit="45"
            placeholder="사유를 입력해주세요."
            @live="liveLengthCheck($event)"
          />
        </div>
        <div class="button-container">
          <SubmitButton
            width="40%"
            height="60px"
            font-size="20px"
            button-string="취소"
            bg-color="#F6F6F6"
            text-color="var(--surface-medium)"
            @click="close"
          />
          <div style="width: 10px"></div>
          <SubmitButton
            :dim="rejectReason === ''"
            width="60%"
            height="60px"
            font-size="20px"
            button-string="등록"
            bg-dim-color="var(--primary-20)"
            bg-color="var(--primary-100)"
            text-dim-color="var(--surface-100)"
            text-color="var(--surface-100)"
            @click="reject"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DropdownDialog from '@/components/Dropdown.vue';
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
import loadingMixin from '@/store/mixins/loading';
import SubmitButton from '@/components/SubmitButton.vue';
export default {
  mixins: [loadingMixin],
  components: { DropdownDialog, SubmitButton, CustomInputTextForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
    },
  },
  data() {
    return {
      rejectList: [
        '거부 사유 선택',
        '직접 입력',
        '오래된 사진으로 판단 불가',
        '동일인이 아님',
        '수정 정보 오류',
      ],
      rejectReason: '',
      needInput: false,
      lenCheck: 0,
    };
  },
  destroyed() {
    this.$log.debug('RejectDialog#destroyed');
  },
  methods: {
    liveLengthCheck($event) {
      this.$log.debug('RejectDialog#liveLengthCheck', $event);
      this.rejectReason = $event;
      this.lenCheck = $event.length;
    },
    close() {
      this.$emit('closeRejectDialog');
    },
    async reject() {
      this.onLoading();
      if (!this.rejectReason) return;
      await this.$store
        .dispatch('setApproveState', {
          id: this.itemData.id,
          isApproved: 0,
          rejectedReason: this.rejectReason,
        })
        .then(res => {
          this.$log.debug('RejectDialog#reject~', res);
          this.offLoading();
          if (res.result_code == 'N100') {
            this.$store.dispatch('showRegistrationSnackbar', {
              message: '심사 거부가 완료되었습니다.',
            });
          }
        });
      this.$emit('closeRejectDialog');
      this.$emit('refreshList');
    },
    selectedRejectReason(event) {
      this.rejectReason = event;
      this.$log.debug(this.rejectReason);
      if (this.rejectReason == this.rejectList[0]) {
        this.rejectReason = '';
      }

      if (this.rejectReason == this.rejectList[1]) {
        this.needInput = true;
        this.rejectReason = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.len-check {
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  margin-top: -28px;
  display: flex;
  justify-content: right;
  margin-bottom: 8px;
  /* On Surface/Disabled */

  color: var(--surface-disabled);
}
.reject-title {
  padding: 32px 34px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: var(--surface-high);
}
.v-card {
  border-radius: 8px !important;
}
.button-container {
  display: flex;
  width: 100%;
  padding: 40px 30px;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    height: 60px;
    cursor: pointer;

    &.cancel {
      width: 124px;
      color: var(--surface-medium);
      margin-right: 9px;
      background: var(--gray-100);
    }

    &.ok {
      width: 191px;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
