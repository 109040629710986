var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:((_vm.$route.params.role == 0
        ? 'signin-admin-background'
        : 'signin-background') + " full-size flex-center signin-container")},[_c('div',{staticClass:"pass-change-content flex-center"},[_c('div',{staticClass:"pass-change-card-container"},[_vm._m(0),_c('form',{staticClass:"pass-change-form flex-center"},[_c('div',{staticClass:"pass-title"},[_vm._v("비밀번호 설정")]),_c('div',{attrs:{"stlye":"position:relative;"}},[_c('div',{staticStyle:{"width":"318px","padding-bottom":"8px"}},[_c('CustomInputTextForm',{attrs:{"validity":"individual-password","placeholder":"신규 비밀번호","is-password":true,"init-val":_vm.passwordInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
                  _vm.passwordInput = $event.val;
                  _vm.isPasswordInputValid = $event.res;
                }}})],1),_c('div',{staticStyle:{"width":"318px","padding":"8px 0"}},[_c('CustomInputTextForm',{attrs:{"validity":"password","placeholder":"신규 비밀번호 확인","is-password":true,"init-val":_vm.passwordCheckInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
                  _vm.passwordCheckInput = $event.val;
                  _vm.errorCheck();
                }}})],1),(_vm.errorMsg)?_c('div',{staticStyle:{"color":"var(--error-100)","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()]),_c('div',{staticClass:"info-message"},[_vm._v(" 9~20자로 영문 대/소문자, 숫자, 특수문자를 포함하세요. (특수문자 : !@#$%^&) ")]),(_vm.buttonActivation)?_c('div',{staticClass:"pass-change-form-btn ok",on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(" 로그인 ")]):_c('div',{staticClass:"pass-change-form-btn default"},[_vm._v("로그인")])]),_c('div',{staticClass:"pass-change-problem"},[_vm._v(" 고객센터 070-4849-1000 | cs@useb.co.kr ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pass-change-title"},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"ekyc main logo"}})])}]

export { render, staticRenderFns }