<template>
  <v-dialog
    v-model="value"
    max-width="720px"
    @click:outside="close"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-text style="padding: 0; margin: 0; overflow-y: scroll">
        <header class="diag-title">{{ title }}</header>
        <div class="add-body">
          <div>
            <div class="title-input">{{ contentTitleOne }}</div>
            <div style="display: flex">
              <input
                :class="{
                  name: !errMsg,
                  'name name--fail': errMsg,
                }"
                :placeholder="contentPlaceholderOne"
                v-model="versionTag"
                @change="notification"
              />
              <button
                class="name--redundancy"
                @click="
                  errMsg.includes('영소문자') || errMsg == ''
                    ? () => {}
                    : checkRedundancy()
                "
              >
                중복확인
              </button>
            </div>
            <div
              v-if="redundancy"
              style="color: var(--primary-100);margin-top:8px;"
            >
              등록 가능한 약관 Key입니다.
            </div>
            <div v-if="errMsg" style="color: var(--error-100);margin-top:8px;">
              {{ errMsg }}
            </div>

            <div class="title-input">{{ contentTitleTwo }}</div>
            <div style="display: flex">
              <textarea
                class="paragraph"
                :placeholder="contentPlaceholderTwo"
                v-model="dictateParagraph"
              />
            </div>
          </div>
          <div class="button-container">
            <div v-ripple @click="close" class="button cancel">취소</div>
            <div
              v-if="redundancy && errMsg === '' && dictateParagraph"
              v-ripple
              @click="register"
              class="button ok"
            >
              등록
            </div>
            <div v-else class="button default" style="cursor: unset">등록</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import server from '@/api/server';
import VALID from '@/components/validation.js';

export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '개정',
    },
    contentTitleOne: {
      type: String,
      default: '버전',
    },
    contentPlaceholderOne: {
      type: String,
      default: '버전을 입력하세요.',
    },
    contentTitleTwo: {
      type: String,
      default: '약관 내용(HTML)',
    },
    contentPlaceholderTwo: {
      type: String,
      default: '약관 내용(HTML)을 입력하세요.',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      versionTag: '',
      dictateParagraph: '',
      errMsg: '',
      redundancy: false,
    };
  },
  async destroyed() {
    this.$log.debug('TncPpDialog#destroyed');
    await this.$store.dispatch('getNoticeList', { kind: this.type });
  },
  computed: {
    typeDecision() {
      return this.title.includes('개인') ? 'Pp' : 'Tnc';
    },
  },
  methods: {
    async close() {
      //initialize
      this.versionTag = '';
      this.errMsg = '';
      this.redundancy = false;
      this.dictateParagraph = '';
      await this.$store.dispatch('getPicaPpList');
      this.$emit('close');
    },
    async register() {
      if (this.checkRedundancy()) {
        server
          .createNotice(this.type, {
            version: this.versionTag,
            content: btoa(encodeURIComponent(this.dictateParagraph)),
          })
          .then(res => {
            if (res.api_response.result_code == 'N100') {
              this.$store.dispatch('showRegistrationSnackbar', {
                message:
                  this.type == 'Pp'
                    ? '개인정보처리방침 개정이 완료되었습니다.'
                    : '이용약관 개정이 완료되었습니다.',
              });
            }
          });
        this.close();
      }
    },
    async checkRedundancy() {
      if (
        await server.checkNoticeVersion(this.type, {
          version: this.versionTag,
        })
      ) {
        this.errMsg = '';
        this.redundancy = true;
        return true;
      } else {
        this.errMsg = '이미 등록된 약관 Key입니다.';
        this.redundancy = false;
        return false;
      }
    },
    notification() {
      this.redundancy = false;
      if (this.versionTag.length == 0) {
        this.redundancy = false;
        this.errMsg = '';
      }

      let decision = VALID.tncPpVersion(this.versionTag);

      console.log(decision);

      if (!this.redundancy) {
        this.errMsg = '중복여부를 확인하세요';
      } else {
        this.errMsg = '';
      }
      if (!decision.result) {
        this.errMsg = decision.msg;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.diag-title {
  padding: 40px 0 0 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--surface-high);
}
.add-body {
  display: flex;
  flex-direction: column;
  margin: 0 40px 0 40px;
  max-height: 85vh;
}
.title-input {
  margin: 20px 0 8px 0;
}
.name {
  height: 56px;
  min-width: 542px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 24px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--redundancy {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    min-width: 90px;
    margin-left: 8px;
    color: var(--surface-100);
    background: var(--surface-medium);
    border-radius: 8px;
  }
  &--fail {
    border: 1px solid var(--error-100);
    caret-color: var(--error-100);
  }
}
.paragraph {
  height: 384px;
  min-width: 640px;
  resize: none;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 16px 24px;
  font-size: 16px;
  caret-color: var(--primary-80);
}
.name:focus,
.paragraph:focus {
  outline: 1px solid var(--primary-80);
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
}
.name--fail:focus {
  outline: none;
  border: 1px solid red;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 8% 0;
  .default {
    width: 248px;
    color: var(--surface-100);
    background: var(--primary-20);
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    height: 60px;
    cursor: pointer;

    &.cancel {
      width: 162px;
      color: var(--surface-medium);
      margin-right: 9px;
      background: var(--gray-100);
    }

    &.ok {
      width: 248px;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
