<template>
  <div class="submodule-container">
    <div>
      <div v-for="(i, index) in SubModuleLists" :key="`${i}-card`">
        <div
          style="
            display: flex;
            height: 24px;
            margin-bottom: 8px;
            justify-content: space-between;
          "
        >
          <div
            :class="{
              'content-color--edit': !dimMode,
              'content-color--default': dimMode,
            }"
            style="margin-right: 4px"
          >
            {{ 'ㄴ ' }}{{ i }}
          </div>

          <div class="flex-center" style="margin-right: 16px">
            <ToggleButton
              @input="check($event, i)"
              :initialData="initData[index]"
              :is-mini="true"
              :is-dim="!editMode"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/SliderToggle.vue';
export default {
  props: {
    initData: {
      type: Array,
      default: () => Array(),
    },
    editMode: {
      type: Boolean,
      default: () => false,
    },
    SubModuleLists: {
      type: Array,
      default: () => Array(),
    },
    dimMode: {
      type: Boolean,
      default: () => false,
    },
  },
  components: { ToggleButton },
  created() {
    this.$log.debug('rerendered ');
  },
  data() {
    return {};
  },
  methods: {
    check(value, name) {
      if (name.includes('Liveness')) {
        name = 'liveness';
      }
      if (name.includes('원본')) {
        name = 'originalImage';
      }
      if (name.includes('모바일') && name.includes('첨부')) {
        name = 'mobileAttach';
      }
      if (name.includes('직접입력')) {
        name = 'manualDictate';
      }
      if (name.includes('PC') && name.includes('첨부')) {
        name = 'pcAttach';
      }
      if (name.includes('SMS')) {
        name = 'smsNotification';
      }
      this.$emit('module', [name, value]);
      this.$log.debug(
        'IdcardModuleSettingComponent#check ~ isClicked',
        value,
        name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.submodule-container {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--surface-medium);
}
.content-color {
  &--edit {
    color: var(--surface-medium);
  }
  &--default {
    color: var(--surface-disabled);
  }
}
</style>
