import axios from 'axios';
import qs from 'qs';
import {
  getBearerToken,
  setToken,
  getToken,
  makeAlcheraError,
  getUserInfo,
  getCompanyPhoneNumber,
  logout,
  destroyPermanentTokens,
  AlcheraError,
} from '@/api/auth';
import Vue from 'vue';

const api = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL || 'https://kyc-dev.aiir.ai:446/',
});
api.interceptors.request.use(getBearerToken);
api.interceptors.response.use(
  response => {
    const data = response.data;
    if (
      data?.api_response?.result_code === 'N100' ||
      data?.result_code === 'N100' ||
      data?.code == 200
    ) {
      return data;
    } else {
      //ID already exists.
      if (
        data?.api_response?.result_code == 'F602' ||
        data?.api_response?.result_code == 'F606'
      ) {
        return data;
      }
      throw makeAlcheraError({ response: { data } });
    }
  },
  error => {
    Vue.$log.debug('server#interceptorErrorHandle~', error);
    throw makeAlcheraError(error);
  }
);

async function signin({ customer_id, username, password }) {
  const data = await api.post(`sign-in`, {
    customer_id,
    username,
    password,
  });
  setToken(data);
  return getToken();
}
async function rootSignIn({ root_name, password }) {
  const data = await api.post(`root/sign-in`, {
    root_name,
    password,
  });
  setToken(data);
  return getToken();
}

/**
 *
 * @param {FormData} params
 * @param {String} params.name
 * @param {String} params.phone_number
 * @param {String} params.birthday
 */
async function startApplication(params) {
  // params.append('reuse', '0');
  return await api.post(`application`, params);
}

/**
 * @param {FormData} params
 * @param {String} params.transaction_id
 * @param {File} params.id_card_image
 * @param {File} [params.id_card_origin] [optional] card image w/o masking
 * @param {File} [params.id_crop_image] [optional] 얼굴 모듈 ON시
 * @param {Number} params.ocr_modified 0: false, 1: true
 * @param {Number} params.verified_id_card 0: false, 1: true
 * @param {String} params.ori_ocr_data
 * @param {String} params.mod_ocr_data
 * @param {String} params.is_manual_input
 * @param {String} [params.is_uploaded] [optional] "true" | "false"
 * @param {String} params.uploaded_type "camera" | "pc" | "mobile"
 */
async function startApplicationIDCard(params) {
  return await api.post(`application/idcard`, params);
}
async function getIdCards() {
  const data = await api.get(`idcards`);
  return data.list;
}
/**
 *
 * @param {FormData} params
 * @param {String} params.transaction_id
 * @param {File} params.selfie_image
 * @param {File} [params.liveness_image] [optional] Liveness 모듈 ON
 */
async function startApplicationFace(params) {
  return await api.post(`application/facecheck`, params);
}

/**
 *
 * @param {FormData} params
 * @param {String} params.transaction_id
 * @param {Number} params.verified_account
 */
async function startApplicationAccount(params) {
  return await api.post(`application/account`, params);
}

async function getUserModule() {
  const data = await api.get(`modules`);
  return data.module;
}

async function getTokenUseb() {
  const data = await api.get(`useb/token`);
  return data.token;
}

/**
 * @param {Number} start
 * @param {Number} count
 */
async function readAdminEvaluableLists(params) {
  return await api.get(`review/manuals?${qs.stringify(params)}`);
}
/**
 * @param {Number} start
 * @param {Number} count
 */
async function readAdminResultLists(params) {
  return await api.get(`review/results?${qs.stringify(params)}`);
}
/**
 * @param {Number} id
 */
async function readDetailedAdminEvaluableList(id) {
  const data = await api.get(`review/manuals/${id}`);
  return data.detail;
}
/**
 * @param {Number} id
 */
async function readDetailedAdminResultList(id) {
  const data = await api.get(`review/results/${id}`);
  return data.detail;
}
async function getSettingThreshold() {
  const data = await api.get(`threshold`);
  return data;
}

/**
 * @param {Object} params
 * @param {Number} params.level
 * @param {String} params.admin_id
 * @param {String} params.admin_name
 */
async function setSettingThreshold(params) {
  return await api.put(`threshold?${qs.stringify(params)}`);
}

/**
 * @param {Number} id
 * @param {Number} isApproved
 */
async function setAuditResult(id, params) {
  return await api.post(`review/manuals/${id}`, {
    ...params,
    auditor_id: 2, // TODO hard coded
  });
}

/**
 * @param {Object} params
 * @param {Number} params.start
 * @param {Number} params.count
 */
async function getSettingThresholdHistory(params) {
  return await api.get(`threshold/logs?${qs.stringify(params)}`);
}
async function createClientList(params) {
  return await api.post(`customers`, params);
}
async function modifyClientList(params) {
  return await api.put(`customers`, params);
}
async function modifyClientModule(params) {
  return await api.put(`modules`, params);
}

async function readClientList(params) {
  const data = await api.get(`customers?${qs.stringify(params)}`);
  return data.list;
}

async function readAdminList(id, params) {
  const data = await api.get(`customers/${id}/admins?${qs.stringify(params)}`);
  return data.list;
}
async function createAdminId(id, params) {
  return await api.post(`customers/${id}/admins`, params);
}

async function readClientDetail(payload) {
  let data = {};
  const customers_data = await api.get(`customers/${payload.id}`);

  let code;
  let message = [];
  let e_data = {};

  // abnormally case
  if (!customers_data || !customers_data.detail) {
    code = 'CE009';
    message = ['올바르지 않은 고객사 정보 입니다.'];
    e_data.result_code = code;
    e_data.result_message = message;
    throw makeAlcheraError({ response: { data: e_data } });
  }

  if (customers_data.detail.status !== 2) {
    if (customers_data.detail.status === 0) {
      code = 'CF006';
      message = ['고객사를 등록 중 입니다.', '1~2분 후 다시 시도해주세요.'];
    } else if (customers_data.detail.status === 1) {
      code = 'CE007';
      message = [
        '고객사를 등록 중 오류가 발생하였습니다.',
        '시스템 관리자에게 문의해주세요.',
      ];
    } else {
      code = 'CE008';
      message = [
        '올바르지 않은 고객사 등록 상태(Status: ' +
          customers_data.detail.status +
          ') 입니다.',
      ];
    }
    e_data.result_code = code;
    e_data.result_message = message;
    throw makeAlcheraError({ response: { data: e_data } });
  }

  // normally case
  const modules_data = await api.get(`modules/${payload.id}`);
  data.customer = customers_data.detail;
  data.module = modules_data.module;
  return data;
}
async function createReviewer(params) {
  return await api.post(`reviewers`, params);
}
async function checkId(params) {
  return await api.post(`check/id`, params);
}
async function checkEmail(params) {
  return await api.post(`check/email`, params);
}
async function readReviewer(params) {
  return await api.get(`reviewers?${qs.stringify(params)}`);
}
async function findReviewerId(params) {
  return await api.post(`find/id`, params);
}
async function findReviewerPassword(params) {
  return await api.post(`find/password`, params);
}
async function manageReviewerApply(id, params) {
  return await api.put(`reviewers/${id}/approve`, params);
}

async function readMypageInfo() {
  return await api.get(`mypage`);
}
async function modifyMypageInfo(params) {
  return await api.put(`mypage`, params);
}
async function activateReviewerAccount(id, params) {
  return await api.put(`reviewers/${id}/active`, params);
}
async function activateAdminAccount(comp, id, params) {
  return await api.put(`customers/${comp}/admins/${id}/active`, params);
}

/**
 *
 * @param {Object} params
 * @param {Number} params.customer_id
 * @param {Number} params.id
 * @param {Boolean} params.approve
 */
async function handleAdminAccountStatus(params) {
  return await api.put(
    `customers/${params.customer_id}/admins/${params.id}/approve`,
    { approve: params.approve }
  );
}

async function checkPicaPpKey(params) {
  const data = await api.post(`guides/check/key`, params, {
    validateStatus: function(status) {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
  });
  return data.is_available;
}
async function createPicaPp(params) {
  return await api.post(`guides`, params);
}
async function deletePicaPp(params) {
  return await api.delete(`guides/${params}`);
}
async function editPicaPp(params) {
  return await api.put(`guides/${params.key}`, {
    content: params.content,
  });
}
async function readPicaPpList() {
  const data = await api.get(`guides`);
  return data.list;
}
async function readPicaPpDetail(params) {
  const data = await api.get(`guides/${params}`);
  return data.detail;
}
async function readPrivacyAgreement() {
  const response = await fetch('./privacy-agreement.json');
  const data = await response.json();
  return data;
}

/**
 * @param {Object} params
 * @param {Text} params.version
 * @param {String} kind tnc || pp
 */
async function checkNoticeVersion(kind, params) {
  const data = await api.post(`notices/${kind}/check/version`, params, {
    validateStatus: function(status) {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
  });
  return data.is_available;
}
/**
 * @param {String} kind pp || tnc
 */
async function readNoticeList(kind, params) {
  const data = await api.get(`notices/${kind}?${qs.stringify(params)}`);
  return data;
}
/**
 * @param {String} kind pp || tnc
 * @param {Number} id pp || tnc
 */
async function readNoticeDetail(kind, id) {
  const data = await api.get(`notices/${kind}/${id}`);
  return data.detail;
}
/**
 * @param {String} kind pp || tnc
 * @param {Object} params
 * @param {String} params.version
 * @param {String} params.content
 */
async function createNotice(kind, params) {
  return await api.post(`notices/${kind}`, params);
}

/**
 *
 * @returns {{identification: boolean, api_response: {result_code: string, result_message: string}}}
 */
async function getConfigs() {
  return await api.get(`configs`);
}

/**
 * @param {boolean} identification
 */
async function setConfigs(identification) {
  await api.put(`configs`, {
    identification,
  });

  return true;
}
async function getRootConfigs(payload) {
  return await api.get(`customers/${payload.id}/configs`);
}
async function getIdCardKind(payload) {
  return await api.get(`customers/${payload.id}/idcards`);
}
async function getClientModule(payload) {
  const data = await api.get(`modules/${payload.id}`);
  return data;
}
async function getClientDetailInfo(payload) {
  return await api.get(`customers/${payload.id}`);
}
/**
 * @param {Object} payload
 * @param {Object} payload.content {"identification" : "true","sms_notification" : "false"}
 * @param {Number} payload.id
 */
async function setRootConfigs(payload) {
  Vue.$log.debug('server#setRootConfigs', payload);
  return await api.put(`customers/${payload.id}/configs`, payload.content);
}
async function getCompIdByName(payload) {
  try {
    return await api.post(`find/customer`, payload);
  } catch (e) {
    return e;
  }
}
async function setIdCardKind(payload) {
  Vue.$log.debug('server#setRootConfigs', payload);
  return await api.put(
    `customers/${payload.id}/idcards/${payload.name}/active`,
    payload.content
  );
}

export default {
  //User Mobile//
  startApplication,
  startApplicationIDCard,
  startApplicationFace,
  startApplicationAccount,
  getUserModule,
  getTokenUseb,
  getIdCards,

  //Token Interceptor//
  AlcheraError,
  makeAlcheraError,
  setToken,
  getToken,
  getUserInfo,
  getCompanyPhoneNumber,
  logout,
  destroyPermanentTokens,

  //Common
  getCompIdByName,
  checkEmail,
  checkId,

  //Reviewer Dashboard
  readAdminEvaluableLists,
  readAdminResultLists,
  readDetailedAdminEvaluableList,
  readDetailedAdminResultList,
  setAuditResult,
  findReviewerId,
  findReviewerPassword,

  //Admin&Reviewer Dashboard
  signin,
  readMypageInfo,
  modifyMypageInfo,

  //Admin Dashboard
  getConfigs,
  setConfigs,
  getSettingThreshold,
  setSettingThreshold,
  getSettingThresholdHistory,
  activateReviewerAccount,
  readReviewer,
  createReviewer,
  manageReviewerApply,

  //Root Dashboard
  rootSignIn,
  readClientList,
  readClientDetail,
  createClientList,
  modifyClientList,
  getClientModule,
  modifyClientModule,
  readAdminList,
  createAdminId,
  activateAdminAccount,
  handleAdminAccountStatus,
  getRootConfigs,
  setRootConfigs,

  //Root Dashboard
  checkPicaPpKey,
  createPicaPp,
  readPicaPpList,
  editPicaPp,
  deletePicaPp,
  readPicaPpDetail,
  readPrivacyAgreement,
  checkNoticeVersion,
  readNoticeList,
  readNoticeDetail,
  createNotice,
  getIdCardKind,
  setIdCardKind,
  getClientDetailInfo,
};
