<template>
  <div>
    <div class="signup-background">
      <div class="signup-board">
        <div class="flex-center">
          <img src="@/assets/useb_black_logo.svg" alt="" />
        </div>
        <div id="signup-card" class="signup-card">
          <RegisterAccount />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RegisterAccount from '@/components/RegisterAccount.vue';
import collision from '@/store/mixins/collision.js';
export default {
  mixins: [collision],
  mounted() {
    this.otherComponent = document.querySelector('#signup-card');
    this.isCollide();
  },
  name: 'SignUp',
  components: {
    RegisterAccount,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
#app {
  background-color: #f9f9ff;
}
.signup-background {
  display: flex;
  justify-content: center;
  height: 100vh;
  //overflow: scroll;
}
.signup-board {
  margin-top: 10vh;
  overflow: scroll;
}
.signup-card {
  margin-top: 40px;
  width: 500px;
  min-height: 670px;
}
</style>
