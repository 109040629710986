<template>
  <div class="root-container-auth">
    <InitLoading v-if="phase === PHASE_INIT_LOADING"></InitLoading>
    <OnBoardingScreen
      v-if="showOnBoardingScreen"
      :appData="appData"
      @cancel="onCancel"
      @next="showOnBoardingScreen = false"
    />
    <PcFileUploadDisabledScreen
      v-if="showPcFileUploadDisabledScreen"
      @start="onCancel"
    ></PcFileUploadDisabledScreen>
    <UserInfoInput
      v-if="
        appData.identification_config_enabled &&
          !showPcFileUploadDisabledScreen &&
          phase === PHASE_USER_INFO_INPUT
      "
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
    ></UserInfoInput>
    <CardSelect
      v-if="phase === PHASE_CARD_SELECT"
      :appData="appData"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :isMultipleModule="isMultipleModule"
      @cancel="onCancel"
      @next="onNext"
    ></CardSelect>
    <CardGuide
      v-if="phase === PHASE_CARD_GUIDE"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      @cancel="onCancel"
      @next="onNext"
    ></CardGuide>
    <CardUpload
      v-if="phase === PHASE_CARD_SCAN && (!isMobile || uploadProcess)"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
      @uploadtype="uploadType = $event"
    ></CardUpload>
    <CardScan
      v-if="phase === PHASE_CARD_SCAN && isMobile && !uploadProcess"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
      @manual="onManual"
      @uploadtype="uploadType = $event"
    ></CardScan>
    <CardNotMatched
      v-if="phase === PHASE_CARD_NOT_MATCHED"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
    ></CardNotMatched>
    <CardLocked v-if="phase === PHASE_CARD_LOCKED" @cancel="onCancel" />
    <CardResult
      v-if="phase === PHASE_CARD_RESULT"
      :appData="appData"
      :manualInput="manualInput"
      :uploadType="uploadType"
      @init="initializeAppData"
      @cancel="onCancel"
      @next="onNext"
      @retry="onRetry"
    ></CardResult>
    <FaceGuide
      v-if="phase === PHASE_FACE_GUIDE"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      @cancel="onCancel"
      @next="onNext"
    ></FaceGuide>
    <FaceScan
      v-if="phase === PHASE_FACE_SCAN"
      :appData="appData"
      @cancel="onCancel"
      @next="onNext"
    ></FaceScan>
    <AccountHolder
      v-if="phase === PHASE_BANK_HOLDER"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      @next="onNext"
      @cancel="onCancel"
    >
    </AccountHolder>
    <BankSelect
      v-if="phase === PHASE_BANK_SEND"
      :phase="currentPhase"
      :phaseItems="phaseItems"
      :appData="appData"
      :selectBank="selectBank"
      :industry="industry"
      @next="onNext"
      @cancel="onCancel"
    ></BankSelect>
    <BankVerify
      v-if="phase === PHASE_BANK_VERIFY"
      :appData="appData"
      :industry="industry"
      @next="onNext"
      @cancel="onCancel"
      @select="onSelect"
    ></BankVerify>
    <VerifyTryOut
      :appData="appData"
      v-if="phase === PHASE_VERIFY_FAIL"
      @cancel="onCancel"
    ></VerifyTryOut>
    <ResultSuccess
      :phase="currentPhase"
      :phaseItems="phaseItems"
      v-if="phase === PHASE_RESULT_SUCCESS"
      @next="onComplete"
    ></ResultSuccess>
    <AccessDenied
      v-if="phase === PHASE_ACCESS_DENIED"
      @ok="onCancel"
    ></AccessDenied>
    <SystemErrorDialog
      v-model="invalidServerResponseErrorFound"
      errorCode="CE001"
      @ok="appDestroy"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Constants from '@/constants';
import InitLoading from '../components/mobile/auth/InitLoading';
import PcFileUploadDisabledScreen from '../components/mobile/auth/PcFileUploadDisabledScreen';
import OnBoardingScreen from '../components/mobile/auth/OnBoardingScreen';
import UserInfoInput from '../components/mobile/auth/UserInfoInput';
import CardSelect from '../components/mobile/auth/CardSelect';
import CardGuide from '../components/mobile/auth/CardGuide';
import CardScan from '../components/mobile/auth/CardScan';
import CardUpload from '../components/mobile/auth/CardUpload';
import CardNotMatched from '../components/mobile/auth/CardNotMatched';
import CardResult from '../components/mobile/auth/CardResult';
import CardLocked from '../components/mobile/auth/CardLocked.vue';
import FaceGuide from '../components/mobile/auth/FaceGuide';
import FaceScan from '../components/mobile/auth/FaceScan';
import AccountHolder from '../components/mobile/openbank/AccountHolder';
import BankSelect from '../components/mobile/openbank/BankSelect';
import BankVerify from '../components/mobile/openbank/BankVerify';
import VerifyTryOut from '../components/mobile/openbank/VerifyTryOut';
import ResultSuccess from '../components/mobile/auth/ResultSuccess';
import AccessDenied from '../components/mobile/auth/AccessDenied';
import SystemErrorDialog from '@/components/mobile/dialog/SystemErrorDialog';
import * as faceApi from '../components/mobile/auth/face-sdk-impl';
import server from '../api/server';
import util from '@/util';
import rules from '../components/mobile/rules';

export default {
  components: {
    InitLoading,
    PcFileUploadDisabledScreen,
    OnBoardingScreen,
    UserInfoInput,
    CardSelect,
    CardGuide,
    CardScan,
    CardUpload,
    CardNotMatched,
    CardResult,
    CardLocked,
    FaceGuide,
    FaceScan,
    AccountHolder,
    BankSelect,
    BankVerify,
    VerifyTryOut,
    ResultSuccess,
    AccessDenied,
    SystemErrorDialog,
  },
  data() {
    const self = this;
    return {
      invalidServerResponseErrorFound: false,
      showOnBoardingScreen: false,
      showPcFileUploadDisabledScreen: false,

      PHASES: Constants.PHASES,

      PHASE_INIT_LOADING: Constants.PHASES.PHASE_INIT_LOADING, // -1
      PHASE_EMPTY_PAGE: Constants.PHASES.PHASE_EMPTY_PAGE, // 0
      PHASE_USER_INFO_INPUT: Constants.PHASES.PHASE_USER_INFO_INPUT, // 1
      PHASE_CARD_SELECT: Constants.PHASES.PHASE_CARD_SELECT, // 2
      PHASE_CARD_GUIDE: Constants.PHASES.PHASE_CARD_GUIDE, // 3
      PHASE_CARD_SCAN: Constants.PHASES.PHASE_CARD_SCAN, // 4
      PHASE_CARD_NOT_MATCHED: Constants.PHASES.PHASE_CARD_NOT_MATCHED, // 5
      PHASE_CARD_RESULT: Constants.PHASES.PHASE_CARD_RESULT, // 6
      PHASE_FACE_GUIDE: Constants.PHASES.PHASE_FACE_GUIDE, // 7
      PHASE_FACE_SCAN: Constants.PHASES.PHASE_FACE_SCAN, // 8
      PHASE_BANK_HOLDER: Constants.PHASES.PHASE_BANK_HOLDER, // 9
      PHASE_BANK_SEND: Constants.PHASES.PHASE_BANK_SEND, // 10
      PHASE_BANK_VERIFY: Constants.PHASES.PHASE_BANK_VERIFY, // 11
      PHASE_VERIFY_FAIL: Constants.PHASES.PHASE_VERIFY_FAIL, // 12
      PHASE_RESULT_SUCCESS: Constants.PHASES.PHASE_RESULT_SUCCESS, // 13
      PHASE_ACCESS_DENIED: Constants.PHASES.PHASE_ACCESS_DENIED, // 14
      PHASE_CARD_LOCKED: Constants.PHASES.PHASE_CARD_LOCKED, // 15

      MODULE: Constants.MODULE,

      phase: 0,
      appStarted: false,
      moduleName: '', // full name is "ocr+status+face+liveness+account"
      phaseModule: {
        // set entry point of application
        // check appData.progress
        first: () => {
          const progress = self.appData.progress;

          if (progress) {
            const ID_CARD_CHECKED = progress.is_id_card_checked;
            const FACE_CHECKED = progress.is_face_checked;
            const ACCOUNT_CHECKED = progress.is_account_checked;

            const MODULE_FACE_ENABLED = self.moduleName.includes(
              Constants.MODULE.FACE
            );
            const MODULE_ACCOUNT_ENABLED = self.moduleName.includes(
              Constants.MODULE.ACCOUNT
            );

            if (ID_CARD_CHECKED) {
              if (MODULE_FACE_ENABLED && !FACE_CHECKED) {
                return self.PHASE_FACE_GUIDE;
              } else if (MODULE_ACCOUNT_ENABLED && !ACCOUNT_CHECKED) {
                return self.PHASE_BANK_HOLDER;
              }
            } else if (FACE_CHECKED) {
              if (MODULE_ACCOUNT_ENABLED && !ACCOUNT_CHECKED) {
                return self.PHASE_BANK_HOLDER;
              }
            } else if (ACCOUNT_CHECKED) {
              // nothing. finished.
            }
          }
          // pre-set user data option selected
          if (self.appData.userInputPass) {
            return self.phaseModule.next(self.PHASE_USER_INFO_INPUT);
          }
          return self.PHASE_USER_INFO_INPUT;
        },
        prev(phase) {
          switch (phase) {
            case self.PHASE_CARD_SCAN:
              return self.PHASE_CARD_SELECT;
            case self.PHASE_BANK_VERIFY:
              return self.PHASE_BANK_SEND;
            default:
              return phase - 1;
          }
        },
        next(phase) {
          const MODULE_OCR_ENABLED = self.moduleName.includes(
            Constants.MODULE.OCR
          );
          const MODULE_FACE_ENABLED = self.moduleName.includes(
            Constants.MODULE.FACE
          );
          const MODULE_ACCOUNT_ENABLED = self.moduleName.includes(
            Constants.MODULE.ACCOUNT
          );

          switch (phase) {
            case self.PHASE_USER_INFO_INPUT:
              if (MODULE_OCR_ENABLED) {
                return self.PHASE_CARD_SELECT;
              } else if (MODULE_FACE_ENABLED) {
                return self.PHASE_FACE_GUIDE;
              } else if (MODULE_ACCOUNT_ENABLED) {
                return self.PHASE_BANK_HOLDER;
              } else {
                if (this.invalidServerResponseErrorFound) {
                  return;
                }
                return self.PHASE_RESULT_SUCCESS;
              }
            case self.PHASE_CARD_SELECT:
              if (!self.isMobile || self.uploadProcess) {
                return self.PHASE_CARD_SCAN;
              } else {
                return self.PHASE_CARD_GUIDE;
              }
            case self.PHASE_CARD_GUIDE:
              return self.PHASE_CARD_SCAN;
            case self.PHASE_CARD_SCAN:
              return self.PHASE_CARD_RESULT;
            case self.PHASE_CARD_NOT_MATCHED:
              return self.PHASE_CARD_RESULT;
            case self.PHASE_CARD_RESULT:
              if (MODULE_FACE_ENABLED) {
                return self.PHASE_FACE_GUIDE;
              } else if (MODULE_ACCOUNT_ENABLED) {
                return self.PHASE_BANK_HOLDER;
              } else {
                if (this.invalidServerResponseErrorFound) {
                  return;
                }
                return self.PHASE_RESULT_SUCCESS;
              }
            case self.PHASE_FACE_GUIDE:
              return self.PHASE_FACE_SCAN;
            case self.PHASE_FACE_SCAN:
              if (MODULE_ACCOUNT_ENABLED) {
                return self.PHASE_BANK_HOLDER;
              } else {
                if (this.invalidServerResponseErrorFound) {
                  return;
                }
                return self.PHASE_RESULT_SUCCESS;
              }
            case self.PHASE_BANK_HOLDER:
              return self.PHASE_BANK_SEND;

            case self.PHASE_BANK_SEND:
              return self.PHASE_BANK_VERIFY;
            case self.PHASE_BANK_VERIFY:
              if (this.invalidServerResponseErrorFound) {
                return;
              }
              return self.PHASE_RESULT_SUCCESS;
            default:
              return -1;
          }
        },
        phase(phase) {
          const MODULE_FACE_ENABLED = self.moduleName.includes(
            Constants.MODULE.FACE
          );
          const MODULE_OCR_ENABLED = self.moduleName.includes(
            Constants.MODULE.OCR
          );

          switch (phase) {
            case self.PHASE_USER_INFO_INPUT:
              return 0;
            case self.PHASE_CARD_SELECT:
            case self.PHASE_CARD_GUIDE:
            case self.PHASE_CARD_SCAN:
              return 1;
            case self.PHASE_FACE_GUIDE:
              return 2;
            case self.PHASE_BANK_HOLDER:
              if (!MODULE_FACE_ENABLED) {
                if (!MODULE_OCR_ENABLED) {
                  return 1;
                }
                return 2;
              }
              return 3;
            case self.PHASE_BANK_SEND:
              if (!MODULE_FACE_ENABLED) {
                if (!MODULE_OCR_ENABLED) {
                  return 1;
                }
                return 2;
              }
              return 3;
            default:
              return -1;
          }
        },
      },
      phaseItems: [],
      appData: { cardScanRetryCount: 0 },
      selectBank: false,
      industry: 0,
      uploadProcess: false,
      manualInput: false,
      uploadType: 'camera',
    };
  },
  computed: {
    ...mapState(['isMobile']),
    currentPhase() {
      return this.phaseModule.phase(this.phase);
    },
    currentPhaseName() {
      return Object.keys(this.PHASES).find(
        phaseName => this.PHASES[phaseName] === this.phase
      );
    },
    isMultipleModule() {
      this.$log.debug('isMultipleModule', this.moduleName);
      if (
        !this.moduleName ||
        this.moduleName === this.MODULE.IDCARD ||
        this.moduleName === this.MODULE.ACCOUNT
      ) {
        return false;
      }
      return true;
    },
  },
  async created() {
    this.$log.debug('Auth#created to', this.appData);
    try {
      this.phase = this.PHASE_INIT_LOADING;

      await this.appStart();
    } catch (error) {
      this.$log.error(error);
    }
  },
  beforeDestroy() {
    faceApi.destoryFaceApi();
  },
  methods: {
    async appStart() {
      this.supportOldBrowsers();
      const nativeHandler = async e => {
        try {
          const response = e.data ? e.data : e;

          if (!response) {
            this.$log.info(
              '[INFO] Auth#~message is skipped, cause : response is undefined'
            );
            return;
          }

          if (response.type === 'webpackOk') {
            this.$log.info(
              '[INFO] Auth#~message is skipped, cause : webpackOk type'
            );
            return;
          }

          this.$log.debug('Auth#~message', response);
          if (this.origin !== e.origin) {
            this.$log.info(
              "[WARNING] origin is replaced : '" +
                this.origin +
                "' -> '" +
                e.origin +
                "'"
            );
            this.origin = e.origin;
          }
          this.$log.debug('Auth#~message origin', this.origin);

          let data;

          if (typeof response === 'string' && response !== 'undefined') {
            try {
              data = JSON.parse(decodeURIComponent(atob(response)));
              this.$log.debug('토큰 =', data);
            } catch (err) {
              this.$log.debug('여기까지는 감');
              return;
              // this.phase = this.PHASE_ACCESS_DENIED;
            }

            // const data = JSON.parse(decodeURIComponent(atob(response)));

            if (data && data.customer_id && data.id && data.key) {
              // get company industry to change openbank scenario(openbank or firmbank)
              const {
                company: { industry },
              } = await server.signin({
                customer_id: parseInt(data.customer_id),
                username: data.id,
                password: data.key,
              });
              this.industry = industry;

              this.$store.commit(
                'setCompanyPhoneNumber',
                server.getCompanyPhoneNumber()
              );

              this.setModule(await server.getUserModule());

              // 안면인증이 활성화 되어 있을 경우 face api 불러오기
              if (this.moduleName.includes(Constants.MODULE.FACE)) {
                this.$log.debug('안면인증 모듈 불러오기');
                if (this.moduleName.includes(Constants.MODULE.LIVENESS)) {
                  await faceApi.initFaceApi({ liveness: true });
                } else {
                  await faceApi.initFaceApi({ liveness: false });
                }
              }

              // OCR이 활성화 되어 있을 경우 아이디 카드 불러오기
              if (this.moduleName.includes(Constants.MODULE.OCR)) {
                this.$log.debug('IDcard 불러옴');
                // check which ID card options to show
                await server.getIdCards().then(list => {
                  this.$store.commit('setIdCardsFilter', list);
                });
              } else {
                this.$log.debug(this.moduleName);
                this.$log.debug('IDcard 안 불러옴');
              }

              // config 불러와서 세팅
              await this.setConfig();

              // OCR 파일 업로드 가능에 따라 페이지 보여주기
              if (this.moduleName.includes(Constants.MODULE.OCR)) {
                this.showPcFileUploadDisabledScreen =
                  !this.appData.manual_upload_id_pc && !this.isMobile;
              }

              // identification 값 기준으로 show input UI 보여준다.
              if (this.appData.identification_config_enabled) {
                this.$log.debug('필수정보 관리 안함 ');
                this.appStarted = true;
                this.phase = this.PHASE_USER_INFO_INPUT;
              } else {
                this.$log.debug('필수정보 관리 함 ', data);
                // 일부로 에러 발생시키기

                if (
                  !data.birthday ||
                  !data.email ||
                  !data.name ||
                  !data.phone_number
                ) {
                  this.$log.debug('필수정보가 제대로 입력되지 않음');
                  this.phase = this.PHASE_ACCESS_DENIED;
                  return;
                }
                // set parameter data
                await this.setApplication(data);
                this.$log.debug('필수정보 입력되어 온보딩 보여주기');

                // 필수정보 입력됬으니 온보딩 화면 보여줌
                this.showOnBoardingScreen = this.isMultipleModule;

                // 안면인증 모듈 불러온 것 확인
                if (
                  !this.moduleName.includes(Constants.MODULE.FACE) ||
                  faceApi.isFaceDetectionModelLoadedSync()
                ) {
                  this.phase = this.phaseModule.first();
                }
                this.appStarted = true;
              }
            } else if (!this.appStarted) {
              this.$log.debug('앱이 시작되지 않음');

              this.$log.warn('[WARNING] params is not exist.');
              this.phase = this.PHASE_ACCESS_DENIED;
            }
          }
          this.handleInvalidBrowsers();
        } catch (e) {
          if (
            !(e instanceof SyntaxError && e.message.includes('JSON')) &&
            !this.appStarted
          ) {
            this.$log.debug('에러가 발생함', e);
            this.$log.error('alcherakyc error', e);
            this.phase = this.PHASE_ACCESS_DENIED;
          }
        }
      };
      //ios
      window.addEventListener('message', nativeHandler);
      //android
      document.addEventListener('message', nativeHandler);
      window.alcherakycreceive = nativeHandler;

      if (this.$route.query && this.$route.query.token) {
        nativeHandler(this.$route.query.token);
      } else {
        this.$log.debug('TOKEN이 없음');
        this.$log.warn('[WARNING] token is not exist.');
        // this.phase = this.PHASE_ACCESS_DENIED;
      }
    },
    // returns true if config is enabled
    // @param { Array, Object } data
    // @param { String } key
    // @return { Boolean }
    isEnabledConfig(data, key) {
      return (
        data[key] ||
        data?.list?.some(item => {
          return item.key === key && item.value;
        })
      );
    },
    async setConfig() {
      const config = await server.getConfigs();

      // CONFIG: handle when "identification" config is set to true/false
      this.appData.identification_config_enabled = this.isEnabledConfig(
        config,
        'identification'
      );

      // CONFIG: set flag for saving original id image
      this.appData.save_original_id_image = this.isEnabledConfig(
        config,
        'save_original_id_image'
      );
      this.$log.debug(
        'Auth#save_original_id_image',
        this.appData.save_original_id_image
      );
      // CONFIG: check if allow upload id by PC
      this.appData.manual_upload_id_pc = this.isEnabledConfig(
        config,
        'manual_upload_id_pc'
      );

      // CONFIG: check if allow upload id by mobile 사진 첨부 옵션
      this.appData.manual_upload_id_mobile = this.isEnabledConfig(
        config,
        'manual_upload_id_mobile'
      );

      // 신분증 직접 입력
      this.appData.manual_input_mobile = this.isEnabledConfig(
        config,
        'manual_input_mobile'
      );
    },
    async setModule(module) {
      this.$log.debug('모듈 불러오기 시작');
      const moduleName = [];
      const phaseItems = [];
      phaseItems.push('정보입력');
      if (module.id_card_ocr) {
        phaseItems.push('신분증 인증');
        moduleName.push(Constants.MODULE.OCR);
      }
      if (module.id_card_verification) {
        moduleName.push(Constants.MODULE.STATUS);
      }
      if (module.face_authentication) {
        phaseItems.push('얼굴 인증');
        moduleName.push(Constants.MODULE.FACE);
      }
      if (module.liveness) {
        moduleName.push(Constants.MODULE.LIVENESS);
      }
      if (module.account_verification) {
        phaseItems.push('계좌 인증');
        moduleName.push(Constants.MODULE.ACCOUNT);
      }
      this.moduleName = moduleName.join('+');
      this.phaseItems = phaseItems;
      this.appData.module = module;

      this.appData.moduleName = this.moduleName;
      this.$log.debug('Auth#setModule', this.moduleName);
    },
    async setApplication(data) {
      const phoneNumberTest = /^\d+$/;
      const birthdayTest = /^\d{4}-\d{2}-\d{2}$/;
      data.name && (data.name = data.name.trim());
      data.phone_number && (data.phone_number = data.phone_number.trim());
      data.birthday && (data.birthday = data.birthday.trim());
      data.email && (data.email = data.email.trim());
      this.$log.debug('Auth#setApplication#data', data);
      if (!data.name || !data.phone_number || !data.birthday || !data.email) {
        // set "userInputPass" flag to false
        this.appData = {
          ...this.appData,
          userInputPass: false,
        };
      } else if (
        data.name &&
        phoneNumberTest.test(data.phone_number) &&
        birthdayTest.test(data.birthday) &&
        rules.email(data.email)
      ) {
        await this.setTransactionId(data);
      } else {
        throw new Error('user info invalid');
      }
    },
    async setTransactionId(data) {
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('phone_number', data.phone_number);
      formData.append('birthday', data.birthday);
      formData.append('email', data.email);
      this.$log.debug('Auth#setApplication#formData', formData);
      const result = await server.startApplication(formData);
      this.appData = {
        ...this.appData,
        userInputPass: true,
        userName: data.name,
        phoneNumber: data.phone_number,
        birthDate: data.birthday,
        email: data.email,
        transaction_id: result.form.transaction_id,
        progress: result.form.progress,
        module: result.form.module,
        reuse: '0', // reuse parameter 토큰 재사용 (이어하기 기능 연관) 현재는 프론트에서 구현되어 있지 않음으로 0 하드코딩
      };
    },

    appDestroy() {
      // release mode에서 타이밍 이슈 발생. sendResult 요청보다 먼저 실행되는 경우가 발생되는 현상
      setTimeout(() => {
        window.close();
        window.open('', '_self', '').close();
        location.replace('about:blank');
      });
    },
    handleInvalidBrowsers() {
      // Add blacklist of fixed number of known browsers where
      // there is an issue with camera permissions on Android.
      //const USERAGENT_BLACKLIST = ["kakao", "naver"];
      const USERAGENT_BLACKLIST = ['kakao', 'daum'];
      const AGENT = navigator.userAgent.toLowerCase();
      const isAndroid = navigator.userAgent.match(/Android/i);

      this.$log.debug(`useragent: ${AGENT}`);
      if (isAndroid && USERAGENT_BLACKLIST.some(ua => AGENT.includes(ua))) {
        this.openAppInNewBrowser();
      }
    },
    openAppInNewBrowser() {
      alert(
        '지원되지 않는 브라우저입니다. 삼성인터넷, 크롬, 사파리 브라우저에서 시도해주세요.'
      );
      this.sendResult('{"result": "close", "cause": "unsupported_browser"}');
      this.appDestroy();

      // launch new browser
      // location.href =
      //   "intent://kyc-demo-dev.useb.co.kr#Intent;scheme=http;package=com.android.chrome;end";
    },
    supportOldBrowsers() {
      this.$log.debug('Auth#supportOldBrowsers');
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          // First get ahold of the legacy getUserMedia, if present
          let getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia;
          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(
              new Error('getUserMedia is not implemented in this browser')
            );
          }
          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
    },
    sendResult(result) {
      this.$log.debug('Auth#sendResult', result);
      const returnMessage = btoa(encodeURIComponent(result));
      if (window.parent) {
        // Browser iframe
        window.parent.postMessage(returnMessage, this.origin);
      }

      if (window.ReactNativeWebView) {
        // react-native webview
        window.ReactNativeWebView.postMessage(returnMessage);
      }

      if (window.webkit && window.webkit.messageHandlers) {
        // iOS: WKScriptMessageHandler WKScriptMessage name(alcherakyc)
        window.webkit.messageHandlers.alcherakyc &&
          window.webkit.messageHandlers.alcherakyc.postMessage(returnMessage);
      } else if (window['alcherakyc']) {
        // Android: WebView JavascriptInterface name(alcherakyc) and JS function(result)
        window['alcherakyc'] &&
          window['alcherakyc']['receive'] &&
          window['alcherakyc']['receive'](returnMessage);
      }
    },
    onCancel(data) {
      this.$log.debug('onCancel 이벤트 발생', data);

      if (data && data.prev) {
        this.$log.debug('뒤로가기 발생, 현재 단계 : ', this.phase);
        this.$log.debug(
          '페이지 전환 타겟 : ',
          this.phaseModule.prev(this.phase)
        );
        if (this.phase === this.PHASE_CARD_SELECT && this.isMultipleModule) {
          this.showOnBoardingScreen = true;
        } else {
          this.phase = this.phaseModule.prev(this.phase);
        }
      } else if (this.showOnBoardingScreen === true) {
        // 온보딩에서 x 버튼 누른 경우
        this.sendResult('{"result": "close"}');
        this.appDestroy();
      } else if (this.phase === this.PHASE_CARD_SELECT) {
        if (this.isMultipleModule) {
          // 모듈이 여러개라 온보딩 보여주기
          this.showOnBoardingScreen = true;
          this.$log.debug('모듈이 여러개라 온보딩 스크린이 보여져야 함');
        } else {
          //모듈이 한개라 그냥 꺼져야 함
          this.sendResult('{"result": "close"}');
          this.appDestroy();
        }
      } else if (this.phase === this.PHASE_CARD_SCAN && data?.upload) {
        this.manualInput = false;
        this.uploadProcess = true;
      } else if (data && data.selectBank) {
        this.phase = this.phaseModule.prev(this.phase);
        this.selectBank = data.selectBank;
      } else if (data && data.fail) {
        this.appData = { ...this.appData, ...data };
        this.onResult(true, data.response);
        this.appDestroy();
      } else {
        this.sendResult('{"result": "close"}');
        this.appDestroy();
      }
    },
    initializeAppData() {
      this.$log.debug('UserInfoInput initialize');
      // this.$set(this.appData, 'cardScanRetryCount', 0);
      // this.$delete(this.appData, 'ocr');

      this.appData = {
        cardScanRetryCount: 0,
        identification_config_enabled: this.appData
          .identification_config_enabled,
        manual_input_mobile: this.appData.manual_input_mobile,
        manual_upload_id_mobile: this.appData.manual_upload_id_mobile,
        manual_upload_id_pc: this.appData.manual_upload_id_pc,
        module: this.appData.module,
        moduleName: this.appData.moduleName,
        save_original_id_image: this.appData.save_original_id_image,
        userName: this.appData.userName,
        birthDate: this.appData.birthDate,
        email: this.appData.email,
        phoneNumber: this.appData.phoneNumber,
      };
    },
    onManual(data) {
      this.$log.debug('onManual', data);
      this.appData = {
        ...this.appData,
        ...data,
      };
      this.manualInput = true;
      this.phase = this.PHASE_CARD_RESULT;
    },
    async onNext(data) {
      // check for server error response
      // TODO call checkServerResponse() only before final confirmation screen
      this.checkServerResponse(data);

      this.$log.debug(
        `Auth.vue#onNext()#${this.currentPhaseName}`,
        data,
        this.appData
      );

      // set app data
      if (
        ![
          Constants.PHASES.PHASE_CARD_NOT_MATCHED,
          Constants.PHASES.PHASE_BANK_HOLDER,
          Constants.PHASES.PHASE_BANK_VERIFY,
        ].includes(this.phase)
      ) {
        this.appData = {
          ...this.appData,
          ...data,
        };
      }
      switch (this.phase) {
        case this.PHASE_USER_INFO_INPUT:
          if (this.appData.module) {
            // set module
            // await this.setModule(this.appData.module);
            // exit function if wrong phase, after updating "phase"
            // set parameter data

            const formattedUserInfo = {
              name: data.userName,
              phone: data.phoneNumber,
              birthday: data.birthDate,
              email: data.email,
            };
            await this.setApplication(formattedUserInfo);
            // 안면인증 모듈 불러오기
            if (
              !this.moduleName.includes(Constants.MODULE.FACE) ||
              faceApi.isFaceDetectionModelLoadedSync()
            ) {
              this.$log.debug('onNext 온보딩 화면 나타나기');
              this.showOnBoardingScreen = this.isMultipleModule;
            }
          }
          break;
        case this.PHASE_CARD_SELECT:
          // data: {
          //   cardIndex: 0,
          // }
          break;
        case this.PHASE_CARD_SCAN: {
          this.appData = {
            ...this.appData,
            cardIndex: this.appData.cardIndex,
          };
          const birthday = util.getBirthdayFromOcr(
            this.appData.cardIndex,
            this.appData.ocr
          );
          // check name & birthday
          if (
            this.appData.userName !== this.appData.ocr.userName ||
            this.appData.birthDate !== birthday
          ) {
            this.$log.debug(
              '입력된 이름 ',
              this.appData.userName,
              ' 스캔된 이름 ',
              this.appData.ocr.userName
            );
            this.$log.debug(
              '입력된 생년월일 ',
              this.appData.birthDate,
              ' 스캔된 생년월일 ',
              birthday
            );

            this.appData.scannedFormattedDate = birthday;

            this.phase = this.PHASE_CARD_NOT_MATCHED;
            return;
          }
          break;
        }
        case this.PHASE_CARD_NOT_MATCHED:
          this.appData.fromCardNotMatchedOriginOcr = { ...this.appData.ocr };
          this.appData.fromCardNotMatched = true;
          if (data && data.userinfoChanged) {
            // OCR 결과를 변경하는 경우
            // backup original ocr
            this.$log.debug(
              '스캔된 ocr 정보',
              this.appData.fromCardNotMatchedOriginOcr
            );

            const newUserInfo = {
              name: this.appData.userName,
              birthday: this.appData.birthDate,
              phone_number: this.appData.phoneNumber,
              email: this.appData.email,
            };

            // change user info
            if (data.updatedEditType === 'user-info') {
              if (data.updatedFields.includes('name')) {
                this.appData.userName = data.updatedName;
                newUserInfo.name = data.updatedName;
              }
              if (data.updatedFields.includes('birthdate')) {
                this.appData.birthDate = data.updatedBirthDate;
                newUserInfo.birthday = data.updatedBirthDate;
              }
            }

            if (data.updatedFields.includes('name')) {
              this.appData.ocr.userName = data.updatedName;
              newUserInfo.name = data.updatedName;
            }

            if (data.updatedFields.includes('birthdate')) {
              // update wrong birthdate
              this.appData.scannedFormattedDate = data.updatedBirthDate;
              newUserInfo.birthday = data.updatedBirthDate;

              // when uploaded document is not foreign passport
              if (
                this.appData.cardIndex !==
                Constants.APP_CARD_INDEX.PASSPORT_ALIEN
              ) {
                const newJuminNo = data.updatedBirthDate
                  .slice()
                  .replace(/[_-]/g, '')
                  .slice(2);

                if (this.appData.ocr?.juminNo1) {
                  this.$log.debug('주민번호 수정');
                  this.appData.ocr.juminNo1 = newJuminNo;
                }
                if (this.appData.ocr?.issueNo1) {
                  this.$log.debug('발급번호 수정');
                  this.appData.ocr.issueNo1 = newJuminNo;
                }
                // update juminNo2
              }
              if (
                this.appData.cardIndex ===
                Constants.APP_CARD_INDEX.PASSPORT_ALIEN
              ) {
                this.$log.debug('임의로 변경함 + 외국인 여권');
                this.appData.ocr.userName = newUserInfo.name;
                this.appData.ocr.birthDate = newUserInfo.birthday;
              }
            }

            await this.setTransactionId(newUserInfo);

            // this.phase = this.phaseModule.first();
            // return;
          } else {
            // ocr 된 결과가 아닌 사용자 임의 입력값이 같다는 것을 의미하므로 바꿔줘야함
            this.$log.debug('ocr ', this.appData.ocr);
            this.$log.debug('appData', this.appData);
            this.appData.ocr.userName = this.appData.userName;
            const newJuminNo = this.appData.birthDate
              .slice()
              .replace(/[_-]/g, '')
              .slice(2);
            if (this.appData.ocr?.juminNo1) {
              this.$log.debug('주민번호 수정');
              this.appData.ocr.juminNo1 = newJuminNo;
            }
            if (this.appData.ocr?.issueNo1) {
              this.$log.debug('발급번호 수정');
              this.appData.ocr.issueNo1 = newJuminNo;
            }

            this.appData.ocr.birthDate = this.appData.birthDate;
          }
          break;
        case this.PHASE_CARD_RESULT:
          if (data && data.fail) {
            if (data.errorCode === 'A005') {
              this.phase = this.PHASE_CARD_LOCKED;
            } else {
              this.onResult(true, data.response);
            }
            return;
          } else if (
            !this.moduleName.includes(Constants.MODULE.FACE) &&
            !this.moduleName.includes(Constants.MODULE.ACCOUNT)
          ) {
            this.onResult(false, data.response);
          }
          break;
        case this.PHASE_FACE_SCAN:
          if (data && data.fail) {
            this.onResult(true, data.response);
            return;
          } else if (!this.moduleName.includes(Constants.MODULE.ACCOUNT)) {
            this.onResult(false, data.response);
          }
          break;
        case this.PHASE_BANK_HOLDER:
          this.appData = {
            ...this.appData,
            // account_holder_name: data.account_holder_name,
          };
          break;
        case this.PHASE_BANK_SEND:
          break;
        case this.PHASE_BANK_VERIFY:
          if (data && data.fail) {
            if (data.errorType === 'tryout') {
              this.appData = {
                ...this.appData,
                errorType: 'tryout',
              };
            } else {
              this.appData = {
                ...this.appData,
                errorType: 'timeout',
              };
            }
            this.phase = this.PHASE_VERIFY_FAIL;
            this.onResult(true, data.response);
            return;
          } else {
            this.onResult(false, data.response);
          }
          break;
        default:
          break;
      }
      this.phase = this.phaseModule.next(this.phase);
    },
    onRetry() {
      if (this.appData.identification_config_enabled) {
        this.phase = this.PHASE_USER_INFO_INPUT;
      } else {
        this.phase = this.PHASE_CARD_SELECT;
      }
      this.manualInput = false;
    },
    onSelect() {
      this.phase = this.PHASE_BANK_SEND;
    },
    onResult(error, response) {
      response.result = error ? 'failed' : 'success';
      if (
        response?.review_result?.face_check &&
        'is_masked' in response.review_result.face_check
      ) {
        // remove is_masked
        delete response.review_result.face_check.is_masked;
      }

      this.sendResult(JSON.stringify(response));
    },
    onComplete() {
      this.sendResult('{"result": "complete"}');
      this.appDestroy();
    },
    checkServerResponse(res) {
      // TODO expand out this function to handle different server response types
      if (res && res.result === 'success' && !res.review_result) {
        this.invalidServerResponseErrorFound = true;
      } else {
        this.invalidServerResponseErrorFound = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container-auth {
  position: fixed;
  overflow: scroll;
  width: 100%;
  height: 100%;
  color: var(--surface-high);
}
</style>

<style>
video::-webkit-media-controls-panel {
  display: none;
  -webkit-appearance: none;
}
video::-webkit-media-controls-play-button {
  display: none;
  -webkit-appearance: none;
}
video::-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none;
}

video::-webkit-media-controls {
  display: none;
  -webkit-appearance: none;
}
</style>
