<template>
  <v-dialog
    v-model="value"
    max-width="720px"
    @click:outside="close"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-text style="padding: 0; margin: 0; overflow-y: scroll">
        <header class="diag-title">{{ title }}</header>
        <div class="add-body">
          <div>
            <div class="title-input">{{ contentList[0][0] }}</div>
            <div style="display: flex">
              <input
                :class="{
                  name: !errMsg,
                  'name name--fail': errMsg,
                }"
                :placeholder="contentList[0][1]"
                v-model="versionTag"
                @change="notification"
              />
              <button
                class="name--redundancy"
                @click="
                  errMsg.includes('영소문자') || errMsg == ''
                    ? () => {}
                    : checkRedundancy()
                "
              >
                중복확인
              </button>
            </div>
            <div
              v-if="redundancy"
              style="color: var(--primary-100);margin-top:8px;"
            >
              등록 가능한 약관 Key입니다.
            </div>
            <div v-if="errMsg" style="color: var(--error-100); margin-top:8px;">
              {{ errMsg }}
            </div>
            <div class="title-input">{{ contentList[1][0] }}</div>
            <div
              v-for="(resultComp, i) in resultJson"
              :key="i"
              style="margin-bottom:10px"
            >
              <div style="display:flex">
                <div>
                  <input
                    class="paragraph paragraph--title"
                    :placeholder="contentList[1][1]"
                    v-model="resultComp['title']"
                  />
                  <textarea
                    class="paragraph paragraph--content"
                    :placeholder="contentList[1][2]"
                    v-model="resultComp['content']"
                  />
                </div>
                <div style="margin-left:8px;">
                  <div v-if="resultJson.length == 1 && i == 0">
                    <img
                      src="@/assets/icon_plus_24.svg"
                      @click="pushNewBlank()"
                      alt="add a new term"
                    />
                  </div>
                  <div
                    v-if="resultJson.length > 1 && i != resultJson.length - 1"
                  >
                    <img
                      src="@/assets/icon_minus_24.svg"
                      alt="pop a term"
                      @click="popBlank(i)"
                    />
                  </div>
                  <div
                    v-if="i == resultJson.length - 1 && resultJson.length > 1"
                    style="display:flex;flex-direction:column;"
                  >
                    <img
                      src="@/assets/icon_set_plus_24.svg"
                      alt="add a new term"
                      @click="pushNewBlank()"
                    />
                    <img
                      style="margin-top:-2px;"
                      src="@/assets/icon_set_minus_24.svg"
                      alt="pop a term"
                      @click="popBlank(i)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container">
            <div v-ripple @click="close" class="button cancel">취소</div>
            <div
              v-if="redundancy && errMsg === '' && isSubmittable()"
              v-ripple
              @click="register"
              class="button ok"
            >
              등록
            </div>
            <div v-else class="button default" style="cursor: unset">등록</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import server from '@/api/server';
import VALID from '@/components/validation.js';

export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '개정',
    },
    contentList: {
      type: Array,
      default: () => [
        ['약관 Key', '약관 Key를 입력하세요.'],
        [
          '약관 내용 (HTML)',
          '제목을 입력하세요.',
          '약관 내용은 HTML 형태로 입력하세요.',
        ],
      ],
    },
  },
  data() {
    return {
      versionTag: '',
      dictateParagraph: '',
      errMsg: '',
      redundancy: false,
      resultJson: [{ title: '', content: '' }],
    };
  },
  async destroyed() {
    this.$log.debug('TncPpDialog#destroyed');
    await this.$store.dispatch('getPicaPpList');
  },
  computed: {},
  methods: {
    isSubmittable() {
      var ret = true;
      this.resultJson.forEach(res => {
        this.$log.debug('PicaPpDialog#isSubmittable', res);
        if (res['title'] == '' || res['content'] == '') {
          this.$log.debug('PicaPpDialog#isSubmittable~false', res);
          ret = false;
          return ret;
        }
      });
      return ret;
    },
    pushNewBlank() {
      let tempResult = JSON.parse(JSON.stringify(this.resultJson));

      tempResult.push({
        title: '',
        content: '',
      });
      this.resultJson = tempResult;
    },
    popBlank(index) {
      let tempResult = JSON.parse(JSON.stringify(this.resultJson));
      if (index > -1) tempResult.splice(index, 1);
      this.resultJson = tempResult;
    },
    async close() {
      //initialize
      this.versionTag = '';
      this.errMsg = '';
      this.redundancy = false;
      this.dictateParagraph = '';
      await this.$store.dispatch('getPicaPpList');
      this.$emit('close');
    },
    async register() {
      let reqData = {};
      let resultJsonIndex = 0;
      this.resultJson.forEach(res => {
        reqData[`agreement_${++resultJsonIndex}`] = res;
      });
      this.$log.debug('PicaPpDialog#register request data check :', reqData);
      this.$log.debug(
        'PicaPpDialog#register request data check :',
        btoa(encodeURIComponent(JSON.stringify(reqData)))
      );
      if (this.checkRedundancy()) {
        server
          .createPicaPp({
            key: this.versionTag,
            content: btoa(encodeURIComponent(JSON.stringify(reqData))),
          })
          .then(res => {
            this.$log.debug('PicaPpDialog#register ~', res);
            if (res.api_response.result_code == 'N100') {
              this.$store.dispatch('showRegistrationSnackbar', {
                message: '약관 가이드 등록이 완료되었습니다.',
              });
            }
          });

        this.close();
      }
    },
    async checkRedundancy() {
      if (await server.checkPicaPpKey({ key: this.versionTag })) {
        this.errMsg = '';
        this.redundancy = true;
        return true;
      } else {
        this.errMsg = '이미 등록된 약관 Key입니다.';
        this.redundancy = false;
        return false;
      }
    },
    notification() {
      this.redundancy = false;
      if (this.versionTag.length == 0) {
        this.redundancy = false;
        this.errMsg = '';
      }
      var decision = null;
      switch (this.type) {
        case 'guidance':
          decision = VALID.guideKey(this.versionTag);
          break;
        case 'tnc':
        case 'pp':
          decision = VALID.tncPpVersion(this.versionTag);
          break;
      }
      console.log(decision);

      if (!this.redundancy) {
        this.errMsg = '중복여부를 확인하세요';
      } else {
        this.errMsg = '';
      }
      if (!decision?.result) {
        this.errMsg = decision.msg;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.diag-title {
  padding: 40px 0 0 40px;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--surface-high);
}
.add-body {
  display: flex;
  flex-direction: column;
  margin: 0 40px 0 40px;
  max-height: 85vh;
}
.title-input {
  margin: 20px 0 8px 0;
}

.name {
  height: 56px;
  min-width: 542px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 24px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--redundancy {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    min-width: 90px;
    margin-left: 8px;
    color: var(--surface-100);
    background: var(--surface-medium);
    border-radius: 8px;
  }
  &--fail {
    border: 1px solid var(--error-100);
    caret-color: var(--error-100);
  }
}
.paragraph {
  max-width: 604px;
  min-width: 604px;
  resize: none;
  border: 1px solid var(--gray-300);
  padding: 16px 24px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--title {
    min-height: 56px;
    border-radius: 8px 8px 0 0;
    border-bottom: 0px solid var(--gray-300) !important;
    margin-bottom: 0.5px;
  }
  &--content {
    margin-top: 0.5px;
    min-height: 274px;
    border-radius: 0 0 8px 8px;
    border-top: 1px solid var(--gray-300) !important;
  }
}
.name:focus,
.paragraph:focus {
  outline: 1px solid var(--primary-80);
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
}
.name--fail:focus {
  outline: 2px solid red;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 8% 0;
  .default {
    width: 248px;
    color: var(--surface-100);
    background: var(--primary-20);
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    height: 60px;
    cursor: pointer;

    &.cancel {
      width: 162px;
      color: var(--surface-medium);
      margin-right: 9px;
      background: var(--gray-100);
    }

    &.ok {
      width: 248px;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
