var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],class:{ 'dropdown-root': true },style:(("width:" + _vm.width + ";height:" + _vm.height + ";")),on:{"click":_vm.onClickDropdown}},[(_vm.open)?_c('div',{staticClass:"dropdown-container-open"},[(_vm.multi)?_c('div',{staticClass:"dropdown-item multi",on:{"click":_vm.close}},[_c('div',{staticClass:"text text-truncate",style:("")},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('img',{style:(("margin-right: " + _vm.iconMargin)),attrs:{"width":_vm.iconSize,"src":require("@/assets/icon-dropdown-close.svg")}})]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:{
        'dropdown-item': true,
        multi: _vm.multi,
        item: true,
        check: _vm.multi && _vm.select.includes(item),
      },style:(("height:" + _vm.height + ";" + (index == 0
            ? 'color:var(--surface-medium)'
            : 'color:var(--surface-high)'))),on:{"click":function($event){return _vm.onClickItem(index, $event)}}},[(_vm.multi)?_c('div',{class:{ uncheck: true, check: _vm.select.includes(item) }},[(_vm.select.includes(item))?_c('img',{style:(("margin-right: " + _vm.iconMargin)),attrs:{"src":require("@/assets/icon-dropdown-check.svg"),"width":_vm.iconSize}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"text",style:(("padding:" + _vm.pad))},[_vm._v(_vm._s(item))]),(!_vm.multi && index === 0)?_c('img',{style:(("margin-right: " + _vm.iconMargin)),attrs:{"src":require("@/assets/icon-dropdown-close.svg"),"width":_vm.iconSize}}):_vm._e()])}),(_vm.multi)?_c('div',{staticClass:"dropdown-item multi",on:{"click":_vm.onClickClear}},[_c('div',{staticClass:"text",staticStyle:{"font-size":"12px"}},[_vm._v("선택 해제")])]):_vm._e()],2):_c('div',{staticClass:"dropdown-container-close",style:(("border:" + _vm.border))},[_c('div',{staticClass:"text-truncate",style:(("flex-grow: 1; padding:" + _vm.pad + ";" + (_vm.items[0] == _vm.select
            ? 'color:var(--surface-medium)'
            : 'color:var(--surface-high)') + ";"))},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('img',{style:(("margin-right: " + _vm.iconMargin)),attrs:{"src":require("@/assets/icon-dropdown-open.svg"),"width":_vm.iconSize}})])])}
var staticRenderFns = []

export { render, staticRenderFns }