<template>
  <section class="eec-expand-half">
    <div class="eec-flex-row" style="margin-top: 56px">
      <div class="label-key">신분증 정보</div>
      <div class="label-value">
        {{ idType }}
      </div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">사진 첨부 여부</div>
      <div v-if="attachedPhoto" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">직접 입력 여부</div>
      <div v-if="manualInput" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">정보 수정 여부</div>
      <div v-if="idCardModified" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>

    <div>
      <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key-list">· 이름</div>
        <div
          :class="{
            'label-value': !isModifiedData.user_name,
            'label-value--red': isModifiedData.user_name,
          }"
        >
          {{ itemData.name }}
        </div>
      </div>

      <div
        v-if="!isModifiedData.foreign_id_number"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· {{ idNumType }}</div>
        <div class="label-value">
          {{ foreignIdNumber }}
        </div>
      </div>
      <div
        v-if="detailedItemData.id_card_modified"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div v-if="isModifiedData.id_number" class="label-key-list">
          · {{ idNumType }}
        </div>
        <div v-if="isModifiedData.id_number" class="label-value--red">
          {{ modifiedForeignIdNumber }}
        </div>
      </div>

      <div
        v-if="!isModifiedData.created_at"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 발급 일자</div>
        <div class="label-value">
          {{ createdAt }}
        </div>
      </div>
      <div
        v-if="isModifiedData.created_at"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 발급 일자</div>
        <div class="label-value--red">
          {{ modifiedCreatedAt }}
        </div>
      </div>
    </div>

    <div class="eec-flex-row" style="margin-top: 24px">
      <div class="label-key">신분증 진위확인</div>
      <div v-if="idCardStatus" class="label-value">확인</div>
      <div v-else class="label-value--red">실패</div>
    </div>
    <div class="eec-split-line"></div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    isModifiedData: {
      type: Object,
      default: () => {},
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    detailedItemData: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  data() {
    return {};
  },
  computed: {
    createdAt() {
      return this.detailedItemData.created_at;
    },
    foreignIdNumber() {
      return this.detailedItemData.foreign_id_number;
    },
    idCardModified() {
      return this.detailedItemData.id_card_modified;
    },
    idCardStatus() {
      return this.detailedItemData.id_card_status;
    },
    idNumType() {
      return this.detailedItemData.id_num_type;
    },
    idType() {
      return this.detailedItemData.id_type;
    },
    modifiedCreatedAt() {
      return this.detailedItemData.modified_created_at;
    },
    modifiedForeignIdNumber() {
      return this.detailedItemData.modified_foreign_id_number;
    },
    modified() {
      return this.detailedItemData.modified_foreign_id_number;
    },
    attachedPhoto() {
      return this.detailedItemData.is_uploaded;
    },
    manualInput() {
      return this.detailedItemData.is_manual_input;
    },
  },
  methods: {},
};
</script>
