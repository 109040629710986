<template>
  <v-dialog
    v-model="value"
    max-width="500px"
    @click:outside="close"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-text style="padding: 0; margin: 0; overflow-y: scroll">
        <header class="diag-title">정보 수정</header>
        <div class="add-body">
          <div>
            <div class="title-input">고객사명</div>
            <div style="display: block">
              <input
                readonly
                :class="{
                  'reviewer-input-form--default': true,
                }"
                v-model="defaultUserInfo.company"
              />
            </div>
            <div class="title-input">아이디</div>
            <div style="display: block">
              <input
                readonly
                :class="{
                  'reviewer-input-form--default': true,
                }"
                v-model="defaultUserInfo.username"
              />
            </div>
            <CustomInputTextForm
              :necessary="true"
              validity="name"
              :init-val="reviewerName"
              title="이름"
              placeholder="이름을 입력하세요"
              @set="
                $event => {
                  reviewerName = $event.val;
                  reviewerNameCheck = $event.res;
                }
              "
            />

            <CustomInputTextForm
              validity="phone"
              :necessary="true"
              title="전화번호"
              :init-val="reviewerPhone"
              placeholder="‘-’를 포함하여 입력하세요."
              @set="
                $event => {
                  reviewerPhone = $event.val;
                  reviewerPhoneCheck = $event.res;
                }
              "
            />
            <CustomInputTextForm
              :necessary="true"
              validity="email"
              title="이메일"
              :needCheck="true"
              placeholder="직접입력"
              :init-val="reviewerEmail"
              @click="reviewerEmailCheck ? email(reviewerEmail) : null"
              @set="
                $event => {
                  reviewerEmail = $event.val;
                  reviewerEmailCheck = $event.res;
                  reviewerEmailDuplicationCheck = '';

                  if (reviewerEmailCheck) {
                    reviewerEmailDuplicationCheck = '중복여부를 확인하세요.';
                  }
                }
              "
            />
            <div
              v-if="reviewerEmailDuplicationCheck"
              :key="`${reviewerEmailDuplicationCheck}-check`"
              :style="
                `font-size: 12px;
                ${
                  reviewerEmailDuplicationCheck.includes('완료')
                    ? 'color:var(--primary-100);'
                    : 'color:var(--error-100);'
                }`
              "
            >
              {{ reviewerEmailDuplicationCheck }}
            </div>

            <CustomInputTextForm
              :necessary="true"
              title="기존 비밀번호"
              validity="password"
              placeholder="비밀번호를 입력하세요."
              :is-password="true"
              @set="
                $event => {
                  reviewerPass = $event.val;
                  reviewerPassChecked = $event.res;
                }
              "
              :init-val="reviewerPass"
              :no-title="true"
              :for-login="true"
              :info-font-small="true"
            />
            <CustomInputTextForm
              title="새 비밀번호"
              validity="individual-password"
              placeholder="새 비밀번호를 입력하세요."
              :is-password="true"
              @set="
                $event => {
                  newPass = $event.val;
                  newPassChecked = $event.res;
                }
              "
              :init-val="newPass"
              :no-title="true"
              :for-login="true"
              :info-font-small="true"
            />
            <CustomInputTextForm
              title="새 비밀번호 확인"
              validity="password"
              placeholder="새 비밀번호를 재입력하세요."
              :is-password="true"
              @set="
                $event => {
                  newPassCheck = $event.val;
                  errorCheck();
                }
              "
              :init-val="newPassCheck"
              :no-title="true"
              :for-login="true"
              :info-font-small="true"
            />
            <div
              v-if="newPassCheckChecked"
              style="color: var(--error-100); font-size: 12px"
            >
              {{ newPassCheckChecked }}
            </div>
          </div>
          <div class="button-container">
            <div v-ripple @click="close" class="button cancel">취소</div>
            <div
              v-if="
                reviewerName != '' &&
                  reviewerEmail != '' &&
                  reviewerPhone != '' &&
                  reviewerPass != ''
              "
              v-ripple
              @click="register"
              class="button ok"
            >
              등록
            </div>
            <div v-else class="button default" style="cursor: unset">등록</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
import server from '../../../api/server';
import Vue from 'vue';
export default {
  components: { CustomInputTextForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
    },
    defaultUserInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.reviewerName = this.defaultUserInfo.name;
    this.reviewerNameCheck = true;
    this.reviewerEmail = this.defaultUserInfo.email;
    this.reviewerEmailCheck = true;
    this.reviewerPhone = this.defaultUserInfo.phone_number;
    this.reviewerPhoneCheck = true;
  },

  data() {
    return {
      compName: '',
      compId: '',
      reviewerId: '',
      reviewerPhone: '',
      reviewerPhoneCheck: undefined,
      reviewerName: '',
      reviewerNameCheck: undefined,
      reviewerEmail: '',
      reviewerEmailCheck: undefined,
      reviewerEmailDuplicationCheck: undefined,

      reviewerPass: '',
      rpVisible: false,
      reviewerPassChecked: undefined,
      newPass: '',
      npVisible: false,
      newPassChecked: undefined,
      newPassCheck: '',
      npcVisible: false,
      newPassCheckChecked: '',
    };
  },
  methods: {
    async close() {
      //initialize
      this.compId = '';
      this.compName = '';
      this.reviewerId = '';
      this.reviewerPhone = '';
      this.reviewerPhoneCheck = undefined;
      this.reviewerName = '';
      this.reviewerNameCheck = undefined;
      this.reviewerEmail = '';
      this.reviewerEmailCheck = undefined;
      this.reviewerPass = '';
      this.rpVisible = false;
      this.reviewerPassChecked = undefined;
      this.newPass = '';
      this.npVisible = false;
      this.newPassChecked = undefined;
      this.newPassCheck = '';
      this.npcVisible = false;
      this.newPassCheckChecked = '';
      this.$emit('close');
    },
    email(email) {
      this.$log.debug('ModifyUserInfo#email', email);
      if (email) {
        this.reviewerEmailDuplicationCheck = '중복확인이 완료되었습니다.';
      }

      //need api
      this.reviewerEmailDuplicationCheck = '중복확인이 완료되었습니다.';
    },

    async register() {
      //TODO
      const data = await server.modifyMypageInfo({
        customer_id: Number(this.defaultUserInfo.customer_id),
        username: this.defaultUserInfo.username,
        name: this.reviewerName,
        email: this.reviewerEmail,
        phone_number: this.reviewerPhone,
        password: this.reviewerPass,
        new_password: this.newPass,
      });
      Vue.$log.debug(data);
      if (data.result_code == 'N100') {
        this.$store.dispatch('showRegistrationSnackbar', {
          message: '계정 정보 수정이 완료되었습니다.',
        });
        this.close();
      }
    },
    errorCheck() {
      this.$log.debug('ModifyUserInfo#errorCheck', this.newPassCheck.length);
      if (this.newPassCheck.length == 0) {
        this.newPassCheckChecked = '비밀번호를 재입력하세요.';
      } else if (this.newPass != this.newPassCheck) {
        this.newPassCheckChecked = '입력하신 비밀번호가 일치하지 않습니다.';
      } else if (this.newPass == this.newPassCheck) {
        this.newPassCheckChecked = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.diag-title {
  padding: 6% 0 0 6%;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--surface-high);
}
.add-body {
  display: flex;
  flex-direction: column;
  margin: 0 6% 0 6%;
  max-height: 85vh;
}
.title-input {
  margin: 20px 0 8px 0;
  &--first {
    float: left;
    margin-bottom: 8px;
  }
  &--second {
    float: left;
    margin-bottom: 8px;
    color: red;
  }
}
.dropdown-list {
  position: absolute;
  outline: 2px solid var(--primary-80);
  width: 88%;
  padding: 0 16px;
  max-height: 110px;
  margin-top: 56px;
  overflow-y: auto;
  background: var(--surface-100);
  border-radius: 8px;
}
.dropdown-item {
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 24px;
  cursor: pointer;
}

.reviewer-input-form {
  height: 56px;
  width: 100%;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--redundancy {
    width: 27%;
    margin-left: 3%;
    color: var(--surface-100);
    background: var(--surface-medium);
    border-radius: 8px;
  }
  &--fail {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    border: 1px solid red;

    caret-color: red;
  }
  &--default {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    border: 1px solid var(--surface-disabled);
    color: var(--surface-medium);
    background: var(--gray-100);
  }
}
.reviewer-input-form:focus {
  outline: 1px solid var(--primary-80);
}
.reviewer-input-form--fail:focus {
  outline: 1px solid red;
}
.reviewer-input-form--default:focus {
  outline: 0px solid var(--surface-disabled);
}
.client-generic-button-left {
  width: 50%;
  height: 56px;
  color: var(--surface-medium);
  background: var(--surface-100);
  border: 1px solid var(--gray-300);
  border-radius: 8px 0 0 8px;
  &--checked {
    width: 50%;
    height: 56px;
    color: var(--surface-medium);
    border: 1px solid var(--primary-80);
    border-radius: 8px 0 0 8px;
    background: var(--surface-100);
  }
}
.client-generic-button-right {
  width: 50%;
  height: 56px;
  color: var(--surface-medium);
  background: var(--surface-100);
  border: 1px solid var(--gray-300);
  border-radius: 0 8px 8px 0;
  &--checked {
    width: 50%;
    height: 56px;
    color: var(--surface-medium);
    background: var(--surface-100);
    border: 1px solid var(--primary-80);
    border-radius: 0 8px 8px 0;
  }
}

.button-container {
  display: flex;
  width: 100%;
  padding: 8% 0;
  .default {
    width: 60%;

    color: var(--surface-100);
    background: var(--primary-20);
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;

    &.cancel {
      width: 40%;
      color: var(--surface-medium);
      margin-right: 9px;
      background: var(--gray-100);
    }

    &.ok {
      width: 60%;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border: solid 3px transparent;
  box-shadow: inset 0 0 10px 10px var(--gray-300);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--gray-300);
}
</style>
