var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":""},on:{"click:outside":function () {},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{staticStyle:{"border-radius":"8px"}},[_c('v-card-text',{staticStyle:{"padding":"0","margin":"0"}},[_c('div',{staticClass:"confirm-title"},[_vm._v("활성 정보 수정")]),_c('div',{staticClass:"content-paragraph"},[_c('div',[_c('div',{staticClass:"content-user-info"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.confirmInfos.item.reviewer_name)+" ")]),_c('div',{staticClass:"id"},[_vm._v(" "+_vm._s(_vm.confirmInfos.item.reviewer_id)+" ")])]),_c('div',{staticClass:"content-change-info"},[_c('div',{class:{
                active: _vm.confirmInfos.value,
                active: !_vm.confirmInfos.value,
              }},[_vm._v(" "+_vm._s(_vm.confirmInfos.value ? "비활성" : "활성")+" ")]),_c('img',{staticStyle:{"width":"20px","margin":"0px 4px"},attrs:{"src":require("@/assets/arrow-right.svg")}}),_c('div',{class:{
                active: _vm.confirmInfos.value,
                active: !_vm.confirmInfos.value,
              },staticStyle:{"color":"var(--primary-100)"}},[_vm._v(" "+_vm._s(_vm.confirmInfos.value ? "활성" : "비활성")+" ")])])]),_c('div',{staticStyle:{"width":"336px","margin":"16px 0px","border-bottom":"1px solid var(--gray-300)"}}),_c('div',{staticClass:"content-paragraph-text"},[_vm._v(" 해당 계정의 활성 상태를 변경하시겠습니까? ")]),_c('div',{staticClass:"content-paragraph-text"},[_vm._v(" 활성 여부를 전환할 경우 서비스에 바로 반영됩니다. ")])]),_c('div',{staticClass:"button-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button cancel",on:{"click":_vm.close}},[_vm._v("취소")]),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button ok",on:{"click":function($event){return _vm.confirm(
              _vm.confirmInfos.item.reviewer_id,
              _vm.confirmInfos.value,
              _vm.confirmInfos.item.id
            )}}},[_vm._v(" 확인 ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }