export const subModules = {
  managementList: function(payload) {
    return {
      //common
      id: payload.id,
      company: payload.name,
      kind:
        payload.industry == 1
          ? '비금융권'
          : payload.industry == 2
          ? '금융권'
          : payload.industry == 3
          ? '가상자산사업자'
          : payload.industry == 4
          ? 'P2P온투업자'
          : '',
      purchase_type: payload.purchase_type == 1 ? '솔루션' : 'API',
      status: payload.active == 1 ? '활성' : '비활성',
      phone: payload.phone_number ? payload.phone_number : '01000000000',
    };
  },
  adminList: function(payload) {
    var list = {};
    list['id'] = payload.id;
    list['admin_id'] = payload.username ?? '더미아이디';
    list['admin_name'] = payload.name ?? '더미이름';
    list['admin_phone'] = payload.phone_number ?? '01079077907';
    list['company_name'] = payload.company_name ?? '';
    list['admin_mail'] = payload.email ?? 'jypark@gmail.com';
    list['activation'] = payload.active ?? false;
    list['is_approve'] = payload.is_approve ?? false;
    return list;
  },
  managementDetailList: function(payload) {
    return {
      //common
      isModified: false,
      client_setting_data: {
        id_card_scenario: {
          card_type: '신분증 확인',
          card_expression:
            '딥러닝 기반의 OCR기술 및 빠른 진위확인으로 금융실명법 비대면 인증 기준에 적합한 보안 인증 솔루션',
          id_card_toggle: payload.id_card_ocr && payload.id_card_verification,
          face_comparison_toggle: false,
          passive_liveness_toggle: false,
          one_point_auth_toggle: false,
        },
        face_comparison_scenario: {
          card_type: '얼굴 확인',
          card_expression:
            'AI기반 안면인식 API와 Liveness API로 고객 얼굴의 고유한 특징 데이터들을 통해 유사도 파악 및 인증 솔루션',
          id_card_toggle: false,
          face_comparison_toggle: payload.face_authentication,
          passive_liveness_toggle: payload.liveness,
          one_point_auth_toggle: false,
        },
        one_point_auth_scenario: {
          card_type: '기존 계좌 인증',
          card_expression:
            '1원과 함께 인증코드(숫자 4자리)를 전송하여 인증하는 솔루션',
          id_card_toggle: false,
          face_comparison_toggle: false,
          passive_liveness_toggle: false,
          one_point_auth_toggle: payload.account_verification,
        },
        configs: [
          {
            key: 'identification',
            value: 'true',
          },
          {
            key: 'sms_notification',
            value: 'true',
          },
        ],
      },
      client_initial_data: {
        id_card_scenario: {
          card_type: '신분증 확인',
          card_expression:
            '딥러닝 기반의 OCR기술 및 빠른 진위확인으로 금융실명법 비대면 인증 기준에 적합한 보안 인증 솔루션',
          id_card_toggle: payload.id_card_ocr && payload.id_card_verification,
          face_comparison_toggle: payload.face_authentication,
          passive_liveness_toggle: payload.liveness,
          one_point_auth_toggle: payload.account_verification,
        },
        face_comparison_scenario: {
          card_type: '얼굴 확인',
          card_expression:
            'AI기반 안면인식 API와 Liveness API로 고객 얼굴의 고유한 특징 데이터들을 통해 유사도 파악 및 인증 솔루션',
          id_card_toggle: payload.id_card_ocr && payload.id_card_verification,
          face_comparison_toggle: payload.face_authentication,
          passive_liveness_toggle: payload.liveness,
          one_point_auth_toggle: payload.account_verification,
        },
        one_point_auth_scenario: {
          card_type: '기존 계좌 인증',
          card_expression:
            '1원과 함께 인증코드(숫자 4자리)를 전송하여 인증하는 솔루션',
          id_card_toggle: payload.id_card_ocr && payload.id_card_verification,
          face_comparison_toggle: payload.face_authentication,
          passive_liveness_toggle: payload.liveness,
          one_point_auth_toggle: payload.account_verification,
        },
        configs: [
          {
            key: 'identification',
            value: 'true',
          },
          {
            key: 'sms_notification',
            value: 'true',
          },
        ],
      },
    };
  },
};
