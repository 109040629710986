<template>
  <div
    :class="
      `signin-container full-size ${
        $route.query.role == 'reviewer'
          ? 'signin-background'
          : 'signin-admin-background'
      }`
    "
    style="display: flex; justify-content: center"
  >
    <FindCridential
      v-if="displayType == 1"
      style="padding-bottom: 8px"
      class="signin-card"
      @result="selectDisplayType($event)"
      @mailError="mailError = true"
      :key="`${displayType}-Find`"
    />
    <ResultCridential
      style="padding-bottom: 8px"
      class="signin-card"
      v-if="
        displayType == 10 ||
          displayType == 11 ||
          displayType == 12 ||
          displayType == 13
      "
      @retry="displayType = 1"
      @mailError="mailError = true"
      :type="displayType"
      :payload="typeInformation"
      :key="`${displayType}-Result`"
    />
    <SystemErrorDialog
      v-if="mailError"
      v-model="mailError"
      :errorCode="''"
      @ok="mailError = false"
    />
  </div>
</template>
<script>
import FindCridential from '@/components/reviewer/signin/FindCridential.vue';
import ResultCridential from '@/components/reviewer/signin/ResultCridential.vue';
import SystemErrorDialog from '@/components/dialog/MailFailureDialog.vue';
export default {
  name: 'SignUp',
  components: {
    FindCridential,
    ResultCridential,
    SystemErrorDialog,
  },
  data() {
    return {
      displayType: 1,
      typeInformation: null,
      mailError: false,
    };
  },

  computed: {},
  methods: {
    selectDisplayType(value) {
      this.displayType = value.type;
      this.typeInformation = value.payload;
    },
  },
};
</script>
<style lang="scss" scoped></style>
