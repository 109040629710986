<template>
  <v-dialog
    v-model="dialog"
    max-width="501px"
    max-height="378px"
    @click:outside="ok"
    @keydown.esc="ok"
    persistent
  >
    <v-card style="border-radius: 8px">
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div class="dialog--title">시스템 에러</div>
        <div class="dialog--content">
          시스템 에러가 발생하였습니다.<br />
          잠시 후 다시 이용해 주세요.
        </div>
        <div class="dialog--paragraph">
          계속해서 문제가 발생한다면 고객센터로 문의해주세요.
        </div>
        <div class="dialog--paragraph" style="margin-bottom: 24px">
          [전화] 070-4849-1000<br />
          [이메일] cs@useb.co.kr
        </div>
        <div
          style="
            margin-bottom: 24px;
            font-size: 16px;
            line-height: 26px;
            color: var(--surface-medium);
            text-align: center;
          "
        >
          에러코드 : {{ errorCode }}
        </div>

        <div class="button-container" style="margin-bottom: 32px">
          <SubmitButton
            width="144px"
            height="48px"
            font-size="20px"
            button-string="확인"
            bg-dim-color="var(--primary-20)"
            bg-color="var(--primary-100)"
            text-dim-color="var(--surface-100)"
            text-color="var(--surface-100)"
            @click="ok"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
  components: {
    SubmitButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errorCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    ok() {
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &--title {
    text-align: center;
    margin-top: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: var(--surface-high);
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &--content {
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--primary-100);
    text-align: center;
  }
  &--paragraph {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-high);
    text-align: center;
  }
}
.button-container {
  display: flex;
  margin: auto;
  margin-bottom: 40px;
  padding: 0 30px;
}
</style>
