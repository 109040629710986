export const subModules = {
  isHangeul: function(str) {
    return /^[가-힣]+$/.test(str); //eslint-disable-line
  },
  isHangeulOrEng: function(str) {
    return /^[가-힣]+$/.test(str) || /^[a-zA-Z]+$/.test(str);
  },
  isEmail: function(str) {
    return /^[a-z0-9\.\-_]+@([a-z0-9\-]+\.)+[a-z]{2,6}$/.test(str); //eslint-disable-line
  },
  isPhone: function(str) {
    return /^[0-9-+\(\)\s]{6,20}$/.test(str); //eslint-disable-line
  },
  isGuidanceKey: function(str) {
    return /^[0-9a-z-]{1,64}$/.test(str); //eslint-disable-line
  },
  isVersionKey: function(str) {
    return /^\d[0-9.]{0,6}\d$/.test(str); //eslint-disable-line
  },
  isCompCode: function(str) {
    return /^[0-9]{0,6}$/.test(str); //eslint-disable-line
  },
  idFormat: function(str) {
    return /^[a-zA-Z0-9]{8,20}$/.test(str); //eslint-disable-line
  },
  passwordFormat: function(str) {
    let regNumSpc = /(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
    let regCase = /(?=.*?[a-z])(?=.*?[A-Z])/;
    return regNumSpc.test(str) && regCase.test(str);
  },
};
