<template>
  <div>
    <div>
      <div class="additional-service-set">부가 서비스</div>
      <div style="display: flex; margin-top: 10px">
        <div class="additional-service-key">· 고객 신원 확인 결과</div>
      </div>
    </div>
    <div style="margin: 8px 0 0 16px">
      <div
        style="
          display: flex;
          height: 24px;
          width: 130px;
          margin-bottom: 8px;
          justify-content: space-between;
        "
      >
        <div class="email-service" style="margin-right: 4px">
          ㄴ 이메일 발송
        </div>
        <div
          class="flex-center"
          style="margin-right: 16px; color: var(--primary-80)"
        >
          ON
        </div>
      </div>
      <SubModuleSettingComponent
        style="width: 130px"
        :sub-module-lists="['SMS 발송']"
        :initData="[sendSms]"
        :edit-mode="editMode"
        @module="$emit('module', $event)"
      />
    </div>
  </div>
</template>

<script>
import SubModuleSettingComponent from '@/components/root/module/SubModuleSettingComponent.vue';
export default {
  props: {
    editMode: {
      type: Boolean,
      default: () => false,
    },
    sendSms: {
      type: Boolean,
      default: () => false,
    },
  },
  components: { SubModuleSettingComponent },
  created() {},
  data() {
    return {
      adminCount: 0,
      reviewerCount: 0,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.email-service {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: flex;
  align-items: center;
  /* On Surface/Medium Emphasis */
  color: var(--surface-medium);
}
.additional-service {
  &-set {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--surface-high);
  }
  &-value {
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-80);
  }
  &-edit {
    position: absolute;
    width: 56px;
    height: 24px;
    margin: 0 8px;
    -moz-appearance: textfield;
    border: 1px solid var(--gray-200);
    border-radius: 4px;
  }
  &-edit:focus {
    outline: none;
  }
  &-button-up {
    position: absolute;
    top: 3px;
    right: 11px;
  }
  &-button-down {
    position: absolute;
    top: 13px;
    right: 11px;
  }
  display: flex;
}
.additional-service-edit::-webkit-inner-spin-button,
.additional-service-edit::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
