<template>
  <div class="root-container">
    <canvas id="canvas_crop" ref="canvas_crop" style="display: none"></canvas>
    <!-- phase1: card capture -->
    <div v-if="phase === PHASE_SCAN" class="container-phase-scan">
      <video
        playsinline
        ref="video"
        autoplay
        style="position: absolute; left: 0; top: 0; z-index: -1; display: none"
        controls
      >
        Video stream not available.
      </video>
      <canvas
        ref="canvas"
        style="position: absolute; left: 0; top: 0; display: none"
      ></canvas>
      <div style="height: 100%">
        <svg
          width="100%"
          height="100%"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="position: absolute; left: 0"
        >
          <mask id="mask-rect">
            <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
            <rect
              :x="rectX"
              :y="rectY"
              :width="rectWidth"
              :height="rectHeight"
              rx="10"
              ry="10"
              fill="black"
              stroke="black"
            />
          </mask>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="#202023B2"
            mask="url(#mask-rect)"
          ></rect>
          <svg
            :x="rectX - 4"
            :y="rectY"
            :width="rectGuideWidth"
            :height="rectHeight"
            viewBox="0 0 30 264"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28 262H10C5.58172 262 2 258.418 2 254V10C2 5.58172 5.58172 2 10 2H28"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            :x="rectX + rectWidth - rectGuideWidth"
            :y="rectY"
            :width="rectGuideWidth"
            :height="rectHeight"
            viewBox="0 0 30 264"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 262H20C24.4183 262 28 258.418 28 254V10C28 5.58172 24.4183 2 20 2H2"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </svg>
      </div>
      <div ref="captureContainer" class="text-info-container">
        <div class="text-info">
          영역 안에 신분증이 꽉 차도록 배치 후<br />하단 버튼을 누르면
          촬영됩니다.
        </div>
        <div class="button-capture">
          <!-- 카메라 버튼 -->
          <img
            ref="capture"
            @click="capture"
            width="80px"
            src="@/assets/Camera.svg"
          />
        </div>
      </div>
      <div style="position: absolute; left: 0; top: 0; color: white">
        {{ status }}
      </div>
      <div
        style="
          position: absolute;
          top: 32px;
          width: 100%;
          text-align: center;
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: var(--surface-100);
        "
      >
        신분증 인증
      </div>
      <img
        @click="stopScan = true"
        src="@/assets/icon-close.svg"
        width="48px"
        class="close"
      />
    </div>
    <!-- phase2: loading -->
    <div v-if="phase === PHASE_LOADING" class="container-phase-loading">
      <img
        src="@/assets/Loading.svg"
        width="172px"
        style="margin-bottom: 50px"
      />
      <img
        src="@/assets/Loading_40px.svg"
        width="40px"
        style="margin-bottom: 16px"
      />
      <div style="font-weight: bold; font-size: 24px; margin-bottom: 8px">
        잠시만 기다려주세요.
      </div>
      <div style="font-size: 16px; color: var(--surface-medium)">
        신분증 정보를 확인 중입니다.
      </div>
    </div>
    <!-- modal -->
    <ServerErrorDialog
      v-model="serverError"
      :icon="errorIcon"
      :title="errorMessageTitle"
      :message="errorMessage"
      :errorCode="errorCode"
      :button="errorButton"
      @cancel="onClickBack"
      @ok="onClickRetry"
    />
    <ExitDialog v-model="stopScan" @ok="$emit('cancel')" @cancel="cancelExit" />
    <CardScanConfirmDialog
      v-model="confirmDialog"
      :image="base64MaskCardImage"
      @ok="onConfirmOk"
      @cancel="onClickRetry"
    />
    <v-file-input
      id="cardScanFileInput"
      accept=".jpg,.jpeg,.png"
      v-model="uploadFile"
      @change="onChangeFile"
    />
    <FileUploadErrorDialog
      :show="showFileUploadErrorDialog"
      :error-type="fileUploadErrorType"
      @ok="onConfirmFileUploadError"
    />
  </div>
</template>

<script>
import Constants from '@/constants';
import useb from '@/api/useb';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import ExitDialog from '../dialog/ExitDialog';
import CardScanConfirmDialog from '../dialog/CardScanConfirmDialog';
import FileUploadErrorDialog from '../dialog/FileUploadErrorDialog';
import * as faceApi from './face-sdk-impl.js';
// import * as netfunnel from './netfunnel.js';
//import * as netfunnelSkin from "./netfunnel-skin.js";
//console.log(netfunnelSkin);   // prevent build error for unused import file
import { mapState } from 'vuex';

export default {
  components: {
    ExitDialog,
    ServerErrorDialog,
    CardScanConfirmDialog,
    FileUploadErrorDialog,
  },
  props: {
    appData: Object,
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function() {
    return {
      origin: '',
      PHASE_SCAN: 1,
      PHASE_LOADING: 2,
      phase: 1,
      confirmDialog: false,
      base64MaskCardImage: '',
      tmpAppData: {},
      stopScan: false,
      systemError: false,
      serverError: false,
      errorIcon: '',
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorCodeOrigin: '',
      errorButton: [],
      errorCodeControlList: [
        'CE003',
        'O003',
        'O005',
        'O010',
        'M003',
        'O021',
        'O022',
      ],
      cardGuideIcon: require('@/assets/icon-card-guide.svg'),
      streaming: false,
      facingMode: 'environment' /** front: user, back: environment */,
      stream: null,
      card: { ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0 },
      // styles
      rectStrokeWidth: 4,
      rectX: 2,
      rectY: 2,
      rectWidth: 0,
      rectHeight: 0,
      rectGuideWidth: 30,
      // face status
      status: '',
      permissionTimeout: null,
      callbackWindowResize: null,
      captureProcess: false,
      MAX_RETRY_COUNT: 5,
      // MAX_RETRY_COUNT: 1,
      lastValidBase64Card: null,
      uploadFile: null,
      showFileUploadErrorDialog: false,
      fileUploadErrorType: '',
      uploadType: 'camera',
      inErrorProgress: false,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber', 'isMobile']),
  },
  async created() {
    this.stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    this.stopStream();
    this.initialize();
  },
  mounted() {
    this.$log.debug('CardScan#mounted appData', this.appData);
    this.callbackWindowResize = () => this.adjustStyles();
    window.addEventListener('resize', this.callbackWindowResize);
  },
  beforeDestroy() {
    this.closeCamera();
    window.removeEventListener('resize', this.callbackWindowResize);
    this.lastValidBase64Card = null;
  },
  methods: {
    cancelExit() {
      if (this.inErrorProgress) {
        this.stopScan = false;
        this.serverError = true;
        this.inErrorProgress = false;
      } else {
        this.stopScan = false;
      }
    },
    proceedCameraPermission() {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        let camera = [];
        for (const device of devices) {
          if (device.kind === 'videoinput') {
            if (device.getCapabilities) {
              const cap = device.getCapabilities();
              if (cap?.facingMode?.includes(this.facingMode)) {
                camera.push(cap.deviceId);
              }
            }
          }
        }
        navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: camera.length
                ? { ideal: camera[camera.length - 1] }
                : null,
              width: { ideal: 1920 },
              height: { ideal: 1920 },
              facingMode: { ideal: this.facingMode },
              focusMode: { ideal: 'continuous' },
              whiteBalanceMode: { ideal: 'continuous' },
              zoom: { ideal: 1 },
            },
            audio: false,
          })
          .then(stream => {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;
            this.stream = stream;
            if (video) {
              const [track] = stream.getVideoTracks();
              const capability = track.getCapabilities();
              this.$log.debug('CardScan#initialize capability', capability);
              if ('srcObject' in video) {
                video.srcObject = stream;
              } else {
                // Avoid using this in new browsers, as it is going away.
                video.src = window.URL.createObjectURL(stream);
              }
              video.addEventListener('loadedmetadata', () => {
                this.$log.debug('CardScan#initialize~onloadedmetadata');
                video.play();
              });
              video.addEventListener('canplay', () => {
                this.$log.debug('CardScan#initialize~canplay', this.streaming);
                if (!this.streaming) {
                  canvas.setAttribute('width', video.videoWidth);
                  canvas.setAttribute('height', video.videoHeight);
                  this.render();
                  this.$log.debug(
                    'CardScan#initialize~canplay',
                    video.videoWidth,
                    video.videoHeight,
                    video.clientWidth,
                    video.clientHeight,
                    this.rectWidth
                  );
                  this.streaming = true;
                  // this.render(); // auto detection start
                }
              });
              video.webkitExitFullscreen();
            } else {
              this.closeCamera();
            }
          })
          .catch(e => {
            this.$log.error('alcherakyc error', e.name, e);
            if (e.name === 'NotAllowedError') {
              this.checkCameraPermission();
            } else if (e.name === 'NotReadableError') {
              this.stopStream();
              this.checkCameraPermission();
            }
          });
      });
    },
    initialize() {
      this.streaming = false;
      this.systemError = false;
      this.serverError = false;
      const supports = navigator.mediaDevices.getSupportedConstraints();
      this.$log.debug('CardScan#initialize supports', supports);
      this.proceedCameraPermission();
    },
    checkCameraPermission() {
      clearTimeout(this.permissionTimeout);
      this.permissionTimeout = setTimeout(() => {
        this.proceedCameraPermission();
      }, 1000);
    },
    checkCameraPlay() {
      this.$log.debug('CardScan#checkCameraPlay');
      if (this.phase === this.PHASE_SCAN) {
        this.stopStream();
        this.proceedCameraPermission();
      }
    },
    render() {
      let videoEl = this.$refs.video;
      let loop = async () => {
        if (
          videoEl.paused ||
          videoEl.ended ||
          !faceApi.isFaceDetectionModelLoadedSync() ||
          this.stopScan
        ) {
          // nothing
        } else {
          this.adjustStyles();
        }
        cancelAnimationFrame(this.requestAnimationFrameId);
      };
      cancelAnimationFrame(this.requestAnimationFrameId);
      this.requestAnimationFrameId = requestAnimationFrame(loop);
    },
    adjustStyles() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const parentElement = canvas.parentElement;
      const minWidth = 400;
      const cardRatio = 260 / minWidth;
      const rectGuideRatio = 30 / 400;
      let cssText = '';
      const ratio = video.clientWidth / video.clientHeight;
      const current = parentElement.clientWidth / parentElement.clientHeight;
      this.$log.debug('CardScan#adjustStyles', ratio, current);
      video.style.display = '';
      if (current < 1) {
        video.style.width = '';
        canvas.style.width = '';
        cssText = 'height: 100%;';
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;
        cssText = `transform: translateX(${-(
          video.clientWidth - parentElement.clientWidth
        ) / 2}px)`;
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;

        this.rectWidth = parentElement.clientWidth * 0.9;
        this.rectHeight = this.rectWidth * cardRatio;
      } else {
        video.style.height = '';
        canvas.style.height = '';
        cssText = 'width: 100%;';
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;
        cssText = `transform: translateY(${-(
          video.clientHeight - parentElement.clientHeight
        ) / 2}px);`;
        video.style.cssText += cssText;
        canvas.style.cssText += cssText;

        this.rectHeight = parentElement.clientHeight * 0.6;
        this.rectWidth = (this.rectHeight * minWidth) / 260;
      }
      this.rectGuideWidth = this.rectWidth * rectGuideRatio;
      this.rectX =
        parentElement.clientWidth / 2 -
        this.rectWidth / 2 +
        this.rectStrokeWidth / 2;
      this.rectY =
        parentElement.clientHeight / 2 -
        this.rectHeight / 2 +
        this.rectStrokeWidth / 2; // center

      let captureContainer = this.$refs.captureContainer;
      captureContainer && (captureContainer.style.display = 'inline-block');
    },
    // render() {
    //   let videoEl = this.$refs.video;
    //   // let canvas = this.$refs.canvas;

    //   let loop = async () => {
    //     if (videoEl.paused || videoEl.ended || this.stopScan) {
    //       // nothing
    //       this.card = { ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0, };
    //     } else {
    //       // const ts = Date.now();
    //       const result = false;
    //       // this.updateTimeStats(Date.now() - ts)
    //       if (result) {
    //         this.card = { ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0, };
    //       } else {
    //         this.card = { ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0, };
    //       }
    //       // this.processDetectLogic(videoEl);
    //     }
    //     cancelAnimationFrame(this.requestAnimationFrameId);
    //     this.requestAnimationFrameId = requestAnimationFrame(loop);
    //   };
    //   cancelAnimationFrame(this.requestAnimationFrameId);
    //   this.requestAnimationFrameId = requestAnimationFrame(loop);
    // },
    cropImage() {
      let video = this.$refs.video;
      let canvas = this.$refs.canvas;
      let canvas_crop = this.$refs.canvas_crop;

      const padding = 50;
      const width = video.videoWidth;
      const height = video.videoHeight;
      const videoSizeRatio = video.videoWidth / video.clientWidth;
      const parentElement = this.$refs.canvas.parentElement;
      let rect = {
        x:
          video.videoWidth / 2 -
          (parentElement.clientWidth / 2) * videoSizeRatio +
          this.rectX * videoSizeRatio,
        y: this.rectY * videoSizeRatio,
        w: this.rectWidth * videoSizeRatio,
        h: this.rectHeight * videoSizeRatio,
      };
      this.$log.debug('CardScan#cropImage', rect);
      let ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, width, height);
      ctx = canvas_crop.getContext('2d');
      canvas_crop.width = rect.w + padding * 2;
      canvas_crop.height = rect.h + padding * 2;
      ctx.drawImage(
        canvas,
        rect.x - padding,
        rect.y - padding,
        rect.w + padding * 2,
        rect.h + padding * 2,
        0,
        0,
        rect.w + padding * 2,
        rect.h + padding * 2
      );
      let image = canvas_crop.toDataURL('image/jpeg');
      return image;
    },
    async cropFaceImage(image) {
      let cardImage = new Image();
      cardImage.src = image;
      const result = await faceApi.detectSingleFaceForCard(cardImage);
      if (!result) {
        throw new useb.UsebError('CE002', '얼굴 감지 실패');
      }
      const box = result.detection.box;
      const face = { x: box.x, y: box.y, w: box.width, h: box.height };
      const padding = 100;
      const canvas_crop = this.$refs.canvas_crop;
      const ctx = canvas_crop.getContext('2d');
      canvas_crop.width = face.w + padding;
      canvas_crop.height = face.h + padding;
      const cropSizeX = face.x - padding / 2;
      const cropSizeY = face.y - padding / 2;
      ctx.drawImage(
        cardImage,
        cropSizeX < 0 ? 0 : cropSizeX,
        cropSizeY < 0 ? 0 : cropSizeY,
        face.w + padding,
        face.h + padding,
        0,
        0,
        face.w + padding,
        face.h + padding
      );
      return canvas_crop.toDataURL('image/jpeg');
    },
    stopStream() {
      cancelAnimationFrame(this.requestAnimationFrameId);
      if (this.stream) {
        this.stream.stop && this.stream.stop();
        let tracks = this.stream.getTracks && this.stream.getTracks();
        this.$log.debug('CardScan#stopStream', tracks);
        if (tracks && tracks.length) {
          tracks.forEach(track => track.stop());
        }
        this.stream = null;
      }
    },
    closeCamera() {
      clearTimeout(this.permissionTimeout);
      this.stopStream();
    },
    startManualInput() {
      let ocr = {
        userName: this.appData.userName,
        birthDate: this.appData.birthDate,
      };
      const juminNo1 = this.appData.birthDate
        .replace('-', '')
        .replace('-', '')
        .slice(2);
      switch (this.appData.cardIndex) {
        // 0
        case Constants.APP_CARD_INDEX.JUMIN:
          ocr = {
            ...ocr,
            juminNo1,
            juminNo2: '',
            _juminNo2: '0******',
            issueDate: '',
          };
          break;
        // 1
        case Constants.APP_CARD_INDEX.DRIVER:
          ocr = {
            ...ocr,
            driverNo: '',
            juminNo1,
            juminNo2: '',
            _juminNo2: '',
            issueDate: '',
          };
          break;
        // 2
        case Constants.APP_CARD_INDEX.PASSPORT_KOREAN:
          ocr = {
            ...ocr,
            passportNo: '',
            issueNo1: '',
            issueNo2: '',
            juminNo1,
            juminNo2: '',
            _juminNo2: '',
            issueDate: '',
            expiryDate: '',
          };
          break;
        // 3
        case Constants.APP_CARD_INDEX.PASSPORT_ALIEN:
          ocr = {
            ...ocr,
            passportNo: '',
            nationality: '',
          };
          break;
        // 4
        case Constants.APP_CARD_INDEX.ALIEN:
          ocr = {
            ...ocr,
            juminNo1,
            juminNo2: '',
            _juminNo2: '0******',
            issueDate: '',
          };
          break;
        default:
          break;
      }

      this.$emit('manual', {
        base64Card: this.lastValidBase64Card,
        base64CardOrigin: this.tmpAppData.base64CardImage,
        ocr,
      });
    },
    onClickBack() {
      this.$log.debug('onClickBack 이벤트 발생');
      if (this.appData.cardScanRetryCount >= this.MAX_RETRY_COUNT) {
        // N(5회)이상 실패 시 직접입력
        // depending on the type of document, create mvp ocr object
        this.startManualInput();
      } else if (
        this.phase === this.PHASE_SCAN ||
        this.phase === this.PHASE_LOADING
      ) {
        this.$log.debug('uploadFile 초기화');
        this.uploadFile = null;
        this.inErrorProgress = true;
        this.stopScan = true;
        // this.$emit('cancel');
      } else {
        this.uploadFile = null;
      }
    },
    onClickRetry() {
      this.$log.debug('uploadFile 현재 상태', this.uploadFile);
      this.$log.debug(this.systemError);
      this.$log.debug(this.errorCodeOrigin);
      this.$log.debug(this.errorCodeControlList);
      if (this.systemError) {
        this.$log.debug('시스템 에러로 재시도');
        // 시스템에러 : 재시도
        this.$emit('cancel');
      } else {
        this.$log.debug('잘못된 데이터 에러로 재시도');
        if (
          // 정보감지 실패(O003 등) : 재시도
          this.errorCodeOrigin &&
          this.errorCodeControlList.includes(this.errorCodeOrigin)
        ) {
          this.$emit('cancel', { prev: true });
        } else if (
          this.errorCodeOrigin &&
          this.errorCodeOrigin === 'CE002' // 안면인증을 사용하는 경우 얼굴 인식에 실패하였을 때
        ) {
          this.initialize();
          this.phase = this.PHASE_SCAN;
        } else {
          if (this.appData.cardScanRetryCount < this.MAX_RETRY_COUNT) {
            // 사진확인 : 재시도
            this.initialize();
            this.phase = this.PHASE_SCAN;
          } else {
            // upload image file123
            let fileInput = document.getElementById('cardScanFileInput');
            if (fileInput) {
              // prevent error for 'File chooser dialog can only be shown with a user activation.'
              fileInput.style.position = 'fixed';
              fileInput.style.top = '-100em';
              fileInput.value = null;
              fileInput.click();
            }
            // prevent focus-out problem(cannot move any step anymore)
            // when cancel to attach file
            this.initialize();
            this.phase = this.PHASE_SCAN;
          }
        }
        this.loading = false;
      }
    },
    onChangeFile(file) {
      this.$log.debug('CardScan#onChangeFile', file);
      // restrict file format
      const ACCEPTED_FILE_SIZE = 10485760; // 10MB
      const ACCEPTED_FILE_FORMATS = /(.*?)\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (file) {
        const isValidFileSize = file.size <= ACCEPTED_FILE_SIZE;
        const isValidFileFormat = file.name.match(ACCEPTED_FILE_FORMATS);

        this.$log.debug(
          'CardScan#onChangeFile#file',
          isValidFileSize,
          isValidFileFormat
        );

        if (!isValidFileSize || !isValidFileFormat) {
          this.fileUploadErrorType = !isValidFileSize ? 'size' : 'format';
          this.showFileUploadErrorDialog = true;
        } else {
          this.uploadFile = file;
          const reader = new FileReader();
          reader.readAsDataURL(this.uploadFile);

          reader.onload = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
              context.drawImage(image, 0, 0, image.width, image.height);
              const base64Card = canvas.toDataURL('image/jpeg');
              this.capture(base64Card);
              this.uploadType = 'mobile';
            };
            image.onerror = error => {
              this.$log.error('CardUpload#onSubmit#imageError: ', error);
              this.fileUploadErrorType = 'invalid-image';
              this.showFileUploadErrorDialog = true;
            };
          };
          reader.onerror = error => {
            this.$log.error('CardScan#onChangeFile#readerError: ', error);
          };
        }
      }
    },
    onConfirmFileUploadError() {
      this.uploadFile = null;
      this.showFileUploadErrorDialog = false;
      this.serverError = true;
    },
    async capture(uploadedFile) {
      this.$log.debug('사진 캡쳐 시작');
      try {
        if (this.captureProcess) return;
        this.captureProcess = true;

        let base64Card;
        if (uploadedFile.split) {
          base64Card = uploadedFile;
        } else {
          base64Card = this.cropImage();
        }

        this.lastValidBase64Card = base64Card;
        this.phase = this.PHASE_LOADING;
        this.closeCamera();
        let base64CardImage, base64Face, mime;
        if (base64Card) {
          const splitedImageData = base64Card.split(',');
          mime = splitedImageData[0];
          base64CardImage = splitedImageData[1]; // remove mime
          // this.$refs.capture.src = base64Card; // for test
          // return;
        }
        if (
          base64Card &&
          this.appData.moduleName.includes(Constants.MODULE.FACE)
        ) {
          base64Face = await this.cropFaceImage(base64Card);
          // if (base64Face) { // for test
          //   this.$refs.capture.src = base64Face;
          //   return;
          // }
        }
        this.tmpAppData = { base64Card, base64Face, mime, base64CardImage };
        this.base64MaskCardImage = base64Card;
        this.confirmDialog = true;
        this.captureProcess = false;
      } catch (e) {
        this.$log.debug('CardScan#capture error', e.errorCode, e.message);
        this.captureProcess = false;
        if (
          e.errorCode &&
          typeof e.errorCode === 'string' &&
          e.errorCode.startsWith(
            Constants.SERVER.ERROR_CODE.CARD_SCAN_FACE_RECOGNITION_FAILED
          )
        ) {
          this.appData.cardScanRetryCount++;
          this.$log.debug(
            'cardScanRetryCount : ' + this.appData.cardScanRetryCount
          );

          if (
            this.appData.cardScanRetryCount >= this.MAX_RETRY_COUNT &&
            (this.appData.manual_upload_id_mobile || // 사진 첨부 옵션
              this.appData.manual_input_mobile) // 직접 입력 옵션
          ) {
            this.errorMessageTitle = ['정보 감지 실패'];
            this.errorMessage = [
              '신분증 인식이 잘되지 않았습니다.',
              '이미 잘 촬영된 신분증을 보유하고 있다면',
              '파일 첨부가 가능합니다.',
            ];
            this.$log.debug(this.appData.manual_input_mobile, '직접 입력 가능');

            if (this.appData.manual_input_mobile) {
              this.errorButton = ['직접 입력', '사진 첨부'];
            } else {
              this.errorButton = ['', '사진 첨부'];
            }
          } else {
            this.errorMessageTitle = [e.message];
            this.errorMessage = [
              '신분증 사진에서',
              '얼굴을 감지하지 못하였습니다.',
              '재시도 하시겠습니까?',
            ];
            this.errorButton = ['종료', '재시도'];
          }
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
        }
        this.errorCodeOrigin = e.errorCode;
        this.errorCode = e.errorCode ? `에러코드 : ${e.errorCode}` : '';
      }
    },
    async onConfirmOk() {
      // netfunnel for waiting queue
      // const _this = this;

      // netfunnel.NetFunnel_Action(
      //   { action_id: 'idcard_ocr' },
      //   {
      //     success: function() {
      //       //대기가 없거나, 대기 종료 후에 넷퍼넬 자원 할당을 받을 때 호출
      //       __impl().then(ret => {
      //         _this.$log.debug('NetFunnel_Action Result : ' + ret);
      //         netfunnel.NetFunnel_Complete();
      //       });
      //     },
      //     continued: function() {
      //       //대기 단계에서 호출
      //       // nothing to do
      //     },
      //     stop: function() {
      //       //대기창의 중지 버튼 클릭 시 호출
      //       netfunnel.NetFunnel_Complete();
      //       _this.initialize();
      //       _this.phase = _this.PHASE_SCAN;
      //     },
      //     error: function(ev, ret) {
      //       //넷퍼넬 서버에서 error응답을 받았을 때
      //       //(default: error callbcak생략시, error도 success로 동작)
      //       netfunnel.NetFunnel_Complete();
      //       try {
      //         if (ret?.code == '513') {
      //           _this.$log.error(
      //             'NetFunnel_Action Skip (cause : ' + ret?.data?.msg
      //           );
      //           __impl();
      //           return;
      //         }
      //
      //         let error_code = 'NFN_ERR';
      //         if (ret?.code) {
      //           error_code += '_' + ret?.code;
      //         }
      //         if (ret?.data?.msg) {
      //           error_code += '_' + ret?.data?.msg;
      //         }
      //
      //         throw new useb.UsebError(
      //           error_code,
      //           'netFunnel Error\n - ' + JSON.stringify(ret)
      //         );
      //       } catch (e) {
      //         errorPopupProcess.call(_this, e);
      //       }
      //     },
      //     /*
      //   bypass: function(ev, ret){
      //     //넷퍼넬 관리자페이지에서 해당 actionID를 bypass(우회) 설정 시 호출
      //   },
      //   block: function(ev, ret){
      //     //넷퍼넬 관리자페이지에서 해당 actionID를 block(차단) 설정 시 호출
      //   },
      //   ipblock: function(ev, ret){
      //     //엑세스 제어기능을 통해, 제어룰에 해당되어 차단되는 경우에 호출
      //   },
      //   expressnumber: function(ev, ret){
      //     //VIP 설정(ip, id)에 등록된 사용자의 요청이 있을 경우에 호출 bypass
      //   }
      //   */
      //   }
      // );

      const __impl = async () => {
        try {
          const {
            base64Card,
            base64Face,
            mime,
            base64CardImage,
          } = this.tmpAppData;
          const cardIndex = this.appData.cardIndex;
          if (cardIndex === Constants.APP_CARD_INDEX.JUMIN) {
            // 주민등록증
            const ocr = await useb.getOcrIdcard(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            if (ocr.idType == 1) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError('O003');
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
            // 운전면허증
            const ocr = await useb.getOcrDriver(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            if (ocr.idType == 2) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError('O003');
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN) {
            // 한국 여권
            this.$log.debug('한국 여권 진입점');
            const ocr = await useb.getOcrPassport(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            if (ocr._juminNo2) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              this.success(ocr, base64Card, base64Face);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN) {
            // 외국 여권
            this.$log.debug('외국인 여권 진입점');
            const ocr = await useb.getOcrPassportOverseas(base64CardImage);
            this.$log.debug(ocr);
            // if (!ocr.image_base64_mask) {
            //   throw new useb.UsebError("CE003");
            // }
            this.success(ocr, base64Card, base64Face);
          } else if (cardIndex === Constants.APP_CARD_INDEX.ALIEN) {
            // 외국인등록증
            const ocr = await useb.getOcrAlien(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
          }
        } catch (e) {
          errorPopupProcess.call(this, e);
          return 'error: ' + e;
        }
        return 'success';
      };

      await __impl();

      const errorPopupProcess = e => {
        this.$log.debug('CardScan#onConfirmOk error', e.errorCode, e.message);
        this.appData.cardScanRetryCount++;
        this.$log.debug(
          'cardScanRetryCount : ' + this.appData.cardScanRetryCount
        );
        if (
          this.appData.cardScanRetryCount >= this.MAX_RETRY_COUNT &&
          this.appData.manual_upload_id_mobile
        ) {
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
          this.errorMessageTitle = ['정보 감지 실패'];

          if (this.appData.manual_input_mobile) {
            this.errorButton = ['직접 입력', '사진 첨부'];
          } else {
            this.errorButton = ['', '사진 첨부'];
          }
          // this.errorButton = ['직접 입력', '사진 첨부'];
          this.errorMessage = [
            '신분증 인식이 잘되지 않았습니다.',
            '이미 잘 촬영된 신분증을 보유하고 있다면',
            '파일 첨부가 가능합니다.',
          ];
        } else if (
          this.appData.cardScanRetryCount > this.MAX_RETRY_COUNT &&
          this.appData.manual_upload_id_mobile
        ) {
          this.onClickBack();
        } else {
          if (
            e.errorCode &&
            typeof e.errorCode === 'string' &&
            this.errorCodeControlList.includes(e.errorCode)
          ) {
            this.serverError = true;
            this.errorIcon = this.cardGuideIcon;
            this.errorButton = ['종료', '재시도'];
            this.errorMessageTitle = ['정보 감지 실패'];
            this.errorMessage = [
              '신분증 사진에서',
              '신분증을 인식하지 못하였습니다.',
              '재시도 하시겠습니까?',
            ];
          } else {
            this.$log.debug(e);
            this.systemError = true;
            this.serverError = true;
            this.errorIcon = '';
            this.errorMessageTitle = [
              '시스템 에러가 발생하였습니다.',
              '잠시 후 다시 이용해 주세요.',
            ];
            this.errorMessage = [
              '계속해서 문제가 발생한다면',
              `고객센터(${this.companyPhoneNumber})으로 문의해주세요.`,
            ];
            this.errorButton = ['', '확인'];
          }
          this.errorCodeOrigin = e.errorCode;
          this.errorCode = e.errorCode ? `에러코드 : ${e.errorCode}` : '';
        }
      };
    },
    success(ocr, base64Card, base64Face) {
      this.$log.debug('CardScan#success', ocr);
      delete ocr.image_base64_mask;
      this.$emit('next', {
        ocr,
        base64Card,
        base64Face,
        base64CardOrigin: this.tmpAppData.base64CardImage,
      });
      this.$emit('uploadtype', this.uploadType);
    },
    // updateTimeStats(timeInMs) {
    //   this.forwardTimes = [timeInMs].concat(this.forwardTimes).slice(0, 30)
    //   const avgTimeInMs = this.forwardTimes.reduce((total, t) => total + t) / this.forwardTimes.length
    //   this.status = `${Math.round(avgTimeInMs)} ms ${(1000 / avgTimeInMs).toFixed(0)} fps`
    // },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--surface-high);
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-size: 14px;

  .dot {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--primary-100);
  }

  .line {
    width: 112px;
    border-top: 1px solid var(--primary-100);
  }

  .dash {
    width: 112px;
    border-top: 1px dashed var(--surface-high);
  }

  .current {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid var(--primary-100);
  }

  .text-info {
    position: absolute;
    width: 100px;
    top: 22px;
    left: -45px;
    text-align: center;
  }
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}

.container-phase-scan {
  height: 100%;

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .text-info-container {
    position: absolute;
    bottom: 250px;
    width: 100%;
    display: none;
  }

  .text-info {
    position: absolute;
    top: 40px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    color: white;
  }

  .button-capture {
    position: absolute;
    top: 154px;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
}

.container-phase-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--secondary-100);
  color: white;
}

.container-phase-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
</style>
