<template>
  <div>
    <div v-if="title.length > 0" class="title-input">
      <div class="title-input--first">{{ title }}</div>
      <div v-if="necessary" class="title-input--second">&nbsp;*필수</div>
    </div>
    <!-- needCheck for a button component -->
    <div v-if="needCheck" style="display: flex">
      <input
        :class="{
          'input-form': errMsg == '' && !errorForm,
          'input-form--fail': errMsg != '' || errorForm,
        }"
        :placeholder="placeholder"
        v-model="text"
        @input="
          validation($event);
          $emit('new');
        "
      />
      <button
        class="input-check"
        @click="
          $emit('click');
          errMsg == '중복여부를 확인하세요.' ? (errMsg = '') : null;
        "
      >
        중복확인
      </button>
    </div>
    <div v-else-if="compNameToCode" style="display: flex">
      <input
        :class="{
          'input-form': errMsg == '',
          'input-form--fail': errMsg != '',
        }"
        style="margin-right: -40px"
        :placeholder="placeholder"
        v-model="text"
        @input="validation($event)"
      />
      <div v-show="compNameChecked" style="height: 56px; line-height: 56px">
        <img
          src="@/assets/comp_check_24.svg"
          width="24px"
          style="margin-top: 15px"
        />
      </div>
    </div>
    <div v-else-if="isPassword" style="display: flex;">
      <input
        v-if="!visible"
        :class="{
          'input-form': true,
        }"
        type="password"
        :placeholder="placeholder"
        v-model="text"
        @input="validation($event)"
        style="margin-right: -49px"
      />
      <input
        v-else
        :class="{
          'input-form': true,
        }"
        type="text"
        :placeholder="placeholder"
        v-model="text"
        @input="validation($event)"
        style="margin-right: -49px"
      />

      <div style="height: 56px; line-height: 56px" @click="visible = !visible">
        <img
          v-show="!visible && text.length == 0"
          src="@/assets/invisible-dim-signin.svg"
          width="24px"
          style="margin-top: 15px"
        />
        <img
          v-show="!visible && text.length > 0"
          src="@/assets/invisible-signin.svg"
          width="24px"
          style="margin-top: 15px"
        />
        <img
          v-if="visible && text.length == 0"
          src="@/assets/visible-dim-signin.svg"
          width="24px"
          style="margin-top: 15px"
        /><img
          v-if="visible && text.length > 0"
          src="@/assets/visible-signin.svg"
          width="24px"
          style="margin-top: 15px"
        />
      </div>
    </div>
    <div v-else style="display: block">
      <input
        :class="{
          'input-form': errMsg == '',
          'input-form--fail': errMsg != '',
        }"
        :placeholder="placeholder"
        v-model="text"
        @input="
          $event => {
            if (validity == 'live') {
              text = $event.target.value.slice(0, lengthLimit);
              $emit('live', text);
            } else {
              validation($event);
            }
          }
        "
      />
    </div>
    <div
      v-if="scsMsg != ''"
      :style="
        `color: var(--primary-100);${
          infoFontSmall ? 'font-size:12px;margin-top:8px' : ''
        }`
      "
    >
      {{ scsMsg }}
    </div>
    <div
      v-if="errMsg != ''"
      :style="
        `color: var(--error-100);${
          infoFontSmall ? 'font-size:12px;margin-top:8px' : ''
        }`
      "
    >
      {{ errMsg }}
    </div>
  </div>
</template>

<script>
import VALID from '@/components/validation.js';
export default {
  name: 'CustomInputTextForm',
  props: {
    title: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: String,
    validity: String,
    necessary: {
      type: Boolean,
      default() {
        return false;
      },
    },
    noTitle: {
      type: Boolean,
      default() {
        return false;
      },
    },
    initVal: {
      type: String,
      default() {
        return '';
      },
    },
    //고객사명 존재시 확인(체크)UI
    compNameToCode: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //중복확인 버튼이 포함된 Input
    needCheck: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //패스워드 가시화 버튼 존재하는 Input
    isPassword: {
      type: Boolean,
      default() {
        return false;
      },
    },
    errorForm: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //validation 결과를 보여준다.
    reason: {
      type: Boolean,
      default() {
        return true;
      },
    },
    infoFontSmall: {
      type: Boolean,
      default() {
        return false;
      },
    },
    forLogin: {
      type: Boolean,
      default() {
        return false;
      },
    },
    lengthLimit: {
      type: Number,
      default() {
        return 2147483647;
      },
    },
  },
  data() {
    return {
      text: this.initVal,
      errMsg: '',
      scsMsg: '',
      visible: false,
      compNameChecked: false,
      debounce: null,
    };
  },
  components: {},
  computed: {},
  methods: {
    async validation(e) {
      this.text = e.target.value;
      if (this.reason) {
        this.errMsg = '';
        this.scsMsg = '';
        var validRes = undefined;
        clearTimeout(this.debounce);
        this.debounce = await setTimeout(async () => {
          switch (this.validity) {
            case 'phone':
              validRes = VALID.phone(this.text);
              if (validRes.result) {
                this.scsMsg = validRes.msg;
              } else {
                this.errMsg = validRes.msg;
              }
              break;
            case 'email':
              validRes = VALID.email(this.text);
              if (validRes.result) {
                //this.scsMsg = validRes.msg;
              } else {
                this.errMsg = validRes.msg;
              }
              break;
            case 'name':
              validRes = VALID.name(this.text);
              if (validRes.result) {
                this.scsMsg = validRes.msg;
              } else {
                this.errMsg = validRes.msg;
              }
              break;
            case 'company-code':
              validRes = VALID.companyCode(this.text);
              if (validRes.result) {
                this.scsMsg = validRes.msg;
              } else {
                this.errMsg = validRes.msg;
              }
              break;
            case 'individual-id':
              validRes = VALID.individualId(this.text);
              if (validRes.result) {
                if (!this.forLogin) {
                  this.errMsg = validRes.msg;
                }
              } else {
                if (!this.forLogin) {
                  this.errMsg = validRes.msg;
                }
              }
              break;
            case 'individual-password':
              validRes = VALID.individualPassword(this.text);
              this.$log.debug('CustomerInputTextForm#validation~', validRes);
              if (validRes.result) {
                this.scsMsg = validRes.msg;
              } else {
                this.errMsg = validRes.msg;
              }
              break;
            case 'client-comp-name':
              this.compNameChecked = false;
              var res = await this.$store.dispatch('checkCompName', {
                customer_name: this.text,
              });
              this.$log.debug('client-comp-name', res);
              if (!res.result) {
                this.errMsg = this.compNameToCode
                  ? res.message == ''
                    ? ''
                    : '존재하지 않는 고객사명입니다.'
                  : res.message;
                validRes = { result: false };
              } else {
                this.errMsg = '';
                this.compNameChecked = true;
                validRes = res;
              }
              break;
            case 'comp-name-redundancy':
              this.errMsg = '중복여부를 확인하세요.';
              if (this.text == '' || this.text == this.initVal)
                this.errMsg = '';
              validRes = { result: true };
              break;
            default:
              validRes = { result: true };
              break;
          }
          this.$log.debug('CustomerInputTextForm#validation~', validRes);
          this.$emit('set', {
            val: this.text,
            res: validRes.result,
            compid: validRes.customer_id ?? '',
          });
        }, 300);
      } else {
        this.$emit('set', {
          val: this.text,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  height: 56px;
  width: 100%;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 24px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--fail {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    padding: 0 24px;
    font-size: 16px;
    border: 1px solid red;
    caret-color: red;
  }
}

.input-check {
  height: 56px;
  width: 27%;
  margin-left: 3%;
  color: var(--surface-100);
  background: var(--surface-medium);
  border-radius: 8px;
}
.input-check:focus {
  outline: none;
}
.input-form:focus {
  border: 1px solid var(--primary-80);
  outline: none;
}
.input-form--fail:focus {
  outline: none;
}
.title-input {
  display: flex;
  margin: 20px 0 8px 0;
  &--first {
    float: left;
    color: var(--surface-medium);
    margin-bottom: 8px;
  }
  &--second {
    float: left;
    margin-bottom: 8px;
    color: red;
  }
}
</style>
