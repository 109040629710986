<template>
  <div class="loading-root">
    <img
      v-if="!success"
      src="@/assets/Loading_40px.svg"
      width="35px"
      style="margin-bottom: 24px"
    />
    <img
      v-else
      src="@/assets/icon-check-green.svg"
      width="60px"
      style="margin-bottom: 11px"
    />
    <div v-for="(message, index) in messages" :key="index" class="text-info">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-root {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #353648cc;

  .text-info {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-100);
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
}
</style>
