import { subModules as regex } from '@/components/submodules/regexp.js';
function phone(input) {
  if (!input) return { result: false, msg: '' };
  return regex.isPhone(input)
    ? { result: true, msg: '' }
    : { result: false, msg: '입력 형식이 올바르지 않습니다.' };
}
function email(input) {
  if (!input) return { result: false, msg: '' };
  return regex.isEmail(input)
    ? { result: true, msg: '중복여부를 확인하세요.' }
    : {
        result: false,
        msg: '입력 형식이 올바르지 않습니다. 확인 후 수정해 주세요',
      };
}
function name(input) {
  if (!input) return { result: false, msg: '' };
  return regex.isHangeulOrEng(input)
    ? { result: true, msg: '' }
    : {
        result: false,
        msg: '한글, 영문 대/소문자, 빈 칸만 사용 가능합니다.',
      };
}
function companyCode(input) {
  if (!input) return { result: false, msg: '' };
  return regex.isCompCode(input)
    ? { result: true, msg: '' }
    : {
        result: false,
        msg: '숫자 6자리만 입력 가능합니다.',
      };
}
function individualPassword(input) {
  if (!input) return { result: false, msg: '' };
  //1.길이 체크
  let regLength = /^.{9,20}$/;
  if (!regLength.test(input)) {
    return {
      result: false,
      msg: '비밀번호 조건에 부합하지 않습니다. 확인 후 수정해 주세요.',
    };
  }
  //2. 특수문자
  return regex.passwordFormat(input)
    ? { result: true, msg: '' }
    : {
        result: false,
        msg: '비밀번호 조건에 부합하지 않습니다. 확인 후 수정해 주세요.',
      };
}
function individualId(input) {
  if (!input) return { result: false, msg: '' };
  return regex.idFormat(input)
    ? { result: true, msg: '중복여부를 확인하세요.' }
    : {
        result: false,
        msg: '8~20자의 영문 대, 소문자만 사용 가능합니다.',
      };
}
function guideKey(input) {
  if (!input) return { result: false, msg: '' };
  return regex.isGuidanceKey(input)
    ? { result: true, msg: '' }
    : { result: false, msg: '영소문자, 하이픈(-), 숫자만 입력 가능합니다' };
}
function tncPpVersion(input) {
  if (!input) return { result: false, msg: '' };
  return regex.isVersionKey(input)
    ? { result: true, msg: '' }
    : {
        result: false,
        msg: '2자 이상 8자 이내의 (.)점, 숫자로 이루어진 형식을 준수해주세요.',
      };
}
export default {
  phone,
  email,
  name,
  guideKey,
  tncPpVersion,
  individualPassword,
  //로그인
  companyCode,
  individualId,
};
