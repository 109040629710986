<template>
  <div class="root-container">
    <div class="container-phase-guide">
      <Step :phase="phase" :items="phaseItems"></Step>

      <div
        style="
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 27px;
          text-align: center;
          margin-top:70px;
        "
      >
        본인 확인을 위해<br />신분증의 원본을 준비해 주세요.
      </div>
      <img
        src="@/assets/icon-card-guide.svg"
        width="177px"
        style="margin: 24px auto 42px;"
      />
      <div style="align-self:center;">
        <div class="text-title-tip">올바른 촬영 TIP</div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>
            신분증의 앞면이 보이도록 놓아주세요.<br />어두운 바닥에 놓으면 더 잘
            인식됩니다.
          </div>
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>
            가이드 영역에 맞추어 반드시
            <span style="color: var(--primary-80)">신분증 원본</span>으로
            촬영하세요.
          </div>
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>
            빛이 반사되지 않도록 주의하세요.<br />훼손이 심한 신분증은 거절될
            수도 있습니다.
          </div>
        </div>
      </div>

      <div class="button-container">
        <div @click="$emit('cancel', { prev: true })" class="button cancel">
          취소
        </div>
        <div @click="$emit('next')" class="button ok">신분증 촬영</div>
      </div>
    </div>
  </div>
</template>

<script>
import Step from './Step';
export default {
  components: { Step },
  props: {
    phase: Number,
    phaseItems: Array,
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  margin-top: 56px;
  width: 100%;
  bottom: 0;
  display: flex;
  padding: 0px 30px 40px 30px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
