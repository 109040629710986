import { render, staticRenderFns } from "./CardUpload.vue?vue&type=template&id=36e84bf2&scoped=true&"
import script from "./CardUpload.vue?vue&type=script&lang=js&"
export * from "./CardUpload.vue?vue&type=script&lang=js&"
import style0 from "./CardUpload.vue?vue&type=style&index=0&id=36e84bf2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e84bf2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
installComponents(component, {VFileInput})
