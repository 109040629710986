var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":""},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"padding":"0","margin":"0","overflow-y":"scroll"}},[_c('header',{staticClass:"diag-title"},[_vm._v("신규 등록")]),_c('div',{staticClass:"add-body"},[_c('div',[_c('CustomInputTextForm',{attrs:{"validity":"individual-id","title":"ID","placeholder":"ID를 입력하세요.","needCheck":true},on:{"click":function($event){return _vm.checkRedundancy()},"set":function ($event) {
                _vm.reviewerId = $event.val;
              },"new":function($event){_vm.reviewerIdCheckMessage = ''}}}),(_vm.reviewerIdCheckMessage)?_c('div',{style:(_vm.reviewerIdCheckMessage.includes('가능한')
                ? { color: 'var(--primary-100)' }
                : { color: 'var(--error-100)' })},[_vm._v(" "+_vm._s(_vm.reviewerIdCheckMessage)+" ")]):_vm._e(),_c('CustomInputTextForm',{key:_vm.forceInit,attrs:{"validity":"name","title":"이름","placeholder":"이름을 입력하세요"},on:{"set":function ($event) {
                _vm.name = $event.val;
                _vm.nameCheck = $event.res;
              }}}),_c('CustomInputTextForm',{key:_vm.forceInit,attrs:{"validity":"phone","title":"전화번호","placeholder":"‘-’를 포함하여 입력하세요."},on:{"set":function ($event) {
                _vm.phone = $event.val;
                _vm.phoneCheck = $event.res;
              }}}),_c('CustomInputTextForm',{key:_vm.forceInit,attrs:{"validity":"email","title":"이메일","placeholder":"직접입력"},on:{"set":function ($event) {
                _vm.email = $event.val;
                _vm.emailCheck = $event.res;
              }}}),_c('div',{staticClass:"title-input"},[_vm._v("활성여부")]),_c('div',{staticStyle:{"display":"flex"}},[_c('button',{class:_vm.isActivated === '활성'
                  ? 'client-generic-button-left--checked'
                  : 'client-generic-button-left',on:{"click":function($event){_vm.isActivated = '활성'}}},[_vm._v(" 활성 ")]),_c('button',{class:_vm.isActivated === '비활성'
                  ? 'client-generic-button-right--checked'
                  : 'client-generic-button-right',on:{"click":function($event){_vm.isActivated = '비활성'}}},[_vm._v(" 비활성 ")])])],1),_c('div',{staticClass:"button-container"},[_c('SubmitButton',{attrs:{"width":"40%","height":"60px","font-size":"20px","button-string":"취소","bg-color":"#F6F6F6","text-color":"var(--surface-medium)"},on:{"click":_vm.close}}),_c('div',{staticStyle:{"width":"10px"}}),_c('SubmitButton',{attrs:{"dim":_vm.needDim,"width":"60%","height":"60px","font-size":"20px","button-string":"등록","bg-dim-color":"var(--primary-20)","bg-color":"var(--primary-100)","text-dim-color":"var(--surface-100)","text-color":"var(--surface-100)"},on:{"click":_vm.register}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }