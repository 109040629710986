<template>
  <v-dialog
    v-model="value"
    max-width="500px"
    @click:outside="close"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-text style="padding: 0; margin: 0; overflow-y: scroll">
        <header class="diag-title">신규 등록</header>
        <div class="add-body">
          <div>
            <CustomInputTextForm
              validity="individual-id"
              title="ID"
              placeholder="ID를 입력하세요."
              @click="checkRedundancy()"
              @set="
                $event => {
                  reviewerId = $event.val;
                }
              "
              :needCheck="true"
              @new="reviewerIdCheckMessage = ''"
            />
            <div
              v-if="reviewerIdCheckMessage"
              :style="
                reviewerIdCheckMessage.includes('가능한')
                  ? { color: 'var(--primary-100)' }
                  : { color: 'var(--error-100)' }
              "
            >
              {{ reviewerIdCheckMessage }}
            </div>

            <CustomInputTextForm
              :key="forceInit"
              validity="name"
              title="이름"
              placeholder="이름을 입력하세요"
              @set="
                $event => {
                  name = $event.val;
                  nameCheck = $event.res;
                }
              "
            />
            <CustomInputTextForm
              :key="forceInit"
              validity="phone"
              title="전화번호"
              placeholder="‘-’를 포함하여 입력하세요."
              @set="
                $event => {
                  phone = $event.val;
                  phoneCheck = $event.res;
                }
              "
            />
            <CustomInputTextForm
              :key="forceInit"
              validity="email"
              title="이메일"
              placeholder="직접입력"
              @set="
                $event => {
                  email = $event.val;
                  emailCheck = $event.res;
                }
              "
            />

            <div class="title-input">활성여부</div>
            <div style="display: flex">
              <button
                :class="
                  isActivated === '활성'
                    ? 'client-generic-button-left--checked'
                    : 'client-generic-button-left'
                "
                @click="isActivated = '활성'"
              >
                활성
              </button>
              <button
                :class="
                  isActivated === '비활성'
                    ? 'client-generic-button-right--checked'
                    : 'client-generic-button-right'
                "
                @click="isActivated = '비활성'"
              >
                비활성
              </button>
            </div>
          </div>
          <div class="button-container">
            <SubmitButton
              width="40%"
              height="60px"
              font-size="20px"
              button-string="취소"
              bg-color="#F6F6F6"
              text-color="var(--surface-medium)"
              @click="close"
            />
            <div style="width: 10px" />
            <SubmitButton
              :dim="needDim"
              width="60%"
              height="60px"
              font-size="20px"
              button-string="등록"
              bg-dim-color="var(--primary-20)"
              bg-color="var(--primary-100)"
              text-dim-color="var(--surface-100)"
              text-color="var(--surface-100)"
              @click="register"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { subModules } from '@/components/submodules/regexp.js';
import SubmitButton from '@/components/SubmitButton.vue';
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
import server from '../../../api/server';
//import Vue from "vue";
export default {
  components: { CustomInputTextForm, SubmitButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
    },
  },
  data() {
    return {
      reviewerIdRedundancyCheckCount: 0,
      reviewerIdRedundancyErrFlag: false,
      reviewerId: '',
      reviewerIdCheckMessage: '',
      phone: '',
      phoneCheck: undefined,
      emailCheck: undefined,
      email: '',
      name: '',
      nameCheck: undefined,
      isActivated: '',
      forceInit: 0,
    };
  },
  computed: {
    needDim() {
      return !(
        this.emailCheck &&
        this.phoneCheck &&
        this.nameCheck &&
        this.isActivated != '' &&
        !this.reviewerIdRedundancyErrFlag &&
        this.reviewerIdRedundancyCheckCount > 0 &&
        this.reviewerId != ''
      );
    },
  },
  methods: {
    async close() {
      //initialize
      this.reviewerIdRedundancyCheckCount = 0;
      this.reviewerIdRedundancyErrFlag = false;
      this.reviewerId = '';
      this.reviewerIdCheckMessage = '';
      this.phone = '';
      this.phoneCheck = undefined;
      this.name = '';
      this.nameCheck = undefined;
      this.emailCheck = undefined;
      this.email = '';
      this.isActivated = '';
      this.forceInit++;
      this.$emit('close');
    },
    hangeul() {
      if (this.name == '') this.nameCheck = undefined;
      else this.nameCheck = subModules.isHangeul(this.name);
    },
    async register() {
      await this.checkRedundancy();
      if (this.reviewerIdCheckMessage == '사용 가능한 아이디입니다.') {
        //TODO
        await this.$store
          .dispatch('registerReviewer', {
            username: this.reviewerId,
            name: this.name,
            password: 'AlcheraUseB1234!',
            email: this.email,
            phone_number: this.phone,
            active: this.isActivated === '활성' ? true : false,
          })
          .then(res => {
            if (res.result_code == 'N100') {
              this.$store.dispatch('showRegistrationSnackbar', {
                message: '심사자 등록이 완료되었습니다.',
              });
              this.$store.dispatch('getReviewerList', {
                start: 0,
                count: 20,
              });
            }
          });
        this.close();
      }
    },
    async checkRedundancy() {
      this.reviewerIdRedundancyCheckCount++;
      var redundancy = await this.$store.dispatch('checkIdRedundancy', {
        customer_id: Number(server.getToken().customer_id),
        value: this.reviewerId,
      });
      if (redundancy) {
        switch (redundancy.api_response.result_code) {
          case 'E600':
            this.reviewerIdCheckMessage =
              '입력 유형이 올바르지 않습니다. 확인 후 수정해 주세요.';
            break;
          case 'F602':
            this.reviewerIdCheckMessage = '이미 사용중인 아이디입니다.';
            break;
          case 'N100':
            this.reviewerIdCheckMessage = '사용 가능한 아이디입니다.';
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.diag-title {
  padding: 6% 0 0 6%;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--surface-high);
}
.add-body {
  display: flex;
  flex-direction: column;
  margin: 0 6% 0 6%;
  max-height: 85vh;
}
.title-input {
  margin: 20px 0 8px 0;
}
.dropdown-list {
  position: absolute;
  outline: 2px solid var(--primary-80);
  width: 88%;
  padding: 0 16px;
  max-height: 110px;
  margin-top: 56px;
  overflow-y: auto;
  background: var(--surface-100);
  border-radius: 8px;
}
.dropdown-item {
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 24px;
  cursor: pointer;
}
.reviewer-input-form {
  height: 56px;
  width: 100%;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 16px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--redundancy {
    width: 27%;
    margin-left: 3%;
    color: var(--surface-100);
    background: var(--surface-medium);
    border-radius: 8px;
  }
  &--fail {
    height: 56px;
    width: 100%;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    border: 1px solid red;
    caret-color: red;
  }
}
.reviewer-input-form:focus {
  outline: 1px solid var(--primary-80);
}
.reviewer-input-form--fail:focus {
  outline: 1px solid red;
}
.client-generic-button-left {
  width: 50%;
  height: 56px;
  color: var(--surface-medium);
  background: var(--surface-100);
  border: 1px solid var(--gray-300);
  border-radius: 8px 0 0 8px;
  &--checked {
    width: 50%;
    height: 56px;
    color: var(--surface-medium);
    border: 1px solid var(--primary-80);
    border-radius: 8px 0 0 8px;
    background: var(--surface-100);
  }
}
.client-generic-button-right {
  width: 50%;
  height: 56px;
  color: var(--surface-medium);
  background: var(--surface-100);
  border: 1px solid var(--gray-300);
  border-radius: 0 8px 8px 0;
  &--checked {
    width: 50%;
    height: 56px;
    color: var(--surface-medium);
    background: var(--surface-100);
    border: 1px solid var(--primary-80);
    border-radius: 0 8px 8px 0;
  }
}
.button-container {
  display: flex;
  width: 100%;
  padding: 8% 0;
  .default {
    width: 60%;
    color: var(--surface-100);
    background: var(--primary-20);
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    &.cancel {
      width: 40%;
      color: var(--surface-medium);
      margin-right: 9px;
      background: var(--gray-100);
    }
    &.ok {
      width: 60%;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border: solid 3px transparent;
  box-shadow: inset 0 0 10px 10px var(--gray-300);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--gray-300);
}
</style>
