<template>
  <div class="slider-container">
    <div class="slider-track" :style="validStyleTrack"></div>
    <div class="slider-track disabled" :style="invalidStyleTrack"></div>
    <div class="slider-thumb-container">
      <div
        v-for="(label, index) in tickLabels"
        :key="index"
        :class="{ 'slider-thumb': true, invalid: value < index }"
        @click="onChange(index)"
      >
        <div v-if="!readonly && index === value" class="slider-thumb-current">
          <div class="slider-thumb-current slider-thumb"></div>
        </div>
        <div
          :class="{
            label: true,
            focus: !readonly && index === value,
            current: index === value,
          }"
        >
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    tickLabels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    ticklength() {
      return this.tickLabels.length - 1;
    },
    validStyleTrack() {
      return `width: ${(this.value / this.ticklength) * 100}%`;
    },
    invalidStyleTrack() {
      return `width: ${((this.ticklength - this.value) / this.ticklength) *
        100}%`;
    },
  },
  methods: {
    onChange(value) {
      if (!this.readonly) {
        this.$log.debug('Slider#onChange', value);
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  position: relative;
  width: 100%;
  height: 22px;
  font-size: 12px;
  color: var(--surface-medium);
  text-align: center;
}

.slider-track {
  position: absolute;
  height: 2px;
  background-color: var(--primary-100);

  &.disabled {
    right: 0;
    background-color: var(--surface-disabled);
  }
}
.slider-thumb-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .slider-thumb {
    margin-top: -4px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: var(--primary-100);
    cursor: pointer;

    &.invalid {
      background-color: var(--surface-disabled);
    }
  }

  .slider-thumb-current {
    position: relative;
    top: -3px;
    left: -3px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border: 1px solid var(--primary-100);
    background-color: white;
    cursor: pointer;

    .slider-thumb {
      top: 6px;
      left: 2px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: var(--primary-100);
    }
  }

  .label {
    margin-top: 15px;
    &.focus {
      margin-top: -1px;
    }
    &.current {
      font-weight: bold;
      color: var(--primary-100);
    }
  }
}
</style>
