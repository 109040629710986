<template>
  <div class="view-container">
    <div class="content-container">
      <div class="settings-types-container">
        <div class="settings-types-text settings-types-text-selected">
          심사 설정
        </div>
      </div>

      <div
        :class="{
          'eval-threshold-settings-container': true,
          edit: isEditingEvalThreshold,
        }"
      >
        <div class="eval-threshold-settings-label">
          얼굴 유사도 판정 민감도 설정값
        </div>
        <div class="eval-threshold-settings-content-center">
          <Slider
            v-model="evalThresholdValue"
            :tickLabels="tickLabels"
            :readonly="!isEditingEvalThreshold"
          ></Slider>
        </div>
        <div class="eval-threshold-settings-btn-container">
          <v-btn
            depressed
            :class="
              `eval-threshold-settings-btn${
                isEditingEvalThreshold ? '-editing' : ''
              }`
            "
            @click="onChangeThreshold"
            :height="48"
            :width="144"
            >{{ isEditingEvalThreshold ? '완료' : '변경' }}</v-btn
          >
        </div>
      </div>
      <div
        :style="{ visibility: isEditingEvalThreshold ? 'visible' : 'hidden' }"
        class="eval-threshold-settings-info"
      >
        설정값이 높을수록 수동심사 범위가 넓어집니다.
      </div>
      <div class="audits-table-container">
        <SetTable
          :needDetail="false"
          :table-name="'set'"
          :titles="TABLE.SET.SIZE"
          :titleNames="TABLE.SET.NAME"
          :contents="auditList"
          :no-data-messages="['설정값 변경 기록이 없습니다.']"
        >
          <template slot-scope="props">
            <div
              :style="
                props.tid === 'threshold_new' ? 'color:var(--primary-100)' : ''
              "
            >
              {{ props.c }}
            </div>
          </template>
        </SetTable>
        <Pagenation :totalCount="15" @update="requestAuditList($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Slider from '@/components/admin/setting/Slider';
import SetTable from '@/components/Table.vue';
import server from '@/api/server';
import Pagenation from '@/components/Pagenation.vue';
import TABLE from '@/table.js';
import Vue from 'vue';
export default {
  name: 'Settings',
  components: { Slider, SetTable, Pagenation },
  data() {
    return {
      selectedTypeIndex: 0,
      isEditingEvalThreshold: false,
      evalThresholdValue: 0,
      tickLabels: [1, 2, 3, 4, 5],
      TABLE: TABLE,
    };
  },
  computed: {
    ...mapState({
      threshold_level: state => state.settings.threshold_level - 1,
      auditList: state => state.settings.auditList,
    }),
    settingTypes() {
      return ['심사 설정', '관리자 설정'];
    },
  },
  async created() {
    await this.requestAuditList({ start: 0, count: 10 });
  },
  methods: {
    async requestAuditList(event) {
      Vue.$log.debug(event);
      try {
        await this.$store.dispatch('getHistory', {
          start: event.start,
          count: event.count,
        });
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.evalThresholdValue = this.threshold_level;
      }
    },
    selectSettingType(index) {
      if (this.selectedTypeIndex !== index) {
        this.selectedTypeIndex = index;
      }
    },
    async onChangeThreshold() {
      if (this.isEditingEvalThreshold) {
        const user = server.getUserInfo();
        try {
          await this.$store
            .dispatch('setThreshold', {
              level: this.evalThresholdValue + 1,
              admin_id: user.id,
              admin_name: user.user_name,
            })
            .then(res => {
              if (res.result_code == 'N100') {
                this.$store.dispatch('showRegistrationSnackbar', {
                  message: '설정깞 변경이 완료되었습니다.',
                });
              }
            });
          await this.$store.dispatch('getHistory', {
            start: 0,
            count: 10,
          });
        } catch (e) {
          // nothing
        }
      }
      this.isEditingEvalThreshold = !this.isEditingEvalThreshold;
    },
  },
};
</script>

<style scoped>
.settings-types-container {
  height: 36px;
  width: 100%;
  margin-bottom: 32px;
  display: flex;
}
.settings-types-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.settings-types-text-selected {
  color: var(--primary-80);
}
.settings-types-text-unselected {
  cursor: pointer;
  color: var(--surface-disabled);
}
.eval-threshold-settings-container {
  display: flex;
  height: 72px;
  width: 100%;
  background-color: var(--surface-100);
}
.eval-threshold-settings-container.edit {
  border: 1px solid var(--primary-80);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 8px rgba(0, 0, 0, 0.1);
}

.eval-threshold-settings-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  color: var(--surface-high);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.eval-threshold-settings-content-center {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.eval-threshold-settings-content-center {
  color: var(--primary-100);
}
.eval-threshold-settings-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}
.eval-threshold-settings-btn-editing {
  background-color: var(--primary-100) !important;
  color: var(--surface-100) !important;
  border-radius: 8px !important;
}
.eval-threshold-settings-btn {
  border-radius: 8px !important;
}
.eval-threshold-settings-info {
  color: var(--primary-60);
  font-size: 14px;
  line-height: 24px;
  margin: 6px 0 23px;
  text-align: center;
}
</style>
