//import Constants from "@/constants.js";

export const subModules = {
  readReviewerList: function(payload) {
    var list = {};
    list['id'] = payload.id;
    list['reviewer_id'] = payload.username ?? '';
    list['reviewer_name'] = payload.name ?? '';
    list['reviewer_phone'] = payload.phone_number ?? '';
    list['reviewer_mail'] = payload.email ?? '';
    list['activation'] = payload.active ?? false;
    list['is_approve'] = payload.is_approve ?? false;
    return list;
  },
};
