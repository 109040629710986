<template>
  <div class="view-container">
    <div class="content-container">
      <div class="admin-types-container">
        <div class="admin-types-area-left">
          <div class="admin-management-title">어드민 관리</div>
          <Dropdown
            style="align-self: end; margin-left: 32px; width: 208px"
            width="208px"
            border="1px solid var(--surface-medium)"
            v-model="clientInspectionPendingFilter"
            :items="clientCriteriaDropdownItems"
            :key="clientInspectionPendingFilter"
            @change="onSelectClient"
          ></Dropdown>
        </div>
        <div class="admin-types-area-right">
          <Dropdown
            width="112px"
            v-model="selectedInspectionPendingFilter"
            :items="searchCriteriaDropdownItems"
            @change="onChangeFilter"
          ></Dropdown>
          <InputSearch
            @change="onChangeSearch"
            style="margin-left: 7px"
          ></InputSearch>
        </div>
      </div>

      <div class="admin-list-table-container">
        <AmTable
          :needDetail="false"
          :table-name="'am'"
          :titles="TABLE.AM.SIZE"
          :titleNames="TABLE.AM.NAME"
          :contents="adminList"
          @accept="clickAccept($event)"
          @deny="openDenyWarningDialog($event)"
          :no-data-messages="[
            '해당 고객사에 등록된 어드민 정보가 없습니다.',
            '어드민을 등록하세요.',
          ]"
          :key="`${tableRerender}-am`"
        >
          <template slot-scope="props">
            <div v-if="props.tid === 'activation'">
              <ToggleButton
                style="vertical-align: middle"
                :initialData="props.c"
                @input="confirmDialogUp($event, props.ctt)"
              ></ToggleButton>
            </div>
          </template>
          <template v-if="!isAlreadySearched" v-slot:no-data>
            <div class="admin-list-table-initial">
              <section style="margin-top: 146px; text-align: center">
                <div>고객사를 검색하여 정보를 관리하세요.</div>
                <div style="margin-top: 16px">
                  <SearchBox
                    @compInfo="selectBySearch($event)"
                    style="width: 420px; height: 56px"
                  />
                </div>
              </section>
            </div>
          </template>
        </AmTable>
        <Pagenation
          v-show="!this.isNotEverSelected"
          :totalCount="90"
          @update="requestAdminList($event)"
          :key="`${tableRerender}-page`"
        />
      </div>
    </div>

    <ConfirmDialog
      v-if="confirmDialogInfo"
      v-model="confirmDialogFlag"
      @close="confirmDialogFlag = false"
      @confirmation="confirmDone($event)"
      :confirmInfos="confirmDialogInfo"
    />
    <ApplyDenyDialog
      v-model="showDeleteAdminConfirmDialog"
      :adminData="selectedAdminData"
      @close="showDeleteAdminConfirmDialog = false"
      @confirmation="clickDelete()"
      :company="selectedClientIndex"
    />
    <AcceptFailDialog
      v-model="showAcceptFailDialog"
      :adminList="adminList"
      @confirm="showAcceptFailDialog = false"
      :selectedAdminConfig="selectedAdminConfig"
      :failReason="failReason"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown';
import InputSearch from '@/components/InputSearch';
import TABLE from '@/table.js';
import Pagenation from '@/components/Pagenation.vue';
import ToggleButton from '@/components/SliderToggle.vue';
import ConfirmDialog from '@/components/root/dialog/ConfirmDialog.vue';
import { mapState } from 'vuex';
import Vue from 'vue';
import server from '../../api/server';
import SearchBox from '@/components/root/common/SearchBox.vue';
import AmTable from '@/components/Table.vue';
import ApplyDenyDialog from '@/components/root/dialog/ApproveDenyDialog.vue';
import AcceptFailDialog from '@/components/root/dialog/ApproveFailDialog.vue';
import loadingMixin from '@/store/mixins/loading';

export default {
  name: 'Evaluation',
  mixins: [loadingMixin],
  components: {
    ToggleButton,
    Dropdown,
    InputSearch,
    ConfirmDialog,
    AmTable,
    SearchBox,
    ApplyDenyDialog,
    AcceptFailDialog,
    Pagenation,
  },
  data() {
    return {
      selectedInspectionPendingFilter: '',
      selectedInspectionCompleteFilter: [],

      clientInspectionPendingFilter: '',
      clientInspectionCompleteFilter: [],

      searchCriteriaDropdownItems: ['전체', '아이디', '고객사명'],
      search: '',
      toastDialogFlag: false,
      activationDialogFlag: false,
      addDialogFlag: false,
      confirmDialogFlag: false,
      confirmDialogInfo: null,
      isNotEverSelected: true,
      clientCompId: null,
      //rerenderItemIdCount: 0,
      TABLE: TABLE,
      showDeleteAdminConfirmDialog: false,
      selectedClientIndex: 0,
      selectedAdminData: {},
      selectedAdminConfig: {},
      showAcceptFailDialog: false,
      failReason: '',
      isAlreadySearched: false,
      tableRerender: false,
    };
  },
  async created() {
    try {
      this.initEverSelected();
      await this.$store.dispatch('getClientList', {
        start: 0,
        name: '',
      });
      await this.$store.dispatch('getClientNameList', {
        start: 0,
        name: '',
      });
    } catch (e) {
      Vue.$log.debug('dispatching error on evaluation' + e);
    }
  },
  computed: {
    ...mapState({
      clientList: state => state.root.clientList,
      clientNameList: state => state.root.clientNameList,
      adminList: state => state.root.adminList,
    }),
    clientCriteriaDropdownItems() {
      return ['고객사 선택'].concat(Object.keys(this.clientNameList));
    },
    selectedInspectionCompleteFilterItemsText() {
      if (this.selectedInspectionCompleteFilter.length === 4) {
        return '전체';
      } else if (this.selectedInspectionCompleteFilter.length === 3) {
        return (
          this.selectedInspectionCompleteFilter.join(', ').slice(0, 7) + '..'
        );
      }
      return this.selectedInspectionCompleteFilter.join(', ');
    },
  },
  methods: {
    isEverSelected() {
      this.isNotEverSelected = false;
    },
    initEverSelected() {
      this.isNotEverSelected = true;
      this.$store.state.root.adminList = [];
    },
    async selectBySearch(index) {
      this.tableRerender = !this.tableRerender;
      index = index.name;
      this.$log.debug('Evaluation#selectBySearch', index);
      this.selectedClientIndex = index;
      this.clientInspectionPendingFilter = index;
      this.isEverSelected();
      this.clientCompId = this.$store.state.root.clientNameList[index];

      await this.$store.dispatch('getAdminList', {
        start: 0,
        count: 10,
        id: this.clientCompId,
      });
      this.isAlreadySearched = true;
    },
    onChangeFilter(index) {
      this.$log.debug('Evaluation#onChangeFilter', index);
    },
    async requestAdminList(event) {
      await this.$store.dispatch('getAdminList', {
        id: this.$store.state.root.clientNameList[this.selectedClientIndex],
        start: event.start,
        count: event.count,
      });
    },
    async onSelectClient(index) {
      this.tableRerender = !this.tableRerender;
      this.$log.debug('Evaluation#onSelectClient', index);
      this.isEverSelected();
      if (index != '고객사 선택') {
        this.selectedClientIndex = index;
        this.clientCompId = this.$store.state.root.clientNameList[index];
        await this.$store.dispatch('getAdminList', {
          start: 0,
          count: 10,
          id: this.clientCompId,
        });
        const { detail } = await server.getClientDetailInfo({
          id: this.clientCompId,
        });
        this.selectedAdminConfig = detail;
      } else {
        this.initEverSelected();
      }
      this.isAlreadySearched = true;
    },
    onChangeSearch(text) {
      this.$log.debug('Evaluation#onChangeSearch', text);
      this.search = text;
    },
    confirmDialogUp(event, content) {
      this.$log.debug('AdminManagement#confirmDialogUp', event);
      if (content.is_approve) {
        this.confirmDialogInfo = { item: content, value: event };
        this.confirmDialogFlag = true;
      }
    },
    async confirmDone(event) {
      await server
        .activateAdminAccount(this.clientCompId, event.identification, {
          active: event.value,
        })
        .then(res => {
          if (res.api_response.result_code == 'N100') {
            this.$store.dispatch('showRegistrationSnackbar', {
              message: '활성 정보 수정이 완료되었습니다.',
            });
          }
        })
        .catch(err => {
          const { errorCode } = err;
          if (errorCode == 'F607') {
            this.failReason = 'done';
            this.showAcceptFailDialog = true;
          }
        });
      await this.$store.dispatch('getAdminList', {
        start: 0,
        count: 10,
        id: this.clientCompId,
      });
    },
    async clickAccept(rowData) {
      this.$log.debug('clickAccept', rowData);
      this.onLoading();

      try {
        const res = await server.handleAdminAccountStatus({
          customer_id: this.clientCompId,
          id: rowData.id,
          approve: true,
        });
        this.$log.debug('AdminManagement#clickAccept#res', res);
        // show snackbar on success
        if (res.api_response.result_code === 'N100') {
          this.$store.dispatch('showCustomTimeoutSnackbar', {
            message: '어드민 신청이 수락되었습니다.',
            timeout: 3000,
          });
        }
      } catch (e) {
        this.$log.debug('AdminManagement#clickAccept#error', e);
        this.openAcceptFailDialog();
      }

      this.refreshAdminAccountsList();
      this.offLoading();
    },
    openAcceptFailDialog() {
      console.log(this.adminList);
      this.failReason = 'accept';
      this.showAcceptFailDialog = true;
    },
    openDenyWarningDialog(rowData) {
      console.log(this.adminList);
      this.selectedAdminData = rowData;
      console.log(rowData);

      this.showDeleteAdminConfirmDialog = true;
    },
    async clickDelete() {
      this.$log.debug('clickDeny', this.selectedAdminData);
      this.onLoading();
      try {
        const res = await server.handleAdminAccountStatus({
          customer_id: this.clientCompId,
          id: this.selectedAdminData.id,
          approve: false,
        });
        this.$log.debug('AdminManagement#clickDeny#res', res);
        // show snackbar on success
        if (res.api_response.result_code === 'N100') {
          this.$store.dispatch('showCustomTimeoutSnackbar', {
            message: '어드민 신청이 거절되었습니다.',
            timeout: 3000,
          });
        }
      } catch (e) {
        this.$log.debug('AdminManagement#clickDeny#error', e);
      }
      this.offLoading();
      this.refreshAdminAccountsList();
    },
    refreshAdminAccountsList() {
      this.onSelectClient(this.selectedClientIndex);
      this.selectedAdminData = {};
    },
  },
};
</script>

<style scoped>
.admin-types-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 23px;
  display: flex;
}
.evaluation-types-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.evaluation-types-text-selected {
  color: var(--primary-80);
}
.evaluation-types-text-unselected {
  cursor: pointer;
  color: var(--surface-disabled);
}
.admin-types-area-left {
  display: flex;
  flex-grow: 1;
}
.admin-types-area-right {
  display: flex;
}
.evaluation-dropdown {
  background-color: var(--surface-100);
  border-radius: 8px;
  width: 130px;
  margin-right: 7px !important;
}
.evaluation-search-bar {
  background-color: var(--surface-100);
  border-radius: 8px;
}
.admin-list-table-container {
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
.empty-selected-options-text {
  padding: 10px 16px;
  font-size: 12px;
  color: var(--surface-medium);
  cursor: pointer;
  opacity: 0.8;
}
.empty-selected-options-text:hover {
  opacity: 1;
}
.selected-filter-options-text {
  padding-top: 1px;
  color: var(--surface-medium);
}
.admin-management-title {
  color: var(--primary-80);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.admin-list-table-initial {
  height: 67vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
