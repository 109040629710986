<template>
  <div :class="{ 'input-search-root': true, focus }">
    <input
      type="text"
      @change="onChange"
      @keyup="onKeyup"
      @focus="focus = true"
      @blur="focus = false"
    />
    <img v-if="focus" src="@/assets/icon-search-focus.svg" width="13px" />
    <img v-else src="@/assets/icon-search-unfocus.svg" width="13px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      focus: false,
    };
  },
  methods: {
    onKeyup(e) {
      if (e.key === 'Enter') {
        this.$emit('submit');
      }
    },
    onChange(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-search-root {
  display: flex;
  align-items: center;
  width: 208px;
  height: 32px;
  border: 1px solid var(--surface-medium);
  border-radius: 8px;
  background-color: white;

  &.focus {
    border-color: var(--primary-80);
  }

  input {
    padding-left: 10px;
    width: 100%;
    height: 16px;
    caret-color: var(--primary-80);
    outline: none;
    border: none;
  }

  img {
    margin: 0 9px;
  }
}
</style>
