<template>
  <section class="eec-expand">
    <section class="eec-expand-half">
      <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key">1원 계좌 인증</div>
        <div
          :class="{
            'label-value--fail': !accountInfo.account_verify,
            'label-value': accountInfo.account_verify,
          }"
        >
          {{ accountInfo.account_verify ? '확인' : '실패' }}
        </div>
      </div>
      <div class="eec-flex-row" style="margin-top: 16px">
        <div class="label-key">예금주 수정 여부</div>
        <div class="label-value" :class="{ modified: accountInfo.isModified }">
          {{ accountInfo.isModified ? 'YES' : ' NO' }}
        </div>
      </div>
      <div class="eec-flex-row" style="margin-top: 16px">
        <div class="label-key">예금주명</div>
        <div
          class="label-value"
          :class="{ modified: accountInfo.isModified }"
          style="font-weight:400"
        >
          {{
            accountInfo.isModified
              ? accountInfo.modified_account_name
              : accountInfo.account_name
          }}
        </div>
      </div>
      <div class="eec-flex-row" style="margin-top: 16px">
        <div class="label-key">고객명</div>
        <div class="label-value">
          {{ accountInfo.customer_name }}
        </div>
      </div>
      <div class="eec-flex-row" style="margin-top: 16px">
        <div class="label-key">금융사명</div>
        <div class="label-value">
          {{ accountInfo.account_bank }}
        </div>
      </div>
      <div class="eec-flex-row" style="margin-top: 16px">
        <div class="label-key">계좌번호</div>
        <div class="label-value">
          {{ accountInfo.account_number }}
        </div>
      </div>
    </section>
  </section>
</template>
<script>
export default {
  props: {
    accountInfo: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    console.log('계좌정보', this.accountInfo);
  },
};
</script>
<style lang="scss" scoped>
.modified {
  color: var(--error-100);
  font-weight: 700;
}
</style>
