<template>
  <div class="root-container">
    <div class="container-phase-guide">
      <Step :phase="phase" :items="phaseItems"></Step>
      <div style="min-height:70px;"></div>
      <div
        style="
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 8px;
          text-align: center;
        "
      >
        신분증을 선택하세요.
      </div>
      <div
        style="
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 40px;
          text-align: center;
          color: var(--surface-medium);
          padding: 0 30px;
        "
      >
        사진이 포함된 신분증으로 본인 확인을 진행합니다.<br />
        선택한 신분증은 이전 단계에서 입력한 정보와 일치해야 합니다.
      </div>
      <div
        v-for="(card, index) in filteredIdCardsList"
        :key="index"
        @click="selectIndex = card.id"
        :class="{ 'select-card': true, selected: selectIndex === card.id }"
      >
        <div class="select-image-area">
          <img :src="card.image" class="select-image" />
        </div>
        <div class="select-name">
          {{ card.name
          }}<span style="font-size:14px;line-height:20px;"
            ><br />{{ card.sub_name }}</span
          >
        </div>
        <div style="flex-grow: 1;"></div>
        <img
          v-if="selectIndex === card.id"
          src="@/assets/complete_24.svg"
          style="margin-right:24px;"
        />
      </div>
      <div style="min-height: 56px;"></div>
      <div class="button-container">
        <div
          @click="
            $emit('cancel', {
              prev: appData.identification_config_enabled ? true : false,
            })
          "
          :class="{ button: true, cancel: true, disabled: selectIndex === -1 }"
        >
          취소
        </div>
        <div
          @click="
            selectIndex !== -1 && $emit('next', { cardIndex: selectIndex })
          "
          :class="{ button: true, ok: true, disabled: selectIndex === -1 }"
        >
          선택 완료
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Step from './Step';
import CONSTANT from '@/constants.js';
export default {
  components: { Step },
  props: {
    phase: Number,
    phaseItems: Array,
    appData: Object,
  },
  mounted() {
    this.$log.debug('CardSelect phase #', this.appData);
  },

  computed: {
    filteredIdCardsList() {
      const idCardsFilter = this.$store.state.idCardsFilter;
      // const idCardsFilter = [
      //   {
      //     active: true,
      //     name: "korean-registration"
      //   },
      //   {
      //     active: true,
      //     name: "korean-driver"
      //   },
      //   {
      //     active: true,
      //     name: "korean-passport"
      //   },
      //   {
      //     active: false,
      //     name: "foreign-passport"
      //   },
      //   {
      //     active: false,
      //     name: "foreign-registration"
      //   },
      // ]
      // this.$log.debug('CardSelect#idCardsFilter', idCardsFilter)
      return this.cardList.filter(card => {
        return idCardsFilter.some(
          filterCard => filterCard.active && filterCard.name === card.filterName
        );
      });
    },
  },
  data() {
    return {
      selectIndex: -1,
      cardList: [
        {
          id: CONSTANT.APP_CARD_INDEX.JUMIN,
          image: require('@/assets/ID card_Sm.svg'),
          name: '주민등록증',
          filterName: 'korean-registration',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.DRIVER,
          image: require('@/assets/Driver_Sm.svg'),
          name: '운전면허증',
          filterName: 'korean-driver',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.PASSPORT_KOREAN,
          image: require('@/assets/Passport_Sm.svg'),
          name: '한국 여권',
          filterName: 'korean-passport',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.PASSPORT_ALIEN,
          image: require('@/assets/Passport_Sm.svg'),
          name: '외국 여권',
          filterName: 'foreign-passport',
        },
        {
          id: CONSTANT.APP_CARD_INDEX.ALIEN,
          image: require('@/assets/Foreigner_Sm.svg'),
          name: '외국인 등록증',
          sub_name: '(국내거소증/영주증)',
          filterName: 'foreign-registration',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.select-card {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 61px;
  min-height: 72px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  margin-bottom: 8px;

  &.selected {
    border: 2px solid var(--primary-100);
  }
}

.select-image-area {
  display: flex;
  align-items: center;
  width: 97px;
  .select-image {
    margin-left: 32px;
  }
}

.select-name {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: black;
}

.button-container {
  width: 100%;
  // bottom: 0;
  display: flex;
  padding: 0px 30px 40px 30px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
      &.disabled {
        background-color: var(--primary-20);
      }
    }
  }
}
</style>
