<template>
  <div
    class="signin-container signin-root-background full-size flex-center"
    style="min-height:800px;"
  >
    <div class="background-signin-image"></div>
    <div class="signin-content flex-center">
      <div class="signin-card-container">
        <div class="signin-title">
          <img src="@/assets/logo.svg" alt="ekyc main logo" />
        </div>
        <form
          id="signin-form"
          :class="{
            'signin-form default flex-center': !error,
            'signin-form error flex-center': error,
          }"
        >
          <div
            style="
              font-weight: bold;
              font-size: 24px;
              color: var(--primary-80);
              margin: 40px 0 38px 0;
            "
          >
            Alchera Login
          </div>
          <div style="width: 318px; padding-bottom: 16px">
            <CustomInputTextForm
              validity="individual-id"
              placeholder="아이디"
              @set="
                $event => {
                  loginFactorForServerError = false;
                  usernameInput = $event.val;
                }
              "
              :init-val="usernameInput"
              :no-title="true"
              :for-login="true"
              :info-font-small="true"
            />
          </div>
          <div style="width: 318px; padding-bottom: 16px">
            <CustomInputTextForm
              validity="password"
              placeholder="비밀번호"
              :is-password="true"
              @set="
                $event => {
                  loginFactorForServerError = false;
                  passwordInput = $event.val;
                }
              "
              :init-val="passwordInput"
              :no-title="true"
              :for-login="true"
              :info-font-small="true"
            />
          </div>

          <div
            v-if="error"
            class="signin-error"
            style="
              "
          >
            아이디 혹은 비밀번호를 잘못 입력하셨습니다.
          </div>
          <div
            style="
              display: flex;
              width: 318px;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <div
              class="flex-center"
              style="display: flex; flex-direction: row"
              @click="permanent = !permanent"
            >
              <img
                v-show="permanent"
                src="@/assets/checkbox_checked_24.svg"
                alt="checked"
              /><img
                v-show="!permanent"
                src="@/assets/checkbox_24.svg"
                alt="unchecked"
              />

              <div style="margin-left: 4px; font-size: 14px;line-height:24px">
                로그인 상태 유지
              </div>
            </div>
          </div>

          <div
            v-if="isLoginAvailable"
            @click="login()"
            class="signin-form-btn ok"
          >
            로그인
          </div>
          <div v-else class="signin-form-btn default">로그인</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from '@/constants';
import server from '../api/server';
import util from '@/util';
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
import collision from '@/store/mixins/collision.js';
export default {
  mixins: [collision],
  mounted() {
    this.otherComponent = document.querySelector('#signin-form');
    this.isCollide();
  },
  updated() {
    this.otherComponent = document.querySelector('#signin-form');
    this.isCollide();
  },
  name: 'RootSignIn',
  components: {
    CustomInputTextForm,
  },
  data() {
    return {
      usernameInput: '',
      passwordInput: '',
      error: '',
      loginFactorForServerError: false,
      visible: false,
      permanent: true,
    };
  },
  computed: {
    isLoginAvailable() {
      return (
        this.usernameInput.length > 0 &&
        this.passwordInput.length > 0 &&
        !this.loginFactorForServerError
      );
    },
  },
  created() {
    if (util.verifyRootToken()) {
      this.$router.push({ name: CONSTANTS.ROUTE_NAME.CLIENT_MANAGEMENT });
    }
  },
  methods: {
    async login() {
      try {
        const TOKEN = await server.rootSignIn({
          root_name: this.usernameInput,
          password: this.passwordInput,
        });

        if (this.permanent) {
          localStorage.setItem('ROOT_TOKEN', JSON.stringify(TOKEN));
        }
        this.$router.push({ name: CONSTANTS.ROUTE_NAME.CLIENT_MANAGEMENT });
      } catch (error) {
        this.error = error;
        this.loginFactorForServerError = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.signin-container {
  background-size: auto 67%;
  background-repeat: repeat-x;
}

.signin-content {
  width: 100%;
}
.signin-title {
  font-size: 32px;

  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  color: var(--surface-100);
}
.input-default {
  height: 56px;
  width: 318px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 24px;
  margin-bottom: 8px;
  font-size: 16px;
  caret-color: var(--primary-80);
}
.input-default:focus {
  outline: 2px solid var(--primary-80);
}
.signin-card-container {
  margin-top: 167px;
  width: 400px;
  height: 677px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.signin-error {
  background: var(--error-10);
  border-radius: 8px;
  height: 40px;
  width: 318px;
  line-height: 40px;
  color: var(--error-100);
  font-size: 12px;
  padding-left: 16px;
  margin: 0 0 16px 0;
}
.signin-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 8px;

  justify-content: left;
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
}
.signin-form-col {
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin-bottom: 10px;
}
.signin-form-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 80px;
  margin-right: 10px;
}

.signin-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  margin: 44px 0 40px 0;
  height: 60px;
  cursor: pointer;

  &.default {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-20);
    cursor: unset;
  }

  &.ok {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-100);
  }
}
</style>
