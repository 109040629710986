<template>
  <div>
    <div>
      <!-- 고객사명           -->
      <CustomInputTextForm
        validity="client-comp-name"
        title="고객사명"
        :info-font-small="true"
        placeholder="고객사명을 입력하세요."
        @set="
          $event => {
            clientNameChecked = $event.res;
            $emit('companyId', $event.compid);
          }
        "
      />
    </div>
    <div style="margin-top: 56px">
      <SubmitButton
        :dim="!clientNameChecked"
        width="100%"
        height="60px"
        font-size="20px"
        button-string="다음"
        bg-dim-color="var(--primary-20)"
        bg-color="var(--primary-100)"
        text-dim-color="var(--surface-100)"
        text-color="var(--surface-100)"
        @click="$emit('next')"
      />
    </div>
  </div>
</template>
<script>
import SubmitButton from '@/components/SubmitButton.vue';
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
export default {
  name: 'SignUp',
  components: {
    SubmitButton,
    CustomInputTextForm,
  },
  data() {
    return {
      clientNameChecked: false,
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
