<template>
  <section
    class="eec-expand-half"
    style="max-height:352px;margin-bottom:16px;overflow:hidden"
  >
    <div class="eec-flex-row" style="margin-top: 24px">
      <div class="label-key">신분증 정보</div>
      <div class="label-value">
        {{ idType }}
      </div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">사진 첨부 여부</div>
      <div v-if="attachedPhoto" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">직접 입력 여부</div>
      <div v-if="manualInput" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">정보 수정 여부</div>
      <div v-if="idCardModified" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>

    <div>
      <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key-list">· 이름</div>
        <div
          :class="{
            'label-value': !isModifiedData.user_name,
            'label-value--red': isModifiedData.user_name,
          }"
        >
          {{ itemData.name }}
        </div>
      </div>
      <div
        v-if="driverNo && !isModifiedData.driver_no"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 운전면허번호</div>
        <div class="label-value">
          {{ driverNo }}
        </div>
      </div>
      <div class="eec-flex-row">
        <div v-if="isModifiedData.driver_no" class="label-key-list">
          · 운전면허번호
        </div>
        <div v-if="isModifiedData.driver_no" class="label-value--red">
          {{ driverNoModified }}
        </div>
      </div>
      <div
        v-if="!isModifiedData.id_number"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· {{ idNumType }}</div>
        <div class="label-value">
          {{ idNumber }}
        </div>
      </div>
      <div v-if="idCardModified" class="eec-flex-row" style="margin-top: 10px">
        <div v-if="isModifiedData.id_number" class="label-key-list">
          · {{ idNumType }}
        </div>
        <div v-if="isModifiedData.id_number" class="label-value--red">
          {{ modifiedIdNumber }}
        </div>
      </div>

      <div
        v-if="!isModifiedData.created_at"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 발급 일자</div>
        <div class="label-value">
          {{ createdAt }}
        </div>
      </div>
      <div
        v-if="isModifiedData.created_at"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 발급 일자</div>
        <div class="label-value--red">
          {{ modifiedCreatedAt }}
        </div>
      </div>
    </div>

    <div class="eec-flex-row" style="margin-top: 24px">
      <div class="label-key">신분증 진위확인</div>
      <div v-if="idCardStatus" class="label-value">확인</div>
      <div v-else class="label-value--red">실패</div>
    </div>
    <div class="eec-split-line"></div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    isModifiedData: {
      type: Object,
      default: () => {},
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    detailedItemData: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  data() {
    return {};
  },
  computed: {
    createdAt() {
      return this.detailedItemData.created_at;
    },
    driverNo() {
      return this.detailedItemData.driver_no;
    },
    driverNoModified() {
      return this.detailedItemData.modified_driver_no;
    },
    idCardModified() {
      return this.detailedItemData.id_card_modified;
    },
    idCardStatus() {
      return this.detailedItemData.id_card_status;
    },
    idNumber() {
      return this.detailedItemData.id_number;
    },
    idNumType() {
      return this.detailedItemData.id_num_type;
    },
    idType() {
      return this.detailedItemData.id_type;
    },
    modifiedCreatedAt() {
      return this.detailedItemData.modified_created_at;
    },
    modifiedIdNumber() {
      return this.detailedItemData.modified_id_number;
    },
    attachedPhoto() {
      return this.detailedItemData.is_uploaded;
    },
    manualInput() {
      return this.detailedItemData.is_manual_input;
    },
  },
  methods: {},
};
</script>
