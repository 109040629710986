<template>
  <div
    class="signin-container signin-admin-background full-size"
    style="display: flex;min-height:800px; justify-content: center"
  >
    <div class="background-signin-image"></div>

    <Login
      class="signin-card"
      loginType="admin"
      style="width: 400px; max-height: 100%; "
      @find="find()"
    />
  </div>
</template>
<script>
import Login from '@/components/reviewer/signin/Login.vue';
import CONSTANTS from '@/constants.js';
import collision from '@/store/mixins/collision.js';
export default {
  mixins: [collision],
  mounted() {
    this.otherComponent = document.querySelector('#signin-form');
    this.isCollide();
  },
  updated() {
    this.otherComponent = document.querySelector('#signin-form');
    this.isCollide();
  },
  name: 'SignIn',
  components: {
    Login,
  },
  data() {
    return {
      customerInput: '',
      usernameInput: '',
      passwordInput: '',
      error: '',
      visible: false,
      permanent: true,
    };
  },
  created() {},

  methods: {
    find() {
      this.$log.debug('AdminSignin#find~ 아이디 찾기');
      this.$router.push({
        name: CONSTANTS.ROUTE_NAME.FIND_ACCOUNT,
        query: { role: 'admin' },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.signin-content {
  width: 100%;
}
.signin-title {
  font-size: 32px;

  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  color: var(--surface-100);
}
.input-default {
  height: 56px;
  width: 318px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 16px;
  margin-bottom: 8px;
  font-size: 16px;
  caret-color: var(--primary-80);
}
.input-default:focus {
  outline: 2px solid var(--primary-80);
}
.signin-card-container {
  margin-top: 167px;
  width: 400px;
  height: 721px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.signin-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 8px;
  justify-content: left;
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
  &.default {
    height: 486px;
  }
  &.error {
    height: 542px;
  }
}
.signin-form-col {
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin-bottom: 10px;
}
.signin-form-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 80px;
  margin-right: 10px;
}

.signin-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  margin-top: 44px;
  height: 60px;
  cursor: pointer;

  &.default {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-20);
    cursor: unset;
  }

  &.ok {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-100);
  }
}
</style>
