import server from '@/api/server.js';
import { subModules } from './submodules/admin';
const module = {
  state: function() {
    return {
      reviewerList: [],
      modifiedInputInfoManagement: {
        activation: false,
      },
      originalInputInfoManagement: {
        activation: false,
      },
      tncGuidanceList: [
        {
          id: '1',
          title: '신분증 인증',
        },
        {
          id: '2',
          title: '계좌 인증',
        },
        {
          id: '3',
          title: '신분증 인증 ˙ 얼굴 인증',
        },
        {
          id: '4',
          title: '신분증 인증 ˙ 계좌 인증',
        },
        {
          id: '5',
          title: '신분증 인증 ˙ 얼굴인증 ˙ 계좌인증',
        },
      ],
      tncGuidanceListDetail: {
        '1': {
          pica: `개인정보 수집 및 이용 동의
          AIIR ID는 고객님의 소중한 개인정보 보호를 위해 최선을 다하고 있으며 개인정보 보호 관련 법규를 준수하고 있습니다. AIIR ID는 고객님의 소중한 개인정보 보호를 위해 최선을 다하고 있으며 개인정보 보호 관련 법규를 준수하고 있습니다. AIIR ID는 고객님의 소중한 개인정보 보호를 위해 최선을 다하고 있으며 개인정보 보호 관련 법규를 준수하고 있습니다.
          …생략…
          본 동의를 거부할 수 있으며, 동의 거부 시 회사가 제공하는 서비스 이용이 불가능합니다.
          ※ 위 개인정보 수집 이용에 동의하십니까?
          고유식별정보 처리 동의
          AIIR ID는 고객님의 소중한 개인정보 보호를 위해 최선을 다하고 있으며 개인정보 보호 관련 법규를 준수하고 있습니다. AIIR ID는 고객님의 소중한 개인정보 보호를 위해 최선을 다하고 있으며 개인정보 보호 관련 법규를 준수하고 있습니다. AIIR ID는 고객님의 소중한 개인정보 보호를 위해 최선을 다하고 있으며 개인정보 보호 관련 법규를 준수하고 있습니다. 
          …생략…
          본 동의를 거부할 수 있으며, 동의 거부 시 회사가 제공하는 서비스 이용이 불가능합니다.
          ※ 위 개인정보 수집 이용에 동의하십니까?`,
          ppg: `개인정보의 처리위탁 회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다. (*표기 업체는 엔에이치엔(주)의 재위탁 업체 입니다.)
          `,
        },
      },
    };
  },
  mutations: {
    setReviewerList(state, params) {
      //state.reviewerList = [];
      state.reviewerList = params;
    },
  },
  actions: {
    async getReviewerList({ rootState, commit }, payload) {
      try {
        const data = await server.readReviewer(payload);
        let elaborated = data.list.map(res => {
          return subModules.readReviewerList(res);
        });
        commit('setReviewerList', elaborated);
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
  },
  getters: {},
};

export default module;
