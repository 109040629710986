var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[_c('input',{class:{
      'admin-input-form': true,
    },staticStyle:{"margin-right":"-48px"},attrs:{"placeholder":"고객사명을 입력하세요."},domProps:{"value":_vm.clientCompName},on:{"input":function($event){return _vm.debounceSearch($event)}}}),_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      !_vm.isChangingNow && _vm.clientCompName.length > 0 && _vm.itemList.length == 0
    ),expression:"\n      !isChangingNow && clientCompName.length > 0 && itemList.length == 0\n    "}],staticClass:"dropdown-list",staticStyle:{"padding":"8px 24px"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"start"}},[_vm._v(" 검색어와 일치하는 항목이 없습니다. ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isChangingNow),expression:"!isChangingNow"}],staticClass:"dropdown-list",attrs:{"hidden":!(_vm.clientCompIsOnSelect && _vm.itemList.length > 0)}},_vm._l((_vm.itemList),function(item){return _c('div',{key:item.id,staticClass:"dropdown-item",on:{"click":function($event){_vm.clientCompName = item.name;
        _vm.clientCompId = item.id;
        _vm.clientCompIsOnSelect = false;
        _vm.$emit('compInfo', {
          id: _vm.clientCompId,
          name: _vm.clientCompName,
          onSelect: _vm.clientCompIsOnSelect,
          item: _vm.itemList,
        });}}},[_c('span',[_vm._v(_vm._s(item.name.substr(0, item.name.search(_vm.searchExpression()))))]),_c('span',{staticStyle:{"color":"blue"}},[_vm._v(_vm._s(item.name.substr( item.name.search(_vm.searchExpression()), _vm.clientCompName.length )))]),_c('span',[_vm._v(_vm._s(item.name.substr( item.name.search(_vm.searchExpression()) + _vm.clientCompName.length, item.name.length )))])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticStyle:{"padding-left":"-48px"}},[_c('img',{staticStyle:{"margin":"16px 0"},attrs:{"src":require("@/assets/search-icon.svg"),"width":"24px"}})])}]

export { render, staticRenderFns }