<template>
  <div id="global-footer" ref="footer" style="position:absolute;bottom:0;">
    <footer style="font-size: 12px; background: var(--primary-5)">
      <tncDialog
        v-model="$store.state.tncFlag"
        :type="1"
        @exit="$store.commit('closeTncPpDialog')"
        :key="`${tncFlag}-tncDialog`"
      />
      <ppDialog
        v-model="$store.state.ppFlag"
        :selectablePps="this.selectableList"
        :initPpParagraph="initPpParagraph"
        :type="2"
        @exit="$store.commit('closeTncPpDialog')"
        :key="`${ppFlag}-ppDialog`"
      />
      <div
        style="
          flex-direction: column;
          padding-top:16px;
          width: 100vw;
          height: 60px;
          border-top: 0.5px solid var(--gray-200);
        "
      >
        <div class="footer">
          <div @click="upTnc" style="color: var(--surface-medium)">
            이용약관
          </div>
          <div class="footer--bar"></div>
          <div @click="upPp">개인정보처리방침</div>
          <div class="footer--bar"></div>
          <div style="color: var(--surface-medium)">고객센터</div>
          <div class="footer--margin"></div>
          <div style="color: var(--surface-medium)">070-4849-1000</div>
          <div class="footer--margin"></div>
          <div style="color: var(--surface-medium)">cs@useb.co.kr</div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import server from '@/api/server';
import tncDialog from '@/components/dialog/tncDialog.vue';
import ppDialog from '@/components/dialog/tncDialog.vue';
export default {
  name: 'GlobalFooter',
  components: {
    tncDialog,
    ppDialog,
  },
  data() {
    return {
      selectableList: {},
      initPpParagraph: '',
    };
  },
  created() {},
  computed: {
    selectablePps() {
      return Object.values(this.selectableList);
    },
    ppFlag() {
      return this.$store.state.ppFlag;
    },
    tncFlag() {
      return this.$store.state.tncFlag;
    },
  },
  methods: {
    upTnc() {
      this.$store.state.tncFlag = true;
    },
    async upPp() {
      const data = await server.readNoticeList('pp');
      let latest = data.latest_version;
      data.list.map(res => {
        if (res.version == latest) {
          this.selectableList['변경 전 개인정보처리방침'] = res.version;
        } else {
          this.selectableList[
            `${res.version} (${res.created_at ?? '날짜 API 미구현'})`
          ] = res.version;
        }
      });
      this.$log.debug('App#upPp~awaited data', data);
      this.$store.state.ppFlag = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: baseline;
  &--bar {
    width: 1px;
    margin: 0 10px;
    height: 10px;
    background: var(--gray-300);
  }
  &--margin {
    margin: 0 5px;
  }
}
</style>
