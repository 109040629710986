<template>
  <div class="root-container">
    <div class="container-phase-guide">
      <Step :phase="phase" :items="phaseItems"></Step>
      <div
        style="
          font-weight: 500;
          font-size: 20px;
          
          margin-top:70px;
          text-align: center;
        "
      >
        얼굴 인증을 시작합니다.
      </div>
      <img
        src="@/assets/icon-guide.svg"
        width="136px"
        style="margin: 32px auto 52px;"
      />
      <div style="align-self:center;">
        <div class="text-title-tip">올바른 촬영 TIP</div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>영역 안에 얼굴을 맞추고 정면을 바라봐 주세요.</div>
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>너무 밝거나 어둡지 않은 공간에서 촬영을 진행해 주세요.</div>
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>
            마스크, 선글라스와 같이 얼굴을 가리는 방해물은 제거 후 촬영해
            주세요.
          </div>
        </div>
      </div>
      <div class="button-container">
        <div @click="$emit('cancel')" class="button cancel">취소</div>
        <div @click="$emit('next')" class="button ok">얼굴 촬영</div>
      </div>
    </div>
  </div>
</template>

<script>
import Step from './Step';
export default {
  components: { Step },
  props: {
    phase: Number,
    phaseItems: Array,
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.spacer {
  flex-grow: 1;
}

.button-container {
  width: 100%;
  bottom: 0;
  display: flex;
  padding: 0px 30px 40px 30px;
  margin-top: 56px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
