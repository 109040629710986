<template>
  <div
    class="container"
    style="
      padding: 0px 30px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
    "
  >
    <img
      @click="stopScan = true"
      src="@/assets/icon-close-32.svg"
      class="icon-close"
    />
    <div class="card-result-title">계좌인증</div>

    <div
      style="text-align: center; margin-bottom: 2rem; margin: 55px auto 30px"
    >
      <h2 class="bank-title">1원을 보냈습니다.</h2>
      <label class="bank-label"
        >입금내역에 표시된 숫자 4자리를 입력해 주세요.</label
      >
    </div>
    <div class="bank_body" style="">
      <v-img
        max-height="144"
        max-width="193"
        :transition="false"
        :src="require('@/assets/bank_history.svg')"
      />
    </div>
    <div class="bank-timer">
      <img src="@/assets/time_20.svg" alt="timer" />
      <p class="bank-timer-countdown">{{ countDownFormat }}</p>
    </div>
    <div class="bank_verify_num">
      <input
        type="number"
        name="input1"
        @blur="onBlur"
        @keyup="onKeyUp"
        pattern="\d*"
        ref="input1"
        :class="{ focus: this.inputFocus.num1 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
      <input
        type="number"
        pattern="\d*"
        @blur="onBlur"
        @keyup="onKeyUp"
        name="input2"
        ref="input2"
        :class="{ focus: this.inputFocus.num2 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
      <input
        type="number"
        pattern="\d*"
        @blur="onBlur"
        @keyup="onKeyUp"
        name="input3"
        ref="input3"
        :class="{ focus: this.inputFocus.num3 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
      <input
        type="number"
        pattern="\d*"
        @blur="onBlur"
        @keyup="onKeyUp"
        name="input4"
        ref="input4"
        :class="{ focus: this.inputFocus.num4 }"
        maxlength="1"
        @focus="onFocus"
        @input="onChange"
      />
    </div>
    <div class="bank_verify_label">
      입금내역이 없다면 등록하신 계좌 정보를 다시 확인해 주세요.
    </div>
    <div class="bank_account_display">
      <div>
        <div
          v-if="appData.tabIdx === 0"
          class="bank_account_num"
          style="margin-left: 0.3rem"
        >
          {{ CONSTANTS.BANK_NAME[appData.bankIdx].fullName }}
        </div>
        <div
          v-if="appData.tabIdx === 1"
          class="bank_account_num"
          style="margin-left: 0.3rem"
        >
          {{ CONSTANTS.BROKERAGE[appData.bankIdx].fullName }}
        </div>
        <div class="bank_account_num">
          {{ appData.account_num }}
        </div>
      </div>
      <v-btn
        color="var(--surface-medium)"
        class="bank_change_btn"
        text
        @click="onClickChangeBank"
        style="padding: 0px; color:#868686"
        :disabled="loading"
        >변경하기</v-btn
      >
    </div>

    <div
      class="verify-btn-container bank-verify-btn-container"
      :class="{ sticky: true }"
      style="
        display: flex;

        bottom: 0px;
        left: 0px;
      "
    >
      <v-btn
        :disabled="disabled"
        @click="verify"
        ref="verifyBtn"
        block
        color="var(--primary-100)"
        style="font-size: 1rem;box-shadow: none;"
      >
        <span v-if="!loading">확인</span>
        <Loading v-else />
      </v-btn>
    </div>
    <ExitDialog v-model="stopScan" @ok="onClickBack" />
    <VerifyFailDialog
      v-model="verifyConfirm"
      @ok="onClickExit"
      @retry="onSelectBank"
      @cancel="onCancel"
      :verifyCount="verifyCount"
    />
    <SystemErrorDialog
      v-model="systemError"
      :errorCode="systemErrorCode"
      @ok="onClickExit"
    />
    <ChangeBankDialog v-model="changeBank" @retry="onSelectBank" />
  </div>
</template>
<script>
import ExitDialog from './dialog/ExitDialog';
import SystemErrorDialog from './dialog/SystemErrorDialog';
import VerifyFailDialog from './dialog/VerifyFailDialog';
import ChangeBankDialog from './dialog/ChangeBankDialog.vue';
import CONSTANTS from './constants';
import EventBus from '@/api/EventBus';
// ! needs to change module import useb from "@/api/useb";
import useb from '@/api/useb';
import server from '@/api/server';
import Loading from './Loading.vue';

export default {
  components: {
    ExitDialog,
    VerifyFailDialog,
    ChangeBankDialog,
    SystemErrorDialog,
    Loading,
  },
  props: {
    appData: Object,
    industry: Number,
  },
  data() {
    return {
      loading: false,
      verifyBtnPosition: true,
      verifyCount: 0,
      verifyConfirm: false,
      systemError: false,
      systemErrorCode: '',
      changeBank: false,
      disabled: true,
      verifyData: {
        transaction_id: '',
        print_content: '',
      },
      stopScan: false,
      CONSTANTS,
      inputFocus: {
        num1: false,
        num2: false,
        num3: false,
        num4: false,
      },
      countDown: 300,

      countDownFormat: '05:00',
    };
  },
  created() {
    this.$log.debug('BankVerify#created', this.appData);
    this.initialize();
    this.countDownTimer();
  },
  mounted() {
    this.initialize();
    this.$refs.input1.focus();
  },
  methods: {
    onBlur(e) {
      this.verifyBtnPosition = true;
      if (
        this.$refs.input1.value &&
        this.$refs.input2.value &&
        this.$refs.input3.value &&
        this.$refs.input4.value
      ) {
        this.disabled = false;
      } else {
        this.diabled = true;
      }
      if (!this.$refs[e.target.name].value) {
        switch (e.target.name) {
          case 'input1':
            this.$set(this.inputFocus, 'num1', false);
            break;
          case 'input2':
            this.$set(this.inputFocus, 'num2', false);
            break;
          case 'input3':
            this.$set(this.inputFocus, 'num3', false);
            break;
          case 'input4':
            this.$set(this.inputFocus, 'num4', false);
            break;
        }
      }
    },
    onCancel() {
      if (this.verifyCount >= 3) {
        this.$emit('cancel', { selectBank: true });
      }
    },
    onClickBack() {
      this.$emit('cancel');
    },
    async countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          if (this.countDown === 0) {
            let formData = new FormData();
            formData.append('transaction_id', this.appData.transaction_id);
            formData.append('verified_account', 0);

            formData.append('finance_company', this.appData.finance_company);
            formData.append('finance_code', this.appData.bank_code);
            formData.append('account_number', this.appData.account_num);
            formData.append('account_holder', this.appData.account_holder);
            formData.append('account_modified', this.appData.account_modified);

            server.startApplicationAccount(formData).then(response => {
              this.$emit('next', {
                fail: true,
                response,
                errorType: 'timeout',
              });
            });
          }
          const minute = this.fillZero(
            2,
            String(parseInt(this.countDown / 60))
          );
          const second = this.fillZero(2, String(this.countDown % 60));

          this.countDownFormat = `${minute}:${second}`;
          this.countDownTimer();
        }, 1000);
      }
    },
    fillZero(width, str) {
      return str.length >= width
        ? str
        : new Array(width - str.length + 1).join('0') + str; //남는 길이만큼 0으로 채움
    },
    async verify() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      // this.disabled = true
      try {
        this.$set(
          this.verifyData,
          'print_content',
          this.$refs.input1.value +
            this.$refs.input2.value +
            this.$refs.input3.value +
            this.$refs.input4.value
        );

        await useb.verifyOpenbank(this.verifyData, this.industry);

        let formData = new FormData();
        formData.append('transaction_id', this.appData.transaction_id);
        formData.append('verified_account', 1);
        formData.append('finance_code', this.appData.bank_code);
        formData.append('finance_company', this.appData.finance_company);
        formData.append('account_number', this.appData.account_num);
        formData.append('account_holder', this.appData.userName);
        formData.append('account_modified', this.appData.account_modified);
        formData.append('mod_account_holder', this.appData.mod_account_holder);
        if (this.appData.modified_account_holder) {
          formData.append(
            'modified_account_holder',
            this.appData.modified_account_holder
          );
        }

        const response = await server.startApplicationAccount(formData);
        this.$emit('next', { response });
        this.loading = false;
        // this.disabled = false
      } catch (err) {
        const { errorCode } = err;
        if (errorCode !== 'V001') {
          this.systemError = true;
          this.systemErrorCode = errorCode;
        } else {
          this.verifyCount += 1;
          this.$log.debug('BankVerify#verify verifyCount', this.verifyCount);

          if (this.verifyCount === 3) {
            try {
              let formData = new FormData();
              formData.append('transaction_id', this.appData.transaction_id);
              formData.append('verified_account', 0);
              formData.append('finance_code', this.appData.bank_code);
              formData.append('finance_company', this.appData.finance_company);
              formData.append('account_number', this.appData.account_num);
              formData.append('account_holder', this.appData.account_holder);
              formData.append(
                'account_modified',
                this.appData.account_modified
              );
              if (this.appData.modified_account_holder) {
                formData.append(
                  'modified_account_holder',
                  this.appData.modified_account_holder
                );
              }
              const response = await server.startApplicationAccount(formData);
              // this.$emit('next', { fail: true, response })
              this.$emit('next', { fail: true, response, errorType: 'tryout' });
            } catch (error) {
              this.$log.debug(error);
              this.systemError = true;
              this.systemErrorCode = errorCode;
            }
          }
          this.verifyConfirm = true;
        }
        this.loading = false;
        this.disabled = false;
      }
    },
    onClickExit() {
      this.stopScan = true;
    },
    initialize() {
      if (this.industry < 3) {
        this.$set(
          this.verifyData,
          'transaction_id',
          this.appData.transaction_id_useb
        );
      } else {
        this.$set(this.verifyData, 'tid', this.appData.transaction_id_useb);
      }
    },
    onClickChangeBank() {
      this.changeBank = true;
    },
    onSelectBank() {
      EventBus.$emit('select');
      this.$emit('cancel', { selectBank: true });
    },
    onKeyUp(e) {
      if (e.key === 'Enter') {
        this.$refs.input4.blur();
        this.verify();
      }
    },
    onFocus(e) {
      this.verifyBtnPosition = false;
      if (
        this.$refs.input1.value &&
        this.$refs.input2.value &&
        this.$refs.input3.value &&
        this.$refs.input4.value
      ) {
        this.disabled = false;
      } else {
        this.diabled = true;
      }
      switch (e.target.name) {
        case 'input1':
          this.$set(this.inputFocus, 'num1', true);
          this.$refs.input1.value = '';
          break;
        case 'input2':
          this.$set(this.inputFocus, 'num2', true);
          this.$refs.input2.value = '';
          break;
        case 'input3':
          this.$set(this.inputFocus, 'num3', true);
          this.$refs.input3.value = '';
          break;
        case 'input4':
          this.$set(this.inputFocus, 'num4', true);
          this.$refs.input4.value = '';
          break;
        default:
          break;
      }
    },
    onChange(e) {
      if (
        this.$refs.input1.value &&
        this.$refs.input2.value &&
        this.$refs.input3.value &&
        this.$refs.input4.value
      ) {
        this.disabled = false;
      } else {
        this.diabled = true;
      }
      switch (e.target.name) {
        case 'input1':
          this.$refs.input2.value = '';
          this.$refs.input2.focus();
          break;
        case 'input2':
          this.$refs.input3.value = '';
          this.$refs.input3.focus();
          break;
        case 'input3':
          this.$refs.input4.value = '';
          this.$refs.input4.focus();
          break;
        case 'input4':
          if (this.$refs.input4.value.length > 1) {
            this.$refs.input4.value = this.$refs.input4.value.slice(0, 1);
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
.bank-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;

  color: var(--surface-high);
}
.bank-label {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--surface-medium);
}
.bank_change_btn span {
  font-size: 14px;
  :disabled {
    color: #ccc !important;
  }
}
.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}
.card-result-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
}
.bank_body {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  display: flex;
}
.bank_account_display {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 10px 16px;
  background: #f7f7f7;
  border-radius: 8px;
  height: 40px;

  div {
    display: flex;
    align-items: center;
  }
  .bank_account_num {
    /* KR/Body 5 - 14 */
    margin-left: 0.5rem;
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--surface-medium);
  }
}
div.bank_account_display > button > span {
  font-size: 14px;
}
.bank_chagne_btn span {
  font-size: 14px !important;
}

.bank-timer {
  margin-top: 8px;
  display: flex;
  width: 60px;
  height: 20px;
  justify-content: space-between;

  .bank-timer-countdown {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    color: var(--error-100);
    margin-top: 1px;
  }
}

@media (max-width: 350px) {
  .bank_verify_num {
    input {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

.bank_verify_num {
  margin: 1rem 0px 11px;
  // width:80%;
  // max-width:350px;
  input {
    width: 62px;
    height: 62px;

    margin: 5px;

    border: 1px solid var(--gray-300);
    box-sizing: border-box;
    /* 2 dp, Primary */

    /* box-shadow: 0px 2px 8px 4px rgba(95, 101, 255, 0.05); */
    filter: drop-shadow(0px 0px 8px rgba(95, 101, 255, 0.1));
    border-radius: 4px;
    text-align: center;

    //input value control
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    /* identical to box height, or 150% */

    text-align: center;
    outline: none;
    /* On Surface/Text / High Emphasis */

    color: var(--surface-high);
    &.focus {
      border: 1px solid var(--primary-80);
      outline: none;
    }
    &:focus-visible {
      border: 1px solid var(--primary-80);
      outline: none;
    }
  }
}
.bank_verify_label {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  /* Gray/500 */

  color: var(--gray-500);
}

.bank-verify-btn-container .v-btn__content {
  color: #fff;
}

.verify-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  padding: 16px 0px 40px;
  /* height: 116px; */
  cursor: pointer;
  background-color: white;
  button {
    height: 60px !important;
    font-size: 1rem;
    border-radius: 8px;
    color: #fff;
    flex: 1;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: var(--primary-20) !important;
  }
}

.btn-container {
  flex: 1;
  width: 100%;
  position: static;
  &.sticky {
    position: sticky;
  }
  display: flex;
  align-items: flex-end;
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 0, 0, 0.12) !important;
    background-color: var(--primary-20) !important;
    color: #fff !important;
  }
  button {
    //size styling
    height: 60px !important;
    padding: 15px 16px;
    border-radius: 8px;

    //color styling
    background: var(--primary-100);

    //text styling
    color: #fff;
    font-family: Noto Sans KR;
    font-style: normal;
    /* font-weight: 500; */
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 29px;
  }
}
</style>
