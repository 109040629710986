<template>
  <div>
    <div class="signin-title">
      <img src="@/assets/logo.svg" alt="ekyc main logo" />
    </div>
    <form
      id="signin-form"
      :class="{
        'signin-form default flex-center': !error,
        'signin-form error flex-center': error,
      }"
    >
      <div
        style="
          font-weight: bold;
          font-size: 24px;
          color: var(--primary-80);
          margin: 40px 0 38px 0;
        "
      >
        {{ defaultInfo[loginType].title }}
      </div>
      <div style="width: 318px; padding-bottom: 16px">
        <CustomInputTextForm
          validity="client-comp-name"
          placeholder="고객사명을 입력하세요."
          @set="
            $event => {
              loginFactorForServerError = false;
              customerInput = $event.val;
              customerInputChecked = $event.res;
              customerInputValue = $event.compid;
            }
          "
          :comp-name-to-code="true"
          :init-val="customerInput"
          :no-title="true"
          :for-login="true"
          :info-font-small="true"
        />
      </div>

      <div style="width: 318px; padding-bottom: 16px">
        <CustomInputTextForm
          validity="individual-id"
          placeholder="아이디"
          @set="
            $event => {
              loginFactorForServerError = false;
              usernameInput = $event.val;
            }
          "
          :init-val="usernameInput"
          :no-title="true"
          :for-login="true"
          :info-font-small="true"
        />
      </div>
      <div style="width: 318px; padding-bottom: 16px">
        <CustomInputTextForm
          validity="password"
          placeholder="비밀번호"
          :is-password="true"
          @set="
            $event => {
              loginFactorForServerError = false;
              passwordInput = $event.val;
            }
          "
          :init-val="passwordInput"
          :no-title="true"
          :for-login="true"
          :info-font-small="true"
        />
      </div>
      <!-- <div v-if="error">{{ error }}</div> -->
      <div stlye="position:relative;">
        <div style="display: flex; flex-direction: row; width: 318px"></div>
        <div v-show="error" class="critical-error">
          {{ error }}
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 318px;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div
          class="flex-center"
          style="display: flex; flex-direction: row"
          @click="permanent = !permanent"
        >
          <img
            v-show="permanent"
            src="@/assets/checkbox_checked_24.svg"
            alt="checked"
          /><img
            v-show="!permanent"
            src="@/assets/checkbox_24.svg"
            alt="unchecked"
          />

          <div style="margin-left: 4px; font-size: 14px;line-height:24px">
            로그인 상태 유지
          </div>
        </div>

        <div class="signin-find flex-center" @click="signup()">
          <div>회원가입</div>
          <img src="@/assets/next_16.svg" alt="" />
        </div>
      </div>

      <div v-if="isLoginAvailable" @click="login()" class="signin-form-btn ok">
        로그인
      </div>
      <div v-else class="signin-form-btn default">로그인</div>
      <div class="signin-problem" @click="$emit('find', { type: 1 })">
        로그인에 문제가 있나요?
      </div>
    </form>
  </div>
</template>
<script>
import CONSTANTS from '@/constants';
import server from '@/api/server';
import util from '@/util';
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';

export default {
  name: 'SignIn',
  props: {
    loginType: {
      type: String,
    },
  },
  components: {
    CustomInputTextForm,
  },
  data() {
    return {
      defaultInfo: {
        reviewer: {
          title: 'Login',
        },
        admin: {
          title: 'Admin Login',
        },
      },
      loginFactorForServerError: false,
      customerInput: '',
      customerInputChecked: false,
      customerInputValue: -1,
      usernameInput: '',
      passwordInput: '',
      error: '',
      visible: false,
      permanent: true,
    };
  },
  created() {
    if (util.verifyReviewerToken()) {
      this.$router.push({ name: CONSTANTS.ROUTE_NAME.EVALUATION_REVIEW });
      this.$log.debug(
        'Login# verify token info on created hook, Token is for Reviewer ',
        util.verifyReviewerToken()
      );
    }
    if (util.verifyAdminToken()) {
      this.$router.push({ name: CONSTANTS.ROUTE_NAME.REVIEWER_MANAGEMENT });
      this.$log.debug(
        'Login# verify token info on created hook, Token is for Admin',
        util.verifyAdminToken()
      );
    }
  },
  computed: {
    isLoginAvailable() {
      return (
        this.usernameInput.length > 0 &&
        this.passwordInput.length > 0 &&
        this.customerInputChecked &&
        !this.loginFactorForServerError
      );
    },
  },
  methods: {
    signup() {
      this.$log.debug('Login#signup~ 회원가입');
      this.$router.push({
        name: CONSTANTS.ROUTE_NAME.SIGN_UP,
        query: { role: this.loginType },
      });
    },
    async login() {
      try {
        const TOKEN = await server.signin({
          customer_id: Number(this.customerInputValue),
          username: this.usernameInput,
          password: this.passwordInput,
        });
        this.$store.commit(
          'setCompanyPhoneNumber',
          server.getCompanyPhoneNumber()
        );

        TOKEN['customer_id'] = this.customerInputValue;
        switch (this.loginType) {
          case 'reviewer':
            if (TOKEN.role != 1) {
              throw { message: '접근 권한이 없음' };
            }
            localStorage.setItem('REVIEWER_TOKEN', JSON.stringify(TOKEN));

            if (this.permanent) {
              // TODO refresh token is not applied
              this.$log.debug('REVIEWER KEEP LOGIN');
              localStorage.setItem(
                'REVIEWER_REFRESG_TOKEN',
                JSON.stringify(TOKEN)
              );
            }
            if (!TOKEN.is_temp_password) {
              this.$router.push({
                name: CONSTANTS.ROUTE_NAME.EVALUATION_REVIEW,
              });
            }
            break;
          case 'admin':
            if (TOKEN.role != 0) {
              throw { message: '접근 권한이 없음' };
            }
            localStorage.setItem('ADMIN_TOKEN', JSON.stringify(TOKEN));
            if (this.permanent) {
              // TODO refresh token is not applied
              this.$log.debug('ADMIN KEEP LOGIN');
              // localStorage.setItem('ADMIN_REFRESH_TOKEN', JSON.stringify(TOKEN));
            }
            if (!TOKEN.is_temp_password) {
              this.$router.push({
                name: CONSTANTS.ROUTE_NAME.REVIEWER_MANAGEMENT,
              });
            }
            break;
          default:
            break;
        }
        if (TOKEN.is_temp_password) {
          //password change
          this.$router.push({
            name: CONSTANTS.ROUTE_NAME.PASS_CHANGE,
            params: {
              role: TOKEN.role,
              customer_id: this.customerInputValue,
              username: this.usernameInput,
              password: this.passwordInput,
              loginType: this.loginType, // for login at passwordchange view
            },
          });
        }
      } catch (error) {
        this.$log.debug('Login#login~ error occur', error);
        switch (error.errorCode) {
          case 'F301':
            this.error =
              '고객사 코드, 아이디 혹은 비밀번호를 잘못 입력하셨습니다.';
            break;
          case 'F302':
          case 'F304':
            this.error = '비활성화된 아이디입니다.';
            break;
          case 'F303':
            this.error = '비활성화된 고객사입니다.';
            break;
          case 'E580':
            this.error = '데이터 베이스가 유효하지 않습니다.';
            break;
          default:
            if (error.message == '접근 권한이 없음')
              this.error =
                '고객사 코드, 아이디 혹은 비밀번호를 잘못 입력하셨습니다.';
            break;
        }
        if (error.message) {
          this.loginFactorForServerError = true;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.signin-title {
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  color: var(--surface-100);
}
.signin-find {
  font-weight: normal;
  color: var(--surface-medium);
  font-size: 12px;
  cursor: pointer;
}
.signin-problem {
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  color: var(--primary-100);
}
</style>
