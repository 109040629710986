import validator from 'validator';

const testIncludesNotNumber = /[^0-9]/;
const testDateFormatRegx = /[^0-9-]/g;
const dateFormatRegx = /^\d{4}[-/.](0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])$/;
export default {
  ////////////////////////////////////////////////
  // input rules
  ////////////////////////////////////////////////
  required: val => !!val,
  koreanAndUpperEng: val => /^[가-힣A-Z\s]+$/.test(val),
  phoneNumber: val => /^(\d{2,3})(\d{3,4})(\d{4})$/.test(val),
  email: val => validator.isEmail(val),
  date: val => dateFormatRegx.test(val),
  expiryDate: val => {
    if (val.length == 10) {
      const now = new Date();
      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth() + 1;
      const nowDate = now.getDate();
      const nowExpirePeriod = new Date(`${nowYear}-${nowMonth}-${nowDate}`);
      const inputExpiryDate = new Date(val);

      if (nowExpirePeriod.getTime() > inputExpiryDate.getTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
  ////////////////////////////////////////////////
  // regx test for transform
  ////////////////////////////////////////////////
  testIncludesNotNumber,
  transformOnlyNumber: text => {
    if (text.length && testIncludesNotNumber.test(text)) {
      return text.substring(0, text.length - 1);
    }
    return text;
  },
  transformOnlyUpperCase: text => {
    return text.toUpperCase();
  },
  onblurTransformDateFormat: text => {
    // in case of "1900-11-2" -> "focusout event is occurred" -> replace to "1900-11-02"
    if (text.length === 9 && text.substr(8, 1) != 0) {
      text = text.substr(0, 8) + '0' + text.substr(8, 1);
    }
    return text;
  },
  transformDateFormat: (text, isBackspace) => {
    if (text) {
      text = text.replace(testDateFormatRegx, '');
    }
    if (!isBackspace && (/^\d{4}$/.test(text) || /^\d{4}-\d{2}$/.test(text))) {
      const checkMaxMonth = /^\d{4}-(\d{2})$/;
      if (checkMaxMonth.test(text) && checkMaxMonth.exec(text)[1] > 12) {
        text = text.slice(0, -1);
      } else {
        text += '-';
      }
    }
    if (!isBackspace && (/^\d{5}$/.test(text) || /^\d{4}-\d{3}$/.test(text))) {
      text = text.slice(0, -1) + '-' + text.slice(-1);
    }
    if (isBackspace && (/^\d{4}-$/.test(text) || /^\d{4}-\d{2}-$/.test(text))) {
      text = text.slice(0, -1);
    }
    const checkMaxDate = /^\d{4}-\d{2}-(\d{2})$/;
    if (
      !isBackspace &&
      checkMaxDate.test(text) &&
      checkMaxDate.exec(text)[1] > 31
    ) {
      text = text.slice(0, -1);
    }
    // check month
    const checkMonth = /^\d{4}-(\d{1})$/;
    if (!isBackspace && checkMonth.test(text)) {
      const result = checkMonth.exec(text);
      if (result[1] > 1) {
        text = text.slice(0, -1) + '0' + text.slice(-1);
      }
    }
    const checkMonthIsZero = /^\d{4}-(\d{2})-/;
    if (!isBackspace && checkMonthIsZero.test(text)) {
      const result = checkMonthIsZero.exec(text);
      if (result[1] == 0) {
        text = text.slice(0, -2);
      }
    }
    if (isBackspace && checkMonth.test(text)) {
      const result = checkMonth.exec(text);
      if (result[1] == 0) {
        text = text.slice(0, -2);
      }
    }
    // check date
    const checkDate = /^\d{4}-\d{2}-(\d{1})$/;
    if (!isBackspace && checkDate.test(text)) {
      const result = checkDate.exec(text);
      if (result[1] > 3) {
        text = text.slice(0, -1) + '0' + text.slice(-1);
      }
    }
    const checkDateIsZero = /^\d{4}-\d{2}-(\d{2})/;
    if (!isBackspace && checkDateIsZero.test(text)) {
      const result = checkDateIsZero.exec(text);
      if (result[1] == 0) {
        text = text.slice(0, -1);
      }
    }
    if (isBackspace && checkDate.test(text)) {
      const result = checkDate.exec(text);
      if (result[1] == 0) {
        text = text.slice(0, -2);
      }
    }
    if (text.length > 10) {
      text = text.slice(0, 10);
    }

    let dob1 = text.replace(/-/gi, '');
    let year = dob1.substr(0, 4) !== '' ? dob1.substr(0, 4) : null;
    let month = dob1.substr(4, 2) !== '' ? dob1.substr(4, 2) : null;
    let day = dob1.substr(6, 2) !== '' ? dob1.substr(6, 2) : null;

    let dob_item_list = [];
    if (year !== null) dob_item_list.push(year);
    if (month !== null) dob_item_list.push(month);
    if (day !== null) dob_item_list.push(day);

    text = dob_item_list.join('-');
    return text;
  },
};
