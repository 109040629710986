<template>
  <div class="root-container">
    <div class="container-phase-complete">
      <img
        src="@/assets/Finish.svg"
        width="234px"
        style="margin-bottom: 30px; margin-top : 156px;"
      />
      <div
        style="
          font-weight: bold;
          font-size: 28px;
          color: var(--primary-100);
          margin-bottom: 24px;
        "
      >
        본인 인증 완료
      </div>
      <div
        style="
          font-size: 16px;
          line-height: 26px;
          color: var(--surface-high);
          text-align: center;
        "
      >
        입력하신 KYC 인증 정보 최종 검토 후<br />
        이메일을 통해 승인 여부를 안내 드리겠습니다.
      </div>

      <div class="button-container" style="width: 100%">
        <div @click="onClickNext" class="button ok">확인</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * emit events
     * next
     */
  },
  methods: {
    onClickNext() {
      this.$emit('next');
    },
  },
};
</script>

<style>
svg rect {
  transform-box: fill-box;
}
</style>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  overflow: auto;
  color: var(--surface-high);
}

.container-phase-complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.spacer {
  flex-grow: 1;
}

.button-container {
  display: flex;
  padding: 0 30px 39px;
  margin-top: 56px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
