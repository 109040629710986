<template>
  <div class="view-container">
    <div class="content-container">
      <div class="client-types-container">
        <div class="client-types-area-left">
          <div class="client-management-title">고객사 관리</div>
          <button
            @click="addDialogFlag = true"
            class="add-button"
            type="submit"
          >
            +등록
          </button>
        </div>
        <div class="client-types-area-right">
          <Dropdown
            style="width: 112px"
            v-show="selectedTypeIndex === 0"
            v-model="selectedInspectionPendingFilter"
            :items="searchCriteriaDropdownItems"
            @change="onChangeFilter"
          ></Dropdown>
          <InputSearch
            @change="onChangeSearch"
            style="margin-left: 7px"
          ></InputSearch>
        </div>
      </div>

      <div class="audits-table-container">
        <CmTable
          v-if="selectedTypeIndex === 0"
          :table-name="'cm'"
          :titles="TABLE.CM.SIZE"
          :titleNames="TABLE.CM.NAME"
          :contents="clientList"
          @getDetail="getDetailedClientInfo($event)"
          :key="`${tableRerender}-cm`"
        >
          <template slot-scope="props">
            <div
              :class="
                props.tid == 'status'
                  ? props.c === '활성'
                    ? 'high-color'
                    : 'low-color'
                  : ''
              "
            >
              {{ props.c }}
            </div>
          </template>
          <template v-slot:detail="props">
            <ClientExpandedContent
              v-if="
                isOpenedFromChild.includes(props.item.id) &&
                  $store.state.root.clientExpandedInfo[props.item.id]
              "
              :item-data="props.item"
              :module-initial-data="
                $store.state.root.clientExpandedInfo[props.item.id]
              "
              @closeExpand="() => {}"
              @modify="() => {}"
            />
          </template>
        </CmTable>
        <Pagenation :totalCount="90" @update="requestClientList($event)" />
      </div>
    </div>

    <AddDialog
      v-model="addDialogFlag"
      @close="addDialogFlag = false"
      :key="addDialogFlag"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown';
import InputSearch from '@/components/InputSearch';
import AddDialog from '@/components/root/dialog/AddDialog.vue';
import Pagenation from '@/components/Pagenation.vue';
import CmTable from '@/components/Table.vue';
import TABLE from '@/table.js';
import { mapState } from 'vuex';
import Vue from 'vue';
import ClientExpandedContent from '@/components/root/ClientExpandedContent.vue';
import collision from '@/store/mixins/collision.js';
export default {
  mixins: [collision],
  name: 'Evaluation',
  components: {
    Dropdown,
    InputSearch,
    ClientExpandedContent,
    AddDialog,
    CmTable,
    Pagenation,
  },
  data() {
    return {
      selectedTypeIndex: 0,
      selectedInspectionPendingFilter: '',
      selectedInspectionCompleteFilter: [],
      searchCriteriaDropdownItems: ['전체', '아이디', '고객사명'],
      search: '',
      tableRerender: false,
      addDialogFlag: false,
      toastDialogFlag: false,
      modifyDialogFlag: false,
      isOpenedFromChild: [],
      TABLE: TABLE,
    };
  },
  mounted() {
    this.otherComponent = document.querySelector('#pagenation-container');
    this.isCollide();
  },
  async created() {
    try {
      await this.$store.dispatch('getClientList', {
        start: 0,
        count: 10,
        name: '',
      });
    } catch (e) {
      Vue.$log.debug('dispatching error on evaluation' + e);
    }
  },
  computed: {
    ...mapState({
      clientList: state => state.root.clientList,
    }),

    selectedInspectionCompleteFilterItemsText() {
      if (this.selectedInspectionCompleteFilter.length === 4) {
        return '전체';
      } else if (this.selectedInspectionCompleteFilter.length === 3) {
        return (
          this.selectedInspectionCompleteFilter.join(', ').slice(0, 7) + '..'
        );
      }
      return this.selectedInspectionCompleteFilter.join(', ');
    },
  },
  methods: {
    async requestClientList(event) {
      this.$log.debug(event);
      try {
        this.tableRerender = !this.tableRerender;
        this.isOpenedFromChild = [];
        await this.$store.dispatch('getClientList', {
          start: event.start,
          count: event.count,
          name: '',
        });
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.nowPage = event.start / 10;
      }
    },
    onChangeFilter(index) {
      this.$log.debug('Evaluation#onChangeFilter', index);
    },
    onChangeSearch(text) {
      this.$log.debug('Evaluation#onChangeSearch', text);
      this.search = text;
    },
    showModifySnackbar() {
      this.modifyDialogFlag = true;
      setTimeout(() => {
        this.modifyDialogFlag = false;
      }, 5000);
    },
    async getDetailedClientInfo(payload) {
      this.$log.debug('ClientManagement#getDetailedClientInfo', payload);
      await this.$store
        .dispatch('getDetailedClientInfo', { id: payload[0] })
        .then(() => {
          if (this.$store.state.rootError) {
            // TODO 에러 반환 시 화살표 토글 다시 위로 올려야 함
          }
          this.$store.commit('changeEditMode', {
            id: payload[0],
            value: false,
          });
        });

      this.isOpenedFromChild = payload[1];
    },
  },
};
</script>

<style scoped>
.client-types-container {
  display: flex;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 23px;
  display: flex;
}
.client-types-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.client-types-text-selected {
  color: var(--primary-80);
}
.client-types-text-unselected {
  cursor: pointer;
  color: var(--surface-disabled);
}
.client-types-area-left {
  display: flex;
  flex-grow: 1;
}
.client-types-area-right {
  display: flex;
}
.client-dropdown {
  background-color: var(--surface-100);
  border-radius: 8px;
  width: 130px;
  margin-right: 7px !important;
}
.client-search-bar {
  background-color: var(--surface-100);
  border-radius: 8px;
}

.empty-selected-options-text {
  padding: 10px 16px;
  font-size: 12px;
  color: var(--surface-medium);
  cursor: pointer;
  opacity: 0.8;
}
.empty-selected-options-text:hover {
  opacity: 1;
}
.selected-filter-options-text {
  padding-top: 1px;
  color: var(--surface-medium);
}
.client-management-title {
  color: var(--primary-80);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.add-button {
  color: white;
  background-color: var(--primary-80);
  border-radius: 10px;
  margin-left: 10px;
  width: 62px;
  height: 32px;
  font-weight: normal;
  font-style: normal;
  align-self: end;
}

.low-color {
  width: 100%;
  padding: 0 10% 0 10%;
  background-clip: padding-box;
  height: 32px;
  line-height: 32px;
  background: var(--gray-100);
  border-radius: 8px;
  color: var(--surface-disabled);
}
.high-color {
  width: 100%;
  padding: 0 10% 0 10%;
  background-clip: padding-box;
  height: 32px;
  line-height: 32px;
  background: var(--primary-5);
  border-radius: 8px;
  color: var(--primary-80);
}
</style>
