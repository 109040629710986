<template>
  <div style="margin: 40px">
    <!-- 재사용 -->
    <div v-if="phase != 3" class="signup-row signup-between">
      <div class="signup-title">{{ titles[phase] }}</div>
      <div class="signup-row signup-between" style="width: 94px; height: 26px">
        <div
          :style="
            `width:26px;height:26px;text-align: center;${
              index == phase
                ? 'background:#EBECFF;border-radius:13px;color:var(--primary-100);'
                : 'background:var(--gray-100);border-radius:13px;color:var(--surface-disabled);'
            }`
          "
          v-for="(title, index) in titles"
          :key="`${index}-signup`"
        >
          {{ index + 1 }}
        </div>
      </div>
    </div>

    <div style="height: 40px" />
    <!-- 약관동의 -->
    <TncAgreement v-if="phase == 0" @next="next()" @cancel="cancel()" />
    <!-- 정보입력1 -->
    <CompNameInfo
      v-if="phase == 1"
      @companyId="setCompId($event)"
      @next="next()"
    />
    <!-- 정보입력2 -->
    <UserInfo
      v-if="phase == 2"
      :customer-id="companyId"
      :key="companyId"
      @next="register($event)"
      @prev="prev"
    />
    <!-- 완료 -->
    <RegisterComplete v-if="phase == 3" @next="complete()" />
  </div>
</template>
<script>
import TncAgreement from '@/components/signup/TncAgreement.vue';
import CompNameInfo from '@/components/signup/CompNameInfo.vue';
import UserInfo from '@/components/signup/UserInfo.vue';
import RegisterComplete from '@/components/signup/RegisterComplete.vue';
import CONSTANTS from '@/constants';
export default {
  name: 'SignUp',
  components: {
    TncAgreement,
    CompNameInfo,
    UserInfo,
    RegisterComplete,
  },
  data() {
    return {
      phase: 0,
      titles: ['약관 동의', '정보 입력', '정보 입력'],
      clientNameChecked: false,
      companyId: '',
    };
  },
  created() {},
  computed: {},
  methods: {
    next() {
      this.phase += 1;
    },
    prev() {
      this.phase -= 1;
    },
    cancel() {
      switch (this.$route.query.role) {
        case 'reviewer':
          this.$router.push({ name: CONSTANTS.ROUTE_NAME.SIGN_IN });
          break;
        case 'admin':
          this.$router.push({ name: CONSTANTS.ROUTE_NAME.ADMIN_SIGN_IN });
          break;
      }
    },
    setCompId(id) {
      this.$log.debug('RegisterAccount#setCompId', id);
      this.companyId = id;
    },
    register(userinfo) {
      this.$log.debug('RegisterAccount#complete~event ', userinfo);
      var body = { ...userinfo, customer_id: Number(this.companyId) };
      var request = null;
      switch (this.$route.query.role) {
        case 'reviewer':
          request = this.$store.dispatch('registerReviewer', body);
          console.log(request);
          break;
        case 'admin':
          request = this.$store.dispatch('registerAdmin', {
            data: body,
            id: Number(this.companyId),
          });
          console.log(request);
          break;
      }
      if (request) {
        request.then(res => {
          if (res.result_code == 'N100') {
            this.next();
          }
        });
      }
      //post api call
    },
    complete() {
      switch (this.$route.query.role) {
        case 'reviewer':
          this.$router.push({ name: CONSTANTS.ROUTE_NAME.SIGN_IN });
          break;
        case 'admin':
          this.$router.push({ name: CONSTANTS.ROUTE_NAME.ADMIN_SIGN_IN });
          break;
      }
      this.$route.params.role;
    },
  },
};
</script>
<style lang="scss">
.signup-between {
  justify-content: space-between;
}
.signup-row {
  display: flex;
  flex-direction: row;
}
.signup-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}
.signup-tnc-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
}
.signup-tnc-detail {
  width: 388px;
  height: 180px;
  margin-top: 16px;
  border: 1px solid var(--gray-200);
  overflow: scroll;
}
.signup-tnc-paragraph {
  padding: 8px 16px;
  font-size: 12px;
}
</style>
