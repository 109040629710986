<template>
  <div class="step-container">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="step-container inner"
    >
      <div
        v-if="index > 0"
        :class="{
          'line-prev': phase > index,
          'line-current': phase === index,
          'line-next': phase < index,
        }"
      ></div>
      <div
        :class="{
          prev: phase > index,
          current: phase === index,
          next: phase < index,
        }"
      >
        <div class="text-info">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phase: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  font-weight: 500;
  font-size: 14px;

  &.inner {
    margin-top: 0;
  }

  .prev {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--primary-100);
  }

  .current {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid var(--primary-100);
    color: var(--primary-100);
  }

  .next {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: 2px solid var(--gray-300);
  }

  .line-prev {
    width: 20vw;
    border-top: 1px solid var(--primary-100);
  }

  .line-current {
    width: 20vw;
    border-top: 1px dashed var(--surface-high);
  }

  .line-next {
    width: 20vw;
    border-top: 1px dashed var(--gray-300);
  }

  .text-info {
    position: absolute;
    width: 100px;
    top: 22px;
    left: -45px;
    text-align: center;
  }
}
</style>
