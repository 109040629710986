import Vue from 'vue';
import server from '@/api/server';
import moment from 'moment';
import { subModules } from './submodules/reviewer';

const module = {
  state: function() {
    return {
      //for saving all data
      auditList: [],
      auditListDetail: {},
      auditResultList: [],
      auditResultListDetail: {},

      auditListTotalCount: 0,
      auditResultListTotalCount: 0,
      modifiedDataAudit: {},
      modifiedData: {},

      customerModuleInfo: {
        id_card_ocr: true,
        id_card_verification: true,
        face_authentication: true,
        account_verification: true,
        liveness: true,
      },
    };
  },
  methods: {},
  mutations: {
    setAuditDetailed(state, payload) {
      try {
        var updatedDetailInfo = JSON.parse(
          JSON.stringify(state.auditListDetail)
        );
        updatedDetailInfo[payload.id] = subModules.caseIntegrated(payload);
        var updatedModifiedData = JSON.parse(
          JSON.stringify(state.modifiedDataAudit)
        );
        updatedModifiedData[payload.id] = subModules.caseModifiedIdentifier(
          updatedDetailInfo[payload.id]
        );

        state.auditListDetail = updatedDetailInfo;
        state.modifiedDataAudit = updatedModifiedData;
        state.customerModuleInfo =
          updatedDetailInfo[payload.id].showable_info_type;
      } catch (e) {
        if (e.toString().includes('JSON')) {
          throw new server.AlcheraError(
            'OCR JSON Parsing Error',
            'OCR JSON HAS SOME DEFECTS'
          );
        }
      }
    },
    setAuditResultDetailed(state, payload) {
      try {
        var updatedDetailInfo = JSON.parse(
          JSON.stringify(state.auditResultListDetail)
        );
        updatedDetailInfo[payload.id] = subModules.caseIntegrated(payload);
        var updatedModifiedData = JSON.parse(
          JSON.stringify(state.modifiedData)
        );
        updatedModifiedData[payload.id] = subModules.caseModifiedIdentifier(
          updatedDetailInfo[payload.id]
        );

        state.auditResultListDetail = updatedDetailInfo;
        state.modifiedData = updatedModifiedData;
        state.customerModuleInfo =
          updatedDetailInfo[payload.id].showable_info_type;
      } catch (e) {
        if (e.toString().includes('JSON')) {
          throw new server.AlcheraError(
            'OCR JSON Parsing Error',
            'OCR JSON HAS SOME DEFECTS'
          );
        }
      }
    },
    setAuditList(state, payload) {
      const threshold = payload.rootData;
      state.auditListTotalCount = payload.data.total_count;
      payload = payload.data.list;
      if (payload?.length) {
        state.auditList = payload.map(singleAudit => {
          return {
            id: `${singleAudit.id}`,
            timestamp: moment(singleAudit.request_time).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            name: singleAudit.name,
            phone_number: singleAudit.phone_number,
            birthdate: singleAudit.birthday,
            status: singleAudit.similarity > threshold * 0.2 ? 'High' : 'Low',
            modified: false,
          };
        });
      } else {
        state.auditList = [];
      }
    },

    setAuditResultList(state, payload) {
      const threshold = payload.rootData;
      state.auditResultListTotalCount = payload.data.total_count;
      payload = payload.data.list;

      if (payload?.length) {
        var status = function(resultType) {
          switch (resultType) {
            case 1:
              return '자동 승인';
            case 2:
              return '자동 거부';
            case 3:
              return '수동 승인';
            case 4:
              return '수동 거부';
            default:
              return '미등록';
          }
        };
        state.auditResultList = payload.map(singleAudit => {
          return {
            id: `${singleAudit.id}`,
            timestamp: moment(singleAudit.request_time).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            name: singleAudit.name,
            phone_number: singleAudit.phone_number,
            birthdate: singleAudit.birthday,
            status: singleAudit.similarity > threshold * 0.2 ? 'High' : 'Low',
            inspect_status: status(singleAudit.result_type),
            modified: false,
          };
        });
      } else {
        state.auditResultList = [];
      }
    },
  },
  actions: {
    async getAuditList({ commit, rootState }, payload) {
      try {
        const data = await server.readAdminEvaluableLists(payload);
        // if (payload.start == 0) {
        commit('setAuditList', {
          data: data,
          rootData: rootState.settings.threshold_level,
        });

        return data;
        // }
        // else {
        //   commit("setMoreAuditList", {
        //     data: data,
        //     rootData: rootState.settings.threshold_level,
        //   });
        // }
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getAuditResultList({ commit, rootState }, payload) {
      try {
        const data = await server.readAdminResultLists(payload);
        Vue.$log.debug('reviewer#getAuditResultList', data);
        commit('setAuditResultList', {
          data: data,
          rootData: rootState.settings.threshold_level,
        });
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },

    async getAuditDetailed({ commit, rootState }, payload) {
      try {
        const data = await server.readDetailedAdminEvaluableList(payload.id);
        commit('setAuditDetailed', data);
        return data.id;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : `${e}`;
        rootState.serverError = true;
      }
    },
    async getAuditResultDetailed({ commit, rootState }, payload) {
      try {
        const data = await server.readDetailedAdminResultList(payload.id);
        commit('setAuditResultDetailed', data);
        return data.id;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },

    async setApproveState({ dispatch, rootState }, payload) {
      try {
        const data = await server.setAuditResult(payload.id, {
          approve: payload.isApproved,
          rejected_reason: payload.rejectedReason,
        });
        dispatch('getAuditList', { start: 0, count: 20 });
        dispatch('getAuditResultList', { result_type: 0, start: 0, count: 20 });
        Vue.$log.debug(
          `setApproveState function on reviewer.js file : ${data}`
        );
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
  },
  getters: {},
};

export default module;
