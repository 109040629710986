<template>
  <v-dialog
    v-model="value"
    max-width="500px"
    @click:outside="() => {}"
    @keydown.esc="close"
    persistent
  >
    <v-card style="border-radius: 8px">
      <v-card-text style="padding: 0; margin: 0">
        <div class="confirm-title">계정 삭제 안내</div>
        <div class="content-paragraph">
          <div>
            <div class="content-user-info">
              <div class="company-name">
                {{ company || '' }}
              </div>
              <div class="divider" />
              <div class="name">
                {{ adminData.admin_name || '' }}
              </div>
              <div class="id">
                {{ adminData.admin_id || '' }}
              </div>
            </div>
          </div>

          <div
            style="
              width: 336px;
              margin-bottom: 16px;
              border-bottom: 1px solid var(--gray-300);
            "
          ></div>
          <div class="content-paragraph-text">
            거절 처리된 계정은 삭제됩니다.
          </div>
          <div class="content-paragraph-text">
            계속 진행하시겠습니까?
          </div>
        </div>
        <div class="button-container">
          <div v-ripple @click="close" class="button cancel">취소</div>
          <div v-ripple @click="confirm" class="button ok">
            삭제
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    adminData: {
      type: Object,
      default: () => {},
    },
    company: {
      type: String,
    },
  },
  mounted() {
    console.log(this.adminData);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$log.debug('this.confirmDialogInfo, event');
      this.$emit('confirmation');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-title {
  text-align: center;
  padding: 40px 0px 32px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--surface-high);
}
.content-paragraph {
  width: 336px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 82px 0 82px;

  .divider {
    margin: 0 16px;
    height: 16px;
    width: 1px;
    background-color: var(--surface-medium);
  }

  .company-name,
  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: black;
  }
  .id {
    margin-left: 4px;
    color: var(--surface-medium);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
.content-paragraph > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 24px;
}

.content-paragraph-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  color: var(--surface-high);
}
.content-user-info {
  display: flex;
  align-items: center;
}
.content-change-info {
  display: flex;
  margin-left: 20px;
}
.content-change-info > div {
  color: var(--surface-high);

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  .active {
    width: 30px;
  }
  .inactive {
    width: 45px;
  }
}
.button-container {
  display: flex;
  width: 100%;
  padding: 40px 40px;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    height: 60px;
    cursor: pointer;

    &.cancel {
      width: 162px;
      color: var(--surface-medium);
      margin-right: 10px;
      background: var(--gray-100);
    }

    &.ok {
      width: 248px;
      color: var(--surface-100);
      background: var(--error-100);
    }
  }
}
</style>
