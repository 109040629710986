<template>
  <v-app-bar
    app
    height="70"
    color="var(--surface-100)"
    style="box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1), 0px 2px 8px 4px rgba(0, 0, 0, 0.05) !important;"
  >
    <img src="@/assets/logo-useb.svg" />

    <v-tabs class="nav-tabs" v-model="tabsIndex">
      <v-tabs-slider color="var(--primary-100)"></v-tabs-slider>
      <v-tab
        v-for="(tab, tIndex) in tabs"
        :key="tIndex"
        @click="navToRoute(tab.route)"
        style="letter-spacing: 0;"
        >{{ tab.name }}</v-tab
      >
    </v-tabs>
    <ProfileDialog
      :img="require('@/assets/account-reviewer.svg')"
      :buttonContents="buttonList"
      :userInfo="defaultUserInfo"
      :role="defaultUserInfo.role"
      :company="defaultUserInfo.company"
      @get="getUserInfo"
      @modify="modifyInfo"
      @logout="logout"
    />
    <ModifyUserInfo
      v-if="defaultUserInfo.role != '루트'"
      :defaultUserInfo="defaultUserInfo"
      v-model="modifyDiagFlag"
      @close="close"
      :key="`${initializeForm}-form`"
    />
  </v-app-bar>
</template>

<script>
import CONSTANTS from '@/constants';
import server from '@/api/server';
import ProfileDialog from '@/components/ProfileDialog.vue';
import ModifyUserInfo from '@/components/admin/dialog/ModifyUserInfo.vue';

export default {
  name: 'GlobalNavigationBar',
  components: {
    ProfileDialog,
    ModifyUserInfo,
  },
  props: {},

  async created() {
    this.$log.debug('is Created');
    this.getUserInfo();
  },
  data() {
    return {
      tabs: [
        {
          name: '...',
        },
      ],
      initializeForm: 0,
      tabsIndex: 0,
      modifyDiagFlag: false,
      buttonList: [],
      defaultUserInfo: {
        active: undefined,
        email: '...',
        id: '...',
        name: '...',
        phone_number: '...',
        username: '...',
        role: '...',
      },
    };
  },
  watch: {
    $route(to, from) {
      this.$log.debug('GlobalNavigationBar#watch$route', from, to);
      this.handlePageReload();
    },
  },
  computed: {},
  mounted() {
    this.handlePageReload();
  },
  methods: {
    handlePageReload() {
      const tabsRouteNameList = this.tabs.map(t => t.route);
      this.tabsIndex = tabsRouteNameList.indexOf(this.$route.name);
    },
    navToRoute(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
    logout() {
      this.$log.debug('logout');
      let routeInfo = server.getToken().role + '';
      if (!routeInfo) {
        routeInfo = '3';
      }
      server.logout();
      switch (routeInfo) {
        case '1':
          this.$router.push({ name: CONSTANTS.ROUTE_NAME.SIGN_IN });
          return;
        case '0':
          this.$router.push({ name: CONSTANTS.ROUTE_NAME.ADMIN_SIGN_IN });
          return;
        default:
          this.$router.push({ name: CONSTANTS.ROUTE_NAME.ROOT_SIGN_IN });
          return;
      }
    },
    async modifyInfo() {
      this.getUserInfo();
      this.modifyDiagFlag = true;
    },
    async getUserInfo() {
      ++this.initializeForm;
      let userInfoResult = {};
      let defaultInfo = null;
      this.buttonList = [
        { message: '정보 수정', img: require('@/assets/Edit_24.svg') },
        { message: '로그아웃', img: require('@/assets/Logout_24.svg') },
      ];
      const tokenInfo = server.getToken();
      if (tokenInfo?.role == 0 || tokenInfo?.role == 1) {
        if (tokenInfo?.role == 0) {
          this.tabs = [
            {
              name: '심사자 관리',
              route: CONSTANTS.ROUTE_NAME.REVIEWER_MANAGEMENT,
            },
            {
              name: '약관 가이드',
              route: CONSTANTS.ROUTE_NAME.TERMS_GUIDANCE,
            },
            {
              name: '정보입력 관리',
              route: CONSTANTS.ROUTE_NAME.REQUIRED_MANAGEMENT,
            },
            {
              name: '심사 설정',
              route: CONSTANTS.ROUTE_NAME.SETTINGS,
            },
          ];
          userInfoResult['role'] = '관리자';
        } else if (tokenInfo?.role == 1) {
          this.tabs = [
            {
              name: '심사 수행',
              route: CONSTANTS.ROUTE_NAME.EVALUATION_REVIEW,
            },
            {
              name: '심사 결과',
              route: CONSTANTS.ROUTE_NAME.EVALUATION_RESULT,
            },
          ];
          userInfoResult['role'] = '심사자';
        }
        defaultInfo = await server.readMypageInfo();
        userInfoResult = JSON.parse(JSON.stringify(defaultInfo.account));
        userInfoResult['company'] = tokenInfo.company.name;
        userInfoResult['customer_id'] = tokenInfo.customer_id;
      } else {
        this.tabs = [
          {
            name: '고객사 관리',
            route: CONSTANTS.ROUTE_NAME.CLIENT_MANAGEMENT,
          },
          {
            name: '어드민 관리',
            route: CONSTANTS.ROUTE_NAME.ADMIN_MANAGEMENT,
          },
          {
            name: '약관 관리',
            route: CONSTANTS.ROUTE_NAME.TERMS_MANAGEMENT,
          },
          {
            name: '약관 가이드',
            route: CONSTANTS.ROUTE_NAME.TERMS_GUIDANCE_MANAGEMENT,
          },
        ];
        this.buttonList.shift();
        userInfoResult['role'] = '루트';
        userInfoResult['company'] = 'UseB';
      }

      this.defaultUserInfo = userInfoResult;
    },
    close() {
      this.modifyDiagFlag = false;
    },
  },
};
</script>

<style>
.nav-tabs {
  margin-left: 104px;
}
.v-tabs-slider-wrapper {
  padding: 0 16px !important;
}
</style>
