export let TOKEN = { token: '', expire: '' };
export class AlcheraError extends Error {
  constructor(errorCode, message, response) {
    super(message);
    this.errorCode = errorCode;
    this.response = response;
  }
}
export function setToken(token) {
  TOKEN = token;
}
function _isTokenExpire() {
  if (!TOKEN || !TOKEN.expire) {
    return true;
  }
  const expire = new Date(TOKEN.expire).getTime();

  const bumperTime = 1000 * 60 * 60; // 1 hour

  return expire - bumperTime < Date.now();
}

export async function getBearerToken(config) {
  if (_isTokenExpire()) {
    await getToken();
  }
  config.headers.Authorization = `Bearer ${TOKEN.token}`;
  return config;
}

export function isAuthorized() {
  return !!TOKEN;
}

export function getUserInfo() {
  if (TOKEN) {
    const info = JSON.parse(atob(TOKEN.token.split('.')[1]));
    return info.payload;
  }
}

export function getCompanyPhoneNumber() {
  const phoneNumber = TOKEN.company?.phone_number || '';
  return phoneNumber;
}

export function getToken() {
  try {
    if (_isTokenExpire()) {
      logout();
    } else {
      return TOKEN;
    }
  } catch (e) {
    throw makeAlcheraError(e);
  }
}
export async function logout() {
  destroyPermanentTokens();
  TOKEN = { token: '', expire: '' };
}
export async function destroyPermanentTokens() {
  localStorage.removeItem('REVIEWER_TOKEN');
  localStorage.removeItem('ROOT_TOKEN');
  localStorage.removeItem('ADMIN_TOKEN');
}

export function makeAlcheraError(e) {
  if (e.errorCode) {
    return e;
  }
  if (e.response) {
    const data = e.response.data;
    if (data && typeof data !== 'string') {
      let code, message;
      if (data.api_response) {
        code = data.api_response.result_code;
        message = data.api_response.result_message;
      } else {
        code = data.result_code;
        message = data.result_message;
      }
      return new AlcheraError(code, `${message} (${code})`, data);
    } else if (data && typeof data == 'string') {
      return new AlcheraError(e.response.status, `${data}`);
    }
    return new AlcheraError(
      e.response.status,
      `${e.response.statusText} (${e.response.status})`
    );
  }
  return e;
}
