var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":""},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"padding":"0","margin":"0","overflow-y":"scroll"}},[_c('header',{staticClass:"diag-title"},[_vm._v("정보 수정")]),_c('div',{staticClass:"add-body"},[_c('div',[_c('div',{staticClass:"title-input"},[_vm._v("고객사명")]),_c('div',{staticStyle:{"display":"block"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.defaultUserInfo.company),expression:"defaultUserInfo.company"}],class:{
                'reviewer-input-form--default': true,
              },attrs:{"readonly":""},domProps:{"value":(_vm.defaultUserInfo.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.defaultUserInfo, "company", $event.target.value)}}})]),_c('div',{staticClass:"title-input"},[_vm._v("아이디")]),_c('div',{staticStyle:{"display":"block"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.defaultUserInfo.username),expression:"defaultUserInfo.username"}],class:{
                'reviewer-input-form--default': true,
              },attrs:{"readonly":""},domProps:{"value":(_vm.defaultUserInfo.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.defaultUserInfo, "username", $event.target.value)}}})]),_c('CustomInputTextForm',{attrs:{"necessary":true,"validity":"name","init-val":_vm.reviewerName,"title":"이름","placeholder":"이름을 입력하세요"},on:{"set":function ($event) {
                _vm.reviewerName = $event.val;
                _vm.reviewerNameCheck = $event.res;
              }}}),_c('CustomInputTextForm',{attrs:{"validity":"phone","necessary":true,"title":"전화번호","init-val":_vm.reviewerPhone,"placeholder":"‘-’를 포함하여 입력하세요."},on:{"set":function ($event) {
                _vm.reviewerPhone = $event.val;
                _vm.reviewerPhoneCheck = $event.res;
              }}}),_c('CustomInputTextForm',{attrs:{"necessary":true,"validity":"email","title":"이메일","needCheck":true,"placeholder":"직접입력","init-val":_vm.reviewerEmail},on:{"click":function($event){_vm.reviewerEmailCheck ? _vm.email(_vm.reviewerEmail) : null},"set":function ($event) {
                _vm.reviewerEmail = $event.val;
                _vm.reviewerEmailCheck = $event.res;
                _vm.reviewerEmailDuplicationCheck = '';

                if (_vm.reviewerEmailCheck) {
                  _vm.reviewerEmailDuplicationCheck = '중복여부를 확인하세요.';
                }
              }}}),(_vm.reviewerEmailDuplicationCheck)?_c('div',{key:(_vm.reviewerEmailDuplicationCheck + "-check"),style:(("font-size: 12px;\n              " + (_vm.reviewerEmailDuplicationCheck.includes('완료')
                  ? 'color:var(--primary-100);'
                  : 'color:var(--error-100);')))},[_vm._v(" "+_vm._s(_vm.reviewerEmailDuplicationCheck)+" ")]):_vm._e(),_c('CustomInputTextForm',{attrs:{"necessary":true,"title":"기존 비밀번호","validity":"password","placeholder":"비밀번호를 입력하세요.","is-password":true,"init-val":_vm.reviewerPass,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
                _vm.reviewerPass = $event.val;
                _vm.reviewerPassChecked = $event.res;
              }}}),_c('CustomInputTextForm',{attrs:{"title":"새 비밀번호","validity":"individual-password","placeholder":"새 비밀번호를 입력하세요.","is-password":true,"init-val":_vm.newPass,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
                _vm.newPass = $event.val;
                _vm.newPassChecked = $event.res;
              }}}),_c('CustomInputTextForm',{attrs:{"title":"새 비밀번호 확인","validity":"password","placeholder":"새 비밀번호를 재입력하세요.","is-password":true,"init-val":_vm.newPassCheck,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
                _vm.newPassCheck = $event.val;
                _vm.errorCheck();
              }}}),(_vm.newPassCheckChecked)?_c('div',{staticStyle:{"color":"var(--error-100)","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.newPassCheckChecked)+" ")]):_vm._e()],1),_c('div',{staticClass:"button-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button cancel",on:{"click":_vm.close}},[_vm._v("취소")]),(
              _vm.reviewerName != '' &&
                _vm.reviewerEmail != '' &&
                _vm.reviewerPhone != '' &&
                _vm.reviewerPass != ''
            )?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button ok",on:{"click":_vm.register}},[_vm._v(" 등록 ")]):_c('div',{staticClass:"button default",staticStyle:{"cursor":"unset"}},[_vm._v("등록")])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }