<template>
  <div>
    <div style="margin: 80px 106px 40px 80px">
      <img src="@/assets/process_success.svg" alt="process" />
    </div>
    <div class="complete--title flex-center">신청이 완료되었습니다.</div>
    <div class="complete--message flex-center">
      입력하신 이메일을 통해 승인 여부를 안내드리겠습니다.
    </div>
    <div style="height: 72px" />
    <div>
      <SubmitButton
        width="100%"
        height="60px"
        font-size="20px"
        button-string="확인"
        bg-dim-color="var(--primary-20)"
        bg-color="var(--primary-100)"
        text-dim-color="var(--surface-100)"
        text-color="var(--surface-100)"
        @click="$emit('next')"
      />
    </div>
  </div>
</template>
<script>
import SubmitButton from '@/components/SubmitButton.vue';
export default {
  name: 'SignUp',
  components: {
    SubmitButton,
  },
  data() {
    return {
      clientNameChecked: false,
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.complete {
  &--title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }
  &--message {
    margin-top: 8px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
