<template>
  <section
    id="pagenation-container"
    style="display: flex; justify-content: space-between; margin-top: 16px"
  >
    <div style="width: 65px"></div>
    <div style="display: flex; justify-content: center width:384px;">
      <div v-if="pageCriteria != 1" @click="prevPage">
        <img src="@/assets/prevpage.svg" alt="prev 10 pages" />
      </div>
      <div
        style="width: 32px; height: 28px;font-size:14px"
        v-for="i in pageCount"
        :key="i"
      >
        <div
          :style="
            `display: flex; justify-content: center;cursor:pointer;${
              pageCriteria + i - 1 == nowPage
                ? 'color:var(--surface-high)'
                : 'color:var(--surface-medium)'
            }`
          "
          @click="
            pageCriteria + i - 1 != nowPage ? getNowPageList(i) : () => {}
          "
          :key="nowPage"
        >
          {{ pageCriteria + i - 1 }}
        </div>
      </div>
      <div v-if="pageCount == 10" @click="nextPage">
        <img src="@/assets/nextpage.svg" alt="next 10 pages" />
      </div>
      <!-- next->nowPage = pageCriteria+10 && pageCritreia+=10 -->
    </div>
    <div class="page-text">{{ pageText }}</div>
  </section>
</template>

<script>
export default {
  name: 'Pagenation',
  data() {
    return {
      nowPage: 1,
      pageCriteria: 1,
    };
  },
  props: {
    totalCount: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  computed: {
    pageCount() {
      if (!this.totalCount || Number.isNaN(parseInt(this.totalCount))) {
        return 0;
      }
      const result = Math.ceil(this.totalCount / 10);
      if (result < 1) return 1;
      else if (this.pageCriteria + 9 > result) {
        return result + 1 - this.pageCriteria;
      } else {
        return 10;
      }
    },
    pageText() {
      return this.totalCount == 0
        ? `0 of 0`
        : `${this.nowPage * 10 - 10} - ${
            this.nowPage * 10 < this.totalCount
              ? this.nowPage * 10
              : this.totalCount
          } of ${this.totalCount}`;
    },
  },
  methods: {
    async prevPage() {
      this.pageCriteria -= 10;
      await this.getNowPageList(1);
      this.nowPage = this.pageCriteria;
    },
    async nextPage() {
      this.pageCriteria += 10;
      await this.getNowPageList(1);
      this.nowPage = this.pageCriteria;
    },
    async getNowPageList(index) {
      this.nowPage = this.pageCriteria + index - 1;
      this.$emit('update', { start: this.nowPage * 10 - 10, count: 10 });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-text {
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: var(--surface-medium);
}
</style>
