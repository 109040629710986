<template>
  <div class="root-container">
    <Step :phase="phase" :items="phaseItems"></Step>
    <div class="userinput-title">개인정보 입력</div>
    <div class="userinput-label">이름</div>
    <InputTextField
      v-model="userinfo.userName"
      :rules="[rules.required, errorRuleName, rules.koreanAndUpperEng]"
      :transform="rules.transformOnlyUpperCase"
      :append="clearIconUserName"
      @append="userinfo.userName = ''"
      @onupdate="validateForm"
      @focus="showUsernameClearIcon = true"
      @blur="showUsernameClearIcon = false"
      :maxlength="45"
      placeholder="이름을 입력하세요."
      :error="errorInvalidName"
      :errorMessage="
        errorInvalidName ? errorInvalidMessage : errorDefaultMessageName
      "
      class="userinput-input"
    />
    <div class="userinput-label">휴대전화 번호</div>
    <InputTextField
      v-model="userinfo.phoneNumber"
      :rules="[rules.required, rules.phoneNumber]"
      @onupdate="validateForm"
      :transform="rules.transformOnlyNumber"
      :append="clearIconPhoneNumber"
      @append="userinfo.phoneNumber = ''"
      @focus="showPhoneNumberClearIcon = true"
      @blur="showPhoneNumberClearIcon = false"
      :maxlength="45"
      placeholder="‘-’를 제외하고 입력하세요."
      errorMessage="입력 형식이 올바르지 않습니다. 예) 01012345678"
      class="userinput-input"
    />
    <div class="userinput-label">생년월일</div>
    <InputTextField
      placeholder="YYYY-MM-DD"
      v-model="userinfo.birthDate"
      :error="errorInvalidBirthDate"
      :errorMessage="
        errorInvalidBirthDate
          ? errorInvalidMessage
          : errorDefaultMessageBirthDate
      "
      :rules="[rules.required, errorRuleBirthDate, rules.date]"
      :transform="rules.transformDateFormat"
      :onblurTransform="rules.onblurTransformDateFormat"
      @onupdate="validateForm"
      class="userinput-input"
    />
    <div class="userinput-label">이메일</div>
    <InputTextField
      v-model="userinfo.email"
      :rules="[rules.required, rules.email]"
      @onupdate="validateForm"
      :maxlength="45"
      placeholder="example@email.com"
      errorMessage="입력 형식이 올바르지 않습니다. 확인 후 수정해 주세요."
      class="userinput-input"
    />
    <div data-description="fixed-button-area" style="min-height: 56px"></div>
    <div class="button-container">
      <div v-ripple @click="onClickBack" class="button cancel">취소</div>
      <div
        v-ripple
        @click="onClickNext"
        :class="{ button: true, next: true, disabled }"
      >
        다음
      </div>
    </div>
    <ServerErrorDialog
      v-model="serverError"
      :icon="errorIcon"
      :title="errorMessageTitle"
      :message="errorMessage"
      :errorCode="errorCode"
      :button="errorButton"
      @cancel="onClickBack"
      @ok="onClickCloseServerError"
    />
  </div>
</template>

<script>
import Step from './Step';
import InputTextField from './InputTextField';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import server from '@/api/server';
import rules from '../rules';
import { mapState } from 'vuex';

export default {
  components: {
    Step,
    InputTextField,
    ServerErrorDialog,
  },
  props: {
    phase: Number,
    phaseItems: Array,
    appData: Object,
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function() {
    return {
      rules,
      userinfo: {
        userName: '',
        phoneNumber: '',
        birthDate: '',
        email: '',
      },
      systemError: false,
      serverError: false,
      errorIcon: '',
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorButton: [],
      disabled: true,
      loading: false,
      iconChecked: require('@/assets/icon-check-green.svg'),
      iconClear: require('@/assets/delete_24.svg'),
      errorInvalidName: false,
      errorInvalidBirthDate: false,
      errorInvalidMessage: '정보가 일치하지 않습니다. 확인 후 수정해 주세요.',
      errorDefaultMessageName: '국문 또는 영문만 입력 가능합니다.',
      errorDefaultMessageBirthDate: '생년월일를 입력해주세요.',
      showUsernameClearIcon: false,
      showPhoneNumberClearIcon: false,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
    clearIconUserName() {
      return this.showUsernameClearIcon && this.userinfo.userName
        ? this.iconClear
        : '';
    },
    clearIconPhoneNumber() {
      return this.showPhoneNumberClearIcon && this.userinfo.phoneNumber
        ? this.iconClear
        : '';
    },
  },
  created() {
    this.$log.debug('UserInfoInput#created appData', this.appData);
    if (this.appData.userName) {
      this.userinfo = {
        userName: this.appData.userName,
        phoneNumber: this.appData.phoneNumber,
        birthDate: this.appData.birthDate,
        email: this.appData.email,
      };
      // if (this.appData.userName !== this.appData.ocr.userName) {
      //   this.errorInvalidName = true;
      // }
      // if (this.appData.scannedFormattedDate) {
      //   this.errorInvalidBirthDate = true;
      // }
    }
  },
  mounted() {
    // this.userinfo = {
    //   userName: '양동현',
    //   phoneNumber: '01071796841',
    //   birthDate: '1984-11-23',
    //   email: 'ee@ee.com',
    // };
    // this.disabled = false;

    // this.$set(this.appData, 'cardScanRetryCount', 0);
    if (this.appData.userName) {
      this.$log.debug('UserInfoInput mounted #', this.appData);
    }
  },
  methods: {
    errorRuleName() {
      return !this.errorInvalidName;
    },
    errorRuleBirthDate() {
      return !this.errorInvalidBirthDate;
    },
    validateForm() {
      this.errorInvalidName = false;
      this.errorInvalidBirthDate = false;
      this.$nextTick(() => {
        if (
          this.userinfo.userName &&
          this.userinfo.phoneNumber &&
          this.userinfo.birthDate &&
          this.userinfo.email
        ) {
          const result = this.$children
            .map(comp => (comp.valid ? comp.validFlag : true))
            .every(val => val);
          this.disabled = !result;
        } else {
          this.disabled = true;
        }
      });
    },
    onClickCloseServerError() {
      if (this.systemError) {
        this.onClickBack();
      } else {
        this.serverError = false;
      }
    },
    onClickBack() {
      this.$emit('cancel');
    },
    async onClickNext() {
      try {
        if (this.disabled || this.loading) return;
        this.loading = true;
        let formData = new FormData();
        formData.append('name', this.userinfo.userName);
        formData.append('phone_number', this.userinfo.phoneNumber);
        formData.append('birthday', this.userinfo.birthDate);
        formData.append('email', this.userinfo.email);
        formData.append('reuse', '0'); // reuse parameter 토큰 재사용 (이어하기 기능 연관) 현재는 프론트에서 구현되어 있지 않음으로 0 하드코딩
        const data = await server.startApplication(formData);
        this.$emit('next', {
          userName: this.userinfo.userName,
          phoneNumber: this.userinfo.phoneNumber,
          birthDate: this.userinfo.birthDate,
          email: this.userinfo.email,
          transaction_id: data.form.transaction_id,
          progress: data.form.progress,
          module: data.form.module,
        });
        this.loading = false;
      } catch (e) {
        this.$log.debug(e);
        this.loading = false;
        this.systemError = true;
        this.serverError = true;
        this.errorIcon = '';
        this.errorMessageTitle = [
          '시스템 에러가 발생하였습니다.',
          '잠시 후 다시 이용해 주세요.',
        ];
        this.errorMessage = [
          '계속해서 문제가 발생한다면',
          `고객센터(${this.companyPhoneNumber})으로 문의해주세요.`,
        ];
        this.errorButton = ['', '확인'];
        this.errorCode = e.errorCode ? `에러코드 : ${e.errorCode}` : '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--surface-high);
}

.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}

.userinput-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: calc(128px - 55px);
  margin-bottom: 8px;
  text-align: center;
}

.userinput-label {
  font-size: 16px;
  color: var(--surface-medium);
  margin-bottom: 8px;
  margin-top: 24px;
  padding: 0 30px;
}

.userinput-input {
  margin: 0 30px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding-bottom: 40px;

  cursor: pointer;
  background-color: white;
  // border-top: 1px solid var(--gray-100);

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
  }

  .cancel {
    flex-grow: 3.5;
    margin-left: 30px;
    margin-right: 10px;
    background-color: var(--gray-100);
    color: var(--surface-medium);
  }

  .next {
    flex-grow: 6.5;
    margin-right: 30px;
    background-color: var(--primary-100);
    color: var(--surface-100);
  }

  .disabled {
    background-color: var(--primary-20);
  }
}
</style>
