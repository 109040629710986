var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signin-container signin-root-background full-size flex-center",staticStyle:{"min-height":"800px"}},[_c('div',{staticClass:"background-signin-image"}),_c('div',{staticClass:"signin-content flex-center"},[_c('div',{staticClass:"signin-card-container"},[_vm._m(0),_c('form',{class:{
          'signin-form default flex-center': !_vm.error,
          'signin-form error flex-center': _vm.error,
        },attrs:{"id":"signin-form"}},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"24px","color":"var(--primary-80)","margin":"40px 0 38px 0"}},[_vm._v(" Alchera Login ")]),_c('div',{staticStyle:{"width":"318px","padding-bottom":"16px"}},[_c('CustomInputTextForm',{attrs:{"validity":"individual-id","placeholder":"아이디","init-val":_vm.usernameInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
                _vm.loginFactorForServerError = false;
                _vm.usernameInput = $event.val;
              }}})],1),_c('div',{staticStyle:{"width":"318px","padding-bottom":"16px"}},[_c('CustomInputTextForm',{attrs:{"validity":"password","placeholder":"비밀번호","is-password":true,"init-val":_vm.passwordInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
                _vm.loginFactorForServerError = false;
                _vm.passwordInput = $event.val;
              }}})],1),(_vm.error)?_c('div',{staticClass:"signin-error",staticStyle:{}},[_vm._v(" 아이디 혹은 비밀번호를 잘못 입력하셨습니다. ")]):_vm._e(),_c('div',{staticStyle:{"display":"flex","width":"318px","flex-direction":"row","justify-content":"space-between"}},[_c('div',{staticClass:"flex-center",staticStyle:{"display":"flex","flex-direction":"row"},on:{"click":function($event){_vm.permanent = !_vm.permanent}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.permanent),expression:"permanent"}],attrs:{"src":require("@/assets/checkbox_checked_24.svg"),"alt":"checked"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.permanent),expression:"!permanent"}],attrs:{"src":require("@/assets/checkbox_24.svg"),"alt":"unchecked"}}),_c('div',{staticStyle:{"margin-left":"4px","font-size":"14px","line-height":"24px"}},[_vm._v(" 로그인 상태 유지 ")])])]),(_vm.isLoginAvailable)?_c('div',{staticClass:"signin-form-btn ok",on:{"click":function($event){return _vm.login()}}},[_vm._v(" 로그인 ")]):_c('div',{staticClass:"signin-form-btn default"},[_vm._v("로그인")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signin-title"},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"ekyc main logo"}})])}]

export { render, staticRenderFns }