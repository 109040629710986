import moment from 'moment';
import Vue from 'vue';
export const subModules = {
  imageConverter: function(str) {
    return 'data:image/jpeg;base64,' + str;
  },

  momentValidCheck: function(str) {
    return moment(str).isValid()
      ? moment(str).format('YYYY.MM.DD')
      : `Invalid date(${str})`;
  },

  decideTypeOfIdCard: function(idType) {
    switch (String(idType)) {
      case '1':
        return ['주민등록증', '주민등록번호'];
      case '2':
        return ['운전면허증', '주민등록번호'];
      case '3':
        return ['한국 여권', '주민등록번호'];
      case '4':
        return ['외국 여권', '주민등록번호'];
      case '5':
      case '5-1':
        return ['외국인등록증', '외국인등록번호'];
      case '5-2':
        return ['거소신고증', '거소신고번호'];
      case '5-3':
        return ['영주증', '외국인등록번호'];
      default:
        return ['미등록', '미등록'];
    }
  },

  caseModifiedIdentifier: function(payload) {
    var modifiedFlag = {};
    if (payload.modified_user_name != payload.user_name)
      modifiedFlag['user_name'] = true;
    else modifiedFlag['user_name'] = false;

    if (payload.modified_id_number != payload.id_number)
      modifiedFlag['id_number'] = true;
    else modifiedFlag['id_number'] = false;
    if (payload.modified_created_at != payload.created_at)
      modifiedFlag['created_at'] = true;
    else modifiedFlag['created_at'] = false;
    if (payload.modified_expiration_date != payload.expiration_date)
      modifiedFlag['expiration_date'] = true;
    else modifiedFlag['expiration_date'] = false;
    if (payload.modified_id_type != payload.id_type)
      modifiedFlag['id_type'] = true;
    else modifiedFlag['id_type'] = false;
    if (payload.modified_id_num_type != payload.id_num_type)
      modifiedFlag['id_num_type'] = true;
    else modifiedFlag['id_num_type'] = false;

    if (payload.modified_driver_no != payload.driver_no)
      modifiedFlag['driver_no'] = true;
    else modifiedFlag['driver_no'] = false;

    if (payload.modified_id_number != payload.id_number)
      modifiedFlag['foreign_id_number'] = true;
    else modifiedFlag['foreign_id_number'] = false;

    if (payload.modified_user_name != payload.user_name)
      modifiedFlag['user_name'] = true;
    else modifiedFlag['user_name'] = false;
    if (payload.modified_passport_no != payload.passport_no)
      modifiedFlag['passport_no'] = true;
    else modifiedFlag['passport_no'] = false;
    if (payload.modified_nationality != payload.nationality)
      modifiedFlag['nationality'] = true;
    else modifiedFlag['nationality'] = false;
    if (payload.modified_birth_date != payload.birth_date)
      modifiedFlag['birth_date'] = true;
    else modifiedFlag['birth_date'] = false;

    if (payload.modified_expiration_date != payload.expiration_date)
      modifiedFlag['expiration_date'] = true;
    else modifiedFlag['expiration_date'] = false;

    Vue.$log.debug('reviewer#modifiedFlag~', modifiedFlag);
    return modifiedFlag;
  },

  statusDecision: function(resultType) {
    switch (resultType) {
      case 1:
        return '자동 승인';
      case 2:
        return '자동 거부';
      case 3:
        return '수동 승인';
      case 4:
        return '수동 거부';
      default:
        return '미등록';
    }
  },

  caseIntegrated: function(payload) {
    var clientData = {};
    clientData['showable_info_type'] = payload.module;
    if (payload.id_card) {
      var original = JSON.parse(
        `${payload.id_card.original_ocr_data}`.replaceAll('\\', '')
      );
      var modified = {};
      if (!payload.id_card.modified) modified = original;
      else
        modified = JSON.parse(
          `${payload.id_card.modified_ocr_data}`.replaceAll('\\', '')
        );
      clientData['id_card_status'] = payload.id_card.verified;
      clientData['is_manual_input'] = payload.id_card.is_manual_input;
      clientData['is_uploaded'] = payload.id_card.is_uploaded;
      clientData['id_card_image'] = this.imageConverter(
        payload.id_card.id_card_image
      );
      if (payload.id_card.id_card_origin) {
        clientData['id_card_origin'] = this.imageConverter(
          payload.id_card.id_card_origin
        );
      }
      clientData['id_crop_image'] = this.imageConverter(
        payload.id_card.id_crop_image
      );
      clientData['id_card_modified'] = payload.id_card.modified;
      clientData['created_at'] = this.momentValidCheck(original.issueDate);
      if (original.idType) {
        clientData['id_type'] = this.decideTypeOfIdCard(original.idType)[0];
        clientData['id_num_type'] = this.decideTypeOfIdCard(original.idType)[1];
      } else if (modified.idType) {
        clientData['id_type'] = this.decideTypeOfIdCard(modified.idType)[0];
        clientData['id_num_type'] = this.decideTypeOfIdCard(modified.idType)[1];
      }

      clientData['driver_no'] = original.driverNo ?? '';

      clientData['foreign_id_number'] =
        original.issueNo1 + '-' + original._issueNo2;
      clientData['modified_foreign_id_number'] =
        modified.issueNo1 + '-' + modified._issueNo2;

      clientData['id_number'] = original.juminNo1 + '-' + original._juminNo2;
      clientData['modified_id_number'] =
        modified.juminNo1 + '-' + modified._juminNo2;

      clientData['modified_created_at'] = this.momentValidCheck(
        modified.issueDate
      );
      clientData['modified_id_type'] = this.decideTypeOfIdCard(
        modified.idType
      )[0];
      clientData['modified_id_num_type'] = this.decideTypeOfIdCard(
        modified.idType
      )[1];
      clientData['modified_driver_no'] = modified.driverNo ?? '';

      //Foreign passport
      clientData['passport_no'] = original.passportNo ?? '';
      clientData['nationality'] = original.nationality ?? '';
      clientData['birth_date'] = this.momentValidCheck(original.birthDate);
      clientData['expiration_date'] = this.momentValidCheck(
        original.expiryDate
      );
      clientData['user_name'] = original.userName;
      clientData['modified_user_name'] = modified.userName;
      clientData['modified_passport_no'] = modified.passportNo ?? '';
      clientData['modified_nationality'] = modified.nationality ?? '';
      clientData['modified_birth_date'] = this.momentValidCheck(
        modified.birthDate
      );
      clientData['modified_expiration_date'] = this.momentValidCheck(
        modified.expiryDate
      );
      clientData['idcard_content_exist'] = true;
    } else {
      clientData['idcard_content_exist'] = false;
    }
    if (payload.module == undefined) {
      clientData['showable_info_type'] = {
        account_verification: false,
        face_authentication: true,
        id_card_ocr: true,
        id_card_verification: true,
        liveness: true,
      };
    }
    if (payload.face_check) {
      clientData['selfie_image'] = this.imageConverter(
        payload.face_check.selfie_image
      );
      clientData['auto_similarity_check'] = payload.face_check.is_same_person;
      clientData['face_covered'] = payload.face_check.is_masked;
      clientData['face_liveness'] = payload.face_check.is_live;

      clientData['face_check_content_exist'] = true;
    } else {
      clientData['face_check_content_exist'] = false;
    }
    if (payload.account) {
      console.log(payload.account);
      clientData['account_verify'] = payload.account.verified;
      clientData['isModified'] = !!payload.account.mod_account_holder;
      clientData['modified_account_name'] = payload.account.mod_account_holder;
      clientData['account_name'] = payload.account.account_holder;
      clientData['customer_name'] = payload.account.account_holder ?? '디폴트';

      clientData['account_number'] =
        payload.account.account_number ?? '110-454-012345';
      clientData['account_bank'] =
        payload.account.finance_company ?? '디폴트뱅크';
      clientData['account_content_exist'] = true;
    } else {
      clientData['account_content_exist'] = false;
    }
    clientData['rejected_reason'] = payload.rejected_reason ?? '';
    clientData['auditor_name'] = payload.reviewer_name ?? '';
    return clientData;
  },
};
