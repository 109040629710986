<template>
  <div class="card-result-fail-root">
    <div
      style="
      margin-top:32px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--surface-high);"
    >
      신분증 인증
    </div>
    <div class="card-result-fail-info-container">
      <img
        src="@/assets/icon-card-fail.svg"
        width="230px"
        style="margin-bottom:51px;"
      />
      <div
        style="
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: var(--primary-100);
        margin-bottom:24px;"
      >
        진위 확인 실패
      </div>
      <div
        style="
        font-weight: normal;
        text-align:center;
        font-size: 16px;
        line-height: 26px;
        color: var(--surface-high);
        margin-bottom:8px;"
      >
        5회 이상 발급 일자 입력 오류로 인해<br />
        고객님의 주민등록증이 잠김 처리되었습니다.<br />
        잠김 해제 후 다시 이용해 주세요.<br />
      </div>
      <div
        style="
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        background-color: #F6F6F6;
        padding: 12px 16px;"
      >
        <div
          style="
          display: flex;
          color: var(--surface-high)"
        >
          <img width="15px" src="@/assets/info_20_black.svg" color="black" />
          <div style="margin-left: 7px;font-weight: 500;">
            주민등록증 잠김해제 방법
          </div>
        </div>
        <ul style="padding-left: 22px;">
          <li>가까운 읍면동 센터 방문</li>
          <li>
            정부24 접속 (<a
              target="_blank"
              href="https://www.gov.kr/main?a=AA090UserJuminIsCertApp"
              >바로가기</a
            >)
          </li>
        </ul>
      </div>
    </div>
    <div @click="$emit('ok')" class="button-container">확인</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  // position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  // height: 100%;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: white;
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>
