<template>
  <v-dialog
    :value="show"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div style="padding: 32px 30px 40px; color: var(--surface-high)">
          <div class="title-text">{{ title }}</div>
          <InputTextField
            ref="userName"
            v-if="editFields.includes('name')"
            v-model="userinfo.userName"
            :rules="[rules.required, errorRuleName, rules.koreanAndUpperEng]"
            :transform="rules.transformOnlyUpperCase"
            @onupdate="validateForm"
            :maxlength="45"
            placeholder="이름을 입력하세요."
            :error="errorInvalidName"
            :errorMessage="
              errorInvalidName ? errorInvalidMessage : errorDefaultMessageName
            "
            class="userinput-input"
          />
          <InputTextField
            ref="birthDate"
            v-if="editFields.includes('birthdate')"
            placeholder="생년월일 8자리를 입력하세요."
            v-model="userinfo.birthDate"
            :error="errorInvalidBirthDate"
            :errorMessage="
              errorInvalidBirthDate
                ? errorInvalidMessage
                : errorDefaultMessageBirthDate
            "
            :rules="[rules.required, errorRuleBirthDate, rules.date]"
            :transform="rules.transformDateFormat"
            :onblurTransform="rules.onblurTransformDateFormat"
            @onupdate="validateForm"
            class="userinput-input"
          />
        </div>
        <div class="button-container">
          <div v-ripple @click="cancel()" class="button cancel">취소</div>
          <div
            v-ripple
            @click="ok()"
            :class="{ button: true, next: true, disabled }"
          >
            확인
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputTextField from '../auth/InputTextField';
import rules from '../rules';

export default {
  name: 'EditInfoDialog',
  components: {
    InputTextField,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    editType: {
      type: String,
      required: true,
      default: '',
    },
    editFields: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      rules,
      userinfo: {
        userName: '',
        birthDate: '',
      },
      iconClear: require('@/assets/delete_24.svg'),
      disabled: true,
      errorInvalidName: false,
      errorInvalidBirthDate: false,
      errorDefaultMessageName: '국문 또는 영문만 입력 가능합니다.',
      errorDefaultMessageBirthDate: '생년월일를 입력해주세요.',
    };
  },
  computed: {
    title() {
      if (['user-info', 'id-result'].includes(this.editType)) {
        return this.editType === 'user-info'
          ? '고객 정보 수정'
          : '신분증 인식 결과 수정';
      }
      return '';
    },
  },
  methods: {
    errorRuleName() {
      return !this.errorInvalidName;
    },
    errorRuleBirthDate() {
      return !this.errorInvalidBirthDate;
    },
    validateForm() {
      this.errorInvalidName = false;
      this.errorInvalidBirthDate = false;
      this.$nextTick(() => {
        if (
          (this.editFields.length === 2 &&
            this.userinfo.userName &&
            this.userinfo.birthDate) ||
          (this.editFields.length === 1 &&
            this.editFields[0] === 'name' &&
            this.userinfo.userName) ||
          (this.editFields.length === 1 &&
            this.editFields[0] === 'birthdate' &&
            this.userinfo.birthDate)
        ) {
          let fields = [];
          if (this.editFields.length === 1) {
            fields.push(
              this.editFields[0] === 'name'
                ? this.$refs.userName
                : this.$refs.birthDate
            );
          } else {
            fields.push(this.$refs.userName);
            fields.push(this.$refs.birthDate);
          }
          const result = fields
            .map(comp => {
              if (comp === undefined) {
                // component 가 존재하지 않는 것은 해당 조건을 판별하지 않는다란 뜻이므로 항상 true 반환
                return true;
              }
              return comp.valid ? comp.validFlag : true;
            })
            .every(val => val);

          this.disabled = !result;
        } else {
          this.disabled = true;
        }
      });
    },
    cancel() {
      this.userinfo = {
        userName: '',
        birthDate: '',
      };
      this.disabled = true;
      this.errorInvalidName = false;
      this.errorInvalidBirthDate = false;

      if (this.$refs.userName) {
        this.$refs.userName._data.validFlag = true;
      }
      if (this.$refs.birthDate) {
        this.$refs.birthDate._data.validFlag = true;
      }

      this.$emit('ok', null);
    },
    ok() {
      if (!this.disabled) {
        this.$emit('ok', {
          ...this.userinfo,
          editType: this.editType,
          updatedFields: this.editFields,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
  color: #363636;
  margin-bottom: 32px;
}
.userinput-input {
  margin-top: 8px;
}
.button-container {
  display: flex;
  margin-bottom: 30px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 3.5;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }
  }

  .next {
    flex-grow: 6.5;
    background-color: var(--primary-100);
    color: var(--surface-100);
  }

  .disabled {
    background-color: var(--primary-20);
  }
}
</style>
