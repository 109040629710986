<template>
  <v-dialog
    :value="show"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div style="margin: 30px 32px; color: var(--surface-high)">
          <div class="title" style="font-weight:500">정보 수정 안내</div>
          <div class="message-top">
            <span>고객님의 정보가 올바르지 않다면</span>
            <span
              ><span class="phone-number-text" style="font-weight:700">{{
                companyPhoneNumber
              }}</span
              >로</span
            >
            <span>정보 수정을 요청하세요.</span>
          </div>
          <div class="message-bottom">
            <span>고객 센터를 통한 수정이 완료된 후</span>
            <span>재시도해 주시기 바랍니다.</span>
          </div>
          <div v-ripple class="button" @click="onClick" style="font-weight:500">
            확인
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CustomerParameterContactInfoDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
  },
  methods: {
    onClick() {
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 16px;
}

.message-top {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  min-width: 300px;
  padding: 16px 40px;
  border-radius: 8px;
  background-color: var(--gray-100);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;

  .phone-number-text {
    color: var(--primary-100);
  }
}

.message-bottom {
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  border-radius: 8px;
  background-color: var(--primary-100);
  color: var(--surface-100);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 36px;
  height: 60px;
}
</style>
