var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.selectedItemData)?_c('div',{staticClass:"eec-container"},[_c('div',{staticClass:"eec-container-child-info"},[_c('div',{staticClass:"expansion-panels",attrs:{"multiple":""}},_vm._l((_vm.moduleData),function(item,i){return _c('div',{key:i},[_c('div',{staticClass:"expansion-panel-item-container"},[_c('div',{staticClass:"font-500",staticStyle:{"text-decoration-line":"underline"}},[_vm._v(" "+_vm._s(("0" + (i + 1)))+" ")]),_c('div',{staticStyle:{"margin-left":"12px"}},[_c('div',{staticClass:"font-500",staticStyle:{"width":"100px"}},[_vm._v(" "+_vm._s(item.text)+" ")])]),(
                (!_vm.accountContentExists && item.text === '기존 계좌 인증') ||
                  (!_vm.selectedItemData.face_check_content_exist &&
                    item.text === '얼굴 인증') ||
                  (!_vm.selectedItemData.idcard_content_exist &&
                    item.text === '신분증 인증')
              )?_c('div',{staticStyle:{"margin-left":"90px","color":"var(--surface-high)"}},[_vm._v(" 해당 없음 ")]):_vm._e()]),_c('div',[(item.text === '기존 계좌 인증' && _vm.accountContentExists)?_c('Account',{attrs:{"account-info":_vm.selectedItemData}}):_vm._e(),(item.text === '신분증 인증' && _vm.idCardContentExists)?_c('section',{staticClass:"eec-expand"},[(['운전', '주민등록'].some(function (str) { return _vm.idType.includes(str); }))?_c('DriveJumin',{attrs:{"isModifiedData":_vm.isModifiedData,"itemData":_vm.itemData,"detailedItemData":_vm.selectedItemData}}):(
                  ['거소', '영주', '외국인'].some(function (str) { return _vm.idType.includes(str); })
                )?_c('Foreign',{attrs:{"isModifiedData":_vm.isModifiedData,"itemData":_vm.itemData,"detailedItemData":_vm.selectedItemData}}):(['외국 여권'].some(function (str) { return _vm.idType.includes(str); }))?_c('ForeignPass',{attrs:{"isModifiedData":_vm.isModifiedData,"itemData":_vm.itemData,"detailedItemData":_vm.selectedItemData}}):(['한국'].some(function (str) { return _vm.idType.includes(str); }))?_c('Pass',{attrs:{"isModifiedData":_vm.isModifiedData,"itemData":_vm.itemData,"detailedItemData":_vm.selectedItemData}}):_vm._e(),_c('div',{staticStyle:{"width":"32px"}}),_c('section',{staticClass:"eec-expand-half"},[_c('div',{staticStyle:{"margin-top":"24px","position":"relative"}},[(!!_vm.selectedItemData)?_c('img',{staticStyle:{"border":"10px solid #c4c4c4","cursor":"pointer"},attrs:{"src":("" + (_vm.isOriginal
                          ? _vm.selectedItemData.id_card_origin
                          : _vm.selectedItemData.id_card_image)),"width":"280","height":"200px"},on:{"click":_vm.openIdCardExpansionDialog}}):_c('img',{staticStyle:{"border":"10px solid #c4c4c4","cursor":"pointer"},attrs:{"src":require("@/assets/icon-card-guide.svg"),"width":"280px","height":"200px"},on:{"click":_vm.openIdCardExpansionDialog}}),_c('img',{staticClass:"icon-expansion",attrs:{"src":require("@/assets/icon-expantion.svg"),"width":"25px"},on:{"click":function($event){_vm.openIdCardExpansionDialog &&
                        _vm.idType.replaceAll(' ', '') !== '외국여권'}}})]),_c('div',{staticStyle:{"height":"1rem"}}),(
                    _vm.selectedItemData.id_card_origin &&
                      _vm.idType.replaceAll(' ', '') !== '외국여권' &&
                      !_vm.manualInput
                  )?_c('div',{staticClass:"flex-center"},[_c('SubmitButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOriginal),expression:"!isOriginal"}],staticStyle:{"font-weight":"500"},attrs:{"width":"108px","height":"36px","bg-color":"var(--surface-100)","line-color":"var(--gray-200)","button-string":"원본 사진 보기","text-color":'var(--surface-medium)'},on:{"click":function () {
                        _vm.isOriginal = true;
                      }}}),_c('SubmitButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOriginal),expression:"isOriginal"}],staticStyle:{"font-weight":"500"},attrs:{"width":"121px","height":"36px","bg-color":"var(--surface-100)","line-color":"var(--gray-200)","button-string":"마스킹 사진 보기","text-color":'var(--surface-medium)'},on:{"click":function () {
                        _vm.isOriginal = false;
                      }}})],1):_vm._e()])],1):_vm._e(),_c('FaceAuthModule',{attrs:{"item":item,"itemData":_vm.itemData,"detailedItemData":_vm.selectedItemData}}),(i != _vm.moduleData.length - 1)?_c('div',{staticClass:"border-bottom-el"}):_c('div',{staticStyle:{"margin-bottom":"48px","width":"95%"}})],1)])}),0)]),(_vm.needAuditInfo)?_c('div',{staticClass:"eec-container-child-auditor-info",staticStyle:{"position":"relative"}},[_c('div',[_c('div',{staticClass:"auditor-section"},[(!_vm.itemData.inspect_status.includes('자동'))?_c('div',[_c('div',{staticClass:"auditor-title font-500"},[_vm._v("심사자 정보")]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"auditor-first-val font-500",staticStyle:{"width":"50px"}},[_vm._v(" 이름 ")]),_c('div',{staticClass:"auditor-second-val",staticStyle:{"width":"140px"}},[_vm._v(" "+_vm._s(_vm.auditorName)+" ")])]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"auditor-first-val font-500",staticStyle:{"width":"50px"}},[_vm._v(" 아이디 ")]),_c('div',{staticClass:"auditor-second-val",staticStyle:{"width":"140px"}},[_vm._v(" "+_vm._s(_vm.auditorName)+" ")])])]):_vm._e(),(_vm.itemData.inspect_status.includes('거부'))?_c('div',[(
                _vm.faceCheckContentExists ||
                  _vm.itemData.inspect_status.includes('수동')
              )?_c('div',{staticClass:"auditor-title font-500"},[_vm._v(" 거부 사유 ")]):_vm._e(),(_vm.itemData.inspect_status.includes('수동'))?_c('div',[_c('div',{staticClass:"auditor-reason"},[_vm._v(" "+_vm._s(_vm.rejectedReason)+" ")])]):_vm._e(),(
                _vm.faceCheckContentExists &&
                  _vm.itemData.inspect_status.includes('자동')
              )?_c('div',[(!_vm.selectedItemData.auto_similarity_check)?_c('div',[_c('div',{staticClass:"auditor-reason"},[_vm._v("동일인이 아님")])]):_vm._e(),(_vm.selectedItemData.face_covered)?_c('div',[_c('div',{staticClass:"auditor-reason"},[_vm._v("얼굴 가림 감지")])]):_vm._e(),(!_vm.selectedItemData.face_liveness)?_c('div',[_c('div',{staticClass:"auditor-reason"},[_vm._v("얼굴 진위 확인 실패")])]):_vm._e()]):_vm._e()]):_vm._e()])])]):_vm._e(),(!!_vm.selectedItemData)?_c('IdCardDialog',{attrs:{"image":_vm.isOriginal
          ? _vm.selectedItemData.id_card_origin
          : _vm.selectedItemData.id_card_image},model:{value:(_vm.dialogIdCard),callback:function ($$v) {_vm.dialogIdCard=$$v},expression:"dialogIdCard"}}):_c('IdCardDialog',{attrs:{"image":require('@/assets/icon-card-guide.svg')},model:{value:(_vm.dialogIdCard),callback:function ($$v) {_vm.dialogIdCard=$$v},expression:"dialogIdCard"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }