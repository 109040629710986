<template>
  <div
    @click="onClickDropdown"
    v-click-outside="close"
    :class="{ 'dropdown-root': true }"
    :style="`width:${width};height:${height};`"
  >
    <div v-if="open" class="dropdown-container-open">
      <div v-if="multi" @click="close" class="dropdown-item multi">
        <div class="text text-truncate" :style="``">
          {{ label }}
        </div>
        <img
          :width="iconSize"
          src="@/assets/icon-dropdown-close.svg"
          :style="`margin-right: ${iconMargin}`"
        />
      </div>
      <div
        v-for="(item, index) in items"
        :key="index"
        @click="onClickItem(index, $event)"
        :class="{
          'dropdown-item': true,
          multi,
          item: true,
          check: multi && select.includes(item),
        }"
        :style="
          `height:${height};${
            index == 0
              ? 'color:var(--surface-medium)'
              : 'color:var(--surface-high)'
          }`
        "
      >
        <div
          v-if="multi"
          :class="{ uncheck: true, check: select.includes(item) }"
        >
          <img
            v-if="select.includes(item)"
            src="@/assets/icon-dropdown-check.svg"
            :width="iconSize"
            :style="`margin-right: ${iconMargin}`"
          />
        </div>
        <div class="text" :style="`padding:${pad}`">{{ item }}</div>
        <img
          v-if="!multi && index === 0"
          src="@/assets/icon-dropdown-close.svg"
          :width="iconSize"
          :style="`margin-right: ${iconMargin}`"
        />
      </div>
      <div v-if="multi" @click="onClickClear" class="dropdown-item multi">
        <div class="text" style="font-size: 12px">선택 해제</div>
      </div>
    </div>
    <div
      v-else
      v-bind:style="`border:${border}`"
      class="dropdown-container-close"
    >
      <div
        class="text-truncate"
        :style="
          `flex-grow: 1; padding:${pad};${
            items[0] == select
              ? 'color:var(--surface-medium)'
              : 'color:var(--surface-high)'
          };`
        "
      >
        {{ label }}
      </div>
      <img
        src="@/assets/icon-dropdown-open.svg"
        :width="iconSize"
        :style="`margin-right: ${iconMargin}`"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * emits: input, change
     */
    value: [String, Array],
    items: {
      type: Array,
      default: () => ['전체'],
    },
    multi: {
      type: Boolean,
      default: false,
    },
    border: {
      type: String,
      default: '1px solid var(--surface-medium);',
    },
    radius: {
      type: String,
      default: '8px',
    },
    width: {
      type: String,
      default: '112px',
    },
    height: {
      type: String,
      default: '32px',
    },
    pad: {
      type: String,
      default: '0 10px',
    },
    iconSize: {
      type: String,
      default: () => '16px',
    },
    iconMargin: {
      type: String,
      default: () => '11px',
    },
  },
  data() {
    let select;
    if (this.multi) {
      if (this.value instanceof Array && this.value.length) {
        select = this.value;
      } else {
        select = [];
      }
    } else {
      if (this.value) {
        select = this.value;
      } else {
        select = this.items[0];
      }
    }
    return {
      open: false,
      select,
    };
  },
  computed: {
    label() {
      if (this.multi) {
        if (this.select.length) {
          return this.select.join(', ');
        } else {
          return '전체';
        }
      } else {
        return this.select;
      }
    },
  },
  methods: {
    close() {
      this.open = false;
    },
    onClickDropdown() {
      this.open = !this.open;
    },
    onClickItem(index, e) {
      if (this.multi) {
        e.stopPropagation();
        const findIndex = this.select.findIndex(v => v === this.items[index]);
        if (findIndex !== -1) {
          this.select = this.select.slice();
          this.select.splice(findIndex, 1);
        } else {
          this.select.push(this.items[index]);
        }
      } else {
        this.select = this.items[index];
      }
      this.$emit('input', this.select);
      this.$emit('change', this.select);
    },
    onClickClear() {
      this.select = [];
      this.$emit('input', this.select);
      this.$emit('change', this.select);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-root {
  position: relative;
  display: flex;
  align-items: center;

  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 24px;
  color: var(--surface-medium);
  text-overflow: ellipsis;

  &.open {
    border-width: 0;
  }
}
.dropdown-item > .text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown-container-open {
  position: absolute;
  top: 0;
  width: 100%;
  border: 1px solid var(--primary-80);
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 3;
  overflow-y: scroll;
  .dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;

    &.multi {
      height: 34px;

      &.item {
        color: var(--surface-high);
      }
      &.item:hover {
        color: var(--primary-80);
      }
    }

    &.check {
      background-color: #ebecff;
      color: var(--primary-80) !important;
      font-weight: bold;
    }

    .uncheck {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      background-color: var(--gray-100);
      border: 1px solid var(--gray-300);
      border-radius: 2px;
      margin-left: 16px;

      &.check {
        border-width: 0;
        background: var(--primary-100);
      }
    }

    .text {
      flex-grow: 1;
      padding: 0 10px;
    }

    &:hover:not(.multi) {
      background-color: var(--primary-10);
    }
  }
}
.dropdown-item:not(:first-child):hover > .text {
  color: var(--primary-80);
}
.dropdown-container-close {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  align-items: center;
}
::-webkit-scrollbar {
  display: none;
}
</style>
