<template>
  <div style="padding: 40px 72px; border-top:1px solid var(--gray-200)">
    <div class="tnc-title">
      <!-- <div class="tnc-title--index" /> -->
      <h4>약관 내용</h4>
    </div>
    <div
      style="padding: 20px; width: 100%; max-height: 320px; border: 1px solid var(--gray-200); overflow: scroll;margin-bottom:56px;"
      v-html="html"
    ></div>
    <div class="tnc-title--html">
      <div class="tnc-title" style="margin:5px 0">
        <!-- <div class="tnc-title--index" /> -->
        <h4>약관 HTML</h4>
      </div>
      <SubmitButton
        :line-color="'var(--gray-200)'"
        :text-color="'var(--primary-80)'"
        width="117px"
        height="36px"
        :button-string="'HTML 복사하기'"
        @click="copyToClipboard(html)"
        style="font-weight: 500;"
      />
    </div>

    <textarea
      ref="htmlText"
      disabled
      style="padding: 20px; width: 100%; min-height: 136px; border: 1px solid var(--gray-200); color: var(--gray-500);"
      v-model="html"
    ></textarea>
  </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton.vue';
import copyMixin from '@/store/mixins/copy.js';
export default {
  name: 'TncTable',
  components: { SubmitButton },
  mixins: [copyMixin],
  props: {
    text: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {};
  },

  computed: {
    html() {
      return this.text ? decodeURIComponent(atob(this.text)) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.tnc-container {
  max-height: 100%;
  display: flex;
  justify-content: center;
}
.tnc-container > p {
  overflow-y: hidden;
  width: 936px;
  margin: 24px 0;
}
.tnc-title {
  display: flex;
  margin: 0 0 8px 0;
  &--index {
    width: 4px;
    height: 16px;
    margin: 5px 8px 5px 0;
    background: var(--surface-high);
  }
  &--html {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    height: 36px;
  }
}
</style>
