import server from '@/api/server';
import moment from 'moment';

const module = {
  state: function() {
    return {
      threshold_level: 0,
      threshold: 0,
      auditList: [],
    };
  },
  mutations: {
    setThreshold(state, payload) {
      state.threshold_level = payload.threshold_level;
      state.threshold = payload.threshold;
    },
    setHistory(state, payload) {
      const list = payload.list;
      if (list?.length) {
        state.auditList = list.map(history => {
          return {
            id: history.id,
            timestamp: moment(history.request_time).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            username: history.admin_id,
            name: history.admin_name,
            threshold_old: history.level_before,
            threshold_new: history.level_after,
          };
        });
      } else {
        state.auditList = [];
      }
    },
  },
  actions: {
    async getThreshold({ commit }) {
      const data = await server.getSettingThreshold();
      commit('setThreshold', data);
    },
    async setThreshold(_, payload) {
      return await server.setSettingThreshold(payload);
    },
    async getHistory({ dispatch, commit }, payload) {
      await dispatch('getThreshold');
      const data = await server.getSettingThresholdHistory(payload);

      commit('setHistory', data);
    },
  },
  getters: {},
};

export default module;
