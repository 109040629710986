<template>
  <v-dialog
    v-model="dialog"
    max-width="420px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
    scrollable
  >
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div
          style="color: var(--primary-100);font-weight: 500; margin:40px auto 25px; font-size: 20px; line-height: 32px;"
        >
          사진 확인
        </div>
        <img
          :src="image"
          width="90%"
          style="align-self: center;border-radius: 8px;
          "
        />
        <div
          style="font-size: 16px; line-height: 26px; margin: 18px auto 8px;color: var(--surface-high);"
        >
          신분증 사진이 선명한지 확인하세요.
        </div>
        <div
          style="font-size: 12px; line-height: 18px;color: var(--surface-medium); margin: 8px auto 40px; text-align:center;"
        >
          빛 반사, 흔들림, 영역 잘림 등으로<br />
          정확한 신분증 인식이 어려울 수 있습니다.
        </div>
        <div class="button-container" style="margin-bottom: 32px">
          <div @click="cancel" class="button cancel">
            재시도
          </div>
          <div @click="ok" class="button ok">확인</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      this.close();
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  margin-bottom: 48px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
