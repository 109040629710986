var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('CustomInputTextForm',{attrs:{"validity":"individual-id","title":"아이디","placeholder":"8~20자의 영문, 숫자로 입력하세요.","needCheck":true,"info-font-small":true},on:{"click":function($event){_vm.userIdCheck ? _vm.userid(_vm.userId) : function () {}},"set":function ($event) {
          _vm.userId = $event.val;
          _vm.userIdCheck = $event.res;
          _vm.userIdDuplicationCheck = '';
        }}}),(_vm.userIdDuplicationCheck)?_c('div',{key:(_vm.userIdDuplicationCheck + "-check"),style:(("font-size: 12px;margin-top:8px;\n              " + (_vm.userIdDuplicationCheck.includes('가능한')
                  ? 'color:var(--primary-100);'
                  : 'color:var(--error-100);')))},[_vm._v(" "+_vm._s(_vm.userIdDuplicationCheck)+" ")]):_vm._e()],1),_c('div',[_c('CustomInputTextForm',{attrs:{"validity":"name","title":"이름","info-font-small":true,"placeholder":"이름을 입력하세요."},on:{"set":function ($event) {
          _vm.userName = $event.val;
          _vm.userNameCheck = $event.res;
        }}})],1),_c('div',[_c('CustomInputTextForm',{attrs:{"validity":"phone","title":"전화번호","info-font-small":true,"placeholder":"‘-’를 포함하여 입력하세요."},on:{"set":function ($event) {
          _vm.userPhone = $event.val;
          _vm.userPhoneCheck = $event.res;
        }}})],1),_c('div',[_c('CustomInputTextForm',{attrs:{"validity":"email","title":"이메일","info-font-small":true,"needCheck":true,"placeholder":"이메일을 입력하세요."},on:{"click":function($event){_vm.userEmailCheck ? _vm.email(_vm.userEmail) : function () {}},"set":function ($event) {
          _vm.userEmail = $event.val;
          _vm.userEmailCheck = $event.res;
          _vm.userEmailDuplicationCheck = '';
          if (_vm.userEmailCheck) {
            _vm.userEmailDuplicationCheck = '중복여부를 확인하세요.';
          }
        }}}),(_vm.userEmailDuplicationCheck)?_c('div',{key:(_vm.userEmailDuplicationCheck + "-check"),style:(("font-size: 12px;margin-top:8px;\n              " + (_vm.userEmailDuplicationCheck.includes('가능한')
                  ? 'color:var(--primary-100);'
                  : 'color:var(--error-100);')))},[_vm._v(" "+_vm._s(_vm.userEmailDuplicationCheck)+" ")]):_vm._e()],1),_c('div',{staticStyle:{"height":"56px"}}),_c('div',{staticClass:"signup-row"},[_c('SubmitButton',{attrs:{"width":"40%","height":"60px","font-size":"20px","button-string":"취소","bg-color":"#F6F6F6","text-color":"var(--surface-medium)"},on:{"click":_vm.prev}}),_c('div',{staticStyle:{"width":"10px"}}),_c('SubmitButton',{attrs:{"dim":!_vm.isRegisterable,"width":"60%","height":"60px","font-size":"20px","button-string":"등록","bg-dim-color":"var(--primary-20)","bg-color":"var(--primary-100)","text-dim-color":"var(--surface-100)","text-color":"var(--surface-100)"},on:{"click":function($event){return _vm.register()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }