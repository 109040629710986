<template>
  <div class="flex-center" style="flex-direction: column">
    <div class="expanded-container">
      <div style="height: 32px"></div>
      <div class="module-container module-size">
        <section class="module-container-title">
          <div class="module-flex">
            <div class="module-subscribe-title">{{ `구독정보` }}</div>
            <div class="module-flex" :key="JSON.stringify(subscriptionTitle)">
              <div class="module-subscribe-content">
                {{ subscriptionTitle.join(' + ') }}
              </div>
              <div class="module-subscribe-info">
                {{
                  subscriptionContent.length > 0
                    ? `(${subscriptionContent.join(', ')})`
                    : ``
                }}
              </div>
            </div>
          </div>
          <div v-if="isEditMode" class="module-flex">
            <div>
              <SubmitButton
                width="50px"
                height="36px"
                bg-color="var(--primary-5)"
                line-color="var(--gray-200)"
                button-string="취소"
                :text-color="'var(--surface-medium)'"
                @click="initMode()"
              />
            </div>
            <div style="width: 8px" />
            <div>
              <SubmitButton
                width="50px"
                height="36px"
                bg-color="white"
                line-color="var(--gray-200)"
                button-string="완료"
                :text-color="'var(--primary-100)'"
                style="font-weight: 500; margin-right: 30px"
                @click="confirmModule()"
              />
            </div>
          </div>
          <div v-else class="module-flex">
            <div>
              <SubmitButton
                width="50px"
                height="36px"
                bg-color="var(--primary-5)"
                line-color="var(--gray-200)"
                button-string="편집"
                :text-color="'var(--surface-medium)'"
                style="font-weight: 400; margin-right: 30px"
                @click="changeEditMode(true)"
              />
            </div>
          </div>
        </section>
        <section class="module-container-content">
          <section class="module-setting">
            <div class="module-setting-title">기능 설정</div>
            <section class="module-cards">
              <div style="margin-bottom: 32px">
                <ModuleSettingComponent
                  :titles="['신분증 인증', '신분증 OCR / 진위확인']"
                  :division-line="true"
                  :edit-mode="isEditMode"
                  :initData="idCardValue"
                  @module="changeModule($event, 'idcard')"
                />

                <div
                  style="border-top: 2px dashed var(--gray-200); height: 16px"
                />
                <div style="margin-left: 16px">
                  <IdCardModuleSettingComponent
                    :edit-mode="isEditMode"
                    :idCardList="idCardKindKey"
                    :idCardValues="idCardKindValue"
                    :dim-mode="!idCardValue"
                    @check="selectIdCardKind($event)"
                  />
                  <SubModuleSettingComponent
                    :sub-module-lists="['원본 사진 제공', '[모바일] 사진 첨부']"
                    :initData="[originalImage, mobileAttach]"
                    @module="changeModule($event[1], $event[0])"
                    :edit-mode="isEditMode"
                    :dim-mode="!idCardValue"
                  /><SubModuleSettingComponent
                    style="padding-left: 18px"
                    :sub-module-lists="['직접입력 추가']"
                    :initData="[mobileManualDictate]"
                    @module="changeModule($event[1], $event[0])"
                    :dim-mode="!idCardValue"
                    :edit-mode="isEditMode"
                  />
                  <SubModuleSettingComponent
                    :sub-module-lists="['[PC] 사진 첨부']"
                    :initData="[pcAttach]"
                    @module="changeModule($event[1], $event[0])"
                    :edit-mode="isEditMode"
                    :dim-mode="!idCardValue"
                  />
                </div>
              </div>
              <div>
                <ModuleSettingComponent
                  :titles="['얼굴 인증', '신분증 사진 및 얼굴 비교']"
                  :division-line="true"
                  :edit-mode="isEditMode"
                  :initData="faceValue"
                  @module="changeModule($event, 'face')"
                />

                <div style="height: 16px" />
                <div style="margin-left: 16px">
                  <SubModuleSettingComponent
                    :sub-module-lists="['Passive Liveness']"
                    :initData="[passiveLiveness]"
                    @module="changeModule($event[1], $event[0])"
                    :edit-mode="isEditMode"
                    :dim-mode="!faceValue"
                  />
                </div>
              </div>
              <div>
                <ModuleSettingComponent
                  :titles="['계좌 인증', '1원 계좌 인증']"
                  :edit-mode="isEditMode"
                  :initData="accountValue"
                  @module="changeModule($event, 'account')"
                />
              </div>
            </section>
          </section>
          <section class="module-divider">
            <div class="module-divider--bar" />
          </section>
          <section class="additional-setting">
            <div class="additional-setting-dir">
              <AdminCountSettingComponent
                :edit-mode="isEditMode"
                :initial-count="countAdminReviewer"
                @count="modifyCount($event)"
              />
              <div class="additional-setting-margin" />
              <AdditionalServiceSettingComponent
                :edit-mode="isEditMode"
                :send-sms="smsNotification"
                @module="changeModule($event[1], $event[0])"
              />
            </div>
          </section>
        </section>
      </div>
    </div>
    <div class="expanded-container module-modify module-size">
      <div>
        <SubmitButton
          width="121px"
          height="36px"
          :text-color="'var(--surface-medium)'"
          bg-color="white"
          line-color="var(--gray-200)"
          @click="modifyDialogFlag = true"
          button-string="고객사 정보 수정"
        />
      </div>
      <div style="height: 32px"></div>
    </div>
    <ModifyDialog
      v-model="modifyDialogFlag"
      :itemData="itemData"
      @close="modifyDialogFlag = false"
      @end="$emit('closeExpand', itemData.id)"
      :key="`${modifyDialogFlag}-modify`"
    />
  </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton.vue';
import ModuleSettingComponent from '@/components/root/module/ModuleSettingComponent.vue';
import SubModuleSettingComponent from '@/components/root/module/SubModuleSettingComponent.vue';
import IdCardModuleSettingComponent from '@/components/root/module/IdcardModuleSettingComponent.vue';
import AdminCountSettingComponent from '@/components/root/module/AdminCountSettingComponent.vue';
import AdditionalServiceSettingComponent from '@/components/root/module/AdditionalServiceSettingComponent.vue';
import ModifyDialog from '@/components/root/dialog/ModifyDialog.vue';
import server from '../../api/server';
export default {
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {
          id: '1',
        };
      },
    },
    moduleInitialData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    ModifyDialog,
    SubmitButton,
    ModuleSettingComponent,
    SubModuleSettingComponent,
    IdCardModuleSettingComponent,
    AdminCountSettingComponent,
    AdditionalServiceSettingComponent,
  },
  data() {
    return {
      modifyDialogFlag: false,
      moduleData: this.moduleInitialData,
      subscriptionTitle: [],
      subscriptionContent: [],
    };
  },
  created() {
    this.subscriptionGenerator();
  },
  computed: {
    countAdminReviewer() {
      return [
        this.moduleData.detail.max_admin,
        this.moduleData.detail.max_reviewer,
      ];
    },
    idCardKindKey() {
      return [
        'korean-registration',
        'korean-driver',
        'korean-passport',
        'foreign-passport',
        'foreign-registration',
      ]; //return Object.keys(this.moduleData.idcards);
    },
    idCardKindValue() {
      return [
        this.moduleData.idcards['korean-registration'],
        this.moduleData.idcards['korean-driver'],
        this.moduleData.idcards['korean-passport'],
        this.moduleData.idcards['foreign-passport'],
        this.moduleData.idcards['foreign-registration'],
      ];
      //return Object.values(this.moduleData.idcards);
    },
    isEditMode() {
      return this.$store.state.root.isEditMode[this.itemData.id];
    },
    idCardValue() {
      return (
        this.moduleData.module.id_card_ocr &&
        this.moduleData.module.id_card_verification
      );
    },
    faceValue() {
      return this.moduleData.module.face_authentication;
    },
    passiveLiveness() {
      return this.moduleData.module.liveness;
    },
    accountValue() {
      return this.moduleData.module.account_verification;
    },
    originalImage() {
      return this.moduleData.configs.save_original_id_image;
    },
    mobileAttach() {
      return this.moduleData.configs.manual_upload_id_mobile;
    },
    mobileManualDictate() {
      return this.moduleData.configs.manual_input_mobile;
    },
    pcAttach() {
      return this.moduleData.configs.manual_upload_id_pc;
    },
    smsNotification() {
      return this.moduleData.configs.sms_notification;
    },
  },
  methods: {
    subscriptionGenerator() {
      var initModule =
        this.$store.state.root.clientExpandedInfo[this.itemData.id].module;
      var compModule = this.moduleData.module;
      this.$log.debug(
        'ClientExpandedContent#subscriptionTitle',
        initModule,
        compModule
      );
      var tempSubscriptionTitle = [];
      var tempSubscriptionContent = [];
      (() => {
        for (var mod of Object.keys(initModule)) {
          switch (mod) {
            case 'id_card_ocr':
              if (initModule[mod]) {
                tempSubscriptionTitle.push('신분증인증');
                tempSubscriptionContent.push('신분증OCR/진위확인');
              }
              break;
            case 'account_verification':
              if (initModule[mod]) {
                tempSubscriptionTitle.push('계좌 인증');
                tempSubscriptionContent.push('1원 계좌인증');
              }
              break;
            case 'face_authentication':
              if (initModule[mod]) {
                tempSubscriptionTitle.push('얼굴 인증');
                tempSubscriptionContent.push('얼굴 비교');
              }
              break;
            case 'liveness':
              if (initModule[mod]) {
                tempSubscriptionContent.push('Liveness');
              }
              break;
          }
        }
      })();

      this.subscriptionTitle = tempSubscriptionTitle;
      this.subscriptionContent = tempSubscriptionContent;
    },

    async confirmModule() {
      var changes = this.compareModuleDiff();
      var promises = [];
      ((changes) => {
        for (var change of changes) {
          ((change) => {
            this.$log.debug(
              'ClientExpandedContent#confirmModule~ switch-case in Immediately-invoked-function',
              change
            );
            switch (change) {
              case 'configs':
                var requestVar = JSON.parse(
                  JSON.stringify(this.moduleData.configs)
                );
                Object.keys(requestVar).forEach((key) => {
                  requestVar[key] = requestVar[key] + '';
                });
                promises.push(
                  server.setRootConfigs({
                    id: this.itemData.id,
                    content: requestVar,
                  })
                );
                return;
              case 'detail':
                delete this.moduleData.detail.status;
                promises.push(server.modifyClientList(this.moduleData.detail));
                return;
              case 'idcards':
                (() => {
                  for (var idcard of Object.keys(this.moduleData.idcards)) {
                    promises.push(
                      server.setIdCardKind({
                        id: this.itemData.id,
                        name: idcard,
                        content: { active: this.moduleData.idcards[idcard] },
                      })
                    );
                  }
                })();
                return;
              case 'module':
                promises.push(
                  server.modifyClientModule({
                    customer_id: Number(this.itemData.id),
                    module: this.moduleData.module,
                  })
                );
                return;
            }
          })(change);
        }
      })(changes);
      await Promise.all(promises).then((res) => {
        this.$log.debug(
          'ClientExpandedContent#result value after API call ',
          res
        );
        var checkResults = true;
        (() => {
          for (var i of res) {
            if (
              (res[i]?.result_code && res[i]?.result_code != 'N100') ||
              (res[i]?.api_response?.result_code &&
                res[i]?.api_response?.result_code != 'N100')
            ) {
              checkResults = false;
            }
          }
        })();
        if (checkResults) {
          this.$store.dispatch('showRegistrationSnackbar', {
            message: '구독 정보 변경이 완료되었습니다.',
          });
          this.$store
            .dispatch('getDetailedClientInfo', {
              id: this.itemData.id,
            })
            .then(() => {
              this.subscriptionGenerator();
            });

          this.changeEditMode(false);
          return;
        }
      });
    },
    modifyCount(event) {
      var setModule = JSON.parse(JSON.stringify(this.moduleData));
      switch (event.auth) {
        case 'reviewer':
          setModule['detail']['max_reviewer'] = event.value;
          break;
        case 'admin':
          setModule['detail']['max_admin'] = event.value;
          break;
      }

      //Need to check the max, min values
      if (
        setModule['detail']['max_reviewer'] < 1 ||
        setModule['detail']['max_reviewer'] > 99
      ) {
        setModule['detail']['max_reviewer'] = 1;
      }

      if (
        setModule['detail']['max_admin'] < 1 ||
        setModule['detail']['max_admin'] > 99
      ) {
        setModule['detail']['max_admin'] = 1;
      }
      this.moduleData = setModule;
    },
    /**
      * @param {Object} params
      * @param {Text} params.version
      * @param {String} kind tnc || pp
      * @returns {Array<String>}
        case : no changes
        case : need to send configs api
        case : need to send detail api
        case : need to send idcards api
        case : need to send module api
    */
    compareModuleDiff() {
      var changes = [];
      //compare configs
      var initConfigs =
        this.$store.state.root.clientExpandedInfo[this.itemData.id].configs;
      var initDetail =
        this.$store.state.root.clientExpandedInfo[this.itemData.id].detail;
      var initIdcards =
        this.$store.state.root.clientExpandedInfo[this.itemData.id].idcards;
      var initModule =
        this.$store.state.root.clientExpandedInfo[this.itemData.id].module;
      var compConfigs = this.moduleData.configs;
      var compDetail = this.moduleData.detail;
      var compIdcards = this.moduleData.idcards;
      var compModule = this.moduleData.module;
      (() => {
        for (var key of Object.keys(initConfigs)) {
          if (initConfigs[key] != compConfigs[key]) {
            changes.push('configs');
            return;
          }
        }
      })();
      (() => {
        for (var key of Object.keys(initDetail)) {
          if (initDetail[key] != compDetail[key]) {
            changes.push('detail');
            return;
          }
        }
      })();
      (() => {
        for (var key of Object.keys(initIdcards)) {
          if (initIdcards[key] != compIdcards[key]) {
            changes.push('idcards');
            return;
          }
        }
      })();
      (() => {
        for (var key of Object.keys(initModule)) {
          if (initModule[key] != compModule[key]) {
            changes.push('module');
            return;
          }
        }
      })();
      this.$log.debug('ClientExpandedContent#compareModuleDiff ', changes);
      return changes;
    },
    initMode() {
      this.$log.debug('ClientExpandedContent#initMode');
      var initialData = JSON.parse(
        JSON.stringify(
          this.$store.state.root.clientExpandedInfo[this.itemData.id]
        )
      );
      this.moduleData = initialData;
      this.changeEditMode(false);
    },
    changeEditMode(value) {
      this.$log.debug('ClientExpandedContent#changeEditMode', value);
      this.$store.commit('changeEditMode', {
        id: this.itemData.id,
        value: value,
      });
    },
    changeSubModule(event) {
      this.$log.debug('ClientExpandedContent#changeSubModule', event);
      var setModule = JSON.parse(JSON.stringify(this.moduleData));
      switch (event[1]) {
        case 'liveness':
          setModule['module']['liveness'] = event[0];
          break;
      }
      this.moduleData = setModule;
    },
    changeModule(event, mod) {
      this.$log.debug('ClientExpandedContent#changeModule', event, mod);
      var setModule = JSON.parse(JSON.stringify(this.moduleData));
      switch (mod) {
        case 'idcard':
          setModule['module']['id_card_ocr'] = event;
          setModule['module']['id_card_verification'] = event;
          if (!event) {
            setModule['module']['face_authentication'] = event;
            setModule['module']['liveness'] = event;
            setModule['configs']['save_original_id_image'] = event;
            setModule['configs']['manual_upload_id_mobile'] = event;
            setModule['configs']['manual_input_mobile'] = event;
            setModule['configs']['manual_upload_id_pc'] = event;
          }
          break;
        case 'face':
          if (!this.idCardValue) break;
          setModule['module']['face_authentication'] = event;
          if (!event) {
            setModule['module']['liveness'] = event;
          }
          break;
        case 'liveness':
          if (!this.faceValue) break;
          setModule['module']['liveness'] = event;
          break;
        case 'account':
          setModule['module']['account_verification'] = event;
          break;
        case 'originalImage':
          setModule['configs']['save_original_id_image'] = event;
          break;
        case 'mobileAttach':
          setModule['configs']['manual_upload_id_mobile'] = event;
          if (!event) {
            setModule['configs']['manual_input_mobile'] = event;
          }
          break;
        case 'manualDictate':
          if (!this.mobileAttach) break;
          setModule['configs']['manual_input_mobile'] = event;
          break;
        case 'pcAttach':
          setModule['configs']['manual_upload_id_pc'] = event;
          break;
        case 'smsNotification':
          setModule['configs']['sms_notification'] = event;
          break;
        default:
          break;
      }
      this.moduleData = setModule;
    },
    changeIdCardModule(event) {
      var setModule = JSON.parse(JSON.stringify(this.moduleData));
      setModule['module']['id_card_ocr'] = event;
      setModule['module']['id_card_verification'] = event;
      this.moduleData = setModule;
    },

    selectIdCardKind(event) {
      this.$log.debug(
        'ClientExpandedContent#selectIdCardKind ~ isClicked',
        event
      );
      var setModule = JSON.parse(JSON.stringify(this.moduleData));
      setModule['idcards'][event] = !setModule['idcards'][event];
      this.$log.debug(
        'ClientExpandedContent#selectIdCardKind ~ isClicked',
        setModule['idcards'][event]
      );
      if (
        setModule['idcards']['korean-registration'] ||
        setModule['idcards']['korean-driver'] ||
        setModule['idcards']['korean-passport'] ||
        setModule['idcards']['foreign-passport'] ||
        setModule['idcards']['foreign-registration']
      ) {
        this.moduleData = setModule;
      } else {
        this.$store.dispatch('showRegistrationSnackbar', {
          message: '최소 1개 이상은 필수 선택입니다.',
          color: 'var(--error-100)',
        });
      }
      this.forceRender++;
    },
  },
};
</script>
<style lang="scss" scoped>
.module-size {
  width: 1000px;
}
.module-flex {
  display: flex;
}
.module-container {
  height: auto;
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  border-radius: 8px;
}
.module-container-title {
  height: 64px;
  background: var(--primary-5);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.module-subscribe {
  &-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 24px 0 32px;
  }
  &-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  &-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-300);
  }
}
.module-container-content {
  display: flex;
  align-items: center;
}
.module-modify {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 32px 0;
}
.module-setting {
  margin: 24px 0 0 32px;
  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}
.module-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.module-cards > div:not(:last-child) {
  margin-right: 16px;
}
.module-divider {
  height: 537px;
  &--bar {
    width: 2px;
    background: var(--gray-200);
    height: 424px;
    margin: 32px 40px 86px 40px;
  }
}
.additional-setting {
  height: 537px;
  padding-top: 24px;
  &-margin {
    height: 56px;
  }
}
@media screen and (max-width: 1024px) {
  .module-divider {
    display: none;
  }
  .module-size {
    width: 824px;
  }
  .module-container-content {
    display: flex;
    flex-direction: column;
  }
  .module-setting {
    margin: 24px 109px 0 32px;
    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
  .additional-setting {
    width: 824px;
    max-height: 160px;
    padding: 0 0 0 32px;
    &-dir {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
    }
    &-margin {
      margin-right: 137px;
    }
  }
}
</style>
