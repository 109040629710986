<template>
  <v-dialog
    v-model="dialog"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div
          style="
              margin-top: 40px;
              margin-bottom: 16px;
              font-weight: 500;
              font-size: 20px;
              line-height: 32px;
              color: var(--surface-high);
              text-align: center;
            "
        >
          시스템 에러가 발생하였습니다.<br />
          잠시 후 다시 이용해 주세요.
        </div>
        <div
          style="
              margin-bottom: 10px;
              font-size: 16px;
              line-height: 26px;
              color: var(--surface-high);
              text-align: center;
            "
        >
          계속해서 문제가 발생한다면<br />
          고객센터({{ companyPhoneNumber }})으로 문의해주세요.
        </div>
        <div
          style="
              margin-bottom: 32px;
              font-size: 16px;
              line-height: 26px;
              color: var(--surface-medium);
              text-align: center;
            "
        >
          에러코드 : {{ errorCode }}
        </div>
        <div class="button-container" style="margin-bottom: 32px">
          <div @click="ok" class="button ok">확인</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errorCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      this.close();
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
