<template>
  <div>
    <div>
      <div
        style="padding: 40px 72px 9px 72px; border-top:1px solid var(--gray-200)"
      >
        <div>
          <div class="pica-title--name" style="margin-bottom:19px">
            약관 내용
          </div>
          <div v-for="(i, index) in picaObject" :key="`${index}-pica-content`">
            <div class="pica-title">
              <div class="pica-title--index" />
              <div class="pica-title--name">{{ i.title }}</div>
            </div>
            <div
              style="padding: 16px; width: 100%; max-height: 320px; border: 1px solid var(--gray-200); overflow: scroll;margin-bottom:19px;"
              v-html="i.content"
            ></div>
          </div>
          <div class="pica-title--name" style="margin:24px 0 19px 0">
            약관 HTML
          </div>
          <div v-for="(i, index) in picaObject" :key="`${index}-pica-html`">
            <div class="pica-title--html">
              <div class="pica-title">
                <div class="pica-title--index" />
                <div class="pica-title--name">{{ i.title }} HTML</div>
              </div>
              <SubmitButton
                :line-color="'var(--gray-200)'"
                :text-color="'var(--primary-80)'"
                width="117px"
                height="36px"
                :button-string="'HTML 복사하기'"
                @click="copyToClipboard(i.content)"
                style="font-weight: 500;"
              />
            </div>
            <div>
              <textarea
                disabled
                class="pica-textarea"
                v-model="i.content"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pica-container" style="margin-bottom: 50px">
      <SubmitButton
        :img="require('@/assets/pp_delete_24.svg')"
        :line-color="'var(--error-100)'"
        :text-color="'var(--error-100)'"
        width="202px"
        height="48px"
        :button-string="'삭제'"
        @click="$emit('remove', idKey)"
      />
      <div style="width: 10px" />
      <SubmitButton
        :line-color="'var(--primary-80)'"
        width="202px"
        height="48px"
        :button-string="'편집'"
        :text-color="'var(--primary-80)'"
        :img="require('@/assets/pp_edit_24.svg')"
        @click="$emit('edit', { key: idKey, id: identificationInfo })"
      />
    </div>
  </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton.vue';
import copyMixin from '@/store/mixins/copy.js';
export default {
  name: 'PicaTable',
  components: { SubmitButton },
  mixins: [copyMixin],
  props: {
    text: {
      type: String,
      default: () => '',
    },
    idKey: {
      type: String,
      default: () => '',
    },
    identificationInfo: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    picaObject() {
      try {
        return this.text ? JSON.parse(decodeURIComponent(atob(this.text))) : {};
      } catch (e) {
        return {
          agreement_1: {
            title: 'JSON 포멧이 잘못되었습니다. 확인해주세요.',
            content: e,
          },
        };
      }
    },
    picaJson() {
      try {
        return this.text ? decodeURIComponent(atob(this.text)) : '';
      } catch (e) {
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pica-container {
  max-height: 100%;
  display: flex;
  justify-content: center;
}
.pica-container > p {
  overflow-y: hidden;
  width: 936px;
  margin: 24px 0;
}
.pica-textarea {
  padding: 20px;
  margin-bottom: 31px;
  width: 100%;
  min-height: 136px;
  border: 1px solid var(--gray-200);
  color: var(--surface-medium);
}

.pica-title {
  display: flex;
  margin: 5px 0 8px 0;
  &--index {
    width: 4px;
    height: 16px;
    margin: 5px 8px 5px 0;
    background: var(--surface-high);
  }
  &--name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &--html {
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
    height: 36px;
  }
}
</style>
