<template>
  <div class="adbc-container">
    <div class="adbc-btn-accept" @click="clickAccept()">수락</div>
    <div class="adbc-divider" />
    <div class="adbc-btn-deny" @click="clickDeny()">거절</div>
  </div>
</template>

<script>
export default {
  name: 'AcceptDenyBtnsColumn',
  methods: {
    clickAccept() {
      this.$emit('accept');
    },
    clickDeny() {
      this.$emit('deny');
    },
  },
};
</script>

<style scoped>
.adbc-container {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  height: inherit;
}

.adbc-btn-accept {
  color: var(--primary-100);
  cursor: pointer;
  word-break: keep-all;
  text-align: center;
}

.adbc-divider {
  background-color: var(--gray-300);
  height: 16px;
  width: 1px;
  margin: 0px 16px;
}

.adbc-btn-deny {
  color: var(--error-100);
  cursor: pointer;
  word-break: keep-all;
  text-align: center;
}
</style>
