export default {
  created() {},
  data() {
    return {
      tableRerender: false,
    };
  },
  methods: {
    onLoading: async function(
      arg1 = ['잠시만 기다려주세요.'],
      arg2 = ['요청하신 작업을 처리 중 입니다.']
    ) {
      this.$store.dispatch('showLoadingScreen', {
        flag: true,
        message: arg1,
        desc: arg2,
      });
    },
    offLoading: function(ms = 0) {
      new Promise(resolve => {
        setTimeout(() => {
          resolve(
            this.$store.dispatch('showLoadingScreen', {
              flag: false,
              message: null,
              desc: null,
            })
          );
        }, ms);
      });
    },
  },
};
