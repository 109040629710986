<template>
  <div class="idcard-container">
    <div>ㄴ 신분증 설정</div>
    <div style="margin: 8px 0 0 14px">
      <div v-for="(value, index) in idCardNames" :key="`${value}-card`">
        <div style="display: flex; height: 24px; margin-bottom: 8px">
          <div @click="check(idCardList[index])">
            <img
              v-show="idCardValues[index] && dimMode && !editMode"
              src="@/assets/checkbox_gray_dim_24.svg"
              alt="checked"
            />
            <img
              v-show="idCardValues[index] && editMode"
              src="@/assets/checkbox_checked_24.svg"
              alt="checked"
            />
            <img
              v-show="idCardValues[index] && !editMode && !dimMode"
              src="@/assets/checkbox_dim_24.svg"
              alt="checked"
            /><img
              v-show="!idCardValues[index]"
              src="@/assets/checkbox_24.svg"
              alt="unchecked"
            />
          </div>

          <div
            :style="
              `margin-right: 4px;color:${
                dimMode ? 'var(--surface-disabled)' : 'var(--surface-medium)'
              };`
            "
          >
            {{ value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean,
      default: () => false,
    },
    dimMode: {
      type: Boolean,
      default: () => false,
    },
    idCardNames: {
      type: Array,
      default: () => {
        return [
          '주민등록증',
          '운전면허증',
          '한국 여권',
          '외국 여권',
          '외국인 등록증',
        ];
      },
    },
    idCardValues: {
      type: Array,
      default: () => {
        return [false, false, false, false, false];
      },
    },
    idCardList: {
      type: Array,
      default: () => {
        return [
          'korean-registration',
          'korean-driver',
          'korean-passport',
          'foreign-passport',
          'foreign-registration',
        ];
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    check(kind) {
      if (!this.editMode) return;
      this.$emit('check', kind);
      this.$log.debug('IdcardModuleSettingComponent#check ~ isClicked', kind);
    },
  },
};
</script>

<style lang="scss" scoped>
.idcard-container {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--surface-medium);
}
</style>
