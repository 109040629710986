<template>
  <div class="view-container">
    <div style="height: 100%;width: 100%;">
      <div class="card-title-area">
        <div class="empty-div" />
        <div class="card-title-text">인증 안내</div>
        <img
          class="close-icon"
          width="30"
          src="@/assets/icon-close-32.svg"
          @click="exitDialog = true"
        />
      </div>
      <div class="card-text-area">
        <div class="card-text-message">
          고객 신원 확인은 아래와 같이 진행됩니다.<br />
          필요한 사항을 확인해 주세요.
        </div>
        <div class="steps-container">
          <div v-for="(step, i) in filteredSteps" :key="i" class="step">
            <div class="step-icon-container">
              <div class="step-icon-bg">
                <img :src="step.iconSrc" width="30" />
              </div>
            </div>
            <div class="step-text-container">
              <div class="step-text-main">{{ step.mainText }}</div>
              <div class="step-text-sub">{{ step.subText }}</div>
            </div>
          </div>
        </div>
        <v-btn
          class="btn-start"
          height="60"
          ripple
          text
          color="var(--surface-100)"
          @click="$emit('next')"
          >시작</v-btn
        >
      </div>
    </div>
    <ExitDialog
      v-model="exitDialog"
      @ok="$emit('cancel')"
      @cancel="exitDialog = false"
    />
  </div>
</template>

<script>
import Constants from '@/constants.js';
import ExitDialog from '../dialog/ExitDialog';

export default {
  name: 'OnBoardingScreen',
  components: { ExitDialog },
  props: {
    appData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      steps: [
        {
          tag: Constants.MODULE.OCR,
          iconSrc: require('@/assets/icon_onboarding_idcard.svg'),
          mainText: '신분증 인증',
          subText: '본인 명의 신분증으로 신원 정보 확인',
        },
        {
          tag: Constants.MODULE.FACE,
          iconSrc: require('@/assets/icon_onboarding_face.svg'),
          mainText: '얼굴 인증',
          subText: '전면 카메라를 이용한 얼굴 촬영',
        },
        {
          tag: Constants.MODULE.ACCOUNT,
          iconSrc: require('@/assets/icon_onboarding_bank.svg'),
          mainText: '계좌 인증',
          subText: '1원 송금 인증코드 입력',
        },
      ],
      exitDialog: false,
    };
  },
  computed: {
    moduleSettings() {
      if (!this.appData.moduleName || this.appData.moduleName === '') {
        return [];
      }
      return this.appData.moduleName && this.appData.moduleName.includes('+')
        ? this.appData.moduleName.split('+')
        : [this.appData.moduleName];
    },
    filteredSteps() {
      return this.steps.filter(step => this.moduleSettings.includes(step.tag));
    },
  },
};
</script>

<style scoped>
.view-container {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: var(--surface-100);
  z-index: 2;
}

.empty-div {
  height: 10px;
  width: 30px;
}

.close-icon {
  cursor: pointer;
}

.card-title-area {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 32px;
}
.card-title-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--surface-high);
}

.card-text-area {
  /* height: calc(100% - 100px); */
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text-message {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: var(--surface-high);
  margin-bottom: 62px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  display: flex;
  width: 352px;
  margin-bottom: 38px;
}

.step-icon-container {
  height: 60px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-icon-bg {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--primary-5);
}

.step-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step-text-main {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--surface-high);
}

.step-text-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--surface-medium);
}

.btn-start {
  background-color: var(--primary-100);
  display: flex;
  padding: 15px 0px !important;
  border-radius: 8px;
}
</style>
