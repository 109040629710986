<template>
  <v-dialog
    v-model="value"
    max-width="550px"
    @click:outside="() => {}"
    @keydown.esc="close"
    persistent
  >
    <v-card style="border-radius: 8px">
      <v-card-text style="padding: 0; margin: 0">
        <div v-if="failReason === 'done'" class="confirm-title">
          활성화 실패
        </div>
        <div v-else class="confirm-title">수락 실패</div>
        <div class="content-message">
          <div v-if="failReason === 'done'" class="content-message-top">
            최대 활성 가능한 수를 초과하였습니다.
          </div>
          <div v-else class="content-message-top">
            최대 어드민 수를 초과하여 수락할 수 없습니다.
          </div>
          <div class="content-message-middle">
            활성 가능한 어드민 수를 확인해 주세요.
          </div>
          <div class="content-message-bottom">
            <div class="content-message-bottom-text">현재 활성화된 수</div>
            <div class="content-message-bottom-count">
              {{ activeAdminCount }} / {{ maxAdmin }}
            </div>
          </div>
        </div>
        <div class="button-container">
          <div v-ripple @click="confirm" class="button ok">
            확인
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    adminList: {
      type: Array,
      default: () => [],
    },
    selectedAdminConfig: {
      type: Object,
      require: true,
    },
    failReason: {
      type: String,
      require: true,
    },
  },
  computed: {
    activeAdminCount() {
      if (this.adminList.length) {
        return this.fillZero(
          2,
          String(this.adminList.filter(admin => admin.activation).length)
        );
        // return this.adminList.filter(admin => admin.activation).length;
      }
      return this.fillZero(2, String(this.adminList.length));
    },
    maxAdmin() {
      return this.fillZero(2, String(this.selectedAdminConfig.max_admin));
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$log.debug('this.confirmDialogInfo, event');
      this.$emit('confirm');
      this.$emit('close');
    },
    fillZero(width, str) {
      return str.length >= width
        ? str
        : new Array(width - str.length + 1).join('0') + str; //남는 길이만큼 0으로 채움
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-title {
  text-align: center;
  padding: 40px 0px 32px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--surface-high);
}
.content-message {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 58.5px;

  .content-message-top {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: var(--primary-100);
    margin-bottom: 8px;
  }

  .content-message-middle {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: var(--surface-high);
    margin-bottom: 8px;
  }

  .content-message-bottom {
    text-align: center;
    color: var(--surface-medium);
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    padding: 4px 12px;
    // width: 176px;
    border-radius: 8px;

    .content-message-bottom-text {
      margin-right: 8px;
    }

    .content-message-bottom-count {
      font-weight: 500;
    }
  }
}
.content-paragraph > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 24px;
}

.content-paragraph-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.5px;
  color: var(--surface-high);
}
.content-user-info {
  display: flex;
  align-items: center;
}
.content-change-info {
  display: flex;
  margin-left: 20px;
}
.content-change-info > div {
  color: var(--surface-high);

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  .active {
    width: 30px;
  }
  .inactive {
    width: 45px;
  }
}
.button-container {
  display: flex;
  width: 100%;
  padding: 40px 40px;
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    height: 60px;
    cursor: pointer;

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
