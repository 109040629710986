export default {
  methods: {
    async copyToClipboard(content) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(content);
        this.$store.dispatch('showRegistrationSnackbar', {
          message: 'HTML 복사가 완료되었습니다.',
        });
      } else {
        this.$store.dispatch('showRegistrationSnackbar', {
          message: 'HTML 복사는 실패되었습니다.',
          color: 'var(--error-100)',
        });
      }
    },
  },
};
