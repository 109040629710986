<template>
  <div>
    <div v-if="!!selectedItemData" class="eec-container">
      <div class="eec-container-child-info">
        <div class="expansion-panels" multiple>
          <div v-for="(item, i) in moduleData" :key="i">
            <div class="expansion-panel-item-container">
              <div class="font-500" style="text-decoration-line: underline">
                {{ `0${i + 1}` }}
              </div>
              <div style="margin-left: 12px">
                <div class="font-500" style="width: 100px">
                  {{ item.text }}
                </div>
              </div>
              <div
                v-if="
                  (!accountContentExists && item.text === '기존 계좌 인증') ||
                    (!selectedItemData.face_check_content_exist &&
                      item.text === '얼굴 인증') ||
                    (!selectedItemData.idcard_content_exist &&
                      item.text === '신분증 인증')
                "
                style="margin-left: 90px; color: var(--surface-high)"
              >
                해당 없음
              </div>
            </div>
            <div>
              <Account
                v-if="item.text === '기존 계좌 인증' && accountContentExists"
                :account-info="selectedItemData"
              />
              <section
                v-if="item.text === '신분증 인증' && idCardContentExists"
                class="eec-expand"
              >
                <DriveJumin
                  v-if="['운전', '주민등록'].some(str => idType.includes(str))"
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <Foreign
                  v-else-if="
                    ['거소', '영주', '외국인'].some(str => idType.includes(str))
                  "
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <ForeignPass
                  v-else-if="['외국 여권'].some(str => idType.includes(str))"
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <Pass
                  v-else-if="['한국'].some(str => idType.includes(str))"
                  :isModifiedData="isModifiedData"
                  :itemData="itemData"
                  :detailedItemData="selectedItemData"
                />
                <div style="width: 32px" />
                <section class="eec-expand-half">
                  <div style="margin-top: 24px; position: relative">
                    <img
                      v-if="!!selectedItemData"
                      @click="openIdCardExpansionDialog"
                      :src="
                        `${
                          isOriginal
                            ? selectedItemData.id_card_origin
                            : selectedItemData.id_card_image
                        }`
                      "
                      width="280"
                      height="200px"
                      style="border: 10px solid #c4c4c4; cursor: pointer"
                    />
                    <img
                      v-else
                      @click="openIdCardExpansionDialog"
                      src="@/assets/icon-card-guide.svg"
                      width="280px"
                      height="200px"
                      style="border: 10px solid #c4c4c4; cursor: pointer"
                    />
                    <img
                      @click="
                        openIdCardExpansionDialog &&
                          idType.replaceAll(' ', '') !== '외국여권'
                      "
                      src="@/assets/icon-expantion.svg"
                      width="25px"
                      class="icon-expansion"
                    />
                  </div>
                  <div style="height: 1rem" />
                  <div
                    v-if="
                      selectedItemData.id_card_origin &&
                        idType.replaceAll(' ', '') !== '외국여권' &&
                        !manualInput
                    "
                    class="flex-center"
                  >
                    <SubmitButton
                      v-show="!isOriginal"
                      width="108px"
                      height="36px"
                      bg-color="var(--surface-100)"
                      line-color="var(--gray-200)"
                      button-string="원본 사진 보기"
                      :text-color="'var(--surface-medium)'"
                      style="font-weight: 500"
                      @click="
                        () => {
                          isOriginal = true;
                        }
                      "
                    />
                    <SubmitButton
                      v-show="isOriginal"
                      width="121px"
                      height="36px"
                      bg-color="var(--surface-100)"
                      line-color="var(--gray-200)"
                      button-string="마스킹 사진 보기"
                      :text-color="'var(--surface-medium)'"
                      style="font-weight: 500"
                      @click="
                        () => {
                          isOriginal = false;
                        }
                      "
                    />
                  </div>
                </section>
              </section>

              <FaceAuthModule
                :item="item"
                :itemData="itemData"
                :detailedItemData="selectedItemData"
              />
              <div v-if="i != moduleData.length - 1" class="border-bottom-el" />
              <div v-else style="margin-bottom: 48px; width: 95%" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="needAuditInfo"
        class="eec-container-child-auditor-info"
        style="position: relative"
      >
        <div>
          <div class="auditor-section">
            <div v-if="!itemData.inspect_status.includes('자동')">
              <div class="auditor-title font-500">심사자 정보</div>
              <div style="display: flex">
                <div class="auditor-first-val font-500" style="width: 50px">
                  이름
                </div>
                <div class="auditor-second-val" style="width: 140px">
                  {{ auditorName }}
                </div>
              </div>
              <div style="display: flex">
                <div class="auditor-first-val font-500" style="width: 50px">
                  아이디
                </div>
                <div class="auditor-second-val" style="width: 140px">
                  {{ auditorName }}
                </div>
              </div>
            </div>

            <div v-if="itemData.inspect_status.includes('거부')">
              <div
                v-if="
                  faceCheckContentExists ||
                    itemData.inspect_status.includes('수동')
                "
                class="auditor-title font-500"
              >
                거부 사유
              </div>
              <div v-if="itemData.inspect_status.includes('수동')">
                <div class="auditor-reason">
                  {{ rejectedReason }}
                </div>
              </div>
              <div
                v-if="
                  faceCheckContentExists &&
                    itemData.inspect_status.includes('자동')
                "
              >
                <div v-if="!selectedItemData.auto_similarity_check">
                  <div class="auditor-reason">동일인이 아님</div>
                </div>
                <div v-if="selectedItemData.face_covered">
                  <div class="auditor-reason">얼굴 가림 감지</div>
                </div>
                <div v-if="!selectedItemData.face_liveness">
                  <div class="auditor-reason">얼굴 진위 확인 실패</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IdCardDialog
        v-if="!!selectedItemData"
        v-model="dialogIdCard"
        :image="
          isOriginal
            ? selectedItemData.id_card_origin
            : selectedItemData.id_card_image
        "
      ></IdCardDialog>
      <IdCardDialog
        v-else
        v-model="dialogIdCard"
        :image="require('@/assets/icon-card-guide.svg')"
      ></IdCardDialog>
    </div>
  </div>
</template>

<script>
import IdCardDialog from '@/components/dialog/IdCardDialog';
import DriveJumin from '@/components/reviewer/evaluation/idcard/DriveJumin';
import Foreign from '@/components/reviewer/evaluation/idcard/Foreign';
import ForeignPass from '@/components/reviewer/evaluation/idcard/ForeignPass';
import Pass from '@/components/reviewer/evaluation/idcard/Pass';
import FaceAuthModule from '@/components/reviewer/evaluation/FaceAuthModule';
import Account from '@/components/reviewer/evaluation/Account';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
  components: {
    IdCardDialog,
    FaceAuthModule,
    DriveJumin,
    Foreign,
    ForeignPass,
    Pass,
    Account,
    SubmitButton,
  },
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
    detailedItemData: {
      type: Object,
      default: () => {},
    },
    isModifiedData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.moduleData = [];
    const mod = this.$store.state.reviewer.customerModuleInfo;
    if (mod.id_card_ocr && mod.id_card_verification)
      this.moduleData = this.moduleData.concat({
        text: '신분증 인증',
        dataOpened: false,
        dataTillOpened: false,
      });
    if (mod.face_authentication)
      this.moduleData = this.moduleData.concat({
        text: '얼굴 인증',
        dataOpened: false,
        dataTillOpened: false,
      });
    if (mod.account_verification)
      this.moduleData = this.moduleData.concat({
        text: '기존 계좌 인증',
        dataOpened: false,
        dataTillOpened: false,
      });
  },
  data() {
    return {
      dialogIdCard: false,
      dialogReject: false,
      moduleData: [],
      isOriginal: false,
    };
  },

  computed: {
    needAuditInfo() {
      return this.itemData.inspect_status.includes('거부');
    },
    isAllOpened() {
      var opened = true;
      this.moduleData.map(res => {
        if (res.dataOpened == false) opened = false;
      });
      return opened;
    },
    selectedItemData() {
      return this.detailedItemData[this.itemData.id];
    },
    accountContentExists() {
      return this.selectedItemData.account_content_exist;
    },
    auditorName() {
      return this.selectedItemData.auditor_name;
    },
    faceCheckContentExists() {
      return this.selectedItemData.face_check_content_exist;
    },
    idCardContentExists() {
      return this.selectedItemData.idcard_content_exist;
    },
    idType() {
      return this.selectedItemData.id_type;
    },
    rejectedReason() {
      return this.selectedItemData.rejected_reason;
    },
    manualInput() {
      return this.selectedItemData.is_manual_input;
    },
  },
  methods: {
    openIdCardExpansionDialog() {
      this.$log.debug('EvaluationExpandedConent#openIdCardExpansionDialog');
      this.dialogIdCard = true;
    },
    refreshAfterRejectTable() {
      try {
        this.$emit('afterAudit');
      } catch {
        this.$log.debug('EvaluationExpandedConent#refreshAfterRejectTable');
      }
    },
    updateTheApprovedState(isApproved) {
      //TODO: update state of the audit
      try {
        this.$emit('afterAudit');
        this.$store.dispatch('setApproveState', {
          id: this.itemData.id,
          isApproved: isApproved,
        });
      } catch {
        this.$log.debug('EvaluationExpandedConent#updateTheApprovedState');
      }
    },
  },
};
</script>

<style lang="scss">
.eec-container {
  display: flex;
}
.eec-expand {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0 0 32px;
  overflow-x: scroll;
}

.eec-expand-half > div > div {
  width: 280px;
}
.eec-flex-row {
  display: flex;
}
.eec-flex-row > .label-key {
  display: flex;
  width: 150px;
  font-weight: 500;
}
.eec-flex-row > .label-key-list {
  width: 150px;
  color: var(--surface-medium);
}

.eec-flex-row > .label-value {
  width: 130px;
  &--modified {
    width: 130px;
    color: blue;
  }
  &--fail {
    width: 130px;
    color: red;
  }
}
.eec-container-child-info {
  display: flex;
  flex-direction: column;
  width: 75%;
  flex-grow: 1;
  overflow-y: scroll;
}
.expansion-panels {
  width: 90%;
  margin: 14px 0 0 9%;
}
.expansion-panel-item-container {
  display: flex;
  font-size: 16px;
  color: var(--primary-100);
  width: 100%;
  margin-top: 40px;
}
.eec-container-child-auditor-info {
  display: flex;
  flex-direction: column;
  width: 25%;
  flex-grow: 1;
  background: var(--primary-5);
}
.icon-expansion {
  position: absolute;
  top: 0;
  left: 255px;
  cursor: pointer;
}
.border-bottom-el {
  margin-top: 32px;
  margin-bottom: 24px;
  width: 95%;
  border-bottom: 1px solid var(--gray-300);
}

.auditor-section {
  margin: 0 10% 0 10%;
  .auditor-title {
    margin-top: 40px;
    margin-bottom: 16px;
  }
  .auditor-first-val {
    width: 50px;
  }
  .auditor-second-val {
    width: 140px;
    font-weight: normal;
  }
  .auditor-reason {
    color: red;
    font-weight: normal;
  }
}
.eec-container-child-auditor-info > div {
  padding-left: 8px;
  background: var(--surface-100);
  background-clip: content-box;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--gray-300);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--gray-300);
}
</style>
