<template>
  <v-dialog
    :value="show"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div
          style="
            margin: 36px 0 28px;
            text-align: center;
          "
        >
          <div class="title-text">{{ title }}</div>
          <p class="message-text" v-html="message" />
        </div>
        <div class="button-container">
          <div class="button ok" @click="$emit('ok')">확인</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FileUploadErrorDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    errorType: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    title() {
      switch (this.errorType) {
        case 'size':
          return '용량 초과';
        case 'format':
          return '파일 형식 오류';
        case 'invalid-image':
          return '파일 형식 오류';
        default:
          return '';
      }
    },
    message() {
      switch (this.errorType) {
        case 'size':
          return '첨부 가능한 파일 용량을 초과하였습니다.<br />10MB 이하만 첨부가 가능합니다.';
        case 'format':
          return '지원하지 않는 형식의 파일입니다.<br />JPG, JPEG, PNG 파일만 첨부할 수 있습니다.';
        case 'invalid-image':
          return '파일의 확장자와 형식이 일치하지않습니다.<br />올바른 파일을 첨부해 주세요.';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  color: var(--surface-high);
  margin-bottom: 4px;
}
.message-text {
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-high);
}
.button-container {
  display: flex;
  margin: 0px 30px 32px 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
