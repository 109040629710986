<template>
  <v-dialog
    v-model="dialog"
    style="width: calc(100% - 96px); max-width:384px"
    width="calc(100% - 96px)"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div
          style="
            margin: 32px 30px 40px;
            font-size: 20px;
            line-height: 32px;
            color: var(--surface-high);
            
          "
        >
          <h2 class="account-holder-modal--title" style="margin-bottom:32px;">
            예금주명
          </h2>
          <InputTextField
            v-model="account_holder_name"
            :transform="rules.transformOnlyUpperCase"
            placeholder="예금주명을 입력하세요."
            class="userinput-input blue-border-constant"
            @keyup="enterSend"
          ></InputTextField>
        </div>
        <div class="button-container" style="margin-bottom: 32px">
          <div @click="cancel" class="button cancel">취소</div>
          <div @click="send" :class="{ disabled }" class="button ok">
            <span v-if="!loading">1원 송금</span>
            <Loading v-else />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputTextField from '../../auth/InputTextField.vue';
import rules from '../../rules';
import useb from '@/api/useb.js';
import Loading from '../Loading.vue';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sendData: {
      type: Object,
      required: true,
    },
    bankIdx: {
      type: Number,
      require: true,
    },
    tabIdx: {
      type: Number,
      requier: true,
    },
  },
  components: { InputTextField, Loading },
  data() {
    return {
      rules,
      dialog: this.value,
      account_holder_name: '',
      disabled: true,
      loading: false,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    account_holder_name: {
      handler: function(newValue) {
        if (newValue.length > 1) {
          this.disabled = false;
        }
      },
    },
  },
  mounted() {
    console.log(this.sendData);
  },
  methods: {
    enterSend(e) {
      if (e.key == 'Enter') {
        this.send();
      }
    },
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      if (this.disabled) {
        return;
      }
      this.close();
      this.$emit('ok', { account_holder_name: this.account_holder_name });
    },
    async send() {
      try {
        let sendData = this.sendData;
        this.loading = true;
        this.disabled = true;

        // !수정안
        sendData = {
          ...sendData,
          account_holder_name: this.account_holder_name,
        };
        // !수정안
        const res = await useb.sendOpenbank(sendData, this.industry);
        this.loading = false;
        this.disabled = false;
        this.$emit('next', {
          finance_company: this.sendData.finance_company,
          account_num: this.sendData.account_num,
          bank_code: this.sendData.bank_code,
          transaction_id_useb: res.transaction_id,

          bankIdx: this.bankIdx,
          tabIdx: this.tabIdx,

          account_holder: this.sendData.account_holder,
          account_modified: 1,
          mod_account_holder: this.account_holder_name,
        });
      } catch (err) {
        this.loading = false;
        this.disabled = false;
        const { errorCode } = err;
        console.log(this.account_holder_name);
        this.$emit('error', {
          errorCode,
          account_holder_name: this.account_holder_name,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-holder-modal--title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  color: var(--surface-high);

  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
}

.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;
    &.disabled {
      // background: var(--primary-20) !important;
    }

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
