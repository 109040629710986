<template>
  <div class="root-container">
    <img
      @click="stopScan = true"
      src="@/assets/icon-close-32.svg"
      class="icon-close"
    />
    <div class="card-not-matched-title">신분증 인증</div>
    <!-- <img
      src="@/assets/icon-caution.svg"
      width="60px"
      style="margin:56px auto 16px;"
    /> -->
    <div class="card-not-matched-info1">
      고객님의 기본 정보를 확인해 주세요.
    </div>
    <div class="card-not-matched-info1" style="margin-bottom: 40px">
      올바른 정보인가요?
    </div>
    <div class="card-not-matched-container">
      <div class="flex-row" style="height: 32px; margin-bottom: 16px">
        <div>고객 정보</div>
        <!-- <div style="flex-grow:1;"></div>
        <button v-if="!appData.userInputPass" @click="onClickEdit('user-info')">
          <span>수정하기</span>
          <img src="@/assets/next_24_2.svg" width="16px" />
        </button> -->
      </div>
      <div v-if="showNameInput" class="flex-row" style="margin-bottom: 8px">
        <div class="label">이름</div>
        <div class="content">{{ showNameInputValue }}</div>
      </div>
      <div v-if="showBirthdayInput" class="flex-row">
        <div class="label">생년월일</div>
        <div class="content">{{ showBirthdayInputValue }}</div>
      </div>
      <!-- <div v-if="appData.userInputPass" class="user-input-invalid">
        <div style="color: var(--surface-medium); display: flex; align-items: center;">
          <img
            src="@/assets/info_20.svg"
            width="20px"
            style="margin-right: 8px;"
          />
          <span>고객 정보가 올바르지 않다면?</span>
        </div>
        <div style="color: var(--surface-high);">
          {{ companyPhoneNumber }}로 정보 수정을 요청하세요.
        </div>
      </div> -->
    </div>
    <div v-if="!appData.userInputPass" class="card-not-matched-subtext">
      <img src="@/assets/info_20.svg" style="margin-right: 8px" />
      <span style="font-size: 13px"
        >잘못된 정보라면 ‘아니오’를 눌러 직접 수정해 주세요.</span
      >
    </div>
    <div class="button-container">
      <div v-ripple @click="onClickDecline" class="button decline">아니오</div>
      <div v-ripple @click="onClickAccept" class="button accept">
        네, 맞습니다.
      </div>
    </div>
    <EditInfoDialog
      :show="showEditInfoDialog"
      :editType="editType"
      :editFields="editFields"
      @ok="onEditComplete($event)"
    />
    <ExitDialog v-model="stopScan" @ok="onClickBack"></ExitDialog>
    <CustomerParameterContactInfoDialog
      :show="showCustomerParameterContactInfoDialog"
      @ok="onCloseCustomerParameterContactInfoDialog"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CustomerParameterContactInfoDialog from '../dialog/CustomerParameterContactInfoDialog';
import EditInfoDialog from '../dialog/EditInfoDialog';
import ExitDialog from '../dialog/ExitDialog';

export default {
  components: {
    CustomerParameterContactInfoDialog,
    EditInfoDialog,
    ExitDialog,
  },
  props: {
    appData: Object,
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function() {
    return {
      stopScan: false,
      showEditInfoDialog: false,
      editType: '',
      editFields: [],
      showCustomerParameterContactInfoDialog: false,
      showNameInput: false,
      showNameInputValue: '',
      showBirthdayInput: false,
      showBirthdayInputValue: '',
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber']),
  },
  mounted() {
    this.$log.debug('CardNotMatched#mounted appData', this.appData);

    if (this.appData.userName !== this.appData.ocr.userName) {
      this.showNameInput = true;
      this.showNameInputValue = this.appData.userName;
    }
    if (this.appData.birthDate !== this.appData.scannedFormattedDate) {
      this.showBirthdayInput = true;
      this.showBirthdayInputValue = this.appData.birthDate;
    }
  },
  methods: {
    initialize() {},
    onClickBack() {
      this.$emit('cancel');
    },
    // goToUserInput() {
    //   this.$emit("next", { userinput: true });
    // },
    // goToNext() {
    //   this.$emit("next");
    // },
    onClickEdit(type) {
      this.editType = type;
      this.editFields = [];
      if (this.appData.userName !== this.appData.ocr.userName) {
        this.editFields.push('name');
      }
      if (this.appData.birthDate !== this.appData.scannedFormattedDate) {
        this.editFields.push('birthdate');
      }
      this.showEditInfoDialog = true;
    },
    onEditComplete(payload) {
      if (payload) {
        this.$log.debug('CardNotMatched#onEditComplete', payload);
        this.$emit('next', {
          userinfoChanged: true,
          updatedName: payload.userName,
          updatedBirthDate: payload.birthDate,
          updatedEditType: payload.editType,
          updatedFields: payload.updatedFields,
        });
      }

      this.showEditInfoDialog = false;
    },
    onClickDecline() {
      if (!this.appData.identification_config_enabled) {
        this.showCustomerParameterContactInfoDialog = true;
      }
      this.onClickEdit('user-info');
    },
    onCloseCustomerParameterContactInfoDialog() {
      this.showCustomerParameterContactInfoDialog = false;
      this.$emit('cancel');
    },
    onClickAccept() {
      let emitPayload = {
        userinfoChanged: false,
        updatedEditType: 'id-result',
        updatedFields: [],
      };
      if (this.appData.userName !== this.appData.ocr.userName) {
        emitPayload.updatedFields.push('name');
        emitPayload.updatedName = this.appData.userName;
      }
      if (this.appData.birthDate !== this.appData.scannedFormattedDate) {
        emitPayload.updatedFields.push('birthdate');
        emitPayload.updatedBirthDate = this.appData.birthDate;
      }
      this.$emit('next', emitPayload);
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--surface-high);
  align-items: center;
}

.icon-close {
  position: absolute;
  width: 32px;
  right: 32px;
  top: 32px;
  cursor: pointer;
}

.card-not-matched-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 56px;
  text-align: center;
}

.card-not-matched-info1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}

.card-not-matched-info2 {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--surface-medium);
}

.card-not-matched-container {
  display: flex;
  flex-direction: column;
  padding: 24px 28px;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  margin: 0 50px 16px;
  max-width: 394px;

  .flex-row {
    display: flex;
    align-items: center;
    margin: 0 4px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 32px;
    background: #ebecff;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-80);
  }

  .label {
    width: 60px;
    margin-right: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--surface-medium);
  }

  .content {
    font-size: 16px;
    line-height: 24px;
    color: var(--surface-high);
  }

  .user-input-invalid {
    display: flex;
    flex-direction: column;
    padding: 15px 19px;
    background: var(--gray-100);
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  }
}

.card-not-matched-subtext {
  display: flex;
  justify-content: center;
  align-content: center;
}

.button-container {
  margin-top: 56px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  cursor: pointer;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
  }

  .decline {
    flex-grow: 3.5;
    margin-left: 30px;
    margin-right: 10px;
    background-color: var(--gray-100);
    color: var(--surface-medium);
  }

  .accept {
    flex-grow: 6.5;
    margin-right: 30px;
    background-color: var(--primary-100);
    color: var(--surface-100);
  }
}
</style>
