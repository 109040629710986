<template>
  <div class="types">
    <div class="types-container">
      <div
        :class="
          `types-text types-text${
            selectedTypeIndex === 0 ? '-selected' : '-unselected'
          }`
        "
        @click="selectSettingType(0)"
      >
        {{ settingTypes[0] }}
      </div>
      <div
        style="height:36px;width:16px;border-bottom:1px solid var(--surface-disabled);"
      />
      <div
        :class="
          `types-text types-text${
            selectedTypeIndex === 1 ? '-selected' : '-unselected'
          }`
        "
        @click="selectSettingType(1)"
      >
        {{ settingTypes[1] }}
      </div>
    </div>

    <div>
      <div v-if="selectedTypeIndex == 0">
        <div>
          <div style="padding:41px 72px 25px 72px ">
            <div>
              <div class="tg-title--name" style="margin-bottom:19px">
                약관 내용
              </div>
              <div
                v-for="(i, index) in picaObject"
                :key="`${index}-tg-content`"
              >
                <div class="tg-title">
                  <div class="tg-title--index" />
                  <div class="tg-title--name">{{ i.title }}</div>
                </div>
                <div
                  style="padding: 16px; width: 100%; max-height: 320px; border: 1px solid var(--gray-200); overflow: scroll;margin-bottom:32px;"
                  v-html="i.content"
                ></div>
              </div>
              <div class="tg-title--name" style="margin:56px 0 19px 0">
                약관 HTML
              </div>
              <div v-for="(i, index) in picaObject" :key="`${index}-tg-html`">
                <div class="tg-title--html">
                  <div class="tg-title">
                    <div class="tg-title--index" />
                    <div class="tg-title--name">{{ i.title }} HTML</div>
                  </div>
                  <SubmitButton
                    :line-color="'var(--gray-200)'"
                    :text-color="'var(--primary-80)'"
                    width="117px"
                    height="36px"
                    :button-string="'HTML 복사하기'"
                    @click="copyToClipboard(i.content)"
                    style="font-weight: 500;"
                  />
                </div>
                <div>
                  <textarea
                    disabled
                    style="padding: 20px; margin-bottom:31px; width: 100%; min-height: 136px; border: 1px solid var(--gray-200); color: var(--surface-medium);"
                    v-model="i.content"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedTypeIndex == 1">
        <div>
          <div style="padding:41px 72px 56px 72px ">
            <div>
              <div v-for="(i, index) in ppgObject" :key="`${index}-tg-content`">
                <div class="tg-title">
                  <!-- <div class="tg-title--index" /> -->
                  <div class="tg-title--name">약관 내용</div>
                </div>
                <div
                  style="padding: 16px; width: 100%; max-height: 320px; border: 1px solid var(--gray-200); overflow: scroll;margin-bottom:32px;"
                  v-html="i.content"
                ></div>
              </div>
              <div v-for="(i, index) in ppgObject" :key="`${index}-tg-html`">
                <div class="tg-title--html">
                  <div class="tg-title">
                    <!-- <div class="tg-title--index" /> -->
                    <div class="tg-title--name">약관 HTML</div>
                  </div>
                  <SubmitButton
                    :line-color="'var(--gray-200)'"
                    :text-color="'var(--primary-80)'"
                    width="117px"
                    height="36px"
                    :button-string="'HTML 복사하기'"
                    @click="copyToClipboard(i.content)"
                    style="font-weight: 500;"
                  />
                </div>
                <div>
                  <textarea
                    disabled
                    style="padding: 20px; width: 100%; min-height: 136px; border: 1px solid var(--gray-200); color: var(--surface-medium);"
                    v-model="i.content"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton.vue';
import copyMixin from '@/store/mixins/copy.js';
export default {
  name: 'TncGuidanceTable',
  components: { SubmitButton },
  mixins: [copyMixin],
  props: {
    text: {
      type: Object,
      default: () => {
        return {
          pica: ``,
          ppg: ``,
        };
      },
    },
  },
  data() {
    return {
      settingTypes: ['개인정보수집동의서', '개인정보처리방침'],
      selectedTypeIndex: 0,
    };
  },
  created() {},
  methods: {
    selectSettingType(index) {
      this.selectedTypeIndex = index;
    },
  },

  computed: {
    picaObject() {
      try {
        return this.text.pica ? JSON.parse(this.text.pica) : {};
      } catch (e) {
        return {
          agreement_1: {
            title: 'JSON 포멧이 잘못되었습니다. 확인해주세요.',
            content: e,
          },
        };
      }
    },
    ppgObject() {
      try {
        return this.text.ppg ? JSON.parse(this.text.ppg) : {};
      } catch (e) {
        return {
          agreement_1: {
            title: 'JSON 포멧이 잘못되었습니다. 확인해주세요.',
            content: e,
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.types-container {
  margin: 51px auto 0 72px;
  height: 36px;
  width: 100%;
  display: flex;
}
.types-text {
  width: auto;
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.types-text-selected {
  color: var(--surface-high);
  height: 36.5px;
  border-bottom: 2px solid black;
}
.types-text-unselected {
  cursor: pointer;
  border-bottom: 1px solid var(--surface-disabled);
  color: var(--surface-medium);
}
.tg-container {
  max-height: 100%;
  display: flex;
  justify-content: center;
}
.tg-container > p {
  overflow-y: hidden;
  width: 936px;
  margin: 24px 0;
}
.tg-title {
  display: flex;
  margin: 5px 0 16px 0;
  &--index {
    width: 4px;
    height: 16px;
    margin: 5px 8px 5px 0;
    background: var(--surface-high);
  }
  &--name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &--html {
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
    height: 36px;
  }
}
</style>
