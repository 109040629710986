<template>
  <div
    class="signin-container signin-background full-size"
    style="display: flex; justify-content: center; min-height:800px;"
  >
    <Login
      class="signin-card"
      loginType="reviewer"
      style="width: 400px; max-height: 100%; overflow: scroll;"
      @find="find()"
    />
  </div>
</template>

<script>
import Login from '@/components/reviewer/signin/Login.vue';
import CONSTANTS from '@/constants.js';
import collision from '@/store/mixins/collision.js';
export default {
  name: 'SignIn',
  components: {
    Login,
  },
  mixins: [collision],
  mounted() {
    this.otherComponent = document.querySelector('#signin-form');
    this.isCollide();
  },
  updated() {
    this.otherComponent = document.querySelector('#signin-form');
    this.isCollide();
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    find() {
      this.$log.debug('Signin#find~ 아이디 찾기');
      this.$router.push({
        name: CONSTANTS.ROUTE_NAME.FIND_ACCOUNT,
        query: { role: 'reviewer' },
      });
    },
  },
};
</script>

<style lang="scss">
.signin-card {
  width: 400px;
  margin-top: 14vh;
  max-height: 100%;
  overflow: scroll;
}

.signin-title {
  font-size: 32px;

  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  color: var(--surface-100);
}

.signin-form {
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 8px;
  justify-content: left;
}

.signin-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  margin: 44px 0 24px 0;
  height: 60px;
  cursor: pointer;

  &.default {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-20);
    cursor: unset;
  }

  &.ok {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-100);
  }
}
.critical-error {
  background: var(--error-10);
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
  color: var(--error-100);
  font-size: 12px;
  padding-left: 16px;
  margin: 8px 0 16px 0;
}
</style>
