<template>
  <v-dialog
    v-model="dialog"
    style="width: calc(100% - 96px)"
    width="calc(100% - 96px)"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        style="display: flex; flex-direction: column; padding: 36px 30px 32px; margin: 0"
      >
        <div
          style="
            margin-bottom:28px;
            font-size: 20px;
            line-height: 32px;
            color: var(--surface-high);
            text-align: center;
          "
        >
          본인 인증이 완료되지 않았습니다.<br />바로 종료하시겠습니까?
        </div>
        <div class="button-container">
          <div @click="ok" class="button cancel">종료</div>
          <div @click="cancel" class="button ok">취소</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
    ok() {
      this.close();
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
