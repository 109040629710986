var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-container"},[_c('div',{staticClass:"content-container"},[_c('div',{staticClass:"client-types-container"},[_c('div',{staticClass:"client-types-area-left"},[_c('div',{staticClass:"client-management-title"},[_vm._v("고객사 관리")]),_c('button',{staticClass:"add-button",attrs:{"type":"submit"},on:{"click":function($event){_vm.addDialogFlag = true}}},[_vm._v(" +등록 ")])]),_c('div',{staticClass:"client-types-area-right"},[_c('Dropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTypeIndex === 0),expression:"selectedTypeIndex === 0"}],staticStyle:{"width":"112px"},attrs:{"items":_vm.searchCriteriaDropdownItems},on:{"change":_vm.onChangeFilter},model:{value:(_vm.selectedInspectionPendingFilter),callback:function ($$v) {_vm.selectedInspectionPendingFilter=$$v},expression:"selectedInspectionPendingFilter"}}),_c('InputSearch',{staticStyle:{"margin-left":"7px"},on:{"change":_vm.onChangeSearch}})],1)]),_c('div',{staticClass:"audits-table-container"},[(_vm.selectedTypeIndex === 0)?_c('CmTable',{key:(_vm.tableRerender + "-cm"),attrs:{"table-name":'cm',"titles":_vm.TABLE.CM.SIZE,"titleNames":_vm.TABLE.CM.NAME,"contents":_vm.clientList},on:{"getDetail":function($event){return _vm.getDetailedClientInfo($event)}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{class:props.tid == 'status'
                ? props.c === '활성'
                  ? 'high-color'
                  : 'low-color'
                : ''},[_vm._v(" "+_vm._s(props.c)+" ")])]}},{key:"detail",fn:function(props){return [(
              _vm.isOpenedFromChild.includes(props.item.id) &&
                _vm.$store.state.root.clientExpandedInfo[props.item.id]
            )?_c('ClientExpandedContent',{attrs:{"item-data":props.item,"module-initial-data":_vm.$store.state.root.clientExpandedInfo[props.item.id]},on:{"closeExpand":function () {},"modify":function () {}}}):_vm._e()]}}],null,false,3881798135)}):_vm._e(),_c('Pagenation',{attrs:{"totalCount":90},on:{"update":function($event){return _vm.requestClientList($event)}}})],1)]),_c('AddDialog',{key:_vm.addDialogFlag,on:{"close":function($event){_vm.addDialogFlag = false}},model:{value:(_vm.addDialogFlag),callback:function ($$v) {_vm.addDialogFlag=$$v},expression:"addDialogFlag"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }