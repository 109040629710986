import server from '@/api/server';
import moment from 'moment';
import { subModules } from './submodules/management';
import Vue from 'vue';

const module = {
  state: function() {
    return {
      clientList: [],
      clientExpandedInfo: {},
      clientListDetail: {},
      clientNameList: {},
      adminList: [],
      tncList: [],
      tncListDetail: {},
      picaPpList: [],
      picaPpDetail: {},
      ppList: [],
      ppListDetail: {},
      isEditMode: {},
    };
  },
  mutations: {
    changeEditMode(state, payload) {
      Vue.$log.debug('root.js#changeEditMode to', payload);
      var isEditTemp = { ...state.isEditMode };
      isEditTemp[payload.id] = payload.value;
      state.isEditMode = isEditTemp;
    },
    modifiedComparison(state, payload) {
      var isModified = false;
      Vue.$log.debug('root.js#modifiedComparision', payload.id);
      var id = payload.id;

      if (
        state.clientListDetail[id].client_setting_data.id_card_scenario
          .id_card_toggle !=
        state.clientListDetail[id].client_initial_data.id_card_scenario
          .id_card_toggle
      ) {
        isModified = true;
      }
      if (
        state.clientListDetail[id].client_setting_data.face_comparison_scenario
          .face_comparison_toggle !=
        state.clientListDetail[id].client_initial_data.face_comparison_scenario
          .face_comparison_toggle
      ) {
        isModified = true;
      }
      if (
        state.clientListDetail[id].client_setting_data.face_comparison_scenario
          .passive_liveness_toggle !=
        state.clientListDetail[id].client_initial_data.face_comparison_scenario
          .passive_liveness_toggle
      ) {
        isModified = true;
      }
      if (
        state.clientListDetail[id].client_setting_data.one_point_auth_scenario
          .one_point_auth_toggle !=
        state.clientListDetail[id].client_initial_data.one_point_auth_scenario
          .one_point_auth_toggle
      ) {
        isModified = true;
      }
      if (
        state.clientListDetail[id].client_setting_data.configs?.filter(
          res => res.key == 'sms_notification'
        )[0].value !=
        state.clientListDetail[id].client_initial_data.configs?.filter(
          res => res.key == 'sms_notification'
        )[0].value
      ) {
        isModified = true;
      }

      var preventImmutable = { ...state.clientListDetail };
      preventImmutable[id].isModified = isModified;
      state.clientListDetail = preventImmutable;
    },
    setNoticeList(state, payload) {
      switch (payload.kind) {
        case 'pp':
          state.ppList = [...payload.data.list];
          break;
        case 'tnc':
          state.tncList = [...payload.data.list];
          break;
      }
    },
    setNoticeDetail(state, payload) {
      var preventImmutable = null;

      Vue.$log.debug('root#setNoticeDetail', payload);
      switch (payload.kind) {
        case 'pp':
          preventImmutable = { ...state.ppListDetail };
          preventImmutable[payload.data.id] = payload.data.content;
          state.ppListDetail = preventImmutable;
          break;
        case 'tnc':
          preventImmutable = { ...state.tncListDetail };
          preventImmutable[payload.data.id] = payload.data.content;
          state.tncListDetail = preventImmutable;
          break;
      }
      console.log(
        'setNoticeDetail',
        payload,
        state.tncListDetail[1],
        state.ppListDetail[1]
      );
    },
    setPicaPpList(state, payload) {
      state.picaPpList = [...payload];
    },
    setPicaPpDetail(state, payload) {
      var preventImmutable = { ...state.picaPpDetail };
      preventImmutable[payload.id] = payload.content;
      state.picaPpDetail = preventImmutable;
    },
    setClientList(state, payload) {
      payload = payload.data;

      if (payload?.length) {
        state.clientList = payload.map(singleClient => {
          return {
            id: `${singleClient.id}`,
            timestamp: moment(singleClient.request_time).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            company: singleClient.company,
            kind: singleClient.kind,
            purchase_type: singleClient.purchase_type,
            status: singleClient.status,
            phone: singleClient.phone,
          };
        });
      } else {
        state.clientList = [];
      }
    },
    setAdminList(state, params) {
      state.adminList = [];
      state.adminList = params.data;
    },

    setChangedOnePointToggle(state, payload) {
      state.clientListDetail[
        payload.id
      ].client_setting_data.one_point_auth_scenario.one_point_auth_toggle =
        payload.value;
    },
    setChangedIdToggle(state, payload) {
      state.clientListDetail[
        payload.id
      ].client_setting_data.id_card_scenario.id_card_toggle = payload.value;
    },
    setChangedFaceToggle(state, payload) {
      state.clientListDetail[
        payload.id
      ].client_setting_data.face_comparison_scenario.face_comparison_toggle =
        payload.value;
    },
    setChangedLivenessToggle(state, payload) {
      state.clientListDetail[
        payload.id
      ].client_setting_data.face_comparison_scenario.passive_liveness_toggle =
        payload.value;
    },
    setClientDetail(state, payload) {
      Vue.$log.debug('root.js#setClientDetail', payload);
      if (payload.data) {
        var preventImmutable = { ...state.clientListDetail };
        preventImmutable[payload.id] = payload.data;
        state.clientListDetail = preventImmutable;
      }

      Vue.$log.debug(
        'root.js#setClientDetail~clientListDetail',
        state.clientListDetail,
        payload.id
      );
    },
    setDetailedClientInfo(state, payload) {
      var preventImmutable = { ...state.clientExpandedInfo };
      preventImmutable[payload.id] = payload.data;
      state.clientExpandedInfo = preventImmutable;

      Vue.$log.debug('root.js#setClientDetail', state.clientExpandedInfo);
    },
    setClientConfigs(state, payload) {
      Vue.$log.debug('root.js#setClientConfigs', payload);
      if (payload.data) {
        var preventImmutable = { ...state.clientListDetail };
        preventImmutable[payload.id]['configs'] = payload.configs;
        state.clientListDetail = preventImmutable;
      }

      Vue.$log.debug(
        'root.js#setClientDetail~clientListDetail',
        state.clientListDetail,
        payload.id
      );
    },
    setClientNameList(state, payload) {
      payload = payload.data;
      if (payload) {
        state.clientNameList = payload;
      }
    },
    setChangedSmsToggle(state, payload) {
      var preventImmutable = { ...state.clientListDetail };

      preventImmutable[payload.id].client_setting_data.configs.map(res => {
        if (res.key == 'sms_notification') res.value = payload.value.toString();
      });
      state.clientListDetail = preventImmutable;
    },
  },
  actions: {
    async getNoticeList({ commit, rootState }, payload) {
      try {
        const data = await server.readNoticeList(payload.kind, {
          start: payload.start,
          count: payload.count,
        });
        await commit('setNoticeList', { data: data, kind: payload.kind });
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getNoticeDetail({ commit, rootState }, payload) {
      try {
        const data = await server.readNoticeDetail(payload.kind, payload.id);
        Vue.$log.debug('root#getNoticeDetail', data);
        await commit('setNoticeDetail', { data: data, kind: payload.kind });
        Vue.$log.debug('root#getNoticeDetail', data);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getPicaPpList({ commit, rootState }) {
      try {
        const data = await server.readPicaPpList();
        await commit('setPicaPpList', data);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getPicaPpDetail({ commit, rootState }, payload) {
      try {
        const data = await server.readPicaPpDetail(payload.key);
        await commit('setPicaPpDetail', data);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async removePicaPp({ rootState }, payload) {
      try {
        const data = await server.deletePicaPp(payload.key);
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async editPicaPp({ rootState }, payload) {
      try {
        const data = await server.editPicaPp(payload);
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getClientList({ commit, rootState }, payload) {
      try {
        const data = await server.readClientList(payload);
        var elaboratedData = [];
        if (data) {
          data.map(res => {
            elaboratedData.push(subModules.managementList(res));
          });
        }
        await commit('setClientList', {
          data: elaboratedData,
        });
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getClientNameList({ commit, rootState }, payload) {
      try {
        const data = await server.readClientList(payload);
        var elaboratedData = {};

        data.map(res => {
          elaboratedData[res.name] = res.id;
        });
        await commit('setClientNameList', {
          data: elaboratedData,
        });
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getAdminList({ commit, rootState }, payload) {
      try {
        let id = payload.id;
        delete payload.id;
        const data = await server.readAdminList(id, payload);
        var elaboratedData = [];
        data.map(res => {
          elaboratedData.push(subModules.adminList(res));
        });
        await commit('setAdminList', {
          data: elaboratedData,
        });
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getClientListForSearch({ rootState }, payload) {
      try {
        const data = await server.readClientList(payload);
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async getClientDetailed({ commit, rootState }, payload) {
      try {
        const data = await server.readClientDetail(payload);
        let elaboratedDetailData = subModules.managementDetailList(data.module);
        elaboratedDetailData.customer = data.customer;

        await commit('setClientDetail', {
          data: elaboratedDetailData,
          id: payload.id,
        });
      } catch (e) {
        // Vue.$log.debug("e json string", JSON.stringify(e));
        const errorCodeControlList = ['CF006', 'CE007', 'CE008', 'CE009'];
        if (errorCodeControlList.includes(e.errorCode)) {
          rootState.customUIDialog = true;
          rootState.customUIMessageTitle = ['고객사 등록 미완료'];
          rootState.customUIMessage = e.response.result_message;
          rootState.customUIerrorCode = '코드 : ' + e.errorCode;
          rootState.customUIButton = ['', '확인'];
        } else {
          rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
          rootState.serverError = true;
        }
      }
    },
    async getDetailedClientInfo({ commit, rootState }, payload) {
      try {
        var result = {};
        var configsMap = {};
        var idcardsMap = {};
        var modulesMap = {};
        var detailsMap = {};

        const clientDetailInfo = await server.getClientDetailInfo(payload);
        if (clientDetailInfo.detail.status === 0) {
          throw { errorCode: 'CF006' };
        } else if (clientDetailInfo.detail.status === 1) {
          throw { errorCode: 'CE007' };
        }

        let values = await Promise.all([
          server.getRootConfigs(payload),
          server.getClientModule(payload),
          server.getIdCardKind(payload),
          clientDetailInfo,
        ]);
        //Vue.$log.debug("root#modules~", modules);
        values[0].list.forEach(res => {
          configsMap[res.key] = res.value;
        });

        values[2].list.forEach(res => {
          idcardsMap[res.name] = res.active;
        });
        modulesMap = values[1].module;
        detailsMap = values[3].detail;

        // if (detailsMap.status === 0) {
        //   throw { errorCode: 'CF006' };
        // } else if (detailsMap.status === 1) {
        //   throw { errorCode: 'CE007' };
        // }

        //Vue.$log.debug("detailsMap", detailsMap);
        result['idcards'] = idcardsMap;
        result['configs'] = configsMap;
        result['module'] = modulesMap;
        result['detail'] = detailsMap;
        await commit('setDetailedClientInfo', { id: payload.id, data: result });
      } catch (e) {
        rootState.rootError = true;
        rootState.rootErrorCode = e.errorCode ? `${e.errorCode}` : e;
      }
    },
    async getClientConfigs({ commit, rootState }, payload) {
      try {
        const data = await server.getRootConfigs(payload);
        await commit('setClientConfigs', {
          configs: data.list,
          id: payload.id,
        });
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async checkRedundancy({ rootState }, payload) {
      try {
        const data = await server.readClientList(payload);
        var check = false; // false for NO redundancy
        data.map(res => {
          if (res.name == payload) check = true;
        });
        return check;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    // async registerAdmin({ rootState }, payload) {
    //   try {
    //     const data = await server.createAdminId(payload.id, payload.data);
    //     return data;
    //   } catch (e) {
    //     rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
    //     rootState.serverError = true;
    //   }
    // },

    async registerClient({ rootState }, payload) {
      try {
        await server.createClientList(payload);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async modifyClient({ rootState }, payload) {
      try {
        return await server.modifyClientList(payload);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async modifyClientModule({ rootState }, payload) {
      try {
        return await server.modifyClientModule(payload);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async modifyClientConfigs({ rootState }, payload) {
      try {
        return await server.setRootConfigs(payload);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
  },
  methods: {},
};

export default module;
