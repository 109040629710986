<template>
  <label :for="`${identifierPrefix}-${id}`" style="cursor: pointer">
    <input
      :id="`${identifierPrefix}-${id}`"
      type="checkbox"
      v-model="detail"
      class="detailed"
      @click="expandDetail(detail)"
    />
    <img
      style="vertical-align: middle"
      src="@/assets/open_32.svg"
      class="detail"
    />
  </label>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'DetailToggle',
  props: {
    id: {
      type: String,
      default: () => '0',
    },
    picaPp: {
      type: String,
      default: () => '',
    },
    identifierPrefix: {
      type: String,
      default: () => '',
    },
    version: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      detail: false,
    };
  },
  components: {},
  computed: {},
  methods: {
    expandDetail(event) {
      Vue.$log.debug(
        'DetailedToggle#expandDetail',
        this.id,
        !event,
        this.picaPp,
        this.version
      );
      this.$emit('expand', {
        id: this.id,
        value: !event,
        key: this.picaPp,
        version: this.version,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detailed {
  width: 0px;
  height: 0px;
  padding: 0px;
  margin: 0px;
}
.detailed ~ .detail {
  transition: 0.4s;
  transform: rotate(0deg);
}
.detailed:checked ~ .detail {
  transition: 0.4s;
  transform: rotate(180deg);
}
</style>
