import Vue from 'vue';
import Vuex from 'vuex';
import server from '@/api/server';
import settings from '@/store/settings';
import reviewer from '@/store/reviewer';
import root from '@/store/root';
import admin from '@/store/admin';
import isMobile from 'ismobilejs';
Vue.use(Vuex);
const device = isMobile(window.navigator);

export default new Vuex.Store({
  state: {
    isMobile: device.phone || device.tablet,
    serverError: false,
    rootError: false,
    rootErrorCode: '',
    errorCode: '',
    tncFlag: false,
    ppFlag: false,
    companyPhoneNumber: '',
    toastDialogFlag: false,
    toastColor: '',
    toastMessage: '',
    loading: {
      message: null,
      flag: false,
      desc: null,
    },
    customUIDialog: false,
    customUIIcon: require('@/assets/warning.svg'),
    customUIMessageTitle: [],
    customUIMessage: [],
    customUIerrorCode: '',
    customUIButton: ['', '확인'],
    idCardsFilter: [],
  },
  mutations: {
    setCompanyPhoneNumber(state, payload) {
      state.companyPhoneNumber = payload;
    },
    setIdCardsFilter(state, payload) {
      state.idCardsFilter = payload;
    },
    closeErrorDialog(state) {
      state.serverError = false;
      state.errorCode = '';
    },
    closeRootErrorDialog(state) {
      state.rootError = false;
      state.rootErrorCode = '';
    },
    closeCustomUIDialog(state) {
      state.customUIDialog = false;
      state.customUIerrorCode = '';
    },
    closeTncPpDialog(state) {
      Vue.$log.debug('index#closeTncPpDialog : work');
      state.tncFlag = false;
      state.ppFlag = false;
    },
    setToast(state, payload) {
      state.toastMessage = payload.message;
      if (payload.color) {
        state.toastColor = payload.color;
      } else {
        state.toastColor = 'var(--success-100)';
      }
      state.toastDialogFlag = true;
    },
    removeToast(state) {
      state.toastMessage = '';
      state.toastDialogFlag = false;
    },
    setLoading(state, payload) {
      var preventImmutable = {
        message: payload.message,
        flag: payload.flag,
        desc: payload.desc,
      };
      state.loading = preventImmutable;
    },
  },
  actions: {
    /*
     * payload.message {String}
     * payload.timeout {Number}
     */
    showCustomTimeoutSnackbar({ commit }, payload) {
      commit('setToast', payload);
      setTimeout(() => {
        commit('removeToast');
      }, payload.timeout || 5000);
    },
    showRegistrationSnackbar({ commit }, event) {
      Vue.$log.debug('index#showReg', event);
      commit('setToast', event);
      setTimeout(() => {
        commit('removeToast');
      }, 3000);
    },
    showLoadingScreen({ commit }, payload) {
      Vue.$log.debug('index#showLoading', payload);
      commit('setLoading', payload);
    },
    async checkCompName({ rootState }, payload) {
      const data = await server.getCompIdByName(payload);
      if (data?.api_response?.result_code == 'N100') {
        return { result: true, customer_id: data.customer_id };
      } else {
        if (data.errorCode == 'E532') {
          return { result: false, message: '등록되지 않은 고객사입니다.' };
        }
        if (data.errorCode == 'E531') {
          return { result: false, message: '' };
        }

        rootState.errorCode = data.errorCode ? `${data.errorCode}` : data;
        rootState.serverError = true;
      }
      Vue.$log.debug('에러확인', data.data, data.errorCode, data.message);
    },
    async checkIdRedundancy({ rootState }, payload) {
      try {
        return await server.checkId(payload);
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        //rootState.serverError = true;
      }
    },
    async checkEmailRedundancy({ rootState }, payload) {
      try {
        return await server.checkEmail(payload);
      } catch (e) {
        Vue.$log.debug('index.js#email', e);
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        //rootState.serverError = true;
      }
    },
    async registerReviewer({ rootState }, payload) {
      try {
        const data = await server.createReviewer(payload);
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
    async registerAdmin({ rootState }, payload) {
      try {
        const data = await server.createAdminId(payload.id, payload.data);
        return data;
      } catch (e) {
        rootState.errorCode = e.errorCode ? `${e.errorCode}` : e;
        rootState.serverError = true;
      }
    },
  },
  modules: {
    settings,
    reviewer,
    root,
    admin,
  },
});
