<template>
  <div
    class="container"
    style="padding: 0 30px 40px; flex: 1; display: flex; flex-direction: column;height:100%;"
  >
    <div class="card-result-title">계좌인증</div>
    <div class="openbank-spacer" style="padding-top: 100px" />
    <div class="body-container" style="text-align: center; margin-bottom: 0rem">
      <v-img
        max-height="181"
        max-width="234"
        style="margin: 0 auto"
        src="@/assets/verify_fail.svg"
        :transition="false"
      />
      <h2 class="bank-title">본인 인증 실패</h2>
      <div v-if="appData.errorType === 'tryout'">
        <label class="bank-label"
          >인증코드 입력 횟수가 초과되었습니다. <br />
          잠시 후 다시 시도해 주세요.
        </label>
        <div class="card-count warn">
          시도 횟수 : 3/3
        </div>
      </div>
      <div v-else>
        <label class="bank-label"
          >유효시간 만료로 인해 인증에 실패하였습니다.
        </label>
      </div>
    </div>
    <div class="button-container">
      <v-btn @click="onClickBack" block color="var(--primary-100)">
        종료
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    appData: Object,
  },
  data() {
    return {};
  },
  components: {},
  mounted() {
    console.log(this.appData.errorType);
  },
  methods: {
    onClickBack() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
.body-container {
  .bank-title {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 42px;

    color: var(--primary-100);
    margin-top: 30px;
    margin-bottom: 1.5rem;
  }
  .bank-label {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 26px;

    color: var(--surface-high);
  }
}
.card-result-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 32px;
  text-align: center;
}
.card-count {
  /* KR / Body 3 - 16 */

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: var(--surface-medium);
  margin-top: 8px;

  &.warn {
    color: var(--error-100);
  }
}

.button-container {
  margin-top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 0px 40px;
  /* height: 116px; */
  cursor: pointer;
  background-color: white;
  button {
    height: 60px !important;
    font-size: 1rem;
    border-radius: 8px;
    color: #fff;
    flex: 1;
  }
}
</style>
