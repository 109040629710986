/* global Module */
let worker = null;
let workerCallback = null;
const isWorker = !!window.Worker; // worker 사용이 가능할 경우
let isInit = false;
let ts = 0;
let forwardTimes = [];
let status = '';
let faceSDK = null;
let attributeExtension = null;
let antispoofingExtension = null;
const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

export async function initFaceApi({ liveness = false }) {
  if (!isInit) {
    isInit = true;
    console.info('face api loading... in worker');
    if (isWorker) {
      worker = new Worker('/face-sdk-impl-worker.js');
      worker.onmessage = e => {
        workerCallback && workerCallback(e.data);
      };
      worker.postMessage(['init', { liveness: liveness }]);
      return new Promise(resolve => {
        workerCallback = () => {
          faceSDK = true;
          resolve();
        };
      });
    } else {
      await new Promise(resolve => {
        let fileCount = 17;
        const completed = () => {
          if (fileCount === 0) {
            resolve();
          }
        };
        const onLoad = () => {
          fileCount--;
          completed();
        };
        //Detect
        Module.FS_createPreloadedFile(
          '/',
          '0.d',
          '/model/0.d',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.l',
          '/model/0.l',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.1v',
          '/model/0.1v',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.2v',
          '/model/0.2v',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.3v',
          '/model/0.3v',
          true,
          false,
          onLoad
        );

        //Feature
        Module.FS_createPreloadedFile(
          '/',
          '0.f',
          '/model/0.f',
          true,
          false,
          onLoad
        );

        //Attribute
        Module.FS_createPreloadedFile(
          '/',
          '0.e',
          '/model/0.e',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.g',
          '/model/0.g',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.m',
          '/model/0.m',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.1o',
          '/model/0.1o',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.2o',
          '/model/0.2o',
          true,
          false,
          onLoad
        );

        //Anti Spoofing
        Module.FS_createPreloadedFile(
          '/',
          '0.a',
          '/model/0.a',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.r',
          '/model/0.r',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.1n',
          '/model/0.1n',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.2n',
          '/model/0.2n',
          true,
          false,
          onLoad
        );
        Module.FS_createPreloadedFile(
          '/',
          '0.b',
          '/model/0.b',
          true,
          false,
          onLoad
        );

        //License
        Module.FS_createPreloadedFile(
          '/',
          'license.cer',
          '/model/license.cer',
          true,
          false,
          onLoad
        );
      });
      faceSDK = new Module.FaceSDK();
      const ret = faceSDK.Initialize('/', '');
      console.log('Face SDK Initialize result', ret.result);
      if (ret.last_error == Module.Error.NoError) {
        console.log('Face SDK Initialize Success');
      } else {
        console.log('Face SDK Initialize Failed', ret.last_error);
      }
      faceSDK.SetMaxDetectableCount(1);
      faceSDK.SetDetectableSize(10, -1);
      faceSDK.SetDetectingInterval(3);
      faceSDK.ResetTrackState();

      if (liveness) {
        console.log('liveness module enabled');
        antispoofingExtension = faceSDK.GetAntispoofingExtension();
        const antispoofRet = antispoofingExtension.Enable(true);
        if (antispoofRet.result) {
          console.log('antispoofingExtension enabled');
        } else {
          console.log('antispoofingExtension failed');
        }
      }

      attributeExtension = faceSDK.GetAttributeExtension();
      const attributeRet = attributeExtension.Enable(true);
      if (attributeRet.result) {
        console.log('Face SDK AttributeExtension enabled');
      } else {
        console.log('Face SDK AttributeExtension failed');
      }
    }
  }
}

export function destoryFaceApi() {
  if (isWorker && worker) {
    worker.postMessage('destroy');
    workerCallback = null;
    worker.terminate();
    worker = null;
    faceSDK = null;
  } else if (faceSDK) {
    if (attributeExtension) attributeExtension.delete();
    attributeExtension = null;
    const ret = faceSDK.Deinitialize();
    console.log(`Face SDK Deinitialized Result ${ret.result}`);
    faceSDK.delete();
    faceSDK = null;
  } else {
    console.log(`Face SDK Deinitialized`);
  }
}

export function isFaceDetectionModelLoadedSync() {
  return !!faceSDK;
}

export async function detectSingleFaceForCard(image, timestats = false) {
  if (timestats) {
    ts = Date.now();
  }
  const imageData = await getImageData(image);
  let result = null;
  if (isWorker && worker) {
    result = await new Promise(resolve => {
      workerCallback = data => resolve(data);
      worker.postMessage(['detectSingleFace', imageData]);
    });
  } else {
    const inputImage = makeInputImage(imageData);
    const faces = faceSDK.DetectFaceInSingleImage(inputImage);
    result = getResult(faces);
    inputImage.ReleaseImageData();
    inputImage.delete();
  }
  if (timestats) {
    updateTimeStats(ts);
  }
  return result;
}

export async function detectSingleFace(
  image,
  { timestats = false, liveness = false }
) {
  if (timestats) {
    ts = Date.now();
  }
  const imageData = await getImageData(image);
  let result = null;
  if (isWorker && worker) {
    result = await new Promise(resolve => {
      workerCallback = data => resolve(data);
      worker.postMessage(['detectSingleFaceContinuous', imageData, liveness]);
    });
  } else {
    const inputImage = makeInputImage(imageData);
    const faces = faceSDK.DetectFaceInContinuousImage(inputImage, { liveness });
    result = getResult(faces);
    inputImage.ReleaseImageData();
    inputImage.delete();
  }
  if (timestats) {
    updateTimeStats(ts);
  }
  return result;
}

function makeInputImage(imageData) {
  let bgrBuffer = rgba2rgb(imageData);
  const inputImage = new Module.InputImage(
    imageData.width,
    imageData.height,
    bgrBuffer
  );
  return inputImage;
}

function getResult(faces) {
  let result = null;
  for (let i = 0; i < faces.faces.length; ++i) {
    let face = faces.faces[i];
    let box = face.box;
    // let landmark = face.landmark.points;
    // let landmark_5pt = face.landmark_5pt.points;
    if (!result) {
      result = {};
    }
    result.detection = { box };
    // just one face detecting
    break;
  }
  return result;
}

async function getImageData(image) {
  const [width, height] = await getSize(image);
  if (canvas.width !== width) {
    canvas.width = width;
  }
  if (canvas.height !== height) {
    canvas.height = height;
  }
  context.drawImage(image, 0, 0);
  const imageData = context.getImageData(0, 0, width, height);
  return imageData;
}

async function getSize(image) {
  let width, height;
  if (image instanceof HTMLVideoElement) {
    width = image.videoWidth;
    height = image.videoHeight;
  } else {
    await new Promise(resolve => {
      image.onload = () => {
        width = image.width;
        height = image.height;
        resolve();
      };
    });
  }
  return [width, height];
}

export function drawCanvas(canvas, result, withLandmarks = false) {
  const box = result.detection.box;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.strokeStyle = 'blue';
  ctx.fillStyle = 'blue';
  ctx.lineWidth = 1;
  ctx.strokeRect(box.x, box.y, box.width, box.height);
  if (withLandmarks) {
    const landmark = result.detection.landmark;
    if (landmark) {
      ctx.strokeStyle = 'green';
      ctx.fillStyle = 'green';
      for (let i = 0; i < landmark.size(); ++i) {
        ctx.fillRect(landmark.get(i).x, landmark.get(i).y, 5, 5);
      }
    }

    const landmark_5pt = result.detection.landmark_5pt;
    if (landmark_5pt) {
      ctx.strokeStyle = 'red';
      ctx.fillStyle = 'red';
      for (let i = 0; i < landmark_5pt.size(); ++i) {
        ctx.fillRect(landmark_5pt.get(i).x, landmark_5pt.get(i).y, 5, 5);
      }
    }
  }
}

export function euclideanDistance(x1, y1, x2, y2) {
  const arr1 = [x1, y1];
  const arr2 = [x2, y2];
  if (arr1.length !== arr2.length)
    throw new Error('euclideanDistance: arr1.length !== arr2.length');

  const desc1 = Array.from(arr1);
  const desc2 = Array.from(arr2);

  return Math.sqrt(
    desc1
      .map((val, i) => val - desc2[i])
      .reduce((res, diff) => res + Math.pow(diff, 2), 0)
  );
}

function updateTimeStats() {
  const time = Date.now() - ts;
  forwardTimes = [time].concat(forwardTimes).slice(0, 30);
  const avgTimeInMs =
    forwardTimes.reduce((total, t) => total + t) / forwardTimes.length;
  status = `${Math.round(avgTimeInMs)} ms ${fps(1000 / avgTimeInMs)} fps`;
}

function fps(num, prec = 2) {
  const f = Math.pow(10, prec);
  return Math.floor(num * f) / f;
}

export function getTimeStats() {
  return status;
}

function rgba2rgb(srgb) {
  let buffer = new ArrayBuffer(3 * srgb.width * srgb.height);
  let bgr = new Uint8ClampedArray(buffer);

  let bgrIndex = 0;
  let srgbIndex = 0;

  for (; bgrIndex < bgr.length; bgrIndex += 3) {
    bgr[bgrIndex] = srgb.data[srgbIndex + 2];
    bgr[bgrIndex + 1] = srgb.data[srgbIndex + 1];
    bgr[bgrIndex + 2] = srgb.data[srgbIndex];

    srgbIndex += 4;
  }

  return bgr;
}
