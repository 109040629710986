<template>
  <div
    class="container"
    style="
      padding: 0 30px 40px;
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    "
  >
    <img
      @click="stopScan = true"
      src="@/assets/icon-close-32.svg"
      class="icon-close"
    />
    <div class="card-result-title">계좌인증</div>
    <div style="text-align: center; margin-bottom: 40px; margin-top: 56px;">
      <h2 class="bank-title">1원 송금을 받으실</h2>
      <h2 class="bank-title">은행 계좌를 선택하세요.</h2>
    </div>

    <div class="account-input-field">
      <v-bottom-sheet scrollable v-model="sheet" inset close-delay="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="bank-select--btn"
            class="bank-select"
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            @click="input1Validity = true"
            ref="bankSelectModal"
            :disabled="loading"
            style="
              width: 100%;
              height: 64px;
              
              display: flex;
              justify-content: flex-start;
              box-shadow: none;
              background: var(--surface-100);
              font-size: 1.25rem;
              line-height: 2rem;
              color: var(--surface-high);
              font-weight: 400;
            "
          >
            <v-img
              v-if="selectedItem !== -1"
              max-height="24px"
              max-width="24px"
              style="margin-right: 0.75rem"
              :transition="false"
              :src="require('@/assets/' + items[tab].items[selectedItem].img)"
            ></v-img>
            {{ bank }}
          </v-btn>
        </template>
        <v-sheet
          class="text-center bank-list-sheet"
          :height="sheetHeight"
          width="100%"
          ref="bankSheet"
        >
          <div
            style="padding:16px;"
            @touchmove="dragHandle"
            @touchstart="dragStart"
          >
            <div class="bottom-sheet-handle"></div>
          </div>
          <v-card style="box-shadow: none">
            <v-tabs
              slider-color="var(--primary-100)"
              colors="#fff"
              v-model="tab"
              align-with-title
              style="box-shadow: none"
            >
              <v-tabs-slider
                style="width: 100%; margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 32px);"
                color="var(--primary-100)"
              ></v-tabs-slider>

              <v-tab
                :ripple="false"
                class="bank-tab"
                style="color: var(--surface-medium);font-weight:400; "
                v-for="(item, idx) in items"
                :key="idx"
              >
                {{ item.title }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(item, idx) in items" :key="idx">
                <v-card flat ref="bankCard">
                  <ul
                    class="bank-list-container"
                    style="padding-left: 0px; "
                    ref="bankContainer"
                  >
                    <li
                      v-for="(bank, idx) in item.items"
                      :key="idx"
                      style="padding: 15px 34px"
                      class="bank-list-item"
                      @click="onClickItem(bank, idx)"
                    >
                      <v-img
                        max-height="36"
                        max-width="36"
                        width="36px"
                        height="36px"
                        style="margin-bottom: 8px"
                        :transition="false"
                        :src="require('@/assets/' + bank.img)"
                      />
                      <v-list-item-title v-text="bank.name"></v-list-item-title>
                    </li>
                  </ul>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-sheet>
      </v-bottom-sheet>
      <InputTextField
        v-model="sendData.account_num"
        :append="clearIconAccountNumber"
        type="number"
        @append="sendData.account_num = ''"
        @keyup="onKeyUp"
        :readonly="loading"
        :transform="rules.transformOnlyNumber"
        @focus="showClearIcon = true"
        @blur="showClearIcon = false"
        placeholder="계좌번호 입력(‘-’제외)"
        class="userinput-input"
        style="margin-top: 16px;"
      />
      <div style="margin-top: 32px;" v-if="!isFirst && sendData.account_num">
        <h2 class="bank-title" style="font-size: 18px; line-height: 24px;">
          입력하신 계좌의 예금주명이
        </h2>
        <h2
          class="bank-title"
          style="font-size: 18px; line-height: 24px; display:flex;align-items:center; margin: 10px 0px;"
        >
          <div class="modified-account" style="heght:24px">
            {{ sendData.account_holder_name }}
          </div>
          가(이) 맞나요?
        </h2>
        <div style="display:flex; justify-content:flex-start; flex-wrap: wrap;">
          <div class="account-radio-btn-container" style="margin-bottom:20px;">
            <input
              type="radio"
              :value="true"
              v-model="bank_account_consistent"
              name="account_holder"
              id="account-confirm"
            />
            <label for="account-confirm" style="margin-right:26px;">예</label>
            <input
              type="radio"
              :value="false"
              v-model="bank_account_consistent"
              name="account_holder"
              id="account-update"
            />
            <label for="account-update">아니오</label>
          </div>
        </div>
        <div v-if="mod_account_holder" class="modified-account">
          {{ `예금주 : ${mod_account_holder}` }}
        </div>
      </div>

      <div class="button-container" ref="btnContainer">
        <v-btn
          class="next"
          dark
          style="box-shadow: none;"
          :disabled="disabled"
          :color="disabled ? 'var(--primary-20)' : 'var(--primary-100)'"
          @click="send"
          :class="{ disabled: disabled, loading }"
        >
          <span v-if="!loading">1원 송금</span>
          <Loading v-else />
        </v-btn>
        <v-dialog
          v-model="dialog.open"
          width="500"
          @click:outside="input1Validity = false"
          max-width="384px"
        >
          <v-card>
            <v-card-text style="padding: 30px; text-align: center">
              <h2 class="modal-title" style="margin-bottom: 0.5rem">
                {{ dialog.title }}
              </h2>
              <p class="modal-contents">
                {{ dialog.contents1 }} <br />
                {{ dialog.contents2 }}
              </p>
              <div class="button-container modal-btn">
                <div class="button ok" @click="onCloseModal">
                  {{ dialog.btnText }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <ExitDialog v-model="stopScan" @ok="onClickBack"></ExitDialog>
    <AccountHolderDialog
      :sendData="sendData"
      :bankIdx="selectedItem"
      :tabIdx="tab"
      v-model="account_holder_dialog"
      @next="onClickHolderChange"
      @cancel="bank_account_consistent = true"
      @error="dialogError"
    ></AccountHolderDialog>
  </div>
</template>
<script>
import CONSTANTS from './constants';
import useb from '@/api/useb';
// import server from '@/api/server'
import AccountHolderDialog from './dialog/AccountHolderDialog';
import ExitDialog from './dialog/ExitDialog';
import InputTextField from '../auth/InputTextField.vue';
import rules from '../rules';
import Loading from './Loading.vue';

export default {
  name: 'BankSelect',
  props: ['phase', 'phaseItems', 'selectBank', 'appData', 'industry'],
  components: {
    ExitDialog,
    InputTextField,
    Loading,
    AccountHolderDialog,
  },
  data() {
    return {
      rules,
      stopScan: false,
      iconClear: require('@/assets/delete_24.svg'),
      showClearIcon: false,
      disabled: true,
      dialog: {
        title: '시스템 오류',
        contents1:
          '시스템 오류로 계좌 확인에 실패하였습니다. 잠시 후 재시도 해주세요.',
        contents2: '잠시 후 재시도 해주세요.',
        btnText: '확인',
        open: false,
      },
      currentStep: 1,
      sheet: false,
      bank: '은행/증권사 선택',
      bankList: CONSTANTS.BANK_NAME,

      selectedItem: -1,
      tab: null,
      items: [
        { title: '은행', name: 'bank', items: CONSTANTS.BANK_NAME },
        { name: 'brokerage', title: '증권사', items: CONSTANTS.BROKERAGE },
      ],
      sendData: {
        bank_code: '',
        account_num: '',
        account_holder_name: '',
        account_modified: 0,
      },
      bank_account_consistent: undefined,
      account_holder_dialog: false,
      mod_account_holder: '',
      loader: null,
      loading: false,

      input1Validity: false,
      input2Validity: false,
      scrollTop: 0,
      sheetHeight: 500,
      isFirst: true, // 첫번재 시도에는 보여주지 않음
      touchStart: null,
      eventAdded: false,
    };
  },

  created() {
    this.initialize();
  },
  mounted() {
    this.initialize();
    this.sheet = this.selectBank;

    // 오픈뱅크 제외시 펌뱅킹 제외
    if ([1, 2].includes(this.industry)) {
      this.items = this.items.map(type => {
        console.log(type);
        return {
          ...type,
          items: type.items.filter(item => {
            return item.bank_support !== 'firmbank';
          }),
        };
      });
    }

    // 펌뱅크 진입 시 오픈뱅크 제외
    if ([3, 4].includes(this.industry)) {
      this.items = this.items.map(type => {
        return {
          ...type,
          items: type.items.filter(item => {
            return item.bank_support !== 'openbank';
          }),
        };
      });
    }
  },
  computed: {
    clearIconAccountNumber() {
      return this.showClearIcon && this.sendData.account_num
        ? this.iconClear
        : '';
    },
  },
  watch: {
    sendData: {
      deep: true,
      handler(val) {
        if (val.bank_code) {
          this.input1Validity = false;
        }
        if (
          val.account_num.length >= 7 &&
          val.bank_code &&
          (this.isFirst ||
            this.bank_account_consistent ||
            this.mod_account_holder)
        ) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
    },
    bank_account_consistent: function(newBool) {
      if (newBool === false) {
        console.log(this.sendData);
        this.$set(this.sendData, 'account_holder', this.appData.userName);
        this.account_holder_dialog = true;
      } else {
        this.mod_account_holder = '';
      }
      if (
        this.sendData.account_num &&
        this.sendData.bank_code &&
        (newBool || this.mod_account_holder)
      ) {
        this.disabled = false;
      }
    },
    sheet: {
      handler: function(newVal) {
        // dont know how to enable scroll
        if (newVal && !this.eventAdded) {
          this.$nextTick(() => {
            // const vWindow = document.querySelector('.v-window')
            // vWindow.addEventListener('scroll', this.sheetScroll)
            this.eventAdded = true;
          });
        }
      },
    },
  },

  methods: {
    onKeyUp(e) {
      if (e.key === 'Enter') {
        this.send();
      }
    },
    dragHandle(e) {
      console.log(e.targetTouches[0].screenY);
    },
    dragStart(e) {
      this.touchStart = e.targetTouches[0].screenY;
      console.log('touchstart', this.touchStart);
    },

    sheetScroll() {
      const vWindow = document.querySelector('.v-window');

      if (this.scrollTop < vWindow.scrollTop) {
        this.sheetHeight = 800;
      } else {
        this.sheetHeight = 500;
      }
      this.scrollTop = vWindow.scrollTop;
    },
    initialize() {
      this.$set(this.sendData, 'account_holder_name', this.appData.userName);
    },

    onClickItem(item, idx) {
      this.bank = item.name;
      this.sheet = false;
      this.selectedItem = idx;
      this.input1Validity = false;
      this.$set(
        this.sendData,
        'bank_code',
        this.items[this.tab].items[idx].bank_code
      );
      this.$set(
        this.sendData,
        'finance_company',
        this.items[this.tab].items[idx].fullName
      );
    },
    onClickBack() {
      this.$emit('cancel');
    },
    onClickHolderChange(event) {
      this.$emit('next', event);
    },
    onCloseModal() {
      this.$set(this.dialog, 'open', false);
    },
    onConfirm() {
      this.dialog = !this.dialog;
      this.loading = true;
    },
    insertBankAccount() {
      this.$set(this.sendData, 'account_num', '');
    },
    getFinanceCompanyName() {
      const name = this.items[this.tab].items[this.selectedItem].name;
      if (this.tab == 1) {
        if (!name.includes('증권')) {
          return name + '증권';
        }
      } else {
        if (!name.includes('은행') && !name.includes('뱅크')) {
          return name + '은행';
        }
      }
      return name;
    },
    async send() {
      try {
        let sendData = this.sendData;
        this.loading = true;
        this.disabled = true;
        this.loader = this.loading;

        if (this.isFirst) {
          sendData = {
            ...sendData,
            account_holder_name: this.appData.userName,
          };
        }
        if (sendData.account_modified) {
          if (this.appData.userName === sendData.account_holder_name) {
            sendData = {
              ...sendData,
              account_modified: 0,
            };
          } else {
            sendData = {
              ...sendData,
              account_modified: 1,
            };
          }
        }

        const res = await useb.sendOpenbank(sendData, this.industry);

        const useb_token = this.industry < 3 ? res.transaction_id : res.tid;

        this.loading = false;
        this.disabled = false;
        this.$emit('next', {
          finance_company: this.items[this.tab].items[this.selectedItem]
            .fullName,
          account_num: this.sendData.account_num,
          bank_code: this.sendData.bank_code,
          transaction_id_useb: useb_token,
          bankIdx: this.selectedItem,
          tabIdx: this.tab,
          account_holder: this.isFirst && this.appData.userName,
          account_modified: this.sendData.account_modified,
          mod_account_holder: this.sendData.account_modified
            ? this.sendData.account_holder_name
            : '',
        });
      } catch (err) {
        this.isFirst = false;
        this.loading = false;
        this.disabled = true;
        const { errorCode } = err;
        // TODO 고정 param 에서 slot 으로 변경 필요
        // TODO 리팩토링 필요
        // TODO 펌뱅킹일 때 useb api 에서 리스폰스가 예금주 오류와 계좌정보 오류가 반대로 나옴.
        // TODO api 변경 시 펌뱅킹에 대한 에러 분기처리 반영 예정
        if (errorCode === 'S013') {
          this.$set(this.dialog, 'title', '송금 실패');
          this.$set(
            this.dialog,
            'contents1',
            '은행 점검 시간으로 인해 송금이 불가하여\n 고객 신원 확인을 종료합니다.'
          );
          this.$set(
            this.dialog,
            'contents2',
            '서비스 가능 시간 확인 후 재시도 해주세요.'
          );
          this.$set(this.dialog, 'btnText', '확인');
          this.$set(this.dialog, 'open', true);
        } else if (errorCode === 'S011' || errorCode === 'S012') {
          // TODO 정해주는 문구로 변경 필요
          this.$set(this.dialog, 'title', '계좌 검증 실패');
          this.$set(
            this.dialog,
            'contents1',
            '입력하신 계좌가 해당 은행에 존재하지 않거나'
          );
          this.$set(this.dialog, 'contents2', '예금주명이 일치하지 않습니다.');
          this.$set(this.dialog, 'btnText', '재시도');
          this.$set(this.dialog, 'open', true);
        } else {
          this.$set(this.dialog, 'title', '시스템 오류');
          this.$set(
            this.dialog,
            'contents1',
            '시스템 오류로 1원 송금에 실패하였습니다.'
          );
          this.$set(this.dialog, 'contents2', '잠시 후 재시도 해주세요.');
          this.$set(this.dialog, 'btnText', '확인');
          this.$set(this.dialog, 'open', true);
        }
      }
    },
    dialogError(e) {
      this.account_holder_dialog = false;
      const { errorCode, account_holder_name } = e;
      console.log(account_holder_name);
      this.$set(this.sendData, 'account_holder_name', account_holder_name);
      this.$set(this.sendData, 'account_modified', 1);
      this.bank_account_consistent = true;

      if (errorCode === 'S013') {
        this.$set(this.dialog, 'title', '송금 실패');
        this.$set(
          this.dialog,
          'contents1',
          '은행 점검 시간으로 인해 송금이 불가하여\n 고객 신원 확인을 종료합니다.'
        );
        this.$set(
          this.dialog,
          'contents2',
          '서비스 가능 시간 확인 후 재시도 해주세요.'
        );
        this.$set(this.dialog, 'btnText', '확인');
        this.$set(this.dialog, 'open', true);
      } else if (errorCode === 'S011' || errorCode === 'S012') {
        // TODO 정해주는 문구로 변경 필요
        this.$set(this.dialog, 'title', '계좌 검증 실패');
        this.$set(
          this.dialog,
          'contents1',
          '입력하신 계좌가 해당 은행에 존재하지 않거나'
        );
        this.$set(this.dialog, 'contents2', '예금주명이 일치하지 않습니다.');
        this.$set(this.dialog, 'btnText', '재시도');
        this.$set(this.dialog, 'open', true);
      } else {
        this.$set(this.dialog, 'title', '시스템 오류');
        this.$set(
          this.dialog,
          'contents1',
          '시스템 오류로 1원 송금에 실패하였습니다.'
        );
        this.$set(this.dialog, 'contents2', '잠시 후 재시도 해주세요.');
        this.$set(this.dialog, 'btnText', '확인');
        this.$set(this.dialog, 'open', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 800px) {
  .container {
    width: 800px !important;
    margin: auto !important;
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  opacity: 1;
}
.bank-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;

  color: var(--surface-high);
}

.account-input-field {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
}
#bank-select--btn.bank-select.v-btn--disabled {
  background-color: var(--gray-100) !important;
  // border: 1px solid var(--surface-medium);
  // background-color: var(--surface-100) !important;
  border: 1px solid var(--surface-disabled);
  color: var(--surface-medium) !important;

  .v-btn__content {
    position: absolute;
    color: var(--surface-medium);
    color: #868686;
  }
}

.bank-select.focus {
  border-color: var(--primary-80);
}
.theme--light.bank-select.v-btn:focus::before {
  opacity: 0;
}
.bank-select:focus {
}

.v-btn__content {
  font-size: 1.25rem;
  letter-spacing: initial;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  line-height: 2rem;
  color: var(--surface-high);
}

.bank-select,
bank-account-input {
  background: var(--surface-100);
  background-color: var(--surface-100);
  color: var(--surface-high);
  height: 64px;

  /* Primary/80% */

  border: 1px solid var(--gray-300);
  /* 2 dp, Primary */

  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
  border-radius: 8px;
}
.bank-account-input.focus {
  .v-input__slot.focus {
    border: 1px solid var(--primary-100) !important;
  }
}
.v-window.v-item-group.v-tabs-items {
  padding-top: 20px;
  height: 420px;
  // height: 800px;
  overflow-y: scroll;
  overflow: scroll-y;
  -webkit-overflow-scrolling: touch;
}
.v-text-field--outlined fieldset {
  border: 1px solid var(--gray-300) !important;
}
.v-text-field--outlined.focus fieldset {
  border: 1px solid var(--primary-80) !important;
}
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1px solid var(--primary-80) !important;
}
.v-input__slot {
  margin-top: 1rem;
  /* border: 1px solid var(--gray-300); */
  border-radius: 8px;
  box-shadow: none !important;
}
.bank-list-sheet {
  /* padding-left: 1rem;
  padding-right: 1rem; */
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  transition: height 1000ms;

  transition-delay: 0ms;
}

.v-tabs .bank-tab {
  width: 50%;
  letter-spacing: 0;
  font-size: 1.25rem;
  margin-left: 0 !important;
  max-width: none;
  border-bottom: 2px solid var(--gray-100);
  &::before {
    display: none;
  }
  &:focus {
    background: #fff;
  }
  &:hover {
    background: #fff;
  }
}

.v-tab--active {
  color: var(--primary-100) !important;
}

.bank-list-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px !important;
}
.bank-list-item {
  display: flex;
  flex-direction: column;
  width: 104px;
  height: 104px;
  align-items: center;
  width: 25%;
  .v-list-item__title {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: var(--surface-high);
  }
}
.bottom-sheet-handle {
  height: 4px;
  width: 60px;
  border-radius: 2px;
  background: #c4c4c4;
  margin: 0 auto;
}

.account-radio-btn-container {
  display: flex;
  align-items: center;
  input {
    appearance: none;
    border: 1.5px solid var(--surface-high);
    border-radius: 50%;
    box-sizing: border-box;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:checked {
      appearance: none;
    }
    &:checked::before {
      content: '';
      display: block;
      appearance: none;
      width: 0.75rem;
      height: 0.75rem;

      background-color: var(--primary-100);
      border-radius: 50%;
      box-sizing: border-box;
    }
  }
  label {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    display: inline-block;
    line-height: 1.5rem;
    color: var(--surface-high);
    &:first-child {
      margin-right: 26px;
    }
  }
}

.modified-account {
  height: 24px;
  background: var(--gray-100);
  border-radius: 4px;
  padding: 0px 10px;
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 4px;
}

/* Modal Css */
.modal-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--surface-high);
}
.v-card .v-card__text .modal-contents {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 26px;
  color: var(--surface-high);
}
// .modal-btn {
//   padding: 15px 16px;
//   height: 60px !important;
//   color: #fff !important;
//   background: var(--primary-100);
//   font-size: 1rem;
//   border-radius: 8px;
//   span {
//     font-size: 1rem;
//   }
// }
.v-btn__loader {
  color: #fff;
  background: var(--primary-100);
  border-radius: 8px;
}
.v-input__slot {
  height: 64px;
}
div .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--primary-20) !important;
  /* background-color: rgba(215, 216, 255, 1) !important; */
  color: var(--surface-100) !important;
}

.button-container {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0px 40px;
  /* height: 116px; */
  cursor: pointer;
  background-color: white;
  button {
    height: 60px !important;
    font-size: 1rem;
    border-radius: 8px;
    color: #fff;
    flex: 1;
  }
  button.disabled {
    background: var(--primary-20);
  }
  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading {
    background-color: var(--primary-100) !important;
  }
}
.button-container.modal-btn {
  display: flex;
  margin-bottom: 0px;
  padding: 0 0px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;
    &.disabled {
      // background: var(--primary-20) !important;
    }

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
