export default Object.freeze({
  TNC: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'created_at', sortable: true, width: 35 },
      { id: 'version', sortable: true, width: 50 },
    ],
    NAME: ['', '등록일', '버전'],
  },
  PP: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'created_at', sortable: true, width: 35 },
      { id: 'version', sortable: true, width: 50 },
    ],
    NAME: ['', '등록일', '버전'],
  },
  PICA: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'key', sortable: false, width: 35 },
      { id: 'blank', sortable: false, width: 50 },
    ],
    NAME: ['', '약관 Key', ''],
  },
  PPG: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'key', sortable: false, width: 35 },
      { id: 'blank', sortable: false, width: 50 },
    ],
    NAME: ['', '약관 Key', ''],
  },
  TNC_GUIDANCE_ADMIN: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'title', sortable: false, width: 35 },
      { id: 'blank', sortable: false, width: 50 },
    ],
    NAME: ['', '사용기능'],
  },
  EP: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'timestamp', sortable: true, width: 22.5 },
      { id: 'name', sortable: true, width: 22.5 },
      { id: 'phone_number', sortable: true, width: 20 },
      { id: 'birthdate', sortable: true, width: 15 },
    ],
    NAME: ['', '제출일시', '성명', '전화번호', '생년월일'],
  },
  EC: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'timestamp', sortable: true, width: 25 },
      { id: 'name', sortable: true, width: 17.5 },
      { id: 'phone_number', sortable: true, width: 17.5 },
      { id: 'birthdate', sortable: true, width: 12.5 },
      { id: 'inspect_status', sortable: true, width: 12.5 },
    ],
    NAME: ['', '제출일시', '성명', '전화번호', '생년월일', '심사구분'],
  },
  CM: {
    SIZE: [
      { id: 'id', sortable: false, width: 12.5 },
      { id: 'company', sortable: false, width: 17.5 },
      { id: 'phone', sortable: false, width: 15 },
      { id: 'kind', sortable: false, width: 15 },
      { id: 'purchase_type', sortable: false, width: 15 },
      { id: 'status', sortable: false, width: 10 },
    ],
    NAME: ['아이디', '고객사명', '전화번호', '구분', '구매형태', '활성여부'],
  },
  AM: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'admin_id', sortable: false, width: 27.5 },
      { id: 'admin_name', sortable: false, width: 12.5 },
      { id: 'admin_phone', sortable: false, width: 20 },
      { id: 'admin_mail', sortable: false, width: 22.5 },
      { id: 'activation', sortable: false, width: 17.5 },
    ],
    NAME: ['', '아이디', '이름', '전화번호', '이메일', '활성여부'],
  },
  SET: {
    SIZE: [
      { id: 'id', sortable: false, width: 0 },
      { id: 'timestamp', sortable: false, width: 32.5 },
      { id: 'username', sortable: false, width: 20 },
      { id: 'name', sortable: false, width: 20 },
      { id: 'threshold_old', sortable: false, width: 10 },
      { id: 'threshold_new', sortable: false, width: 10 },
      { id: '', sortable: false, width: 7.5 },
    ],
    NAME: ['', '변경일시', '아이디', '이름', '변경 전', '변경 후'],
  },
  RM: {
    SIZE: [
      { id: '', sortable: false, width: 0 },
      { id: 'reviewer_id', sortable: true, width: 22.5 },
      { id: 'reviewer_name', sortable: true, width: 15 },
      { id: 'reviewer_phone', sortable: true, width: 20 },
      { id: 'reviewer_mail', sortable: true, width: 22.5 },
      { id: 'activation', sortable: false, width: 5 },
      { id: 'is_approve', sortable: false, width: 15 },
    ],
    NAME: ['', '아이디', '이름', '전화번호', '이메일', '활성여부', ''],
  },
});
