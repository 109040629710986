<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="value" :color="toastColor" width="400px" height="60px">
      <div
        style="
          padding-left: 8px;
          font-size: 16px;
          font-weight:500;
          color: var(--surface-100);
          line-height: 24px;
        "
      >
        {{ passedText }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="$emit('input', false)">
          <img src="@/assets/cancel-24.svg" alt="close the dialog" />
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: () => {},
    },
    passedText: {
      type: String,
      default: () => {},
    },
    toastColor: {
      type: String,
      default: () => 'var(--success-100)',
    },
  },
  data: () => ({}),
  methods: {
    open() {},
    close() {},
  },
};
</script>

<style lang="scss" scoped></style>
