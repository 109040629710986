<template>
  <v-dialog
    v-model="dialog"
    style="width: calc(100% - 96px)"
    width="calc(100% - 96px)"
    max-width="384px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text
        style="
          display: flex;
          flex-direction: column;
          padding: 0px 30px;
          padding-top: 2rem;
          margin: 0;
          text-align: center;
        "
      >
        <img
          @click="stopScan = true"
          src="@/assets/warning.svg"
          class="icon-close"
          style="
            width: 60px;
            height: 60px;
            margin: 0px auto 18px;
            display: none;
          "
        />
        <div class="card-title">시스템 오류</div>
        <div class="card-body">
          시스템 오류로 인증에 실패하였습니다.<br />잠시 후 재시도 해주세요.<br />{{
            errorCode ? `에러 코드: ${errorCode}` : ''
          }}
        </div>

        <div class="button-container" style="margin-bottom: 32px">
          <div @click="cancel" class="button ok">확인</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errorCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    cancel() {
      this.close();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  color: var(--surface-high);
}

.card-body {
  /* KR / Body 3 - 16 */

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 26px;

  color: var(--surface-high);
  margin: 8px 0px 32px;
}
.button-container {
  display: flex;
  margin-bottom: 39px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
