<template>
  <div>
    <div v-if="!editMode">
      <div class="admin-count-set">관리자 수 설정</div>
      <div style="display: flex; margin-top: 10px">
        <div class="admin-count-key">· 어드민</div>
        <div class="admin-count-value">{{ initialCount[0] }}명</div>
      </div>
      <div style="display: flex">
        <div class="admin-count-key">· 심사자</div>
        <div class="admin-count-value">{{ initialCount[1] }}명</div>
      </div>
    </div>
    <div v-else>
      <div class="admin-count-set">관리자 수 설정</div>
      <div style="display: flex; margin-top: 10px">
        <div class="admin-count-key">· 어드민</div>
        <div style="position: relative; width: 72px">
          <input
            ref="adminCount"
            class="admin-count-edit"
            v-model="initialCount[0]"
            type="number"
            style="text-align: center"
            @change="$event => modifyCount('admin', $event.target.value)"
          />
          <img
            class="admin-count-button-up"
            src="@/assets/number_up.svg"
            alt=""
            @click="modifyCount('admin', initialCount[0] + 1)"
          />
          <img
            class="admin-count-button-down"
            src="@/assets/number_down.svg"
            alt=""
            @click="modifyCount('admin', initialCount[0] - 1)"
          />
        </div>
        <div class="admin-count-key">명</div>
      </div>
      <div style="display: flex">
        <div class="admin-count-key">· 심사자</div>
        <div style="position: relative; width: 72px">
          <input
            ref="adminCount"
            class="admin-count-edit"
            v-model="initialCount[1]"
            type="number"
            style="text-align: center"
            @change="modifyCount('reviewer', $event.target.value)"
          />
          <img
            class="admin-count-button-up"
            src="@/assets/number_up.svg"
            alt=""
            @click="modifyCount('reviewer', initialCount[1] + 1)"
          />
          <img
            class="admin-count-button-down"
            src="@/assets/number_down.svg"
            alt=""
            @click="modifyCount('reviewer', initialCount[1] - 1)"
          />
        </div>
        <div class="admin-count-key">명</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editMode: {
      type: Boolean,
      default: () => false,
    },
    initialCount: {
      type: Array,
      default: () => {
        return [-1, -1];
      },
    },
  },
  components: {},
  created() {},
  data() {
    return {};
  },
  methods: {
    modifyCount(auth, value) {
      this.$emit('count', { auth: auth, value: Number(value) });
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-count {
  &-set {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--surface-high);
  }
  &-value {
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-80);
  }
  &-edit {
    position: absolute;
    width: 56px;
    height: 24px;
    margin: 0 8px;
    -moz-appearance: textfield;
    border: 1px solid var(--gray-200);
    border-radius: 4px;
  }
  &-edit:focus {
    outline: none;
  }
  &-button-up {
    position: absolute;
    top: 3px;
    right: 11px;
  }
  &-button-down {
    position: absolute;
    top: 13px;
    right: 11px;
  }
  display: flex;
}
.admin-count-edit::-webkit-inner-spin-button,
.admin-count-edit::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
