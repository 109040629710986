<template>
  <v-dialog
    v-model="value"
    max-width="700px"
    max-height="500px"
    @click:outside="exit"
    @keydown.esc="exit"
    persistent
  >
    <v-card style="border-radius: 8px">
      <v-card-text
        style="
          display: flex;
          flex-direction: column;
          padding: 0;
          max-height: 85vh;
          overflow-y: scroll;
        "
      >
        <section style="margin: 40px">
          <section
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: 24px;
            "
          >
            <div
              style="font-size: 24px; line-height: 36px; color: var(--surface-high)"
            >
              {{
                type == 1 ? '이용약관' : type == 2 ? '개인정보 처리방침' : ''
              }}
            </div>
            <div @click="exit"><img src="@/assets/cancel_32.svg" alt="" /></div>
          </section>
          <section style="overflow-y: scroll; height: 400px">
            <section
              v-show="type == 2"
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 15px;
              "
            >
              <div
                style="font-size: 16px; line-height: 24px; color: var(--surface-high)"
              >
                {{ '㈜유스비 개인정보 처리방침' }}
              </div>
              <div>
                <Dropdown
                  width="206px"
                  style="font-size: 14px"
                  v-model="selectedText"
                  @change="getPpHtml($event)"
                  :items="selectablePpVersion"
                />
              </div>
            </section>
            <section>
              <div v-show="type == 1" v-html="tncHtml" />
              <div v-show="type == 2" v-html="ppHtml" />
            </section>
          </section>
        </section>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue';
import server from '../../api/server';
export default {
  components: {
    Dropdown,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
      //0 : undefined
      //1 : 이용약관
      //2 : 개인정보 처리방침
    },
    selectablePps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    initPpParagraph: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
  data() {
    return {
      selectedText: '',
      tncHtml: ``,
      ppHtml: ``,
    };
  },
  async created() {
    switch (this.type) {
      case 2:
        if (this.ppFlag) {
          await this.getPpHtml('변경 전 개인정보처리방침');
        }
        break;
      case 1:
        if (this.tncFlag) {
          await this.getTncHtml();
        }
        break;
      default:
        break;
    }
  },
  computed: {
    selectablePpVersion() {
      return Object.keys(this.selectablePps);
    },
    ppFlag() {
      return this.$store.state.ppFlag;
    },
    tncFlag() {
      return this.$store.state.tncFlag;
    },
  },
  methods: {
    async getPpHtml(item) {
      this.$log.debug(
        'tncDialog#getPpHtml~item',
        item,
        this.selectablePps[item]
      );
      const data = await server.readNoticeDetail(
        'pp',
        this.selectablePps[item]
      );
      this.$log.debug('tncDialog#getPpHtml~api data', data);
      this.ppHtml = decodeURIComponent(atob(data.content));
    },
    async getTncHtml() {
      const list = await server.readNoticeList('tnc');
      const data = await server.readNoticeDetail('tnc', list.latest_version);
      this.$log.debug('tncDialog#getTncHtml~api data', data);
      this.tncHtml = decodeURIComponent(atob(data.content));
    },
    exit() {
      this.$emit('exit');
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border: solid 3px transparent;
  box-shadow: inset 0 0 10px 10px var(--gray-300);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--gray-300);
}
</style>
