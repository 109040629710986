<template>
  <div class="card-result-fail-root">
    <div
      style="
      margin-top:32px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--surface-high);"
    >
      얼굴 인증
    </div>
    <div class="card-result-fail-info-container">
      <img
        src="@/assets/일러스트_얼굴인증실패.svg"
        width="184px"
        style="margin-bottom:57px;margin-right: 22px;"
      />
      <div
        style="
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: var(--primary-100);
        margin-bottom:24px;"
      >
        얼굴 인증 실패
      </div>
      <div
        style="
        font-weight: normal;
        text-align:center;
        font-size: 16px;
        line-height: 26px;
        color: var(--surface-high);
        margin-bottom:8px;"
      >
        얼굴 위변조 여부 판정 결과<br />
        실제 얼굴 여부 인증에 실패하였습니다.
      </div>
      <div
        v-if="errorCode"
        style="
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: var(--surface-medium);
        "
      >
        에러코드 : {{ errorCode }}
      </div>
    </div>
    <div @click="$emit('ok')" class="button-container">종료</div>
  </div>
</template>

<script>
export default {
  props: {
    errorCode: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: white;
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>
