<template>
  <div>
    <div class="signin-title">
      <img src="@/assets/logo.svg" alt="ekyc main logo" />
    </div>
    <div class="signin-form default flex-center">
      <div v-if="type == 10 || type == 12">
        <div
          v-html="content[type][5].replace(/(?:\r\n|\r|\n)/g, '<br />')"
          style="text-align: center; font-size: 18px; margin-top: 48px"
        ></div>
        <div
          v-html="content[type][4].replace(/(?:\r\n|\r|\n)/g, '<br />')"
          class="second-content"
        ></div>
        <div class="account-box flex-center">
          <div
            v-html="content[type][3].replace(/(?:\r\n|\r|\n)/g, '<br />')"
            style="text-align: center"
            :class="{ 'primary-color': type == 10 }"
          ></div>
        </div>

        <div class="notification">
          <div style="margin-right: 4px">
            <img src="@/assets/info_20.svg" alt="info" />
          </div>
          <div
            class="notification-content"
            v-html="content[type][2].replace(/(?:\r\n|\r|\n)/g, '<br />')"
          ></div>
        </div>
      </div>
      <div v-if="type == 11 || type == 13">
        <div
          v-html="content[type][5].replace(/(?:\r\n|\r|\n)/g, '<br />')"
          style="text-align: center; font-size: 18px; margin-top: 48px"
        ></div>
        <div class="second-content">
          <img :src="content[type][2]" alt="Caution" />
        </div>
        <div style="text-align: center; margin: 32px 0 12px 0">
          <div
            style="color: var(--error-100)"
            v-html="content[type][3].replace(/(?:\r\n|\r|\n)/g, '<br />')"
          ></div>
          <div
            style="margin-top: 8px; color: var(--surface-medium)"
            v-html="content[type][4].replace(/(?:\r\n|\r|\n)/g, '<br />')"
          ></div>
        </div>
      </div>
      <div style="display: flex; width: 400px; padding: 38px">
        <SubmitButton
          width="124px"
          height="60px"
          font-size="16px"
          :button-string="content[type][0]"
          :button-loading="isLoadingFlag"
          bg-color="#F6F6F6"
          text-color="var(--surface-medium)"
          @click="prev()"
          :key="`${isLoadingFlag}-Button`"
        />
        <div style="width: 9px" />
        <SubmitButton
          width="191px"
          height="60px"
          font-size="16px"
          :button-string="content[type][1]"
          bg-dim-color="var(--primary-20)"
          bg-color="var(--primary-100)"
          text-dim-color="var(--surface-100)"
          text-color="var(--surface-100)"
          @click="next()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SubmitButton from '@/components/SubmitButton.vue';
import server from '@/api/server';
import CONSTANTS from '@/constants';
import collision from '@/store/mixins/collision.js';
export default {
  mixins: [collision],
  name: 'ResultCridential',
  props: {
    type: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    payload: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  components: {
    SubmitButton,
  },
  data() {
    return {
      isLoadingFlag: false,
      content: {
        // 하단 좌측부터 상단 우측 방향으로 컨텐츠 나열
        10: [
          '비밀번호 찾기',
          '로그인',
          '정보 보호를 위해 아이디의 일부는 *로 표기됩니다.\n전체아이디는 고객센터로 문의해 주세요.',
          this.payload.userid,
          '입력하신 정보로 조회된 아이디는\n아래와 같습니다.',
          '아이디 찾기 결과',
        ],
        11: [
          '이전으로',
          '재시도',
          require('@/assets/caution_60px.svg'),
          '일치하는 정보가 없습니다.',
          '입력하신 정보로 조회된 내역이 없습니다.\n가입 여부를 다시 한 번 확인해 주세요.',
          '아이디 찾기 결과',
        ],
        12: [
          '재발송',
          '로그인',
          '메일이 보이지 않는다면 스팸메일함을 확인하거나\n재발송 또는 고객센터로 문의해 주세요.',
          this.payload.userEmail,
          '비밀번호 재설정 메일을 발송하였습니다.\n아래 이메일 계정의 메일함을 확인해 주세요.',
          '비밀번호 찾기 안내',
        ],
        13: [
          '이전으로',
          '재시도',
          require('@/assets/caution_60px.svg'),
          '일치하는 정보가 없습니다.',
          '입력하신 정보로 조회된 내역이 없습니다.\n가입 여부를 다시 한 번 확인해 주세요.',
          '비밀번호 찾기 결과',
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.otherComponent = document.querySelector('.signin-form');
    this.isCollide();
  },
  updated() {
    this.otherComponent = document.querySelector('.signin-form');
    this.isCollide();
  },
  computed: {},
  methods: {
    async prev() {
      switch (this.type) {
        case 10:
          this.$router.replace({
            name: CONSTANTS.ROUTE_NAME.FIND_ACCOUNT,
            query: {
              type: 1,
              role: this.$route.query.role,
            },
          });
          this.$router.go(0);
          break;
        case 12:
          //재발송
          try {
            this.isLoadingFlag = true;
            await server
              .findReviewerPassword({
                customer_id: Number(this.payload.customerId),
                name: this.payload.userName,
                email: this.payload.userEmail,
                username: this.payload.userId,
              })
              .then(() => {
                this.isLoadingFlag = false;
              });
          } catch (e) {
            this.isLoadingFlag = false;
            this.$emit('mailError');
          }
          break;
        default:
          this.$router.go(0);
          break;
      }
    },
    next() {
      switch (this.type) {
        case 10:
        case 12:
          if (this.$route.query.role == 'reviewer')
            this.$router.push({ name: CONSTANTS.ROUTE_NAME.SIGN_IN });

          if (this.$route.query.role == 'admin')
            this.$router.push({ name: CONSTANTS.ROUTE_NAME.ADMIN_SIGN_IN });
          break;
        default:
          this.$emit('retry');
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.primary-color {
  color: var(--primary-100);
}
.account-box {
  margin: 24px;
  width: 270px;
  height: 64px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
}
.second-content {
  text-align: center;
  margin-top: 40px;
}
.notification {
  margin: 0px 12px;
  display: flex;
}
.notification-content {
  font-size: 12px;
  color: var(--surface-medium);
}
.notification-content {
  font-size: 12px;
  color: var(--surface-medium);
}
</style>
