import Vue from 'vue';
import VueRouter from 'vue-router';
// import { auth } from '@/api'
import Constants from '@/constants.js';

import PassChange from '@/views/PassChange.vue';
import Page404 from '@/views/404.vue';
import SignIn from '@/views/SignIn.vue';
import RootSignIn from '@/views/RootSignIn.vue';
import AdminSignIn from '@/views/AdminSignIn.vue';
import Auth from '@/views/Auth';
import EvaluationReview from '@/views/EvaluationReview.vue';
import EvaluationResult from '@/views/EvaluationResult.vue';
import Settings from '@/views/admin/Settings.vue';
import ClientManagement from '@/views/root/ClientManagement.vue';
import AdminManagement from '@/views/root/AdminManagement.vue';
import ReviewerManagement from '@/views/admin/ReviewerManagement.vue';
import RequiredManagement from '@/views/admin/RequiredManagement.vue';
import TermsGuidance from '@/views/admin/TermsGuidance.vue';
import TermsManagement from '@/views/root/TermsManagement.vue';
import TermsGuidanceManagement from '@/views/root/TermsGuidanceManagement.vue';
import FindAccount from '@/views/FindAccount.vue';
import SignUp from '@/views/SignUp.vue';
import util from '@/util';

Vue.use(VueRouter);

let routes = [
  {
    path: '/reviewer',
    name: Constants.ROUTE_NAME.SIGN_IN,
    component: SignIn,
    meta: {
      auth: false,
    },
  },
  {
    path: '/evaluation-review',
    name: Constants.ROUTE_NAME.EVALUATION_REVIEW,
    component: EvaluationReview,
    meta: {
      auth: false,
    },
  },
  {
    path: '/evaluation-result',
    name: Constants.ROUTE_NAME.EVALUATION_RESULT,
    component: EvaluationResult,
    meta: {
      auth: false,
    },
  },

  {
    path: '/admin/settings',
    name: Constants.ROUTE_NAME.SETTINGS,
    component: Settings,
    meta: {
      auth: false,
    },
  },
  {
    path: '/auth',
    name: Constants.ROUTE_NAME.AUTH,
    component: Auth,
    meta: {
      auth: true,
    },
  },

  {
    path: '/root/client-management',
    name: Constants.ROUTE_NAME.CLIENT_MANAGEMENT,
    component: ClientManagement,
    meta: {
      auth: true,
    },
  },
  {
    path: '/root/terms-management',
    name: Constants.ROUTE_NAME.TERMS_MANAGEMENT,
    component: TermsManagement,
    meta: {
      auth: true,
    },
  },
  {
    path: '/root/admin-management',
    name: Constants.ROUTE_NAME.ADMIN_MANAGEMENT,
    component: AdminManagement,
    meta: {
      auth: true,
    },
  },
  {
    path: '/root',
    name: Constants.ROUTE_NAME.ROOT_SIGN_IN,
    component: RootSignIn,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin',
    name: Constants.ROUTE_NAME.ADMIN_SIGN_IN,
    component: AdminSignIn,
    meta: {
      auth: true,
    },
  },
  {
    path: '/pass-change',
    name: Constants.ROUTE_NAME.PASS_CHANGE,
    component: PassChange,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/reviewer-management',
    name: Constants.ROUTE_NAME.REVIEWER_MANAGEMENT,
    component: ReviewerManagement,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/required-management',
    name: Constants.ROUTE_NAME.REQUIRED_MANAGEMENT,
    component: RequiredManagement,
    meta: {
      auth: true,
    },
  },
  {
    path: '/admin/terms-guidance',
    name: Constants.ROUTE_NAME.TERMS_GUIDANCE,
    component: TermsGuidance,
    meta: {
      auth: true,
    },
  },
  {
    path: '/root/terms-guidance-management',
    name: Constants.ROUTE_NAME.TERMS_GUIDANCE_MANAGEMENT,
    component: TermsGuidanceManagement,
    meta: {
      auth: true,
    },
  },
  {
    path: '/find-account',
    name: Constants.ROUTE_NAME.FIND_ACCOUNT,
    component: FindAccount,
    meta: {
      auth: false,
    },
  },
  {
    path: '/sign-up',
    name: Constants.ROUTE_NAME.SIGN_UP,
    component: SignUp,
    meta: {
      auth: false,
    },
  },
  {
    path: '/404',
    name: Constants.ROUTE_NAME.PAGE_404,
    component: Page404,
    meta: {
      auth: false,
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(function(to, from, next) {
  // console.log(to, from, "every single routing is pending", to.name);
  switch (to.name) {
    case Constants.ROUTE_NAME.ADMIN_MANAGEMENT:
    case Constants.ROUTE_NAME.CLIENT_MANAGEMENT:
    case Constants.ROUTE_NAME.TERMS_MANAGEMENT:
    case Constants.ROUTE_NAME.TERMS_GUIDANCE_MANAGEMENT:
      if (util.verifyRootToken()) {
        next();
      } else {
        next({ name: Constants.ROUTE_NAME.ROOT_SIGN_IN });
      }
      break;
    case Constants.ROUTE_NAME.EVALUATION:
    case Constants.ROUTE_NAME.EVALUATION_REVIEW:
    case Constants.ROUTE_NAME.EVALUATION_RESULT:
    case Constants.ROUTE_NAME.ANALYSIS:
      if (util.verifyReviewerToken()) {
        next();
      } else {
        next({ name: Constants.ROUTE_NAME.SIGN_IN });
      }
      break;
    case Constants.ROUTE_NAME.TERMS_GUIDANCE:
    case Constants.ROUTE_NAME.REVIEWER_MANAGEMENT:
    case Constants.ROUTE_NAME.REQUIRED_MANAGEMENT:
    case Constants.ROUTE_NAME.SETTINGS:
      if (util.verifyAdminToken()) {
        next();
      } else {
        next({ name: Constants.ROUTE_NAME.ADMIN_SIGN_IN });
      }
      break;
    case Constants.ROUTE_NAME.AUTH:
    case Constants.ROUTE_NAME.ROOT_SIGN_IN:
    case Constants.ROUTE_NAME.SIGN_IN:
    case Constants.ROUTE_NAME.ADMIN_SIGN_IN:
    case Constants.ROUTE_NAME.PASS_CHANGE:
    case Constants.ROUTE_NAME.SIGN_UP:
    case Constants.ROUTE_NAME.FIND_ACCOUNT:
      next();
      break;
    case Constants.ROUTE_NAME.PAGE_404:
      next();
      break;
  }
});
// router.beforeResolve(auth.navigationGuard)

export default router;
