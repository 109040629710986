var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("signin-container full-size " + (_vm.$route.query.role == 'reviewer'
        ? 'signin-background'
        : 'signin-admin-background')),staticStyle:{"display":"flex","justify-content":"center"}},[(_vm.displayType == 1)?_c('FindCridential',{key:(_vm.displayType + "-Find"),staticClass:"signin-card",staticStyle:{"padding-bottom":"8px"},on:{"result":function($event){return _vm.selectDisplayType($event)},"mailError":function($event){_vm.mailError = true}}}):_vm._e(),(
      _vm.displayType == 10 ||
        _vm.displayType == 11 ||
        _vm.displayType == 12 ||
        _vm.displayType == 13
    )?_c('ResultCridential',{key:(_vm.displayType + "-Result"),staticClass:"signin-card",staticStyle:{"padding-bottom":"8px"},attrs:{"type":_vm.displayType,"payload":_vm.typeInformation},on:{"retry":function($event){_vm.displayType = 1},"mailError":function($event){_vm.mailError = true}}}):_vm._e(),(_vm.mailError)?_c('SystemErrorDialog',{attrs:{"errorCode":''},on:{"ok":function($event){_vm.mailError = false}},model:{value:(_vm.mailError),callback:function ($$v) {_vm.mailError=$$v},expression:"mailError"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }