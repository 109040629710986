<template>
  <div style="height: 88px; width: 217px">
    <div
      class="card"
      :style="`${initData ? '' : 'background:var(--gray-100);'}`"
    >
      <div :style="`${cardStyle}font-weight: 500;`">
        {{ titles[0] }}
      </div>
      <ToggleButton
        @input="changeModule($event)"
        :initialData="initData"
        :is-dim="!editMode"
      />
    </div>
    <div
      class="card-content"
      :style="
        `${!editMode && !initData ? 'color:var(--surface-disabled);' : ''}`
      "
    >
      {{ titles[1] }}
    </div>
    <div
      v-if="divisionLine"
      style="border-top: 2px dashed var(--gray-200); height: 16px"
    />
  </div>
</template>

<script>
import ToggleButton from '@/components/SliderToggle.vue';
export default {
  props: {
    titles: Array,
    divisionLine: {
      type: Boolean,
      default: () => false,
    },
    editMode: {
      type: Boolean,
      default: () => false,
    },
    initData: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    cardStyle() {
      return this.editMode
        ? //on edit mode
          this.initData
          ? 'color:var(--primary-100);'
          : 'color:var(--surface-medium);'
        : //not edit mode
        this.initData
        ? 'color:var(--primary-100);'
        : 'color:var(--surface-disabled);';
    },
  },
  components: { ToggleButton },
  data() {
    return {};
  },
  methods: {
    changeModule(event) {
      this.$log.debug('ModuleSettingComponent#changeModule', event);
      this.$emit('module', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 40px;
  border-radius: 8px;
  background: var(--primary-5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  &-content {
    font-weight: 400;
    margin: 8px 0 16px 16px;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
