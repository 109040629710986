var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step-container"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"step-container inner"},[(index > 0)?_c('div',{class:{
        'line-prev': _vm.phase > index,
        'line-current': _vm.phase === index,
        'line-next': _vm.phase < index,
      }}):_vm._e(),_c('div',{class:{
        prev: _vm.phase > index,
        current: _vm.phase === index,
        next: _vm.phase < index,
      }},[_c('div',{staticClass:"text-info"},[_vm._v(_vm._s(item))])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }