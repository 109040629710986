<template>
  <div class="loading-root">
    <div style="flex-grow: 1"></div>
    <img
      src="@/assets/Loading_40px_black.svg"
      width="35px"
      style="margin-bottom: 24px;"
    />
    <div
      v-for="(message, index) in messages"
      :key="`message-${index}`"
      class="text-info"
    >
      {{ message }}
    </div>
    <div
      v-for="(message, index) in submessage"
      :key="`submessage-${index}`"
      class="text-info-sub"
    >
      {{ message }}
    </div>
    <div style="flex-grow: 1"></div>
    <img
      src="@/assets/useb-alchera-logo.svg"
      width="155px"
      style="margin-bottom:35px"
    />
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      default: () => ['고객 신원 확인을 위한', '페이지로 이동 중입니다'],
    },
    submessage: {
      type: Array,
      default: () => ['잠시만 기다려 주세요.'],
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-root {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .text-info {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--surface-high);
    margin-bottom: 8px;
  }
  .text-info-sub {
    font-size: 16px;
    line-height: 26px;
    color: var(--surface-medium);
  }
}
</style>
