<template>
  <div
    class="btn-logout"
    style="position: relative"
    v-click-outside="closeProfileDialog"
  >
    <img :src="img" @click="getUserInfo()" style="height: 36px" />
    <div v-show="profileDialog" class="profile-dialog-frame">
      <div style="margin: 16px 20px 20px 20px">
        <div style="margin-bottom: 10px; font-size: 14px; line-height: 24px">
          <section
            class="ellipsis"
            style="
              color: var(--surface-medium);
              font-size: 12px;
              line-height: 20px;
              height: 98px;
            "
          >
            <div
              class="ellipsis"
              :title="userInfo.name"
              style="font-weight: 500; color: var(--surface-high); margin-bottom: 4px"
            >
              {{ userInfo.name }}
            </div>
            <div :title="userInfo.username" class="ellipsis">
              {{ userInfo.username }}
            </div>
            <div :title="role" class="ellipsis">{{ role }}</div>
            <div
              class="ellipsis"
              style="border: 0.5px solid var(--gray-300); margin: 6px 0"
            ></div>
            <div :title="company" class="ellipsis">{{ company }}</div>
          </section>
        </div>
        <section>
          <div
            class="ellipsis"
            style="
              margin-top: 20px;
              font-size: 14px;
              line-height: 24px;
              color: var(--surface-high);
            "
            v-for="l in buttonContents"
            :key="l.message"
            @click="select(l.message)"
          >
            <section
              class="ellipsis"
              style="display: flex; justify-content: row"
            >
              <div><img :src="l.img" alt="" /></div>
              <div style="margin-left: 8px">{{ l.message }}</div>
            </section>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
//import Vue from "vue";
export default {
  props: {
    img: {
      type: String,
      default: () => '',
    },
    buttonContents: {
      type: Array,
      default: () => [
        {
          message: null,
          img: null,
        },
      ],
    },
    userInfo: {
      type: Object,
      default: () => ({
        active: undefined,
        email: '',
        id: null,
        name: '',
        phone_number: '',
        username: '',
      }),
    },
    role: String,
    company: String,
  },
  data() {
    return {
      profileDialog: false,
    };
  },
  methods: {
    closeProfileDialog() {
      this.profileDialog = false;
    },
    async getUserInfo() {
      this.profileDialog = !this.profileDialog;
      await this.$emit('get');
    },
    select(l) {
      if (l === '정보 수정') {
        this.$emit('modify');
      } else if (l === '로그 아웃') {
        this.$emit('logout');
      } else {
        //exception
        this.$emit('logout');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-logout {
  color: var(--error-100);
  white-space: nowrap;
  cursor: pointer;

  font-weight: 500;
  font-size: 14px;
}
.profile-dialog-frame {
  position: absolute;
  width: 159px;
  // height: 230px;
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  left: -100px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
