<template>
  <div class="view-container">
    <div class="content-container">
      <div class="evaluation-types-container">
        <div class="evaluation-types-area-left">
          <div class="evaluation-type-title">
            심사 수행 {{ `(${auditListTotalCount})` }}
          </div>
        </div>
        <div class="evaluation-types-area-right">
          <Dropdown
            style="width: 112px"
            v-model="selectedInspectionPendingFilter"
            :items="inspectionPendingDropdownItems"
            @change="onChangeFilter"
          ></Dropdown>

          <InputSearch
            @change="onChangeSearch"
            style="margin-left: 7px"
          ></InputSearch>
        </div>
      </div>
      <div class="audits-table-container">
        <EpTable
          :table-name="'ep'"
          :titles="TABLE.EP.SIZE"
          :titleNames="TABLE.EP.NAME"
          :contents="$store.state.reviewer.auditList"
          @getDetail="getDetailUserAuditInfo($event)"
          :key="`${tableRerender}-ep`"
        >
          <template v-slot:detail="props">
            <EvaluationExpandedContent
              v-if="isOpenedFromChild.includes(props.item.id)"
              :itemData="props.item"
              :detailedItemData="$store.state.reviewer.auditListDetail"
              :isModifiedData="isModifiedData[props.item.id]"
            />
          </template>
        </EpTable>
      </div>
      <Pagenation
        :totalCount="auditListTotalCount"
        @update="getAuditList($event)"
      />
    </div>
  </div>
</template>

<script>
import EvaluationExpandedContent from '@/components/reviewer/evaluation/audit/EvaluationExpandedContent.vue';

import Dropdown from '@/components/Dropdown';
import InputSearch from '@/components/InputSearch';
import { mapState } from 'vuex';
import TABLE from '@/table.js';
import EpTable from '@/components/Table.vue';
import Pagenation from '@/components/Pagenation.vue';
import loadingMixin from '@/store/mixins/loading';
export default {
  name: 'Evaluation',
  mixins: [loadingMixin],
  components: {
    Dropdown,
    InputSearch,
    EpTable,
    Pagenation,
    EvaluationExpandedContent,
  },

  data() {
    return {
      inspectionPendingDropdownItems: ['전체', '전화번호', '이름', '생년월일'],
      selectedInspectionPendingFilter: '',
      search: '',
      TABLE: TABLE,
      isOpenedFromChild: [],
    };
  },
  async created() {
    try {
      this.onLoading();
      await this.getAuditList({ start: 0, count: 10 }).then(res => {
        this.$log.debug(res);
        var ms = 0;
        if (res.api_response.result_code == 'N100') {
          ms = 700;
        }
        this.offLoading(ms);
      });
    } catch {
      this.offLoading();
      this.$log.debug('dispatching error on evaluation');
    }
  },
  async destroyed() {
    this.$store.state.reviewer.auditList = [];
    this.$store.state.reviewer.auditListTotalCount = 0;
    this.$store.state.reviewer.auditListDetail = {};
    this.$store.state.reviewer.modifiedDataAudit = {};
  },
  computed: {
    ...mapState({
      auditList: state => state.reviewer.auditList,
      auditListTotalCount: state => state.reviewer.auditListTotalCount,
      detailedAuditList: function(state) {
        return state.reviewer.auditListDetail;
      },
      isModifiedData: state => state.reviewer.modifiedDataAudit,
    }),

    selectedInspectionCompleteFilterItemsText() {
      if (this.selectedInspectionCompleteFilter.length === 4) {
        return '전체';
      } else if (this.selectedInspectionCompleteFilter.length === 3) {
        return (
          this.selectedInspectionCompleteFilter.join(', ').slice(0, 7) + '..'
        );
      }
      return this.selectedInspectionCompleteFilter.join(', ');
    },
  },
  methods: {
    onChangeFilter(index) {
      this.$log.debug('Evaluation#onChangeFilter', index);
    },
    onChangeSearch(text) {
      this.$log.debug('Evaluation#onChangeSearch', text);
      this.search = text;
    },

    async getAuditList(payload) {
      this.tableRerender = !this.tableRerender;
      this.isOpenedFromChild = [];
      this.onLoading();
      return await this.$store.dispatch('getAuditList', payload).then(() => {
        this.offLoading();
      });
    },
    async getDetailUserAuditInfo(payload) {
      if (payload[2]) {
        this.onLoading();
        await this.$store
          .dispatch('getAuditDetailed', { id: payload[0] })
          .then(res => {
            if (res) {
              this.$log.debug('dispatch 결과 : ', res);
            }
            this.offLoading();
          });
      }
      this.isOpenedFromChild = [...payload[1]];
    },
  },
};
</script>

<style scoped>
.evaluation-types-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 23px;
  display: flex;
}
.evaluation-types-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.evaluation-types-text-selected {
  color: var(--primary-80);
}
.evaluation-types-text-unselected {
  cursor: pointer;
  color: var(--surface-disabled);
}
.evaluation-types-area-left {
  display: flex;
  flex-grow: 1;
}
.evaluation-types-area-right {
  display: flex;
}
.evaluation-dropdown {
  background-color: var(--surface-100);
  border-radius: 8px;
  width: 130px;
  margin-right: 7px !important;
}
.evaluation-search-bar {
  background-color: var(--surface-100);
  border-radius: 8px;
}

.empty-selected-options-text {
  padding: 10px 16px;
  font-size: 12px;
  color: var(--surface-medium);
  cursor: pointer;
  opacity: 0.8;
}
.empty-selected-options-text:hover {
  opacity: 1;
}
.evaluation-type-title {
  color: var(--primary-80);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.selected-filter-options-text {
  padding-top: 1px;
  color: var(--surface-medium);
}
.page-text {
  width: 65px;

  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: var(--surface-medium);
}
</style>
