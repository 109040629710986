export default Object.freeze({
  ROUTE_NAME: {
    SIGN_IN: 'SignIn',
    ROOT_SIGN_IN: 'RootSignIn',
    ADMIN_SIGN_IN: 'AdminSignIn',
    SIGN_UP: 'SignUp',
    FIND_ACCOUNT: 'FindAccount',
    MAIN: 'Main',
    AUTH: 'Auth',
    ANALYSIS: 'Analysis',
    DASHBOARD: 'Dashboard',
    EVALUATION: 'Evaluation',
    MANAGER: 'Manager',
    SETTINGS: 'Settings',
    ROOT_DASHBOARD: 'RootDashboard',
    CLIENT_MANAGEMENT: 'ClientManagement',
    ADMIN_MANAGEMENT: 'AdminManagement',
    REVIEWER_MANAGEMENT: 'ReviewerManagement',
    TERMS_MANAGEMENT: 'TermsManagement',
    TERMS_GUIDANCE_MANAGEMENT: 'TermsGuidanceManagement',
    TERMS_GUIDANCE: 'TermsGuidance',
    ADMIN_DASHBOARD: 'AdminDashboard',
    OPENBANK: 'Openbank',
    REQUIRED_MANAGEMENT: 'RequiredManagement',
    PASS_CHANGE: 'PassChange',

    EVALUATION_REVIEW: 'EvaluationReview',
    EVALUATION_RESULT: 'EvaluationResult',

    PAGE_404: 'Page404',
  },
  USERNAME: 'username',
  PASSWORD: 'password',
  CARD_IDTYPE: {
    JUMIN: 1,
    DRIVER: 2,
    PASSPORT_KOR: 3,
    PASSPORT_ALIEN: 4,
  },
  MODULE: {
    OCR: 'ocr',
    STATUS: 'status',
    IDCARD: 'ocr+status',
    FACE: 'face',
    LIVENESS: 'liveness',
    ACCOUNT: 'account',
  },
  APP_CARD_INDEX: {
    JUMIN: 0,
    DRIVER: 1,
    PASSPORT_KOREAN: 2,
    PASSPORT_ALIEN: 3,
    ALIEN: 4,
    ALIEN_REGI: 1, // 등록증
    ALIEN_RESI: 2, // 거소증
    ALIEN_PERM: 3, // 영주증
  },
  DISPLAY_MODULE: {
    //1원 계좌 인증
    ONE_POINT: 1,
    //신분증 OCR / 진위확인
    OCR_CHECK: 2,
    //신분증 사진 및 얼굴 비교
    ID_CARD_COMPARE: 3,
    PASSIVE_LIVENESS: 4,
    ONE_OCR_CHECK: 5,
    ONE_ID_CARD_COMPARE: 6,
    ONE_PASSIVE_LIVENESS: 7,
  },
  PHASES: {
    PHASE_INIT_LOADING: -1,
    PHASE_EMPTY_PAGE: 0,
    PHASE_USER_INFO_INPUT: 1,
    PHASE_CARD_SELECT: 2,
    PHASE_CARD_GUIDE: 3,
    PHASE_CARD_SCAN: 4,
    PHASE_CARD_NOT_MATCHED: 5,
    PHASE_CARD_RESULT: 6,
    PHASE_FACE_GUIDE: 7,
    PHASE_FACE_SCAN: 8,
    PHASE_BANK_HOLDER: 9,
    PHASE_BANK_SEND: 10,
    PHASE_BANK_VERIFY: 11,
    PHASE_VERIFY_FAIL: 12,
    PHASE_RESULT_SUCCESS: 13,
    PHASE_ACCESS_DENIED: 14,
    PHASE_CARD_LOCKED: 15,
  },
  SERVER: {
    ERROR_CODE: {
      CARD_SCAN_FACE_RECOGNITION_FAILED: 'CE002',
      CARD_UPLOAD_FACE_RECOGNITION_FAILED: 'F001',
    },
  },
});
