<template>
  <div class="view-container">
    <div class="content-container">
      <div class="evaluation-types-container">
        <div class="evaluation-types-area-left">
          <div class="evaluation-type-title font-500">
            심사 결과 {{ `(${auditResultListTotalCount})` }}
          </div>
        </div>
        <div class="evaluation-types-area-right">
          <Dropdown
            width="112px"
            v-model="selectedTypeOfAudit"
            :items="typeOfAudit"
            @change="onChangeFilter"
          ></Dropdown>
          <Dropdown
            style="margin-left: 7px"
            width="112px"
            v-model="selectedFilter"
            :items="typeOfFilter"
            @change="onChangeFilter"
          ></Dropdown>
          <InputSearch
            @change="onChangeSearch"
            style="margin-left: 7px"
          ></InputSearch>
        </div>
      </div>
      <div class="audits-table-container">
        <EcTable
          :table-name="'ec'"
          :titles="TABLE.EC.SIZE"
          :titleNames="TABLE.EC.NAME"
          :contents="$store.state.reviewer.auditResultList"
          @getDetail="getDetailUserResultInfo($event)"
          :key="`${tableRerender}-ec`"
          @sort="onSort"
        >
          <template slot-scope="props">
            <div
              :class="
                props.tid == 'inspect_status'
                  ? props.c.includes('승인')
                    ? 'accept-color font-500'
                    : 'reject-color font-500'
                  : ''
              "
            >
              {{ props.c }}
            </div>
          </template>
          <template v-slot:detail="props">
            <EvaluationExpandedContent
              v-if="isOpenedFromChild.includes(props.item.id)"
              :itemData="props.item"
              :detailedItemData="$store.state.reviewer.auditResultListDetail"
              :isModifiedData="isModifiedData[props.item.id]"
            />
          </template>
        </EcTable>
        <Pagenation
          :totalCount="auditResultListTotalCount"
          @update="getResultList($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown';
import InputSearch from '@/components/InputSearch';
import EvaluationExpandedContent from '@/components/reviewer/evaluation/result/EvaluationExpandedContent.vue';
import EcTable from '@/components/Table.vue';
import TABLE from '@/table.js';
import { mapState } from 'vuex';
import Pagenation from '@/components/Pagenation.vue';
import loadingMixin from '@/store/mixins/loading';

export default {
  name: 'Evaluation',
  mixins: [loadingMixin],
  components: {
    Pagenation,
    Dropdown,
    EcTable,
    InputSearch,
    EvaluationExpandedContent,
  },
  data() {
    return {
      typeOfAudit: [
        '심사 구분',
        '자동 승인', //1
        '자동 거부', //2
        '수동 승인', //3
        '수동 거부', //4
      ],
      selectedTypeOfAudit: '심사 구분',
      typeOfFilter: ['전체', '전화번호', '이름', '생년월일'],
      selectedFilter: '전체',
      search: '',
      TABLE: TABLE,
      isOpenedFromChild: [],
      typeOfResult: 0,
    };
  },
  async created() {
    try {
      await this.getResultList({
        result_type: this.resultType,
        start: 0,
        count: 10,
      });
    } catch (e) {
      this.$log.debug('dispatching error on evaluation', e);
    }
  },
  async destroyed() {
    this.$store.state.reviewer.auditResultList = [];
    this.$store.state.reviewer.auditResultListTotalCount = 0;
    this.$store.state.reviewer.auditResultListDetail = {};
    this.$store.state.reviewer.modifiedData = {};
  },
  computed: {
    ...mapState({
      auditResultList: state => state.reviewer.auditResultList,
      auditResultListTotalCount: state =>
        state.reviewer.auditResultListTotalCount,
      detailedAuditResultList: function(state) {
        return state.reviewer.auditResultListDetail;
      },
      isModifiedData: function(state) {
        return state.reviewer.modifiedData;
      },
    }),
    resultType() {
      return this.typeOfAudit.indexOf(this.selectedTypeOfAudit);
    },
  },
  methods: {
    async onChangeFilter() {
      await this.getResultList({
        result_type: this.resultType,
        start: 0,
        count: 10,
      });
    },
    async onSort(event) {
      console.log(event);
    },
    onChangeSearch(text) {
      this.$log.debug('Evaluation#onChangeSearch', text);
      this.search = text;
    },
    async getResultList(payload) {
      this.tableRerender = !this.tableRerender;
      var preventImmutable = [];
      this.isOpenedFromChild = preventImmutable;
      this.onLoading();
      await this.$store
        .dispatch('getAuditResultList', {
          ...payload,
          result_type: this.resultType,
        })
        .then(() => {
          this.offLoading();
        });
    },
    async getDetailUserResultInfo(payload) {
      if (payload[2]) {
        this.onLoading();
        await this.$store
          .dispatch('getAuditResultDetailed', { id: payload[0] })
          .then(res => {
            this.offLoading();
            if (res) {
              this.$log.debug('dispatch 결과 : ', res);
            }
          });
      }
      this.isOpenedFromChild = [...payload[1]];
    },
  },
};
</script>

<style scoped>
.accept-color {
  color: var(--success-100);
}
.reject-color {
  color: var(--error-100);
}

.evaluation-types-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 23px;
  display: flex;
}

.evaluation-types-area-left {
  display: flex;
  flex-grow: 1;
}
.evaluation-types-area-right {
  display: flex;
}

.evaluation-type-title {
  color: var(--primary-80);
  font-size: 24px;
  line-height: 36px;
}
</style>
