var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title.length > 0)?_c('div',{staticClass:"title-input"},[_c('div',{staticClass:"title-input--first"},[_vm._v(_vm._s(_vm.title))]),(_vm.necessary)?_c('div',{staticClass:"title-input--second"},[_vm._v(" *필수")]):_vm._e()]):_vm._e(),(_vm.needCheck)?_c('div',{staticStyle:{"display":"flex"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:{
        'input-form': _vm.errMsg == '' && !_vm.errorForm,
        'input-form--fail': _vm.errMsg != '' || _vm.errorForm,
      },attrs:{"placeholder":_vm.placeholder},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.text=$event.target.value},function($event){_vm.validation($event);
        _vm.$emit('new');}]}}),_c('button',{staticClass:"input-check",on:{"click":function($event){_vm.$emit('click');
        _vm.errMsg == '중복여부를 확인하세요.' ? (_vm.errMsg = '') : null;}}},[_vm._v(" 중복확인 ")])]):(_vm.compNameToCode)?_c('div',{staticStyle:{"display":"flex"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:{
        'input-form': _vm.errMsg == '',
        'input-form--fail': _vm.errMsg != '',
      },staticStyle:{"margin-right":"-40px"},attrs:{"placeholder":_vm.placeholder},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.text=$event.target.value},function($event){return _vm.validation($event)}]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.compNameChecked),expression:"compNameChecked"}],staticStyle:{"height":"56px","line-height":"56px"}},[_c('img',{staticStyle:{"margin-top":"15px"},attrs:{"src":require("@/assets/comp_check_24.svg"),"width":"24px"}})])]):(_vm.isPassword)?_c('div',{staticStyle:{"display":"flex"}},[(!_vm.visible)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:{
        'input-form': true,
      },staticStyle:{"margin-right":"-49px"},attrs:{"type":"password","placeholder":_vm.placeholder},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.text=$event.target.value},function($event){return _vm.validation($event)}]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:{
        'input-form': true,
      },staticStyle:{"margin-right":"-49px"},attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.text=$event.target.value},function($event){return _vm.validation($event)}]}}),_c('div',{staticStyle:{"height":"56px","line-height":"56px"},on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.visible && _vm.text.length == 0),expression:"!visible && text.length == 0"}],staticStyle:{"margin-top":"15px"},attrs:{"src":require("@/assets/invisible-dim-signin.svg"),"width":"24px"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.visible && _vm.text.length > 0),expression:"!visible && text.length > 0"}],staticStyle:{"margin-top":"15px"},attrs:{"src":require("@/assets/invisible-signin.svg"),"width":"24px"}}),(_vm.visible && _vm.text.length == 0)?_c('img',{staticStyle:{"margin-top":"15px"},attrs:{"src":require("@/assets/visible-dim-signin.svg"),"width":"24px"}}):_vm._e(),(_vm.visible && _vm.text.length > 0)?_c('img',{staticStyle:{"margin-top":"15px"},attrs:{"src":require("@/assets/visible-signin.svg"),"width":"24px"}}):_vm._e()])]):_c('div',{staticStyle:{"display":"block"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],class:{
        'input-form': _vm.errMsg == '',
        'input-form--fail': _vm.errMsg != '',
      },attrs:{"placeholder":_vm.placeholder},domProps:{"value":(_vm.text)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.text=$event.target.value},function ($event) {
          if (_vm.validity == 'live') {
            _vm.text = $event.target.value.slice(0, _vm.lengthLimit);
            _vm.$emit('live', _vm.text);
          } else {
            _vm.validation($event);
          }
        }]}})]),(_vm.scsMsg != '')?_c('div',{style:(("color: var(--primary-100);" + (_vm.infoFontSmall ? 'font-size:12px;margin-top:8px' : '')))},[_vm._v(" "+_vm._s(_vm.scsMsg)+" ")]):_vm._e(),(_vm.errMsg != '')?_c('div',{style:(("color: var(--error-100);" + (_vm.infoFontSmall ? 'font-size:12px;margin-top:8px' : '')))},[_vm._v(" "+_vm._s(_vm.errMsg)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }