<template>
  <section
    v-if="item.text === '얼굴 인증' && faceCheckContentExists"
    class="eec-expand"
    style="max-height:288px;margin-bottom:8px;overflow:hidden"
  >
    <section class="eec-expand-half">
      <!-- <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key">마스크 착용 여부</div>
        <div
          v-if="!detailedItemData[itemData.id].face_covered"
          class="label-value"
        >
          미착용
        </div>
        <div v-else class="label-value--red">착용</div>
      </div> -->
      <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key">
          <div
            style="width: 128px; display: flex; justify-content: space-between"
          >
            <div style="width 120px;">얼굴정보 유사도</div>

            <img
              @click="similarityDiagFlag = true"
              v-click-outside="
                () => {
                  similarityDiagFlag = false;
                }
              "
              src="@/assets/tooltip.svg"
              width="10px"
            />
            <div style="position: relative">
              <div v-show="similarityDiagFlag" class="tooltip">
                <div>신분증 사진과 셀피 사진을 비교하여 유사도 제공</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="autoSimilarityCheck" class="label-value">High</div>
        <div v-else class="label-value--red">Low</div>
      </div>
      <div v-if="liveness" class="eec-flex-row" style="margin-top: 16px">
        <div class="label-key">
          <div
            style="width: 128px; display: flex; justify-content: space-between"
          >
            <div style="width 120px;">얼굴 위변조 검사</div>

            <img
              @click="livenessDiagFlag = true"
              v-click-outside="
                () => {
                  livenessDiagFlag = false;
                }
              "
              src="@/assets/tooltip.svg"
              width="10px"
            />

            <div style="position: relative">
              <div v-show="livenessDiagFlag" class="tooltip">
                <div>실제 사람 얼굴인지 여부를 검사</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="faceLiveness" class="label-value">통과</div>
        <div v-else class="label-value--red">실패</div>
      </div>
    </section>
    <div style="width: 32px" />
    <section class="eec-expand-half">
      <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key">신분증 사진</div>
        <img
          v-if="!!detailedItemData"
          width="120px"
          height="120px"
          :src="idCropImage"
          style="border: 2px solid #d8d8d8"
        />
        <img
          v-else
          width="120px"
          height="120px"
          style="border: 2px solid #d8d8d8"
        />
      </div>
      <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key">검출 사진</div>
        <img
          v-if="!!detailedItemData"
          width="120px"
          height="120px"
          :src="selfieImage"
          style="border: 2px solid #d8d8d8"
        />
        <img
          v-else
          width="120px"
          height="120px"
          style="border: 2px solid #d8d8d8"
        />
      </div>
    </section>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    detailedItemData: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  data() {
    return {
      similarityDiagFlag: false,
      livenessDiagFlag: false,
    };
  },
  computed: {
    selectedItemData() {
      return this.detailedItemData;
    },
    autoSimilarityCheck() {
      return this.selectedItemData.auto_similarity_check;
    },
    faceCheckContentExists() {
      return this.selectedItemData.face_check_content_exist;
    },
    faceLiveness() {
      return this.selectedItemData.face_liveness;
    },
    idCropImage() {
      return this.selectedItemData.id_crop_image;
    },
    liveness() {
      return this.selectedItemData.showable_info_type.liveness;
    },
    selfieImage() {
      return this.selectedItemData.selfie_image;
    },
  },
  methods: {},
};
</script>
<style scoped>
.tooltip {
  position: absolute;
  left: -15px;
  width: 150px;
  height: 50px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  line-height: 18px;
  word-spacing: -1px;
}
</style>
