export default {
  data() {
    return {
      footerComponent: null,
      otherComponent: null,
      hideComponent: null,
    };
  },
  created() {
    window.addEventListener('resize', this.isCollide);
  },
  updated() {
    this.otherComponent = document.querySelector('#pagenation-container');
    this.footerComponent = document.querySelector('#footer-criteria');
    this.hideComponent = document.querySelector('#global-footer');
    this.isCollide();
  },
  mounted() {
    this.footerComponent = document.querySelector('#footer-criteria');
    this.hideComponent = document.querySelector('#global-footer');
    this.isCollide();
  },
  destroyed() {
    window.removeEventListener('resize', this.isCollide);
  },
  methods: {
    isCollide() {
      if (this.footerComponent && this.otherComponent) {
        let footerRect = this.footerComponent.getBoundingClientRect();
        let otherComponentRect = this.otherComponent.getBoundingClientRect();

        //console.log('isCollide', otherComponentRect, footerRect);
        let result = !(
          footerRect.top >
          otherComponentRect.top + otherComponentRect.height + 20
        );

        if (result) {
          this.hideComponent.style.cssText = 'position:relative;';
        } else {
          this.hideComponent.style.cssText = 'position:absolute;bottom:0;';
        }
      } else return;
    },
  },
};
