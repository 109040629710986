<template>
  <div class="card-result-fail-root">
    <div class="card-result-fail-info-container">
      <img
        src="@/assets/얼굴_실패.svg"
        width="103px"
        style="margin-bottom: 40px; margin-left: 10px"
      />
      <div
        style="
          font-weight: bold;
          font-size: 28px;
          line-height: 36px;
          color: var(--primary-100);
          margin-bottom: 24px;
        "
      >
        일시적 오류 발생
      </div>
      <div
        style="
          font-weight: normal;
          text-align: center;
          font-size: 16px;
          line-height: 26px;
          color: var(--surface-high);
          margin-bottom: 16px;
        "
      >
        잘못된 경로 또는 비정상적 접근이 감지되어<br />
        고객 신원 확인을 종료합니다.<br />
        잠시 후 다시 시도해 주세요.
      </div>
      <div
        style="
          font-weight: normal;
          text-align: center;
          font-size: 14px;
          line-height: 21px;
          color: var(--surface-medium);
        "
      >
        동일한 문제가 지속적으로 발생한다면<br />
        아래 메일로 문의주시기 바랍니다.<br />
        <a href="mailto:cs@useb.co.kr" style="color: var(--surface-medium)"
          >cs@useb.co.kr</a
        >
      </div>
    </div>
    <div @click="$emit('ok')" class="button-container">확인</div>
  </div>
</template>

<script>
export default {
  props: {
    errorCode: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: white;
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>
