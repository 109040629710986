<template>
  <div class="view-container">
    <div class="content-container">
      <div class="admin-types-container">
        <div class="admin-types-area-left">
          <div class="admin-management-title">심사자 관리</div>
          <!-- <UpsideEnrollButton
            @click="addDialogFlag = true"
            :button-string="`+등록`"
          /> -->
        </div>
        <div class="admin-types-area-right">
          <Dropdown
            style="width: 112px"
            v-show="selectedTypeIndex === 0"
            v-model="selectedInspectionPendingFilter"
            :items="searchCriteriaDropdownItems"
            @change="onChangeFilter"
          ></Dropdown>
          <InputSearch
            @change="onChangeSearch"
            @submit="searchByKeyword"
            style="margin-left: 7px"
          ></InputSearch>
        </div>
      </div>

      <div class="admin-list-table-container">
        <RmTable
          v-if="true"
          :needDetail="false"
          :table-name="'rm'"
          :titles="TABLE.RM.SIZE"
          :titleNames="TABLE.RM.NAME"
          :contents="reviewerList"
          @sort="onSort"
        >
          <template slot-scope="props">
            <div v-if="props.tid === 'activation'">
              <ToggleButton
                style="vertical-align: middle"
                :initialData="props.c"
                @input="confirmDialogUp($event, props.ctt)"
                :disabled="!props.ctt.is_approve"
              ></ToggleButton>
            </div>

            <div v-else-if="props.tid === 'is_approve'">
              <!-- props.c 반대여야 한다.  -->
              <div v-if="!props.c" class="flex-center">
                <button
                  @click="manageReviewerApply(props.ctt.id, true)"
                  class="btn ok"
                >
                  수락
                </button>

                <div class="btn-spacer"></div>

                <button class="btn cancel" @click="applyDeny(props.ctt)">
                  거절
                </button>
              </div>
            </div>
          </template>
        </RmTable>
        <Pagenation :totalCount="15" @update="requestReviewerList($event)" />
      </div>
    </div>
    <ConfirmDialog
      v-if="confirmDialogInfo"
      v-model="confirmDialogFlag"
      @close="confirmDialogFlag = false"
      @confirmation="confirmDone($event)"
      :confirmInfos="confirmDialogInfo"
    />
    <ApplyDenyDialog
      v-if="applyDenyDialogInfo"
      v-model="applyDenyDialogFlag"
      @close="applyDenyDialogFlag = false"
      :confirmInfos="applyDenyDialogInfo"
      @confirmation="manageReviewerApply($event, false)"
    />
    <RegisterReviewerDialog
      v-model="addDialogFlag"
      @close="addDialogFlag = false"
    />
    <LimitExceededDialog
      v-model="limitExceededDialogFlag"
      @close="limitExceededDialogFlag = false"
      :reviewerMaxAdminError="reviewerMaxAdminError"
      :reviewerCurrentAdmin="reviewerCurrentAdmin"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown';
import InputSearch from '@/components/InputSearch';
import ToggleButton from '@/components/SliderToggle.vue';
import Pagenation from '@/components/Pagenation.vue';
import RegisterReviewerDialog from '@/components/admin/dialog/RegisterReviewerDialog.vue';
import ConfirmDialog from '@/components/admin/dialog/ConfirmDialog.vue';
import ApplyDenyDialog from '@/components/admin/dialog/ApplyDenyDialog';
import LimitExceededDialog from '@/components/admin/dialog/LimitExceededDialog';

import { mapState } from 'vuex';
import RmTable from '@/components/Table.vue';
import server from '../../api/server';
import TABLE from '@/table.js';
import loadingMixin from '@/store/mixins/loading';

export default {
  name: 'ReviewerManagement',
  mixins: [loadingMixin],
  components: {
    ToggleButton,
    Dropdown,
    InputSearch,
    RegisterReviewerDialog,
    ConfirmDialog,
    // UpsideEnrollButton,

    RmTable,
    LimitExceededDialog,
    Pagenation,
    ApplyDenyDialog,
  },
  data() {
    return {
      selectedTypeIndex: 0,
      selectedInspectionPendingFilter: '',
      selectedInspectionCompleteFilter: [],
      searchCriteriaDropdownItems: [
        '전체',
        '아이디',
        '이름',
        '전화번호',
        '이메일',
      ],
      search: '',
      addDialogFlag: false,
      confirmDialogFlag: false,
      confirmDialogInfo: null,
      applyDenyDialogFlag: false,
      applyDenyDialogInfo: null,
      limitExceededDialogFlag: false,
      limitExceededDialogInfo: null,
      toastDialogFlag: false,
      TABLE: TABLE,
      nowPage: 0,
      loading: false,
      reviewerMaxAdminError: 0,
      reviewerCurrentAdmin: 0,
    };
  },
  async created() {
    await this.$store.dispatch('getReviewerList', {
      start: 0,
      count: 10,
    });
  },
  computed: {
    ...mapState({
      reviewerList: state => state.admin.reviewerList,
    }),

    selectedInspectionCompleteFilterItemsText() {
      if (this.selectedInspectionCompleteFilter.length === 4) {
        return '전체';
      } else if (this.selectedInspectionCompleteFilter.length === 3) {
        return (
          this.selectedInspectionCompleteFilter.join(', ').slice(0, 7) + '..'
        );
      }
      return this.selectedInspectionCompleteFilter.join(', ');
    },
  },
  methods: {
    async requestReviewerList(event) {
      this.$log.debug(event);
      try {
        await this.$store.dispatch('getReviewerList', {
          start: event.start,
          count: event.count,
        });
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.nowPage = event.start / 10;
      }
    },
    async onSort(event) {
      let order_field;
      switch (event.id) {
        case 'reviewer_id':
          order_field = 'username';
          break;
        case 'reviewer_name':
          order_field = 'name';
          break;
        case 'reviewer_phone':
          order_field = 'phone_number';
          break;
        case 'reviewer_mail':
          order_field = 'email';
          break;
      }
      await this.$store.dispatch('getReviewerList', {
        start: this.nowPage * 10,
        count: 10,
        order_field,
      });
    },
    onChangeFilter(index) {
      this.$log.debug('Evaluation#onChangeFilter', index);
      console.log(this.selectedInspectionPendingFilter);
    },
    onChangeSearch(text) {
      this.$log.debug('Evaluation#onChangeSearch', text);
      this.search = text;
    },
    async searchByKeyword() {
      let filter;
      switch (this.selectedInspectionPendingFilter) {
        case '아이디':
          filter = 'username';
          break;
        case '전화번호':
          filter = 'phone_number';
          break;
        case '이름':
          filter = 'name';
          break;
        case '이메일':
          filter = 'email';
          break;
      }
      await this.$store.dispatch('getReviewerList', {
        start: 0,
        count: 10,
        keyword_field: filter,
        keyword: this.search,
      });
    },
    confirmDialogUp(event, content) {
      this.$log.debug('AdminManagement#confirmDialogUp', event);
      if (content.is_approve) {
        this.confirmDialogInfo = { item: content, value: event };
        this.confirmDialogFlag = true;
      }
    },
    async manageReviewerApply(id, is_approve) {
      this.onLoading();

      try {
        await server.manageReviewerApply(id, {
          approve: is_approve,
        });

        await this.$store.dispatch('getReviewerList', {
          start: this.nowPage * 10,
          count: 10,
        });
        this.offLoading();
        if (is_approve) {
          this.$store.dispatch('showRegistrationSnackbar', {
            message: '심사자 신청이 수락되었습니다.',
          });
        } else {
          this.$store.dispatch('showRegistrationSnackbar', {
            message: '심사자 신청이 거절되었습니다.',
            color: 'var(--error-100)',
          });
        }
      } catch (error) {
        this.offLoading();
        const { errorCode, response } = error;
        this.$log.error(error);
        if (errorCode === 'F607') {
          console.log(response);
          this.reviewerMaxAdminError = response.max_count;
          this.reviewerCurrentAdmin = response.current_count;
          this.$nextTick(() => {
            this.limitExceededDialogFlag = true;
          });
        }
      }
    },
    applyDeny(value) {
      this.applyDenyDialogFlag = true;
      this.applyDenyDialogInfo = { item: value };
    },

    async confirmDone(event) {
      this.loading = true;
      //need api
      await server
        .activateReviewerAccount(event.identification, {
          active: event.value,
        })
        .then(res => {
          this.$log.debug(res);
          this.loading = false;
          if (res.result_code == 'N100') {
            this.$store.dispatch('showRegistrationSnackbar', {
              message: '활성 정보 수정이 완료되었습니다.',
            });
          }
        })
        .catch(err => {
          const { errorCode, response } = err;
          if (errorCode == 'F607') {
            this.reviewerMaxAdminError = response.max_count;
            this.reviewerCurrentAdmin = response.current_count;
            this.limitExceededDialogFlag = true;
            this.loading = false;
          }
          this.loading = false;
        });
      await this.$store.dispatch('getReviewerList', {
        start: this.nowPage * 10,
        count: 10,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-types-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 23px;
  display: flex;
}
.evaluation-types-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.evaluation-types-text-selected {
  color: var(--primary-80);
}
.evaluation-types-text-unselected {
  cursor: pointer;
  color: var(--surface-disabled);
}
.admin-types-area-left {
  display: flex;
  flex-grow: 1;
}
.admin-types-area-right {
  display: flex;
}
.evaluation-dropdown {
  background-color: var(--surface-100);
  border-radius: 8px;
  width: 130px;
  margin-right: 7px !important;
}
.evaluation-search-bar {
  background-color: var(--surface-100);
  border-radius: 8px;
}
.admin-list-table-container {
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  /* overflow-y: auto; */
}
.empty-selected-options-text {
  padding: 10px 16px;
  font-size: 12px;
  color: var(--surface-medium);
  cursor: pointer;
  opacity: 0.8;
}
.empty-selected-options-text:hover {
  opacity: 1;
}
.selected-filter-options-text {
  padding-top: 1px;
  color: var(--surface-medium);
}
.admin-management-title {
  color: var(--primary-80);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.add-button {
  color: white;
  background-color: var(--primary-80);
  border-radius: 10px;
  margin-left: 10px;
  width: 62px;
  height: 32px;
  font-weight: normal;
  font-style: normal;
  align-self: end;
}
.btn-spacer {
  height: 16px;
  width: 1px;
  background: var(--gray-300);
}
.btn {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  outline: none;
  /* identical to box height, or 162% */

  display: flex;
  align-items: center;
  text-align: center;
  &.ok {
    color: var(--primary-100);
    margin-right: 16px;
  }
  &.cancel {
    color: var(--error-100);
    margin-left: 16px;
  }
}
</style>
