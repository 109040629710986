<template>
  <div class="view-container">
    <div class="content-container">
      <div class="title-container">
        <div style="display: flex; flex-direction: row">
          <div class="title-text">
            {{ '약관 가이드' }}
          </div>
        </div>
      </div>
      <div class="list-table-container">
        <TncGuidanceTable
          :table-name="'tnc-guidance'"
          :titles="TABLE.TNC_GUIDANCE_ADMIN.SIZE"
          :titleNames="TABLE.TNC_GUIDANCE_ADMIN.NAME"
          :contents="tncGuidanceList"
        />
        <Pagenation :totalCount="tncGuidanceList.length" @update="() => {}" />
      </div>
    </div>
  </div>
</template>

<script>
import TABLE from '@/table.js';
import TncGuidanceTable from '@/components/Table.vue';
import Pagenation from '@/components/Pagenation.vue';

export default {
  name: 'TermsGuidance',
  components: {
    TncGuidanceTable,
    Pagenation,
  },
  async created() {},
  data() {
    return {
      TABLE: TABLE,
      tncGuidanceList: [
        {
          id: 'idcard',
          title: '신분증 인증',
        },
        {
          id: 'account',
          title: '계좌 인증',
        },
        {
          id: 'idcard-face',
          title: '신분증 인증 ˙ 얼굴 인증',
        },
        {
          id: 'idcard-account',
          title: '신분증 인증 ˙ 계좌 인증',
        },
        {
          id: 'idcard-face-account',
          title: '신분증 인증 ˙ 얼굴인증 ˙ 계좌인증',
        },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.title-container {
  height: 36px;
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.title-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.title-text {
  color: var(--primary-80);
}

.list-table-container {
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
</style>
