<template>
  <div class="root-container">
    <canvas id="canvas_crop" ref="canvas_crop" style="display: none"></canvas>
    <!-- phase1: card capture -->
    <div v-if="phaseUpload === PHASE_SCAN" class="container-phase-guide">
      <Step :phase="phase" :items="phaseItems"></Step>
      <!-- <div class="spacer" style="padding-top:50px;"></div> -->
      <div
        style="
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          margin: 70px auto 27px;
          text-align: center;
        "
      >
        본인 확인을 위해<br />신분증 사진을 첨부해 주세요.
      </div>
      <img
        src="@/assets/icon-card-guide.svg"
        width="177px"
        style="margin: 24px auto 42px; "
      />
      <div style="display:flex;justify-content:center;">
        <div style="width: 50%; position:relative;">
          <v-file-input
            accept=".jpg,.jpeg,.png"
            background-color="var(--gray-100)"
            class="rounded-xl"
            clearable
            clear-icon="clear"
            filled
            flat
            solo
            ref="cardInput"
            :height="40"
            dense
            v-model="uploadFile"
            @change="onChangeFile"
            style="visibility:hidden; border-raidus: 8px; width: calc(100% - 36px); height: 40px; margin-left: 10px;margin-bottom: 32px;"
          ></v-file-input>
          <div
            v-if="!uploadFile"
            @click="clickInput"
            class="input-status-container"
            style=""
          >
            <div
              style="font-size:1rem; font-weight: 500;color: var(--surface-medium) "
            >
              사진 첨부
              <span
                style="font-size:13px; font-weight: 400;color: var(--surface-medium)"
                >(JPG, JPEG, PNG / 10MB 이하만 가능)</span
              >
            </div>
            <div style="margin-right:-8px">
              <img src="@/assets/upload_plus.svg" alt="upload" />
            </div>
          </div>
          <div @click="clickInput" class="input-status-container" v-else>
            <div style="flex: 1; height: 100%;">
              {{ uploadFile.name }}
            </div>
            <div style="margin-right: -8px" @click="clickClear">
              <img src="@/assets/cardClear.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div style="align-self:center;">
        <div class="text-title-tip">올바른 사진 TIP</div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>
            반드시
            <span style="color: var(--primary-80)">신분증 원본</span> 앞면이
            촬영된 사진으로 제출하세요.
          </div>
        </div>
        <div class="tip-container">
          <img
            src="@/assets/icon-check.svg"
            height="10px"
            style="margin-right: 16px"
          />
          <div>
            빛 반사, 흔들림이 없는 사진인지 확인해 주세요.<br />
            훼손이 심한 신분증은 거절될 수도 있습니다.
          </div>
        </div>
      </div>
      <!-- <div class="spacer" style="padding-bottom: 100px;"></div> -->
      <div class="button-container">
        <div @click="$emit('cancel', { prev: true })" class="button cancel">
          취소
        </div>
        <div
          @click="onSubmit"
          :class="{ button: true, ok: true, disabled: !this.uploadFile }"
        >
          신분증 제출
        </div>
      </div>
    </div>
    <!-- phase2: loading -->
    <div v-if="phaseUpload === PHASE_LOADING" class="container-phase-loading">
      <img
        src="@/assets/Loading.svg"
        width="172px"
        style="margin-bottom: 50px"
      />
      <img
        src="@/assets/Loading_40px.svg"
        width="40px"
        style="margin-bottom: 16px"
      />
      <div style="font-weight: bold; font-size: 24px; margin-bottom: 8px">
        잠시만 기다려주세요.
      </div>
      <div style="font-size: 16px; color: var(--surface-medium)">
        신분증 정보를 확인 중입니다.
      </div>
    </div>
    <!-- modal -->
    <ServerErrorDialog
      v-model="serverError"
      :icon="errorIcon"
      :title="errorMessageTitle"
      :message="errorMessage"
      :errorCode="errorCode"
      :button="errorButton"
      @cancel="onClickBack"
      @ok="onClickRetry"
    />
    <CardScanConfirmDialog
      v-model="confirmDialog"
      :upload="true"
      :image="base64MaskCardImage"
      @ok="onConfirmOk"
      @cancel="onClickRetry"
    />
    <FileUploadErrorDialog
      :show="showFileUploadErrorDialog"
      :error-type="fileUploadErrorType"
      @ok="onConfirmFileUploadError()"
    />
    <ExitDialog
      v-model="exitDialog"
      @ok="$emit('cancel')"
      @cancel="exitCancel"
    />
  </div>
</template>
<script>
import Constants from '@/constants';
import useb from '@/api/useb';
import Step from './Step';
import ServerErrorDialog from '../dialog/ServerErrorDialog';
import CardScanConfirmDialog from '../dialog/CardScanConfirmDialog';
import FileUploadErrorDialog from '../dialog/FileUploadErrorDialog';
import ExitDialog from '../dialog/ExitDialog';
import * as faceApi from './face-sdk-impl.js';
// import * as netfunnel from './netfunnel.js';
//import * as netfunnelSkin from "./netfunnel-skin.js";
//console.log(netfunnelSkin);   // prevent build error for unused import file
import { mapState } from 'vuex';

export default {
  components: {
    Step,
    ServerErrorDialog,
    CardScanConfirmDialog,
    FileUploadErrorDialog,
    ExitDialog,
  },
  props: {
    phase: Number,
    phaseItems: Array,
    appData: Object,
    /**
     * emit events
     * cancel
     * next
     */
  },
  data: function() {
    return {
      PHASE_SCAN: 1,
      PHASE_LOADING: 2,
      phaseUpload: 1,
      confirmDialog: false,
      base64MaskCardImage: '',
      tmpAppData: {},
      systemError: false,
      serverError: false,
      exitDialog: false,
      errorIcon: '',
      errorMessageTitle: [],
      errorMessage: [],
      errorCode: '',
      errorCodeOrigin: '',
      errorButton: [],
      errorCodeControlList: ['O003', 'O005', 'O010', 'M003', 'O021', 'O022'],
      cardGuideIcon: require('@/assets/icon-card-guide.svg'),
      card: { ltX: 0, ltY: 0, rtX: 0, rtY: 0, lbX: 0, lbY: 0, rb: 0, rbY: 0 },
      uploadFile: null,
      showFileUploadErrorDialog: false,
      fileUploadErrorType: '',
    };
  },
  computed: {
    ...mapState(['companyPhoneNumber', 'isMobile']),
  },
  methods: {
    exitCancel() {
      this.exitDialog = false;
      this.serverError = true;
    },
    clickInput() {
      this.$refs.cardInput.$refs.input.click();
    },
    clickClear(e) {
      e.stopPropagation();
      this.uploadFile = null;
    },
    async onChangeFile(file) {
      this.$log.debug('CardUpload#onChangeFile', file);

      // 파일 크기가 크면 image onload 하는데 시간이 걸리기 때문에 일차적으로 확장자 파일 크기 점검
      const ACCEPTED_FILE_SIZE = 10485760; // 10MB
      const ACCEPTED_FILE_FORMATS = /(.*?)\.(jpg|jpeg|png|JPG|JPEG|PNG)$/;
      if (file && !this.captureProcess) {
        const isValidFileSize = file.size <= ACCEPTED_FILE_SIZE;
        const isValidFileFormat = file.name.match(ACCEPTED_FILE_FORMATS);

        this.$log.debug(
          'file#onChangeFile()',
          !this.captureProcess,
          isValidFileSize,
          isValidFileFormat
        );

        if (!isValidFileSize || !isValidFileFormat) {
          this.fileUploadErrorType = !isValidFileSize ? 'size' : 'format';
          this.$log.debug('open dialog');
          this.showFileUploadErrorDialog = true;
          return;
        }
      }
      this.$log.debug('파일 확장자 및 용량 검사 진행 완료');
      // 이미지를 캔버스에 대입해서 이미지가 유효한지 검증
      const readFile = () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(this.uploadFile);
          reader.onload = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
              context.drawImage(image, 0, 0, image.width, image.height);
              resolve();
            };
            image.onerror = error => {
              this.$log.error('CardUpload#onSubmit#imageError: ', error);
              this.fileUploadErrorType = 'invalid-image';
              this.showFileUploadErrorDialog = true;
              reject(error);
            };
          };
        });
      };
      await readFile();

      this.uploadFile = file;
    },
    onConfirmFileUploadError() {
      this.uploadFile = null;
      this.showFileUploadErrorDialog = false;
    },
    async cropFaceImage(image) {
      let cardImage = new Image();
      cardImage.src = image;
      const result = await faceApi.detectSingleFaceForCard(cardImage);
      if (!result) {
        throw new useb.UsebError(
          Constants.SERVER.ERROR_CODE.CARD_UPLOAD_FACE_RECOGNITION_FAILED,
          '얼굴 감지 실패'
        );
      }
      const box = result.detection.box;
      const face = { x: box.x, y: box.y, w: box.width, h: box.height };
      const padding = 200;
      const canvas_crop = this.$refs.canvas_crop;
      const ctx = canvas_crop.getContext('2d');
      canvas_crop.width = face.w + padding;
      canvas_crop.height = face.h + padding;
      const cropSizeX = face.x - padding / 2;
      const cropSizeY = face.y - padding / 2;
      ctx.drawImage(
        cardImage,
        cropSizeX < 0 ? 0 : cropSizeX,
        cropSizeY < 0 ? 0 : cropSizeY,
        face.w + padding,
        face.h + padding,
        0,
        0,
        face.w + padding,
        face.h + padding
      );
      return canvas_crop.toDataURL('image/jpeg');
    },
    onClickBack() {
      if (
        this.phaseUpload === this.PHASE_SCAN ||
        this.phaseUpload === this.PHASE_LOADING
      ) {
        // this.$emit('cancel', { prev: true });
        // this.$emit('cancel');
        this.exitDialog = true;
      }
    },
    onClickRetry() {
      if (this.systemError) {
        this.$emit('cancel');
      } else {
        if (
          this.errorCodeOrigin &&
          this.errorCodeControlList.includes(this.errorCodeOrigin)
        ) {
          this.$emit('cancel', { prev: true });
        } else {
          this.uploadFile = null;
          this.phaseUpload = this.PHASE_SCAN;
        }
      }
    },
    onSubmit() {
      if (this.uploadFile) {
        const reader = new FileReader();
        reader.readAsDataURL(this.uploadFile);
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            context.drawImage(image, 0, 0, image.width, image.height);
            const base64Card = canvas.toDataURL('image/jpeg');

            this.capture(base64Card);
          };
          image.onerror = error => {
            this.$log.error('CardUpload#onSubmit#imageError: ', error);
            this.fileUploadErrorType = 'invalid-image';
            this.showFileUploadErrorDialog = true;
          };
        };
        reader.onerror = error => {
          this.$log.error('CardUpload#onSubmit#readerError: ', error);
        };
      }
    },
    async capture(base64Card) {
      try {
        if (this.captureProcess) return;
        this.captureProcess = true;
        this.phaseUpload = this.PHASE_LOADING;
        let base64CardImage, base64Face, mime;
        if (base64Card) {
          const splitedImageData = base64Card.split(',');
          mime = splitedImageData[0];
          base64CardImage = splitedImageData[1]; // remove mime
          // this.$refs.capture.src = base64Card; // for test
          // return;
        }
        if (
          base64Card &&
          this.appData.moduleName.includes(Constants.MODULE.FACE)
        ) {
          base64Face = await this.cropFaceImage(base64Card);
          // if (base64Face) { // for test
          //   this.$refs.capture.src = base64Face;
          //   return;
          // }
        }
        this.tmpAppData = { base64Card, base64Face, mime, base64CardImage };
        this.base64MaskCardImage = base64Card;
        this.confirmDialog = true;
        this.captureProcess = false;
      } catch (e) {
        this.$log.debug('CardScan#capture error', e.errorCode, e.message);
        this.captureProcess = false;
        if (
          e.errorCode &&
          typeof e.errorCode === 'string' &&
          e.errorCode.startsWith(
            Constants.SERVER.ERROR_CODE.CARD_UPLOAD_FACE_RECOGNITION_FAILED
          )
        ) {
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
          this.errorMessageTitle = [e.message];
          this.errorMessage = [
            '신분증 사진에서',
            '얼굴을 감지하지 못하였습니다.',
            '재시도 하시겠습니까?',
          ];
          this.errorButton = ['종료', '재시도'];
        }
        this.errorCodeOrigin = e.errorCode;
        this.errorCode = e.errorCode ? `에러코드 : ${e.errorCode}` : '';
      }
    },
    async onConfirmOk() {
      // netfunnel for waiting queue
      // const _this = this;
      // netfunnel.NetFunnel_Action(
      //   { action_id: 'idcard_ocr' },
      //   {
      //     success: function() {
      //       //대기가 없거나, 대기 종료 후에 넷퍼넬 자원 할당을 받을 때 호출
      //       __impl().then(ret => {
      //         _this.$log.debug('NetFunnel_Action Result : ' + ret);
      //         netfunnel.NetFunnel_Complete();
      //       });
      //     },
      //     continued: function() {
      //       //대기 단계에서 호출
      //       // nothing to do
      //     },
      //     stop: function() {
      //       //대기창의 중지 버튼 클릭 시 호출
      //       netfunnel.NetFunnel_Complete();
      //       //_this.uploadFile = null;
      //       _this.phaseUpload = _this.PHASE_SCAN;
      //     },
      //     error: function(ev, ret) {
      //       //넷퍼넬 서버에서 error응답을 받았을 때
      //       //(default: error callbcak생략시, error도 success로 동작)
      //       netfunnel.NetFunnel_Complete();
      //       try {
      //         if (ret?.code == '513') {
      //           _this.$log.error(
      //             'NetFunnel_Action Skip (cause : ' + ret?.data?.msg
      //           );
      //           __impl();
      //           return;
      //         }
      //
      //         let error_code = 'NFN_ERR';
      //         if (ret?.code) {
      //           error_code += '_' + ret?.code;
      //         }
      //         if (ret?.data?.msg) {
      //           error_code += '_' + ret?.data?.msg;
      //         }
      //
      //         throw new useb.UsebError(
      //           error_code,
      //           'netFunnel Error\n - ' + JSON.stringify(ret)
      //         );
      //       } catch (e) {
      //         errorPopupProcess.call(_this, e);
      //       }
      //     },
      //     /*
      //   bypass: function(ev, ret){
      //     //넷퍼넬 관리자페이지에서 해당 actionID를 bypass(우회) 설정 시 호출
      //   },
      //   block: function(ev, ret){
      //     //넷퍼넬 관리자페이지에서 해당 actionID를 block(차단) 설정 시 호출
      //   },
      //   ipblock: function(ev, ret){
      //     //엑세스 제어기능을 통해, 제어룰에 해당되어 차단되는 경우에 호출
      //   },
      //   expressnumber: function(ev, ret){
      //     //VIP 설정(ip, id)에 등록된 사용자의 요청이 있을 경우에 호출 bypass
      //   }
      //   */
      //   }
      // );

      const __impl = async () => {
        try {
          const {
            base64Card,
            base64Face,
            mime,
            base64CardImage,
          } = this.tmpAppData;
          const cardIndex = this.appData.cardIndex;
          if (cardIndex === Constants.APP_CARD_INDEX.JUMIN) {
            // 주민등록증
            const ocr = await useb.getOcrIdcard(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            if (ocr.idType == 1) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError('O003');
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.DRIVER) {
            // 운전면허증
            const ocr = await useb.getOcrDriver(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            if (ocr.idType == 2) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              throw new useb.UsebError('O003');
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_KOREAN) {
            // 한국 여권
            this.$log.debug('한국 여권 진입점');
            const ocr = await useb.getOcrPassport(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            if (ocr._juminNo2) {
              this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
            } else {
              this.success(ocr, base64Card, base64Face);
            }
          } else if (cardIndex === Constants.APP_CARD_INDEX.PASSPORT_ALIEN) {
            // 외국 여권
            this.$log.debug('외국인 여권 진입점');
            const ocr = await useb.getOcrPassportOverseas(base64CardImage);
            this.$log.debug(ocr);
            // if (!ocr.image_base64_mask) {
            //   throw new useb.UsebError("O003");
            // }
            this.success(ocr, base64Card, base64Face);
          } else if (cardIndex === Constants.APP_CARD_INDEX.ALIEN) {
            // 외국인등록증
            const ocr = await useb.getOcrAlien(base64CardImage);
            if (!ocr.image_base64_mask) {
              throw new useb.UsebError('O003');
            }
            this.success(ocr, `${mime},${ocr.image_base64_mask}`, base64Face);
          }
        } catch (e) {
          errorPopupProcess.call(this, e);
          return 'error: ' + e;
        }
        return 'success';
      };
      await __impl();

      const errorPopupProcess = e => {
        this.$log.debug('CardScan#onConfirmOk error', e.errorCode, e.message);
        if (
          e.errorCode &&
          typeof e.errorCode === 'string' &&
          this.errorCodeControlList.includes(e.errorCode)
        ) {
          this.serverError = true;
          this.errorIcon = this.cardGuideIcon;
          this.errorButton = ['종료', '재시도'];
          this.errorMessageTitle = ['정보 감지 실패'];
          this.errorMessage = [
            '신분증 사진에서',
            '신분증을 인식하지 못하였습니다.',
            '재시도 하시겠습니까?',
          ];
        } else {
          this.$log.debug(e);
          this.systemError = true;
          this.serverError = true;
          this.errorIcon = '';
          this.errorMessageTitle = [
            '시스템 에러가 발생하였습니다.',
            '잠시 후 다시 이용해 주세요.',
          ];
          this.errorMessage = [
            '계속해서 문제가 발생한다면',
            `고객센터(${this.companyPhoneNumber})으로 문의해주세요.`,
          ];
          this.errorButton = ['', '확인'];
        }
        this.errorCodeOrigin = e.errorCode;
        this.errorCode = e.errorCode ? `에러코드 : ${e.errorCode}` : '';
      };
    },
    success(ocr, base64Card, base64Face) {
      this.$log.debug('CardScan#success', ocr);
      delete ocr.image_base64_mask;
      this.$emit('next', {
        ocr,
        base64Card,
        base64Face,
        base64CardOrigin: this.tmpAppData.base64CardImage,
      });
      this.$emit('uploadtype', this.isMobile ? 'mobile' : 'pc');
    },
  },
};
</script>

<style lang="scss" scoped>
.root-container {
  position: relative;
  height: 100%;
  color: var(--surface-high);
  overflow: auto;
}

.container-phase-guide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-title-tip {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 20px;
  }

  .tip-container {
    display: flex;
    align-items: baseline;
    text-align: left;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 16px;
    font-size: 16px;
  }
}

.spacer {
  flex-grow: 1;
}

// v-input custom
.input-status-container {
  // height: 40px;
  min-width: 200px;
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0px;
  width: calc(100% - 24px);
  left: 24px;
  padding: 8px 24px;
  background: var(--gray-100);
}

.v-input__slot {
  border-radius: 8px !important;
}

.button-container {
  margin-top: 56px;
  width: 100%;
  bottom: 0;
  display: flex;
  padding: 0px 30px 40px 30px;
  background-color: white;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1rem;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }

    &.disabled {
      background-color: var(--primary-20);
    }
  }
}

.container-phase-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--secondary-100);
  color: white;
}
</style>
