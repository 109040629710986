<template>
  <v-dialog
    v-model="dialog"
    max-width="501px"
    max-height="378px"
    @click:outside="ok"
    @keydown.esc="ok"
    persistent
    hide-overlay
  >
    <v-card style="border-radius: 8px">
      <v-card-text
        style="display: flex; flex-direction: column; padding: 0; margin: 0"
      >
        <div
          style="
            text-align: center;
            margin-top: 40px;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: var(--surface-high);
            flex: none;
            order: 0;
            flex-grow: 0;
          "
        >
          알림
        </div>
        <div
          style="
            margin-top: 24px;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            color: var(--primary-100);
            text-align: center;
          "
        >
          로그인 시간이 만료되었습니다.
        </div>
        <div class="button-container" style="margin-bottom: 32px">
          <div @click="ok" class="button ok">확인</div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    errorCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    ok() {
      this.$emit('ok');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  margin: auto;
  margin-bottom: 40px;
  padding: 0 30px;

  height: 48px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;

    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.ok {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--primary-100);
    }
  }
}
</style>
