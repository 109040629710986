<template>
  <div class="view-container">
    <div class="content-container">
      <div class="types-container">
        <div style="display: flex; flex-direction: row">
          <div
            v-for="(sType, sIndex) in settingTypes"
            :key="sType"
            :class="
              `types-text types-text${
                sIndex === selectedTypeIndex ? '-selected' : '-unselected'
              }`
            "
            @click="selectSettingType(sIndex)"
          >
            {{ sType }}
          </div>
        </div>
        <UpsideEnrollButton @click="enroll" :button-string="`+개정`" />
      </div>
      <div class="list-table-container" :key="`${selectedTypeIndex}-table`">
        <PpTable
          v-show="selectedTypeIndex == 0"
          :table-name="'pp'"
          :titles="TABLE.PP.SIZE"
          :titleNames="TABLE.PP.NAME"
          :contents="ppList"
          :no-data-messages="['항목이 없습니다.']"
        />
        <TncTable
          v-show="selectedTypeIndex == 1"
          :table-name="'tnc'"
          :titles="TABLE.TNC.SIZE"
          :titleNames="TABLE.TNC.NAME"
          :contents="tncList"
          :no-data-messages="['항목이 없습니다.']"
        />
        <Pagenation
          :totalCount="totalPageCount"
          @update="showTncList($event)"
          :key="`${selectedTypeIndex}-page`"
        />
      </div>
    </div>
    <ReviseDialog
      v-model="revisionFlag"
      :title="
        selectedTypeIndex == 1 ? '이용약관 개정' : '개인정보처리방침 개정'
      "
      @close="revisionFlag = false"
      :type="selectedTypeIndex == 0 ? 'pp' : 'tnc'"
      :key="`${revisionFlag}-revise`"
    />
  </div>
</template>

<script>
import UpsideEnrollButton from '@/components/UpsideEnrollButton.vue';
import TncTable from '@/components/Table.vue';
import PpTable from '@/components/Table.vue';
import ReviseDialog from '@/components/root/dialog/TncPpDialog.vue';
import Pagenation from '@/components/Pagenation.vue';
import Vue from 'vue';
import TABLE from '@/table.js';
import { mapState } from 'vuex';
export default {
  name: 'TermsManagement',
  components: {
    UpsideEnrollButton,
    TncTable,
    PpTable,
    Pagenation,
    ReviseDialog,
  },
  data() {
    return {
      selectedTypeIndex: 0,
      revisionFlag: false,
      TABLE: TABLE,
      totalPageCount: 0,
    };
  },
  async created() {
    let result = await this.$store.dispatch('getNoticeList', {
      kind: 'pp',
      start: 0,
      count: 10,
    });
    this.totalPageCount = result.total_count;
  },
  destroyed() {
    this.$store.state.ppList = [];
    this.$store.state.tncList = [];
    this.$store.state.tncListDetail = {};
    this.$store.state.ppListDetail = {};
  },
  computed: {
    settingTypes() {
      return ['개인정보처리방침', '이용약관'];
    },
    ...mapState({
      ppList: state => state.root.ppList,
      tncList: state => state.root.tncList,
    }),
  },
  methods: {
    async showTncList(event) {
      let result = await this.$store.dispatch('getNoticeList', {
        kind: this.selectedTypeIndex == 0 ? 'pp' : 'tnc',
        start: event.start,
        count: event.count,
      });
      this.totalPageCount = result.total_count;
    },
    async selectSettingType(index) {
      let result = null;
      switch (index) {
        case 0:
          result = await this.$store.dispatch('getNoticeList', {
            kind: 'pp',
            start: 0,
            count: 10,
          });
          break;
        case 1:
          result = await this.$store.dispatch('getNoticeList', {
            kind: 'tnc',
            start: 0,
            count: 10,
          });
          break;
        default:
          break;
      }
      this.$log.debug('TermsManagement#selectSettingType', result);
      this.totalPageCount = result.total_count;
      if (this.selectedTypeIndex !== index) {
        this.selectedTypeIndex = index;
      }
    },
    enroll() {
      //use selectedTypeIndex
      this.revisionFlag = true;
      Vue.$log.debug('TermsManagement#enroll');
    },
  },
};
</script>

<style scoped lang="scss">
.types-container {
  height: 36px;
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.types-text {
  display: flex;
  align-items: center;
  margin-right: 32px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.types-text-selected {
  color: var(--primary-80);
}
.types-text-unselected {
  cursor: pointer;
  color: var(--surface-disabled);
}
.list-table-container {
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
</style>
