<template>
  <div>
    <div>
      <CustomInputTextForm
        validity="individual-id"
        title="아이디"
        placeholder="8~20자의 영문, 숫자로 입력하세요."
        :needCheck="true"
        :info-font-small="true"
        @click="userIdCheck ? userid(userId) : () => {}"
        @set="
          $event => {
            userId = $event.val;
            userIdCheck = $event.res;
            userIdDuplicationCheck = '';
          }
        "
      />
      <div
        v-if="userIdDuplicationCheck"
        :key="`${userIdDuplicationCheck}-check`"
        :style="
          `font-size: 12px;margin-top:8px;
                ${
                  userIdDuplicationCheck.includes('가능한')
                    ? 'color:var(--primary-100);'
                    : 'color:var(--error-100);'
                }`
        "
      >
        {{ userIdDuplicationCheck }}
      </div>
    </div>
    <div>
      <CustomInputTextForm
        validity="name"
        title="이름"
        :info-font-small="true"
        placeholder="이름을 입력하세요."
        @set="
          $event => {
            userName = $event.val;
            userNameCheck = $event.res;
          }
        "
      />
    </div>
    <div>
      <CustomInputTextForm
        validity="phone"
        title="전화번호"
        :info-font-small="true"
        placeholder="‘-’를 포함하여 입력하세요."
        @set="
          $event => {
            userPhone = $event.val;
            userPhoneCheck = $event.res;
          }
        "
      />
    </div>
    <div>
      <CustomInputTextForm
        validity="email"
        title="이메일"
        :info-font-small="true"
        :needCheck="true"
        placeholder="이메일을 입력하세요."
        @click="userEmailCheck ? email(userEmail) : () => {}"
        @set="
          $event => {
            userEmail = $event.val;
            userEmailCheck = $event.res;
            userEmailDuplicationCheck = '';
            if (userEmailCheck) {
              userEmailDuplicationCheck = '중복여부를 확인하세요.';
            }
          }
        "
      />
      <div
        v-if="userEmailDuplicationCheck"
        :key="`${userEmailDuplicationCheck}-check`"
        :style="
          `font-size: 12px;margin-top:8px;
                ${
                  userEmailDuplicationCheck.includes('가능한')
                    ? 'color:var(--primary-100);'
                    : 'color:var(--error-100);'
                }`
        "
      >
        {{ userEmailDuplicationCheck }}
      </div>
    </div>
    <div style="height: 56px" />
    <div class="signup-row">
      <SubmitButton
        width="40%"
        height="60px"
        font-size="20px"
        button-string="취소"
        bg-color="#F6F6F6"
        text-color="var(--surface-medium)"
        @click="prev"
      />
      <div style="width: 10px" />
      <SubmitButton
        :dim="!isRegisterable"
        width="60%"
        height="60px"
        font-size="20px"
        button-string="등록"
        bg-dim-color="var(--primary-20)"
        bg-color="var(--primary-100)"
        text-dim-color="var(--surface-100)"
        text-color="var(--surface-100)"
        @click="register()"
      />
    </div>
  </div>
</template>
<script>
import SubmitButton from '@/components/SubmitButton.vue';
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';

export default {
  name: 'SignUp',
  components: {
    SubmitButton,
    CustomInputTextForm,
  },
  props: {
    customerId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      userId: '',
      userIdCheck: false,
      userIdDuplicationCheck: '',
      userName: '',
      userNameCheck: false,
      userPhone: '',
      userPhoneCheck: false,
      userEmail: '',
      userEmailCheck: false,
      userEmailDuplicationCheck: '',
    };
  },
  created() {},
  computed: {
    isRegisterable() {
      return (
        this.userIdDuplicationCheck.includes('가능한') > 0 &&
        this.userEmailDuplicationCheck.includes('가능한') > 0 &&
        this.userNameCheck &&
        this.userPhoneCheck
      );
    },
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    async userid(account) {
      this.$log.debug('ModifyUserInfo#email', account);
      var redundancy = await this.$store.dispatch('checkIdRedundancy', {
        customer_id: Number(this.customerId),
        value: this.userId,
      });
      if (redundancy) {
        switch (redundancy.api_response.result_code) {
          case 'E600':
            this.userIdDuplicationCheck =
              '입력 유형이 올바르지 않습니다. 확인 후 수정해 주세요.';
            break;
          case 'F602':
            this.userIdDuplicationCheck = '이미 사용중인 아이디입니다.';
            break;
          case 'N100':
            this.userIdDuplicationCheck = '사용 가능한 아이디입니다.';
            break;
          default:
            break;
        }
      }
    },
    async email(account) {
      this.$log.debug('ModifyUserInfo#email', account);
      var redundancy = await this.$store.dispatch('checkEmailRedundancy', {
        customer_id: Number(this.customerId),
        value: this.userEmail,
      });
      if (redundancy) {
        switch (redundancy.api_response.result_code) {
          case 'E600':
            this.userEmailDuplicationCheck =
              '입력 유형이 올바르지 않습니다. 확인 후 수정해 주세요.';
            break;
          case 'N100':
            this.userEmailDuplicationCheck = '사용 가능한 이메일입니다.';
            break;
          case 'F606':
            this.userEmailDuplicationCheck = '이미 사용중인 이메일입니다.';
            break;
          default:
            break;
        }
      }
      this.$log.debug('UserInfo#email', redundancy);
    },
    register() {
      var userinfo = {
        username: this.userId,
        name: this.userName,
        phone_number: this.userPhone,
        email: this.userEmail,
      };
      this.$emit('next', userinfo);
    },
  },
};
</script>
<style lang="scss" scoped></style>
