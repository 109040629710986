var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.text === '얼굴 인증' && _vm.faceCheckContentExists)?_c('section',{staticClass:"eec-expand",staticStyle:{"max-height":"288px","margin-bottom":"8px","overflow":"hidden"}},[_c('section',{staticClass:"eec-expand-half"},[_c('div',{staticClass:"eec-flex-row",staticStyle:{"margin-top":"24px"}},[_c('div',{staticClass:"label-key"},[_c('div',{staticStyle:{"width":"128px","display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{}},[_vm._v("얼굴정보 유사도")]),_c('img',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
              function () {
                _vm.similarityDiagFlag = false;
              }
            ),expression:"\n              () => {\n                similarityDiagFlag = false;\n              }\n            "}],attrs:{"src":require("@/assets/tooltip.svg"),"width":"10px"},on:{"click":function($event){_vm.similarityDiagFlag = true}}}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.similarityDiagFlag),expression:"similarityDiagFlag"}],staticClass:"tooltip"},[_c('div',[_vm._v("신분증 사진과 셀피 사진을 비교하여 유사도 제공")])])])])]),(_vm.autoSimilarityCheck)?_c('div',{staticClass:"label-value"},[_vm._v("High")]):_c('div',{staticClass:"label-value--red"},[_vm._v("Low")])]),(_vm.liveness)?_c('div',{staticClass:"eec-flex-row",staticStyle:{"margin-top":"16px"}},[_c('div',{staticClass:"label-key"},[_c('div',{staticStyle:{"width":"128px","display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{}},[_vm._v("얼굴 위변조 검사")]),_c('img',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
              function () {
                _vm.livenessDiagFlag = false;
              }
            ),expression:"\n              () => {\n                livenessDiagFlag = false;\n              }\n            "}],attrs:{"src":require("@/assets/tooltip.svg"),"width":"10px"},on:{"click":function($event){_vm.livenessDiagFlag = true}}}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.livenessDiagFlag),expression:"livenessDiagFlag"}],staticClass:"tooltip"},[_c('div',[_vm._v("실제 사람 얼굴인지 여부를 검사")])])])])]),(_vm.faceLiveness)?_c('div',{staticClass:"label-value"},[_vm._v("통과")]):_c('div',{staticClass:"label-value--red"},[_vm._v("실패")])]):_vm._e()]),_c('div',{staticStyle:{"width":"32px"}}),_c('section',{staticClass:"eec-expand-half"},[_c('div',{staticClass:"eec-flex-row",staticStyle:{"margin-top":"24px"}},[_c('div',{staticClass:"label-key"},[_vm._v("신분증 사진")]),(!!_vm.detailedItemData)?_c('img',{staticStyle:{"border":"2px solid #d8d8d8"},attrs:{"width":"120px","height":"120px","src":_vm.idCropImage}}):_c('img',{staticStyle:{"border":"2px solid #d8d8d8"},attrs:{"width":"120px","height":"120px"}})]),_c('div',{staticClass:"eec-flex-row",staticStyle:{"margin-top":"24px"}},[_c('div',{staticClass:"label-key"},[_vm._v("검출 사진")]),(!!_vm.detailedItemData)?_c('img',{staticStyle:{"border":"2px solid #d8d8d8"},attrs:{"width":"120px","height":"120px","src":_vm.selfieImage}}):_c('img',{staticStyle:{"border":"2px solid #d8d8d8"},attrs:{"width":"120px","height":"120px"}})])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }