<template>
  <div class="card-result-fail-root">
    <div
      style="
      margin-top:32px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--surface-high);"
    >
      신분증 인증
    </div>
    <div class="card-result-fail-info-container">
      <img
        src="@/assets/icon-card-fail.svg"
        width="230px"
        style="margin-bottom:51px;"
      />
      <div
        style="
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: var(--primary-100);
        margin-bottom:24px;"
      >
        본인 인증 실패
      </div>
      <div
        style="
        font-weight: normal;
        text-align:center;
        font-size: 16px;
        line-height: 26px;
        color: var(--surface-high);
        margin-bottom:8px;"
      >
        신분증 진위 확인이 가능한 횟수를 초과하여<br />
        본인 인증에 실패하였습니다.<br />
        처음부터 다시 시도해주세요.
      </div>
      <div
        style="
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: var(--surface-medium);
        "
      >
        시도 횟수 : 3/3
      </div>
    </div>
    <div @click="$emit('ok')" class="button-container">확인</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 56px;
  // flex-grow: 1;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: white;
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>
