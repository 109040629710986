<template>
  <section class="eec-expand-half">
    <div class="eec-flex-row" style="margin-top: 24px">
      <div class="label-key">신분증 정보</div>
      <div class="label-value">
        {{ idType }}
      </div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">사진 첨부 여부</div>
      <div v-if="attachedPhoto" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">직접 입력 여부</div>
      <div v-if="manualInput" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>
    <div class="eec-flex-row" style="margin-top: 16px">
      <div class="label-key">정보 수정 여부</div>
      <div v-if="idCardModified" class="label-value--red font-500">YES</div>
      <div v-else class="label-value">NO</div>
    </div>

    <div>
      <div class="eec-flex-row" style="margin-top: 24px">
        <div class="label-key-list">· 이름</div>
        <div
          :class="{
            'label-value': !isModifiedData.user_name,
            'label-value--red': isModifiedData.user_name,
          }"
        >
          {{ itemData.name }}
        </div>
      </div>

      <div
        v-if="passportNo && !isModifiedData.passport_no"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 여권번호</div>
        <div class="label-value">
          {{ passportNo }}
        </div>
      </div>
      <div class="eec-flex-row">
        <div v-if="isModifiedData.passport_no" class="label-key">
          · 여권번호
        </div>
        <div v-if="isModifiedData.passport_no" class="label-value--red">
          {{ modifiedPassportNo }}
        </div>
      </div>

      <div
        v-if="!isModifiedData.nationality"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 국적</div>
        <div class="label-value">
          {{ nationality }}
        </div>
      </div>
      <div
        v-if="isModifiedData.nationality"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 국적</div>
        <div class="label-value--red">
          {{ modifiedNationality }}
        </div>
      </div>
      <div
        v-if="!isModifiedData.birth_date"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 생년월일</div>
        <div class="label-value">
          {{ birthDate }}
        </div>
      </div>
      <div
        v-if="isModifiedData.birth_date"
        class="eec-flex-row"
        style="margin-top: 10px"
      >
        <div class="label-key-list">· 생년월일</div>
        <div class="label-value--red">
          {{ modifiedBirthDate }}
        </div>
      </div>
    </div>

    <div class="eec-flex-row" style="margin-top: 24px">
      <div class="label-key">신분증 진위확인</div>
      <div v-if="idCardStatus" class="label-value">확인</div>
      <div v-else class="label-value--red">실패</div>
    </div>
    <div class="eec-split-line"></div>
  </section>
</template>

<script>
export default {
  components: {},
  props: {
    isModifiedData: {
      type: Object,
      default: () => {},
    },
    itemData: {
      type: Object,
      default: () => {},
    },
    detailedItemData: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  data() {
    return {};
  },
  computed: {
    birthDate() {
      return this.detailedItemData.birth_date;
    },
    idCardModified() {
      return this.detailedItemData.id_card_modified;
    },
    idCardStatus() {
      return this.detailedItemData.id_card_status;
    },
    idType() {
      return this.detailedItemData.id_type;
    },
    modifiedBirthDate() {
      return this.detailedItemData.modified_birth_date;
    },
    modifiedNationality() {
      return this.detailedItemData.modified_nationality;
    },
    modifiedPassportNo() {
      return this.detailedItemData.modified_passport_no;
    },
    nationality() {
      return this.detailedItemData.nationality;
    },
    passportNo() {
      return this.detailedItemData.passport_no;
    },
    attachedPhoto() {
      return this.detailedItemData.is_uploaded;
    },
    manualInput() {
      return this.detailedItemData.is_manual_input;
    },
  },
  methods: {},
};
</script>
