var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('form',{key:(_vm.selectedFindType + "-selected"),class:{
      'signin-form default flex-center': !_vm.error,
      'signin-form error flex-center': _vm.error,
    }},[_c('div',{staticClass:"select-find-type flex-center"},[_c('div',{class:((_vm.selectedFindType == 0 ? 'selected' : 'unselected') + " flex-center"),on:{"click":function($event){_vm.selectedFindType = 0}}},[_vm._v(" 아이디 찾기 ")]),_c('div',{class:((_vm.selectedFindType == 1 ? 'selected' : 'unselected') + " flex-center"),on:{"click":function($event){_vm.selectedFindType = 1}}},[_vm._v(" 비밀번호 찾기 ")])]),_c('div',{staticStyle:{"width":"318px","padding-bottom":"16px"}},[_c('CustomInputTextForm',{attrs:{"validity":"client-comp-name","placeholder":"고객사명을 입력하세요.","comp-name-to-code":true,"init-val":_vm.customerInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
            _vm.customerInput = $event.val;
            _vm.customerInputChecked = $event.res;
            _vm.customerInputValue = $event.compid;
          }}})],1),(_vm.selectedFindType == 1)?_c('div',{staticStyle:{"width":"318px","padding-bottom":"16px"}},[_c('CustomInputTextForm',{attrs:{"validity":"individual-id","placeholder":"아이디","init-val":_vm.userIdInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"click":function($event){return _vm.checkRedundancy()},"set":function ($event) {
            _vm.userIdInput = $event.val;
          }}})],1):_vm._e(),_c('div',{staticStyle:{"width":"318px","padding-bottom":"16px"}},[_c('CustomInputTextForm',{attrs:{"validity":"name","placeholder":"이름","init-val":_vm.usernameInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"click":function($event){return _vm.checkRedundancy()},"set":function ($event) {
            _vm.usernameInput = $event.val;
          }}})],1),_c('div',{staticStyle:{"width":"318px","padding-bottom":"16px"}},[_c('CustomInputTextForm',{attrs:{"validity":"email","placeholder":"이메일","init-val":_vm.emailInput,"no-title":true,"for-login":true,"info-font-small":true},on:{"set":function ($event) {
            _vm.emailInput = $event.val;
          }}})],1),_c('div',{attrs:{"stlye":"position:relative;"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","width":"318px"}}),(_vm.error)?_c('div',{staticClass:"critical-error"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableNext && !_vm.isLoadingOn),expression:"enableNext && !isLoadingOn"}],staticClass:"signin-form-btn ok",on:{"click":function($event){return _vm.find()}}},[_vm._v(" 다음 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.enableNext && !_vm.isLoadingOn),expression:"!enableNext && !isLoadingOn"}],staticClass:"signin-form-btn default"},[_vm._v(" 다음 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingOn),expression:"isLoadingOn"}],staticClass:"signin-form-btn ok"},[_c('img',{attrs:{"src":require("@/assets/Loading_40px.svg"),"alt":"loading animation"}})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signin-title"},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"ekyc main logo"}})])}]

export { render, staticRenderFns }