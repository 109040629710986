<template>
  <div
    :class="
      `${
        $route.params.role == 0
          ? 'signin-admin-background'
          : 'signin-background'
      } full-size flex-center signin-container`
    "
  >
    <div class="pass-change-content flex-center">
      <div class="pass-change-card-container">
        <div class="pass-change-title">
          <img src="@/assets/logo.svg" alt="ekyc main logo" />
        </div>
        <form class="pass-change-form flex-center">
          <div class="pass-title">비밀번호 설정</div>

          <div stlye="position:relative;">
            <div style="width: 318px; padding-bottom: 8px">
              <CustomInputTextForm
                validity="individual-password"
                placeholder="신규 비밀번호"
                :is-password="true"
                @set="
                  $event => {
                    passwordInput = $event.val;
                    isPasswordInputValid = $event.res;
                  }
                "
                :init-val="passwordInput"
                :no-title="true"
                :for-login="true"
                :info-font-small="true"
              />
            </div>
            <div style="width: 318px; padding: 8px 0">
              <CustomInputTextForm
                validity="password"
                placeholder="신규 비밀번호 확인"
                :is-password="true"
                @set="
                  $event => {
                    passwordCheckInput = $event.val;
                    errorCheck();
                  }
                "
                :init-val="passwordCheckInput"
                :no-title="true"
                :for-login="true"
                :info-font-small="true"
              />
            </div>
            <div
              v-if="errorMsg"
              style="color: var(--error-100); font-size: 12px"
            >
              {{ errorMsg }}
            </div>
          </div>
          <div class="info-message">
            9~20자로 영문 대/소문자, 숫자, 특수문자를 포함하세요. (특수문자 :
            !@#$%^&)
          </div>
          <div
            v-if="buttonActivation"
            @click="changePassword()"
            class="pass-change-form-btn ok"
          >
            로그인
          </div>
          <div v-else class="pass-change-form-btn default">로그인</div>
        </form>
        <div class="pass-change-problem">
          고객센터 070-4849-1000 | cs@useb.co.kr
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANTS from '@/constants';
import server from '@/api/server';
import CustomInputTextForm from '@/components/CustomInputTextForm.vue';
//import server from "@/api/server";

export default {
  name: 'PassChange',
  components: {
    CustomInputTextForm,
  },
  data() {
    return {
      passwordInput: '',
      isPasswordInputValid: false,
      passwordCheckInput: '',
      visible: false,
      checkVisible: false,
      errorMsg: '',
      error: '',
    };
  },
  async created() {
    await server.destroyPermanentTokens();
  },
  computed: {
    buttonActivation() {
      return (
        this.isPasswordInputValid &&
        !this.errorMsg &&
        this.passwordCheckInput.length > 0 &&
        this.passwordInput.length > 0
      );
    },
  },
  methods: {
    async changePassword() {
      try {
        const data = await server
          .modifyMypageInfo({
            customer_id: Number(this.$route.params.customer_id),
            username: this.$route.params.username,
            password: this.$route.params.password,
            new_password: this.passwordCheckInput,
          })
          .then(res => {
            this.$log.debug('PassChange#Success or not', res);
          });

        this.$log.debug('PassChange#login', data);

        await this.login();
      } catch (error) {
        this.error = error;
      }
    },
    errorCheck() {
      if (!this.passwordCheckInput) {
        this.errorMsg = '비밀번호를 재입력하세요.';
      } else if (this.passwordInput != this.passwordCheckInput) {
        this.errorMsg = '입력하신 비밀번호가 일치하지 않습니다.';
      } else if (this.passwordInput == this.passwordCheckInput) {
        this.errorMsg = '';
      }
    },
    async login() {
      try {
        const TOKEN = await server.signin({
          customer_id: Number(this.$route.params.customer_id),
          username: this.$route.params.username,
          password: this.passwordInput,
        });

        this.$store.commit(
          'setCompanyPhoneNumber',
          server.getCompanyPhoneNumber()
        );

        // TOKEN['customer_id'] = Number(this.$route.params.customer_id); 왜 필요하지??
        switch (this.$route.params.loginType) {
          case 'reviewer':
            if (TOKEN.role != 1) {
              throw { message: '접근 권한이 없음' };
            }

            localStorage.setItem('REVIEWER_TOKEN', JSON.stringify(TOKEN));
            this.$router.push({
              name: CONSTANTS.ROUTE_NAME.EVALUATION_REVIEW,
            });

            break;
          case 'admin':
            if (TOKEN.role != 0) {
              throw { message: '접근 권한이 없음' };
            }
            localStorage.setItem('ADMIN_TOKEN', JSON.stringify(TOKEN));
            this.$router.push({
              name: CONSTANTS.ROUTE_NAME.REVIEWER_MANAGEMENT,
            });

            break;
          default:
            break;
        }
      } catch (error) {
        this.$log.debug(error);
        switch (error.message) {
          case 'inactive Account':
            this.error = '비활성화된 아이디입니다.';
            break;
          case 'incorrect Username or Password':
            this.error =
              '고객사 코드, 아이디 혹은 비밀번호를 잘못 입력하셨습니다.';
            break;
          case 'DB read error.':
            this.error = '데이터 베이스가 유효하지 않습니다.';
            break;
          case '접근 권한이 없음':
            this.error =
              '고객사 코드, 아이디 혹은 비밀번호를 잘못 입력하셨습니다.';
            //this.error = '접근 권한이 없습니다.';
            break;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info-message {
  margin-top: 8px;
  width: 318px;
  font-weight: 400;
  font-size: 13px;
  color: var(--surface-medium);
}
.pass-title {
  font-weight: 500;
  font-size: 24px;
  color: var(--primary-80);
  margin: 40px 0 38px 0;
}

.pass-change-content {
  width: 400px;
  margin-top: 14vh;
  max-height: 100%;
  overflow: scroll;
}
.pass-change-title {
  font-size: 32px;

  font-weight: 300;
  text-align: center;
  margin-bottom: 32px;
  color: var(--surface-100);
}
.input-default {
  height: 56px;
  width: 318px;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 16px;
  margin-bottom: 8px;
  font-size: 16px;
  caret-color: var(--primary-80);
}
.input-default:focus {
  outline: 2px solid var(--primary-80);
}
.pass-change-card-container {
  width: 400px;
  height: 721px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.pass-change-form {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  border-radius: 8px;
  justify-content: left;
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
}
.pass-change-form-col {
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin-bottom: 10px;
}
.pass-change-form-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 80px;
  margin-right: 10px;
}
.pass-change-problem {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--surface-medium);
  margin-top: 16px;
}
.pass-change-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  margin: 44px;
  height: 60px;
  cursor: pointer;

  &.default {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-20);
    cursor: unset;
  }

  &.ok {
    width: 324px;
    color: var(--surface-100);
    background: var(--primary-100);
  }
}
</style>
