<template>
  <div class="card-result-fail-root">
    <div
      style="
      margin-top:32px;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--surface-high);"
    >
      신분증 인증
    </div>
    <div class="card-result-fail-info-container">
      <img
        src="@/assets/icon-card-fail.svg"
        width="230px"
        style="margin-bottom:51px;"
      />
      <div
        style="
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        color: var(--primary-100);
        margin-bottom:24px;"
      >
        진위 확인 실패
      </div>
      <div
        style="
        font-weight: normal;
        text-align:center;
        font-size: 16px;
        line-height: 26px;
        color: var(--surface-high);
        margin-bottom:24px;"
      >
        5회 이상 발급 일자 입력 오류로 인해<br />
        고객님의 주민등록증이 잠김 처리되었습니다.<br />
        잠김 해제 후 다시 이용해 주세요.
      </div>
      <div class="unlock-guide">
        <div class="unlock-guide--title">
          <img src="@/assets/unlock_info.svg" alt="info-icon" />
          <span>주민등록증 잠김해제 방법</span>
        </div>
        <div class="unlock-guide--body">
          <div>가까운 읍면동 센터 방문</div>
          <div>
            정부24 접속 (<a
              target="_blank"
              style="text-decoration:underline; color:var(--primary-100)"
              href="https://www.gov.kr/main?a=AA090UserJuminIsCertApp&img=031"
              >바로가기</a
            >)
          </div>
        </div>
      </div>
    </div>
    <div @click="$emit('cancel')" class="button-container">확인</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  // position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  // height: 100%;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 56px auto;
}
.unlock-guide {
  padding: 16px 18px;
  background: #f7f7f7;
  border-radius: 8px;
  min-width: 260px;
  .unlock-guide--title {
    display: flex;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--surface-high);
    img {
      margin-right: 6.5px;
    }
  }
  .unlock-guide--body {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--surface-high);
    div::before {
      content: '';
      display: inline-block;
      margin: 0px 8px;
      width: 4px;
      height: 4px;
      background: var(--surface-high);
      border-radius: 50%;
      vertical-align: middle;
    }
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  width: calc(100% - 60px);
  color: white;
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>
