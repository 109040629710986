import server from './api/server';
import CONSTANTS from './constants';

function verifyRootToken() {
  var ROOT_TOKEN = localStorage.getItem('ROOT_TOKEN');

  ROOT_TOKEN = JSON.parse(ROOT_TOKEN);
  if (ROOT_TOKEN) {
    server.setToken(ROOT_TOKEN);
  }
  if (!ROOT_TOKEN) {
    if (server.getToken()) {
      // TODO : 추후 응닶값 Role에 따른 리턴값 세분화. API 업데이트되면 토큰 통합
      return true;
    }
    return false;
  }
  if (new Date(ROOT_TOKEN.expire).getTime() - 3600000 < Date.now()) {
    return false;
  }

  if (server.getToken()) {
    if (server.getToken().token == ROOT_TOKEN.token) {
      return true;
    }
  }
  server.setToken(ROOT_TOKEN);
  return true;
}
function verifyReviewerToken() {
  var REVIEWER_TOKEN = localStorage.getItem('REVIEWER_TOKEN');

  REVIEWER_TOKEN = JSON.parse(REVIEWER_TOKEN);
  if (REVIEWER_TOKEN) {
    server.setToken(REVIEWER_TOKEN);
  }
  if (!REVIEWER_TOKEN) {
    return false;
  }
  if (new Date(REVIEWER_TOKEN.expire).getTime() - 3600000 < Date.now()) {
    return false;
  }

  if (server.getToken()) {
    if (server.getToken().token == REVIEWER_TOKEN.token) {
      return true;
    }
  }
  server.setToken(REVIEWER_TOKEN);
  return true;
}
function verifyAdminToken() {
  var ADMIN_TOKEN = localStorage.getItem('ADMIN_TOKEN');

  ADMIN_TOKEN = JSON.parse(ADMIN_TOKEN);
  if (ADMIN_TOKEN) {
    server.setToken(ADMIN_TOKEN);
  }
  if (!ADMIN_TOKEN) {
    return false;
  }
  if (new Date(ADMIN_TOKEN.expire).getTime() - 3600000 < Date.now()) {
    return false;
  }

  if (server.getToken()) {
    if (server.getToken().token == ADMIN_TOKEN.token) {
      return true;
    }
  }
  server.setToken(ADMIN_TOKEN);
  return true;
}
function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

async function blobToDataURL(blob, callback) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(e) {
      const data = e.target.result;
      callback && callback(data);
      resolve(data);
    };
    reader.onerror = function(e) {
      reject(e);
    };
    reader.readAsDataURL(blob);
  });
}

function getBirthdayFromOcr(cardIndex, ocr) {
  let birthday;
  if (cardIndex === CONSTANTS.APP_CARD_INDEX.PASSPORT_KOREAN && !ocr.juminNo2) {
    // const juminNo2 = ocr.mrz2.substring(28, 35);
    // birthday = getBirthday(ocr.juminNo1, juminNo2);

    // Description :
    // (임시코드) 신여권 mrz2의 28 ~ 35 글자가 주민번호뒷자리가 아님
    // 1900년생인지 2000생인지 구별할수 없어 임시 방어 코드 추가
    // 100살 넘는사람이 오면 오류남
    const currentYY = Number(String(new Date().getFullYear()).substr(2, 2));
    const userYY = Number(ocr.juminNo1.substr(0, 2));
    birthday = userYY <= currentYY ? '20' + ocr.juminNo1 : '19' + ocr.juminNo1;
  } else if (ocr.birthDate) {
    birthday = ocr.birthDate;
  } else if (ocr.issueNo1) {
    birthday = getBirthday(ocr.issueNo1, ocr.issueNo2);
  } else if (ocr.juminNo1) {
    birthday = getBirthday(ocr.juminNo1, ocr.juminNo2);
  } else {
    return birthday;
  }

  return `${birthday.substring(0, 4)}-${birthday.substring(
    4,
    6
  )}-${birthday.substring(6, 8)}`;
}

function getBirthday(juminNo1, juminNo2) {
  const number = parseInt(juminNo2.slice(0, 1));
  if (number === 0) {
    // 1800년대 출생여(2)
    return '18' + juminNo1;
  } else if (number === 9) {
    // 1800년대 출생남(1)
    return '18' + juminNo1;
  } else if (number < 3) {
    // 1900년대 출생남(1)/여(2)
    return '19' + juminNo1;
  } else if (number < 5) {
    // 2000년대 출생남(3)/여(4)
    return '20' + juminNo1;
  } else if (number < 7) {
    // 1900년대 출생남(5)/여(6) - 외국인
    return '19' + juminNo1;
  } else if (number < 9) {
    // 2000년대 출생남(7)/여(8) - 외국인
    return '20' + juminNo1;
  } else {
    // 샘플케이스의 경우 발생될 수 있어 예외 처리성격으로 넣어 둠
    return '19' + juminNo1;
  }
}

function makeTestOcrData(_idType) {
  const idType1 = _idType.split('-')[0];
  const idType2 = _idType.split('-')[1];
  if (idType1 == CONSTANTS.CARD_IDTYPE.JUMIN) {
    return {
      idType: 1,
      userName: '홍길동',
      juminNo1: '800123',
      juminNo2: '1111111',
      _juminNo2: '1******',
      issueDate: '20120423',
    };
  } else if (idType1 == CONSTANTS.CARD_IDTYPE.DRIVER) {
    return {
      idType: 2,
      userName: '홍길동',
      driverNo: '경기-04-057303-30',
      juminNo1: '800123',
      juminNo2: '1111111',
      _juminNo2: '1******',
      issueDate: '20120423',
    };
  } else if (idType1 == CONSTANTS.CARD_IDTYPE.PASSPORT_KOR) {
    return {
      idType: 3,
      userName: '홍길동',
      userNameEng: 'KSCHOI',
      passportNo: 'KM180273182',
      issueNo1: '800123',
      issueNo2: '1111111',
      _juminNo2: '1******',
      issueDate: '20120423',
      expiryDate: '20300123',
    };
  } else if (idType1 == CONSTANTS.CARD_IDTYPE.PASSPORT_ALIEN) {
    return {
      idType: 4,
      userName: 'GILDONG HONG',
      passportNo: 'KM180273182',
      nationality: 'KOR',
      gneder: 'M',
      birthDate: '19800123',
    };
  } else if (idType2 == CONSTANTS.APP_CARD_INDEX.ALIEN_REGI) {
    return {
      idType: '5-1',
      country: 'KOREA',
      visa: 'D-4',
      userName: 'GILDONG HONG',
      issueNo: '800123-1111111',
      issueNo1: '800123',
      issueNo2: '1111111',
      _juminNo2: '1******',
      issueDate: '20120423',
    };
  } else if (idType2 == CONSTANTS.APP_CARD_INDEX.ALIEN_RESI) {
    return {
      idType: '5-2',
      country: 'KOREA',
      visa: 'D-4',
      userName: 'GILDONG HONG',
      issueNo: '800123-1111111',
      issueNo1: '800123',
      issueNo2: '1111111',
      _issueNo2: '1******',
      issueDate: '20120423',
    };
  } else if (idType2 == CONSTANTS.APP_CARD_INDEX.ALIEN_PERM) {
    return {
      idType: '5-3',
      country: 'KOREA',
      visa: 'D-4',
      userName: 'GILDONG HONG',
      issueNo: '800123-1111111',
      issueNo1: '800123',
      issueNo2: '1111111',
      _juminNo2: '1******',
      issueDate: '20120423',
    };
  }
}
export default {
  dataURItoBlob,
  blobToDataURL,
  getBirthdayFromOcr,
  getBirthday,
  makeTestOcrData,
  verifyRootToken,
  verifyReviewerToken,
  verifyAdminToken,
};
