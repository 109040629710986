<template>
  <div class="view-container">
    <div class="content-container">
      <div class="required-info-container">
        <div class="required-info-area-left">
          <div class="required-info-title">정보입력 관리</div>
        </div>
      </div>
      <div class="required-info-content-container" style="height: 72px">
        <div>
          <div style="margin-left: 74px; width: 195px; letter-spacing: -0.5px">
            필수 정보 입력 화면 사용 여부
          </div>

          <div
            style="
              margin-left: 103px;
              width: 89px;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div :style="{ width: '45px' }">
              {{
                $store.state.admin.modifiedInputInfoManagement.activation
                  ? '사용'
                  : '미사용'
              }}
            </div>

            <ToggleButton
              :initialData="
                $store.state.admin.modifiedInputInfoManagement.activation
              "
              :key="$store.state.admin.originalInputInfoManagement.activation"
              @input="activationControl($event)"
            />
          </div>
        </div>
        <section class="button-container" style="margin-right: 74px">
          <button disabled v-if="isModified" class="default">
            {{ '변경' }}
          </button>
          <button
            v-if="!isModified"
            class="submit"
            v-ripple
            @click="sendChange"
          >
            {{ '변경' }}
          </button>
        </section>
      </div>
      <div class="required-info-list-container" style="height: 168px">
        <div
          :style="
            $store.state.admin.originalInputInfoManagement.activation
              ? {}
              : { color: 'var(--surface-disabled)' }
          "
        >
          <div style="width: 195px">사용자 필수 정보</div>
          <div style="width: 120px; margin-left: 103px; line-height: 30px">
            <div>· 이름</div>
            <div>· 생년월일</div>
            <div>· 전화번호</div>
            <div>· 이메일</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/SliderToggle.vue';
import { mapState } from 'vuex';
import server from '../../api/server';

export default {
  name: 'ReviewerManagement',
  components: {
    ToggleButton,
  },
  data() {
    return {
      toastDialogFlag: false,
      toastMessage: '',
    };
  },
  async created() {
    await this.initActivationStatus();
  },
  computed: {
    ...mapState({
      isModified: state =>
        state.admin.modifiedInputInfoManagement.activation ==
        state.admin.originalInputInfoManagement.activation,
    }),
  },
  methods: {
    async initActivationStatus() {
      var initVar = await server.getConfigs();
      this.$log.debug('RequiredManagement#initActivationStatus', initVar);
      var initResult =
        initVar?.list?.filter(res => res.key == 'identification')[0]?.value ==
        true;
      this.$store.state.admin.originalInputInfoManagement.activation = initResult;
      this.$store.state.admin.modifiedInputInfoManagement.activation = initResult;
    },
    activationControl(event) {
      this.$store.state.admin.modifiedInputInfoManagement.activation = event;
    },
    async sendChange() {
      await server
        .setConfigs(
          this.$store.state.admin.modifiedInputInfoManagement.activation.toString()
        )
        .then(res => {
          if (res == true) {
            this.showSnackbar('사용 여부 변경이 완료되었습니다.');
            this.initActivationStatus();
          } else {
            this.initActivationStatus();
          }
        });
    },
    showSnackbar(eventMessage) {
      this.$store.dispatch('showRegistrationSnackbar', {
        message: eventMessage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.required-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 23px;
}
.required-info-content-container {
  min-width: 670px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  margin-bottom: 23px;
  display: flex;
}
.required-info-content-container > div,
.required-info-content-container > div > div {
  display: flex;
  flex-direction: row;
}
.required-info-list-container {
  min-width: 670px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: white;
  margin-bottom: 23px;
  display: flex;
}
.required-info-list-container > div {
  display: flex;
  flex-direction: row;
  margin: 24px 0 0 74px;
}
.required-info-area-left {
  display: flex;
  flex-grow: 1;
}
.required-info-area-right {
  display: flex;
}

.required-info-title {
  color: var(--primary-80);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.button-container {
  margin: 50px;
  display: flex;
  justify-content: center;
  .submit {
    border-radius: 8px;
    width: 144px;
    height: 48px;
    color: var(--surface-100);
    background: var(--primary-100);
  }
  .default {
    border-radius: 8px;
    width: 144px;
    height: 48px;
    color: var(--surface-medium);
    background: var(--gray-100);
  }
}
</style>
