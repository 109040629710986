<template>
  <label :class="{ 'toggle-button': !isMini, 'toggle-button-mini': isMini }">
    <input
      :disabled="disabled"
      type="checkbox"
      :value="initialData"
      @click.prevent="toggleChange($event)"
    />
    <span
      :class="{
        'on-off-dim-toggle': isDim && !isMini,
        'on-off-toggle': !isDim && !isMini,
        'on-off-dim-toggle-mini': isDim && isMini,
        'on-off-toggle-mini': !isDim && isMini,
        checked: initialData,
      }"
    ></span>
  </label>
</template>

<script>
//import Vue from "vue";
export default {
  name: 'ToggleButton',
  props: {
    initialData: Boolean,
    disabled: Boolean,
    isDim: {
      type: Boolean,
      default: () => false,
    },
    isMini: {
      type: Boolean,
      default: () => false,
    },
    toggleButtonStyle: {
      type: Object,
      default: () => {
        return {
          width: '32px',
          height: '16px',
        };
      },
    },
  },
  components: {},
  computed: {},
  methods: {
    toggleChange(event) {
      if (this.isDim) return;
      this.$log.debug(event);
      this.$emit('input', !event.target.value.includes('true'), this.id);
    },
  },
};
</script>

<style scoped>
.toggle-button {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
}
.toggle-button-mini {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 12px;
}
.toggle-button input,
.toggle-button-mini input {
  opacity: 0;
  width: 0;
  height: 0;
}
.on-off-toggle,
.on-off-dim-toggle,
.on-off-toggle-mini,
.on-off-dim-toggle-mini {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: var(--surface-disabled);
  transition: 0.4s;
}
.on-off-toggle:before,
.on-off-dim-toggle:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  top: 2px;
  background-color: var(--surface-100);
  transition: 0.4s;
  border-radius: 20px;
}
.on-off-toggle-mini:before,
.on-off-dim-toggle-mini:before {
  position: absolute;
  content: '';
  height: 9px;
  width: 9px;
  left: 1.5px;
  bottom: 1.5px;
  top: 1.5px;
  background-color: var(--surface-100);
  transition: 0.4s;
  border-radius: 20px;
}
.on-off-dim-toggle.checked,
.on-off-dim-toggle-mini.checked {
  background-color: var(--primary-40);
}
.on-off-toggle.checked,
.on-off-toggle-mini.checked {
  background-color: var(--primary-100);
}
.on-off-toggle.checked:before,
.on-off-dim-toggle.checked:before {
  transform: translateX(16px);
}
.on-off-toggle-mini.checked:before,
.on-off-dim-toggle-mini.checked:before {
  transform: translateX(12px);
}
</style>
