var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":""},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"padding":"0","margin":"0","height":"800px","position":"relative"}},[_c('header',{staticClass:"diag-title"},[_vm._v("정보 수정")]),_c('div',{staticClass:"add-body"},[_c('div',[_c('CustomInputTextForm',{attrs:{"error-form":_vm.clientCompNameRedundancyMsg.includes('입력하신'),"init-val":_vm.itemData.company,"validity":"comp-name-redundancy","title":"고객사명","placeholder":"고객사명을 입력하세요.","infoFontSmall":true,"needCheck":true},on:{"click":function($event){return _vm.checkRedundancy()},"set":function ($event) {
                _vm.clientCompName = $event.val;
                if (_vm.clientCompName == _vm.itemData.company) {
                  _vm.clientCompCheck = true;
                } else {
                  _vm.clientCompCheck = false;
                }
                _vm.clientCompNameRedundancyMsg = '';
              }}}),(_vm.clientCompNameRedundancyMsg)?_c('div',{style:(((_vm.clientCompNameRedundancyMsg.includes('확인')
                  ? 'color: var(--primary-100)'
                  : 'color: var(--error-100)') + ";font-size:12px;margin-top:8px;"))},[_vm._v(" "+_vm._s(_vm.clientCompNameRedundancyMsg)+" ")]):_vm._e(),_c('div',{staticStyle:{"height":"24px"}}),_c('CustomInputTextForm',{attrs:{"init-val":this.phone,"validity":"phone","title":"전화번호","infoFontSmall":true,"placeholder":"‘-’를 포함하여 입력하세요."},on:{"set":function ($event) {
                _vm.phone = $event.val;
                _vm.phoneCheck = $event.res;
              }}}),_c('div',{staticClass:"title-input"},[_vm._v("구분")]),(_vm.value)?_c('Dropdown',{staticStyle:{"align-self":"center","width":"100%","height":"56px"},attrs:{"items":_vm.kindList,"icon-size":"24px","icon-margin":"24px","pad":"0 24px","width":"100%","height":"56px","border":"1px solid var(--gray-300)"},on:{"change":function ($event) {
                this$1.selectedKind = $event;
              }},model:{value:(_vm.selectedKind),callback:function ($$v) {_vm.selectedKind=$$v},expression:"selectedKind"}}):_vm._e(),_c('div',{staticClass:"title-input"},[_vm._v("구매형태")]),_c('div',{staticStyle:{"display":"flex"}},[_c('button',{class:_vm.purchaseType === '솔루션'
                  ? 'client-generic-button-left--checked'
                  : 'client-generic-button-left',on:{"click":function($event){_vm.purchaseType = '솔루션'}}},[_vm._v(" 솔루션 ")]),_c('button',{class:_vm.purchaseType === 'API'
                  ? 'client-generic-button-right--checked'
                  : 'client-generic-button-right',on:{"click":function($event){_vm.purchaseType = 'API'}}},[_vm._v(" API ")])]),_c('div',{staticClass:"title-input"},[_vm._v("활성여부")]),_c('div',{staticStyle:{"display":"flex"}},[_c('button',{class:_vm.isActivated === '활성'
                  ? 'client-generic-button-left--checked'
                  : 'client-generic-button-left',on:{"click":function($event){_vm.isActivated = '활성'}}},[_vm._v(" 활성 ")]),_c('button',{class:_vm.isActivated === '비활성'
                  ? 'client-generic-button-right--checked'
                  : 'client-generic-button-right',on:{"click":function($event){_vm.isActivated = '비활성'}}},[_vm._v(" 비활성 ")])]),_c('div',{staticStyle:{"margin-top":"8px","display":"flex","flex-direction":"row"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.finalCheck),expression:"finalCheck"}],staticClass:"activation-check",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.finalCheck)?_vm._i(_vm.finalCheck,null)>-1:(_vm.finalCheck)},on:{"change":function($event){var $$a=_vm.finalCheck,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.finalCheck=$$a.concat([$$v]))}else{$$i>-1&&(_vm.finalCheck=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.finalCheck=$$c}}}}),_c('div',{staticStyle:{"font-size":"12px","color":"var(--primary-100)"}},[_vm._v(" 활성여부를 전활할 경우 서비스에 바로 반영됩니다. ")])])],1)]),_c('div',{staticStyle:{"border-top":"1px solid var(--gray-100)"}},[_c('div',{staticClass:"button-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button cancel",on:{"click":_vm.close}},[_vm._v("취소")]),(
              _vm.clientCompName != '' &&
                _vm.clientCompCheck &&
                _vm.purchaseType != '' &&
                _vm.selectedKind != '' &&
                _vm.selectedKind != '선택' &&
                _vm.isActivated != '' &&
                _vm.finalCheck == true &&
                _vm.phoneCheck
            )?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"button ok",on:{"click":_vm.modify}},[_vm._v(" 등록 ")]):_c('div',{staticClass:"button default",staticStyle:{"cursor":"unset"}},[_vm._v("등록")])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }