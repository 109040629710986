<template>
  <div class="card-result-fail-root">
    <div class="card-result-fail-info-container">
      <img
        src="@/assets/얼굴_실패.svg"
        width="103px"
        style="margin-bottom: 40px; margin-left: 10px"
      />
      <div
        style="
          font-weight: bold;
          font-size: 28px;
          line-height: 36px;
          color: var(--primary-100);
          margin-bottom: 24px;
        "
      >
        일시적 오류 발생
      </div>
      <div
        style="
          font-weight: normal;
          text-align: center;
          font-size: 16px;
          line-height: 26px;
          color: var(--surface-high);
          margin-bottom: 16px;
        "
      >
        잘못된 경로 또는 비정상적 접근이 감지되어<br />
        고객 신원 확인을 종료합니다.<br />
        잠시 후 다시 시도해주세요.
      </div>
      <div
        style="
          font-weight: normal;
          text-align: center;
          font-size: 14px;
          line-height: 21px;
          color: var(--surface-medium);
        "
      >
        동일한 문제가 지속적으로 발생한다면<br />
        아래 메일로 문의주시기 바랍니다.<br />
        <a href="mailto:cs@useb.co.kr" style="color: var(--surface-medium)"
          >cs@sample.com</a
        >
      </div>
    </div>
    <div class="button-container" @click="closeApp()">확인</div>
  </div>
</template>

<script>
export default {
  name: 'Page404',
  methods: {
    closeApp() {
      this.sendResult('{"result": "close"}');
      this.appDestroy();
    },
    sendResult(result) {
      this.$log.debug('404#sendResult', result);
      const returnMessage = btoa(encodeURIComponent(result));
      if (window.parent) {
        // Browser iframe
        window.parent.postMessage(returnMessage, this.origin);
      }

      if (window.ReactNativeWebView) {
        // react-native webview
        window.ReactNativeWebView.postMessage(returnMessage);
      }

      if (window.webkit && window.webkit.messageHandlers) {
        // iOS: WKScriptMessageHandler WKScriptMessage name(alcherakyc)
        window.webkit.messageHandlers.alcherakyc &&
          window.webkit.messageHandlers.alcherakyc.postMessage(returnMessage);
      } else if (window['alcherakyc']) {
        // Android: WebView JavascriptInterface name(alcherakyc) and JS function(result)
        window['alcherakyc'] &&
          window['alcherakyc']['receive'] &&
          window['alcherakyc']['receive'](returnMessage);
      }
    },
    appDestroy() {
      // release mode에서 타이밍 이슈 발생. sendResult 요청보다 먼저 실행되는 경우가 발생되는 현상
      setTimeout(() => {
        window.close();
        window.open('', '_self', '').close();
        location.replace('about:blank');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result-fail-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
}

.card-result-fail-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 150px;
  margin-bottom: 56px;
}

.button-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  width: calc(100% - 60px);
  color: white;
  background-color: var(--primary-100);
  border-radius: 8px;
  height: 60px;
  margin: 0 30px 48px;
}
</style>
