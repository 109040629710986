<template>
  <div>
    <table v-if="contents.length != 0" class="table-container">
      <thead class="table-thead">
        <th
          class="table-heading"
          :class="{ 'table-heading-expand': title.sortable }"
          :width="`${title.width}%`"
          v-for="(title, index) in titles"
          :key="title.id"
        >
          <div @click="sort(title)">
            {{ titleNames[index] }}

            <img
              v-if="title.sortable"
              src="@/assets/table-expand.png"
              alt="expand"
              style="position:absolute;bottom:21px;margin-left:4px;"
            />
          </div>
        </th>
        <th width="15%" v-show="needDetail">상세보기</th>
      </thead>
      <tbody>
        <template v-for="content in contents">
          <tr
            style="height: 72px; line-height: 72px; text-align: center"
            :key="`${content.id}-0`"
          >
            <!-- (content의 id)/(title의 이름) 을 Key로 지정하여 Duplicate를 막는다. -->
            <td
              class="default"
              :title="content[title.id]"
              v-for="title in titles"
              :key="`${content.id}/${title.id}`"
            >
              <div v-if="!ellipsisList.includes('tnc-guidance')">
                <slot
                  v-if="title.width > 0"
                  :tid="title.id"
                  :c="content[title.id]"
                  :ctt="content"
                >
                  {{ tableContent(title.id, content[title.id]) }}
                </slot>
              </div>
              <slot
                v-else-if="title.width > 0"
                :tid="title.id"
                :c="content[title.id]"
                :ctt="content"
              >
                {{ tableContent(title.id, content[title.id]) }}
              </slot>
            </td>
            <td v-show="needDetail">
              <div>
                <Detail
                  :id="`${content.id}`"
                  :pica-pp="content.key"
                  :version="content.version"
                  :identifier-prefix="tableName"
                  @expand="expandedView($event)"
                />
              </div>
            </td>
            <td v-if="tableName === 'am' && !content.is_approve">
              <AcceptDenyBtnsColumn
                @accept="clickAccept(content)"
                @deny="clickDeny(content)"
              />
            </td>
          </tr>
          <!--  -->
          <tr
            :key="`${content.id}-1`"
            v-if="isOpened.includes(content.id)"
            style="border-bottom: 1px solid var(--surface-disabled)"
          ></tr>

          <tr
            v-show="needDetail"
            :id="`${content.id}`"
            :key="`${content.id}-2`"
          >
            <td :colspan="titles.length + 1" style="overflow: hidden">
              <transition name="expanded">
                <slot name="detail" :item="content" :open="isOpened">
                  <TncExpanded
                    v-if="
                      isOpened.includes(`${content.id}`) && tableName == 'tnc'
                    "
                    :text="tncListDetail[`${content.id}`]"
                  />
                  <PpExpanded
                    v-if="
                      isOpened.includes(`${content.id}`) && tableName == 'pp'
                    "
                    :text="ppListDetail[`${content.id}`]"
                  />
                  <PicaExpanded
                    v-if="
                      isOpened.includes(`${content.id}`) &&
                        tableName == 'pica' &&
                        picaPpDetail[`${content.id}`]
                    "
                    :text="picaPpDetail[`${content.id}`]"
                    :idKey="content.key"
                    :identificationInfo="`${content.id}`"
                    @remove="$emit('picaPpRemove', $event)"
                    @edit="$emit('picaPpEdit', $event)"
                  />
                  <PpgExpanded
                    v-if="
                      isOpened.includes(`${content.id}`) &&
                        tableName == 'ppg' &&
                        picaPpDetail[`${content.id}`]
                    "
                    :text="picaPpDetail[`${content.id}`]"
                    :idKey="content.key"
                    :identificationInfo="`${content.id}`"
                    @remove="$emit('picaPpRemove', $event)"
                    @edit="$emit('picaPpEdit', $event)"
                  />
                  <TncGuidanceExpanded
                    v-if="
                      isOpened.includes(`${content.id}`) &&
                        tableName == 'tnc-guidance'
                    "
                    :text="tncGuidanceListDetail[`${content.id}`]"
                    :identificationInfo="`${content.id}`"
                    @remove="$emit('ppgRemove', $event)"
                    @edit="$emit('ppgEdit', $event)"
                  />
                </slot>
              </transition>
            </td>
          </tr>
          <tr
            style="border-bottom: 4px solid var(--primary-5)"
            :key="`${content.id}-3`"
          ></tr>
        </template>
      </tbody>
    </table>

    <table v-else class="table-container">
      <th
        class="table-heading"
        :width="`${title.width}%`"
        v-for="(title, index) in titles"
        :key="title.id"
      >
        <div @click="sort(title.id)">{{ titleNames[index] }}</div>
      </th>
      <th width="15%" v-show="needDetail">상세보기</th>

      <tr style="border-top: 1px solid var(--primary-80)"></tr>
      <td :colspan="titles.length + 1">
        <slot name="no-data">
          <div class="no-data" style="max-width:100%;">
            <img
              src="@/assets/icon-no-data.svg"
              width="144px"
              style="margin-bottom: 24px"
            />
            <div v-for="noDataMessage in noDataMessages" :key="noDataMessage">
              {{ noDataMessage }}
            </div>
          </div>
        </slot>
      </td>
    </table>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import Detail from '@/components/DetailToggle.vue';
import TncExpanded from '@/components/root/table/TncTable.vue';
import PpExpanded from '@/components/root/table/TncTable.vue';
import PpgExpanded from '@/components/root/table/PpgTable.vue';
import PicaExpanded from '@/components/root/table/PicaTable.vue';
import AcceptDenyBtnsColumn from '@/components/root/table/AcceptDenyBtnsColumn.vue';
import TncGuidanceExpanded from '@/components/admin/table/TncGuidanceTable.vue';
import server from '../api/server';
import collision from '@/store/mixins/collision.js';
export default {
  name: 'Table',
  mixins: [collision],
  components: {
    Detail,
    PpExpanded,
    TncExpanded,
    PicaExpanded,
    PpgExpanded,
    AcceptDenyBtnsColumn,
    TncGuidanceExpanded,
  },
  props: {
    titles: {
      type: Array,
      default: () => [],
    },
    titleNames: {
      type: Array,
      default: () => [],
    },
    contents: {
      type: Array,
      default: () => [],
    },
    needDetail: {
      type: Boolean,
      default: () => true,
    },
    whichTypeOfDetail: {
      type: String,
      default: () => '',
    },
    tableName: {
      type: String,
      default: () => '',
    },
    noDataMessages: {
      type: Array,
      default: () => ['존재하지 않습니다.'],
    },
  },
  mounted() {
    this.otherComponent = document.querySelector('#pagenation-container');
    this.isCollide();
  },
  updated() {},
  data() {
    return {
      isOpened: [],
      ident: null,
      ellipsisList: ['tnc-guidance'],
    };
  },
  computed: {
    ...mapState({
      tncListDetail: state => state.root.tncListDetail,
      ppListDetail: state => state.root.ppListDetail,
      tncGuidanceListDetail: state => state.admin.tncGuidanceListDetail,
      picaListDetail: state => state.root.picaListDetail,
      ppgListDetail: state => state.root.ppgListDetail,
      picaPpDetail: state => state.root.picaPpDetail,
      adminList: state => state.root.adminList,
    }),
    additionalButtonStyle() {
      return {
        'background-color': this.bgColor,
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  methods: {
    sort(title) {
      if (title.sortable) {
        this.$emit('sort', title);
        Vue.$log.debug(title.id);
      }
    },
    tableContent(tid, content) {
      switch (tid) {
        case 'created_at':
          return this.toStringByFormatting(new Date(content));
        default:
          return content;
      }
    },
    // phoneNumberFormatter(source, delimiter = '-') {
    //   const start = source.substr(0, 3);
    //   const mid = source.substr(3, 4);
    //   const end = source.substr(7, 4);
    //   return [start, mid, end].join(delimiter);
    // },
    toStringByFormatting(source, delimiter = '-') {
      const year = source.getFullYear();
      const month = this.leftPad(source.getMonth() + 1);
      const day = this.leftPad(source.getDate());
      return [year, month, day].join(delimiter);
    },
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`;
    },
    async expandedView(payload) {
      Vue.$log.debug('Table#expandedView', payload, this.tableName);
      payload.id = String(payload.id);
      if (payload.value) {
        if (!this.isOpened.includes(payload.id)) {
          this.isOpened.push(payload.id);
        } else {
          this.isOpened = [...this.isOpened];
        }
      } else this.isOpened.splice(this.isOpened.indexOf(payload.id), 1);
      Vue.$log.debug('Table#expandedView~isOpened', this.isOpened);
      switch (this.tableName) {
        case `tnc`:
          await this.$store.dispatch('getNoticeDetail', {
            kind: 'tnc',
            id: payload.version,
          });
          break;
        case `pp`:
          await this.$store.dispatch('getNoticeDetail', {
            kind: 'pp',
            id: payload.version,
          });
          break;
        case `pica`:
        case `ppg`:
          this.$log.debug('Table#expandedView#picappg', payload);
          await this.$store.dispatch('getPicaPpDetail', { key: payload.key });
          break;
        case `tnc-guidance`:
          var preventImmutable = {
            ...this.$store.state.admin.tncGuidanceListDetail,
          };
          preventImmutable[payload.id] = {};
          var pica = await server.readPicaPpDetail(`guide-pa-${payload.id}`);
          var ppg = await server.readPicaPpDetail(`guide-pp-${payload.id}`);
          preventImmutable[payload.id]['pica'] = decodeURIComponent(
            atob(pica.content)
          );
          preventImmutable[payload.id]['ppg'] = decodeURIComponent(
            atob(ppg.content)
          );
          this.$store.state.admin.tncGuidanceListDetail = preventImmutable;
          this.$log.debug(
            'Table#expandedView~tnc-guidance',
            this.$store.state.admin.tncGuidanceListDetail
          );
          break;
        case `ep`:
          this.ident = this.$emit('getDetail', [
            payload.id,
            this.isOpened,
            payload.value,
          ]);
          break;
        case `ec`:
          //add payload.value for remove unnecessary api call
          this.$emit('getDetail', [payload.id, this.isOpened, payload.value]);
          break;
        case `cm`:
          this.$log.debug('Table#expandedView~cm', payload.id, this.isOpened);
          this.$emit('getDetail', [payload.id, this.isOpened]);
          break;
        default:
          break;
      }
    },
    clickAccept(item) {
      this.$emit('accept', item);
    },
    clickDeny(item) {
      this.$emit('deny', item);
    },
  },
};
</script>

<style scoped>
th {
  font-size: 14px;
  line-height: 21px;
  font-weight: normal;
  /* border-bottom: 1px solid var(--primary-80); */
  color: var(--surface-medium);
}
.table-container {
  width: 100%;
  background: var(--surface-100);
  border-collapse: collapse;
}
.table-thead {
  border-bottom: 1px solid var(--primary-80);
}
.table-heading {
  height: 48px;
  line-height: 48px;
  position: relative;
}
.table-heading-expand {
  cursor: pointer;
}
.table-heading img {
  vertical-align: middle;
}
.expanded-enter-active,
.expanded-leave-active {
  transition: max-height 1s;
  -webkit-transition: max-height 1s;
  max-height: 100vh;
}
.expanded-enter,
.expanded-leave-to {
  max-height: 0;
}
.add-button {
  color: white;
  border-radius: 10px;
  margin-left: 10px;
  font-weight: normal;
  font-style: normal;
  align-self: end;
}
.no-data {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

td.default > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  margin: 0 auto;
}
td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  margin: 0 auto;
}
</style>
