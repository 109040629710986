<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    @click:outside="() => {}"
    @keydown.esc="() => {}"
    persistent
  >
    <v-card>
      <v-card-text style="height: 290px">
        <div>
          <div
            style="
              padding-top: 40px;
              font-weight: bold;
              font-size: 24px;
              line-height: 36px;
              color: var(--surface-high);
              display: flex;
              justify-content: center;
            "
          >
            약관 삭제
          </div>
          <div
            style="
              margin: 24px 0 32px 0;
              font-size: 16px;
              line-height: 24px;
              color: var(--surface-high);
              text-align: center;
            "
          >
            <div style="margin-bottom: 10px">
              {{ ppKey }}
            </div>

            <div>해당 약관 항목을 삭제하시겠습니까?</div>
          </div>
          <div class="button-container" style="margin-bottom: 32px">
            <div @click="close" class="button cancel">취소</div>
            <div @click="remove" class="button remove">삭제</div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    ppKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('input', false);
    },
    remove() {
      this.$emit('remove');
      this.$store.dispatch('removePicaPp', { key: this.ppKey }).then(res => {
        if (res.api_response.result_code === 'N100') {
          this.$store.dispatch('showRegistrationSnackbar', {
            message: `약관이 삭제되었습니다.`,
          });
          this.$store.dispatch('getPicaPpList');
        }
      });
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  margin-bottom: 39px;
  padding: 0 30px;

  .button,
  button:focus {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    height: 60px;
    cursor: pointer;
    user-select: none;

    &.cancel {
      flex-grow: 0.65;
      background: var(--gray-100);
      color: var(--surface-medium);
      margin-right: 10px;
    }

    &.remove {
      flex-grow: 1;
      color: var(--surface-100);
      background: var(--error-100);
    }
  }
}
</style>
