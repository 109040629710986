<template>
  <div style="display: flex">
    <input
      :class="{
        'admin-input-form': true,
      }"
      placeholder="고객사명을 입력하세요."
      style="margin-right: -48px"
      :value="clientCompName"
      @input="debounceSearch($event)"
    />
    <button style="padding-left: -48px">
      <img src="@/assets/search-icon.svg" width="24px" style="margin: 16px 0" />
    </button>
    <div
      v-show="
        !isChangingNow && clientCompName.length > 0 && itemList.length == 0
      "
      class="dropdown-list"
      style="padding:8px 24px; "
    >
      <div style="display:flex; justify-content:start">
        검색어와 일치하는 항목이 없습니다.
      </div>
    </div>
    <div
      v-show="!isChangingNow"
      :hidden="!(clientCompIsOnSelect && itemList.length > 0)"
      class="dropdown-list"
    >
      <div
        v-for="item in itemList"
        :key="item.id"
        class="dropdown-item"
        @click="
          clientCompName = item.name;
          clientCompId = item.id;
          clientCompIsOnSelect = false;
          $emit('compInfo', {
            id: clientCompId,
            name: clientCompName,
            onSelect: clientCompIsOnSelect,
            item: itemList,
          });
        "
      >
        <span>{{
          item.name.substr(0, item.name.search(searchExpression()))
        }}</span>
        <span style="color: blue">{{
          item.name.substr(
            item.name.search(searchExpression()),
            clientCompName.length
          )
        }}</span
        ><span>{{
          item.name.substr(
            item.name.search(searchExpression()) + clientCompName.length,
            item.name.length
          )
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clientDefault: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      clientCompId: null,
      clientCompName: this.clientDefault,
      clientCompIsOnSelect: false,
      itemList: [],
      isChangingNow: false,
    };
  },
  computed: {},
  methods: {
    async getCompNameList(str) {
      var compNameList = await this.$store.dispatch('getClientListForSearch', {
        start: 0,
        name: str,
      });
      this.$log.debug('compNameList#getCompNameList', compNameList);
      if (compNameList) {
        this.itemList = compNameList;
      } else {
        this.itemList = [];
      }
      this.isChangingNow = false;
    },
    searchExpression() {
      let tempExp = this.clientCompName;
      return tempExp.replaceAll(
        /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, //eslint-disable-line
        '\\$&'
      );
    },
    async debounceSearch(event) {
      this.isChangingNow = true;
      this.clientCompName = event.target.value;
      this.clientCompIsOnSelect = true;
      if (this.clientCompName?.length !== 0) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.getCompNameList(this.clientCompName);
        }, 300);
      } else {
        clearTimeout(this.debounce);
        this.itemList = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.admin-input-form {
  height: 56px;
  width: 100%;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  padding: 0 24px;
  font-size: 16px;
  caret-color: var(--primary-80);
  &--redundancy {
    width: 27%;
    margin-left: 3%;
    color: var(--surface-100);
    background: var(--surface-medium);
    border-radius: 8px;
  }
  &--fail {
    height: 56px;
    width: 70%;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
    border: 1px solid red;

    caret-color: red;
  }
}
.admin-input-form:focus {
  outline: 1px solid var(--primary-80);
  box-shadow: 0px 0px 8px rgba(95, 101, 255, 0.1),
    0px 2px 8px 4px rgba(95, 101, 255, 0.05);
}
.dropdown-list {
  position: absolute;
  outline: 1px solid var(--primary-80);
  width: inherit;
  max-height: 110px;
  margin-top: 56px;
  overflow-y: auto;
  background: var(--surface-100);
  border-radius: 8px;
}
.dropdown-item:hover,
.dropdown-item {
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 24px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #ebecff;
}
.dropdown-item:hover:nth-child(1),
.dropdown-item:nth-child(1) {
  padding: 8px 24px 4px 24px;
}
.dropdown-item:hover:last-child,
.dropdown-item:last-child {
  padding: 4px 24px 8px 24px;
}
.dropdown-item:hover:only-child,
.dropdown-item:only-child {
  padding: 8px 24px 8px 24px;
}
</style>
