<template>
  <div>
    <div class="signup-row signup-tnc-title">
      <div style="cursor: pointer" @click="clickAll()">
        <img
          v-if="paCheck"
          src="@/assets/complete_24_checked.svg"
          alt="checked"
        />
        <img v-else src="@/assets/complete_24_unchecked.svg" alt="unchecked" />
      </div>
      <div style="width: 10px" />
      <div>모두 동의합니다.</div>
    </div>

    <div style="height: 32px" />

    <div class="signup-row">
      <div
        class="flex-center"
        style="cursor: pointer"
        @click="paCheck = !paCheck"
      >
        <img v-if="paCheck" src="@/assets/check_20_checked.svg" alt="checked" />
        <img v-else src="@/assets/check_20_unchecked.svg" alt="unchecked" />
      </div>

      <div style="width: 8px" />

      <div style="width: 368px">{{paTitle}}</div>
      <div>
        <label for="pa">
          <input
            id="pa"
            type="checkbox"
            style="display: none"
            v-model="paDetail"/><img
            class="detail-toggle"
            src="@/assets/open_24_grey.svg"
            alt="open"
        /></label>
      </div>
    </div>
    <transition name="fade">
      <div v-if="paDetail" class="flex-center">
        <div class="signup-tnc-detail">
          <p class="signup-tnc-paragraph" v-html="paContent"></p>
        </div>
      </div>
    </transition>

    <div style="height: 32px" />

    <div class="signup-row">
      <SubmitButton
        width="40%"
        height="60px"
        font-size="20px"
        button-string="동의 안 함"
        bg-color="#F6F6F6"
        text-color="var(--surface-medium)"
        @click="$emit('cancel')"
      />
      <div style="width: 10px" />
      <SubmitButton
        :dim="!tncNext"
        width="60%"
        height="60px"
        font-size="20px"
        button-string="동의"
        bg-dim-color="var(--primary-20)"
        bg-color="var(--primary-100)"
        text-dim-color="var(--surface-100)"
        text-color="var(--surface-100)"
        @click="$emit('next')"
      />
    </div>
  </div>
</template>
<script>
import SubmitButton from '@/components/SubmitButton.vue';
import server from '../../api/server';
export default {
  name: 'SignUp',
  components: {
    SubmitButton,
  },
  data() {
    return {
      paCheck: false,
      paDetail: false,
      paTitle: '',
      paContent: '',
    };
  },
  async created() {
    const paData = await server.readPrivacyAgreement();
    this.$log.debug(paData);
    this.paTitle = paData.agreement_1.title;
    this.paContent = paData.agreement_1.content;
  },
  computed: {
    tncNext() {
      return this.paCheck;
    },
  },
  methods: {
    clickAll() {
      if (this.paCheck) {
        this.paCheck = false;
      } else {
        this.paCheck = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#pa ~ .detail-toggle,
#pat ~ .detail-toggle {
  transition: 0.4s;
  transform: rotate(0deg);
}
// #pat ~ .detail-toggle {
//   transition: 0.4s;
//   transform: rotate(0deg);
// }
#pa:checked ~ .detail-toggle,
#pat:checked ~ .detail-toggle {
  transition: 0.4s;
  transform: rotate(180deg);
}
.fade-enter-active,
.fade-leave-active {
  max-height: 196px;
  overflow: hidden;
  transition: max-height 1.5s cubic-bezier(0, 1, 0, 1);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  max-height: 0px;
  overflow: hidden;
}
// #pat:checked ~ .detail-toggle {
//   transition: 0.4s;
//   transform: rotate(180deg);
// }
</style>
